import {Action,ActionType} from "../../model/actionTypes"
import {PayPlan} from "../../model/rtp1"
import createReducer from "./createReducer"
import {Alert} from "../../model";




export const rtp1 = createReducer({},{
    [ActionType.LOAD_PAY_PLAN_SUCCESS](state:PayPlan,
                                          action:Action<PayPlan>){
        return Object.assign({...state}, action.payload);},
})