import React, {useEffect,useState} from "react";
import {Modal, Icon,Loader,Dimmer,Table} from "semantic-ui-react";
import {useActions} from "../../redux/actions";
import {formatMinutes} from "../../common";
import * as openIssueActions from "../../redux/actions/openIssues";

const moment = require('moment');

interface openIssuesModalProps {
    application: string,
    hideOpenIssuesModal: any
}

const OpenIssuesModal:React.FC<any>=(props:openIssuesModalProps)=>{

    const openIssuesActions = useActions(openIssueActions);
    const [loader , setLoader] =useState(false);
    const [openIssues , setOpenIssues]= useState<any>([]);

    useEffect(()=>{
        fetchOpenIssues();
    },[]);

    const fetchOpenIssues = async () => {
        setLoader(true);

        openIssuesActions.fetchOpenIssues(props.application)
        .then((res:any)=>{
            setLoader(false);
            setOpenIssues(res);
        });
    }

    return(
        <Modal open={true}
            onClose={props.hideOpenIssuesModal}
            centered={false}
            size='large'
            closeIcon>
                { loader && <Dimmer active={true}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                }
                <Modal.Content>
                    <h3 className='text-center mb20'>App Status Tracker</h3>
                        <div className="table-responsive FreezeTable">
                        <Table sortable striped size='small' unstackable className="customTable">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell content="Application"/>
                                    <Table.HeaderCell content="Down Time Start"/>
                                    <Table.HeaderCell content="What Is Down"/>
                                    <Table.HeaderCell content="What to do in mean time"/>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    openIssues.length > 0 ?
                                    openIssues.map((item, key) => 
                                        <Table.Row key={'data_' + key}>
                                            {
                                                <Table.Cell>{item.application}</Table.Cell>
                                            }
                                            {
                                                <Table.Cell>{moment(item.downTimeStart).format('D MMMM YYYY HH:mm')}</Table.Cell>
                                            }
                                            {
                                                <Table.Cell>{item.whatIsDown}</Table.Cell>
                                            }
                                            {
                                                <Table.Cell>{item.whatToDoMeanTime}</Table.Cell>
                                            }
                                        </Table.Row>

                                    ) :
                                    <Table.Row>
                                        <Table.Cell colSpan={13} className="text-center" content="No Data"/>
                                    </Table.Row>
                                }
                            </Table.Body>
                        </Table>
                   </div>
                </Modal.Content>
                
        </Modal>)
}

export default OpenIssuesModal