import {ActionType} from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const GetLastFetched =
    () => async (dispatch: Function, getState: Function, api: any) => {
        const url = `Claims/gettimestamp/LastFetched`;
        dispatch({type: ActionType.LOADER, payload: true});
        try {
            const resp = await api.get(url);
            dispatch({
                type: ActionType.LOAD_LAST_FETCHED,
                payload: {lastFetched: resp},
            });
            dispatch({type: ActionType.LOADER, payload: false});
            return resp;
        } catch (err) {
            dispatch({type: ActionType.LOADER, payload: false});
            return dispatch(errorHandler(err));
        }
    }

export const FetchMemberStatus = (memberStatusGet: any) => async (dispatch: Function, getState: Function, api: any) => {
            const params = JSON.stringify(memberStatusGet)
            const url = `Claims/getmemberstatus/${memberStatusGet.identifier}/${params}`;
            dispatch({type: ActionType.LOADER, payload: true});
            try {
                const resp = await api.get(url);
                dispatch({
                    type: ActionType.LOAD_MEMBER_STATUS,
                    payload: {memberStatus: resp},
                });
                dispatch({type: ActionType.LOADER, payload: false});
                return resp;
            } catch (err) {
                dispatch({type: ActionType.LOADER, payload: false});
                return dispatch(errorHandler(err));
            }
        };

export const FetchMemberStatusByDentaXStatus = (memberStatusGet: any) => async (dispatch: Function, getState: Function, api: any) => {
    const params = encodeURIComponent(JSON.stringify(memberStatusGet))
    const url = `Claims/getMemberStatusByDentalXStatus/${params}`;
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const resp = await api.get(url);
        dispatch({type: ActionType.LOADER, payload: false});
        return resp;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
};
export const FetchStatusCount = (obj: any) => async (dispatch: Function, getState: Function, api: any) => {
    const params = JSON.stringify(obj)
    const url = `Claims/getClaimsStatusCount/${params}`;
    try {
        const resp = await api.get(url);
        dispatch({
            type: ActionType.LOAD_MEMBER_STATUS,
            payload: {memberStatusCount: resp},
        });
    } catch (error) {
        return dispatch(errorHandler(error));
    }
}

export const FetchDentalXchangeReport = (obj: any) => async (dispatch: Function, getState: Function, api: any) => {
    const params = JSON.stringify(obj)
    const url = `Claims/dentalXchange/${params}`;
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const resp = await api.get(url);
        dispatch({
            type: ActionType.LOAD_MEMBER_STATUS,
            payload: {dentalXchange: resp},
        });
        dispatch({type: ActionType.LOADER, payload: false});
    } catch (error) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(error));
    }
}

export const sendEmailWithAttachment = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        await api.post(`report/sendEmailAttachment`, params);
        dispatch({ type: ActionType.LOADER, payload: false });
        return true;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const ClearClaimStatus =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        const url = `Claims/clearClaimStatus`;
        dispatch({type: ActionType.LOADER, payload: true});
        try {
            const resp = await api.delete(url, data);
            dispatch({
                type: ActionType.CLEAR_CLAIM_STATUS,
                payload: {success: resp},
            });
            dispatch({type: ActionType.LOADER, payload: false});
        } catch (error) {
            dispatch({type: ActionType.LOADER, payload: false});
            return dispatch(errorHandler(error));
        }
    };

export const SubmitNotes =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        const url = `Claims/saveNotes`;
        dispatch({type: ActionType.LOADER, payload: true});
        try {
            const resp = await api.post(url, {...data});
            dispatch({type: ActionType.SAVE_NOTES, payload: {success: resp}});
            dispatch({type: ActionType.LOADER, payload: false});
        } catch (error) {
            dispatch({type: ActionType.LOADER, payload: false});
            return dispatch(errorHandler(error));
        }
    };

export const GetClaimsStatus = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
    const url = `Claims/getClaimsStatus/` + params;
    dispatch({type: ActionType.LOADER, payload: true})
    try {
        const resp = await api.get(url);
        dispatch({type: ActionType.LOADER, payload: false});
        return resp;
    } catch (error) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(error));
    }
}

export const GetEmdeonClaimsStatus = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
    const url = `Claims/getEmdeonClaimsStatus/` + params;
    dispatch({type: ActionType.LOADER, payload: true})
    try {
        const resp = await api.get(url);
        dispatch({type: ActionType.LOADER, payload: false});
        return resp;
    } catch (error) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(error));
    }
}

export const GetOpenDentalData = () => async (dispatch: Function, getState: Function, api: any) => {
    const url = `Claims/getOpenDentalData`;
    dispatch({type: ActionType.LOADER, payload: true})
    try {
        const resp = await api.get(url);
        dispatch({type: ActionType.LOADER, payload: false});
        return resp;
    } catch (error) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(error));
    }
}

export const getProviderAccounts = () => async (dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });

  try {
    const response = await api.get(`Claims/providerAccounts`);
    dispatch({ type: ActionType.PROVIDER_ACCOUNTS, payload: response  });
    dispatch({ type: ActionType.LOADER, payload: false });
    return response;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    dispatch({ type: ActionType.PROVIDER_ACCOUNTS, payload: []  });
    return dispatch(errorHandler(err));
  }
};

export const GetDentalXChangeStatusLastScrapeTime = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        dispatch({ type: ActionType.LOADER, payload: true });
        const response = await api.get(`Claims/getLastRunTimeForScraper`);
        dispatch({ type: ActionType.LOADER, payload: false });
        return response;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}
