import { App } from "../../model";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";
const initialState:App = {
	leftSidebarOpen: false,
	loader: false,
	practiceList : [],
	regions : {}
}
export const app = createReducer(initialState, {
	[ActionType.SIDEBAR_OPEN](state: App, action: Action<App>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.LOADER](state: App, action: Action<App>) {
		return Object.assign({...state}, { loader: action.payload });
	},
	[ActionType.SAVE_USAGE](state: App, action: Action<App>) {
		return Object.assign({...state},  action.payload );
	},
	[ActionType.LOAD_PRACTICE_LIST](state: App, action: Action<App>) {
		return Object.assign({...state},  action.payload );
	},
	[ActionType.LOAD_REGION_LIST](state: App, action: Action<App>) {
		return Object.assign({...state},  action.payload );
	},

});
