import  React ,{useEffect, useState} from 'react'
import {HELP_PAGE} from '../../constants'
import HelpPageModal from './helpPageModal'
import OpenIssuesModal from './openIssuesModal'
import * as openIssuesActions from "../../redux/actions/openIssues";
import { useActions } from '../../redux/actions';

interface HelpMarkProps {
    helpPage?: HELP_PAGE,
    application?: string
}


const HelpMark:React.FC<HelpMarkProps>=(props)=>{

    const openIssuesAction = useActions(openIssuesActions);
    const [appStatusTrackerModal, setAppStatusTrackerModal] = useState(false)
    const [helpPageModal, setHelpModal] = useState(false)
    const [helpPageId, setHelpPageId] = useState(null)
    const [isThereOpenIssues, setIsThereOpenIssues] = useState(false);

    useEffect(() => {
      setTimeout(function () {
        checkOpenIssues();
      },1000)
    }, [])

  const openOpenIssuesModal = () => {
    setAppStatusTrackerModal(true);
  }

  const checkOpenIssues = async () => {

    if(props.application != undefined) {
      openIssuesAction.checkOpenIssues(props.application)
        .then((result) => {

          setIsThereOpenIssues(result);
        });
    }
  }

  const hideOpenIssuesModal = () => {
    setAppStatusTrackerModal(false);
  }

  const openHelpPage = (pageId) => {
      setHelpModal(true);
      setHelpPageId(pageId)

  };

  const hideHelpPageModal =()=>{
    setHelpModal(false);
  };


    return (
      <div className='helpMark'>
        { props.helpPage &&
          <span onClick={() => openHelpPage(props.helpPage)}><img className="cursorPointer" src="/images/help-icon.png" alt="Help"/></span>
        }
        { isThereOpenIssues &&
          <span onClick={() => openOpenIssuesModal()}><img className="cursorPointer" src="/images/downtime-icon.svg" alt="Downtime Icon"/></span>
        }
        { appStatusTrackerModal &&
          <OpenIssuesModal application={props.application} hideOpenIssuesModal={hideOpenIssuesModal} />
        }
        { helpPageModal &&
           <HelpPageModal hideHelpPageModal = {hideHelpPageModal} helpPage={props.helpPage} />
        }
      </div>
    );


}

export default HelpMark
