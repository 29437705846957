import React from "react";
import {Table} from "semantic-ui-react";
import {numberWithCommas} from "../constants";

const MemberCountSummary:React.FC<any>=(props)=>{
    const tableBody = () => {
        let tableData = [];
        let countTotal = 0, amountSumTotal = 0, paidAmountSumTotal = 0;
        const combinedData = [];
        const combinedRecord = {
            "status": null,
            "amountSum": 0,
            "paidAmountSum": 0,
            "count": 0
        };
        props.summary && props.summary.forEach((item: any) => {
            if (!item.status) {
                combinedRecord.amountSum += item.amountSum;
                combinedRecord.paidAmountSum += item.paidAmountSum || 0;
                combinedRecord.count += item.count;
            } else {
                combinedData.push(item);
            }
        });

        if (combinedRecord.count > 0) {
            combinedData.unshift(combinedRecord);
        }

        combinedData && combinedData.forEach((item:any) => {
            countTotal = countTotal+ parseFloat(item.count);
            amountSumTotal = amountSumTotal + (item.amountSum ? parseFloat(item.amountSum) : 0);
            paidAmountSumTotal = paidAmountSumTotal + (item.paidAmountSum ? parseFloat(item.paidAmountSum) : 0);
            tableData.push( <Table.Row>
                <Table.Cell className="clickable" onClick={()=>{props.handleStatusClick && props.handleStatusClick(item.status) }}>{item.status ? item.status : 'Null'}</Table.Cell>
                <Table.Cell>{item.count}</Table.Cell>
                <Table.Cell>{numberWithCommas(item.amountSum ? item.amountSum.toFixed(2):0)}</Table.Cell>
                <Table.Cell>{numberWithCommas(item.paidAmountSum ? item.paidAmountSum.toFixed(2):0)}</Table.Cell>
            </Table.Row>)
        })
        tableData.push( <Table.Row>
            <Table.Cell>{'Total'}</Table.Cell>
            <Table.Cell>{countTotal}</Table.Cell>
            <Table.Cell>{numberWithCommas(amountSumTotal ? amountSumTotal.toFixed(2):0)}</Table.Cell>
            <Table.Cell>{numberWithCommas(paidAmountSumTotal ? paidAmountSumTotal.toFixed(2):0)}</Table.Cell>
        </Table.Row>)
        return tableData;
    }
    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Count</Table.HeaderCell>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.HeaderCell>Amount Paid</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            {tableBody()}
        </Table>
    )
}

export default MemberCountSummary