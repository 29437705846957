import React from "react";
import { useState } from "react";
import {Modal, Table, Button, Checkbox} from "semantic-ui-react";
import moment from "moment/moment";
import _ from "lodash";
import {claims} from "../../../redux/reducers/claims";

interface Props {
    data: {claimRequestData?:object ,month?:string,productionData?:object};
    closePopup: () => void;
    sendData:Function;
}

const ClaimRequestPopup: React.FC<Props> = ({ data ,closePopup,sendData }) => {

    const [practices] = useState(Object.keys(data&&data.claimRequestData?data.claimRequestData:[]));
     const [claimReqState,setClaimReqState] = useState({
            claimRequestData:data.claimRequestData,
            productionData:data.productionData,
            selectedMonth:data.month,
            setLevelOne:true,
            setLevelTwo:false
        })
    const [ showHideData ,setCheckBoxes] = useState({isDollars:true , isTTL:true ,isGP:true})

    const renderClaimRequest = (monthSelected:string) => {
            const dataForMonth = {};
            for (const practice of practices) {
                if(claimReqState.claimRequestData[practice][monthSelected]){
                    dataForMonth[practice] = claimReqState.claimRequestData[practice][monthSelected];
                }
            }
            
            const claimRequestByCarrier: any = {};
            Object.values(dataForMonth).forEach((value: any) => {
                if (value&&Object.keys(value).length > 0) {
                Object.entries(value).forEach(([code, data]: any) => {
                    if (!claimRequestByCarrier[code] && code!=='total') {
                        claimRequestByCarrier[code] = {
                            
                        }
                        claimRequestByCarrier[code][code] = 0;
                    }
                    if (claimRequestByCarrier[code] && code!=='total')
                        claimRequestByCarrier[code][code]+=data
                    if(code=="total"){
                        if(!claimRequestByCarrier["total"]) {                       
                        claimRequestByCarrier[code] = {
                            total:0,
                        }
                        } 
    
                         claimRequestByCarrier[code]["total"]+=data
                   
                    }
                });
                
                }
            });
    
            //calculate total productiondata for month
            const totalProductionData = Object.keys(claimReqState.productionData)&&claimReqState.productionData[monthSelected]?claimReqState.productionData[monthSelected].production:0;
    
            Object.entries(claimRequestByCarrier).forEach(([cname, data]: [string,object]) => {
    
                    data["percentageOfTotal"] = ((data[cname] / claimRequestByCarrier.total["total"]) * 100).toFixed(2);
                    data["percentageOfGrossProduction"] = ((data[cname] / totalProductionData) * 100).toFixed(2);
    
            });
            let sortedData = {total:{}}
            const keys = Object.keys(claimRequestByCarrier);
            // Sort the keys based on absolute value of their corresponding values
            keys.sort((a, b) => {
                if (!claimRequestByCarrier[a] || !claimRequestByCarrier[b]) return 0; // check if properties exist
                return Math.abs(claimRequestByCarrier[b][b]) - Math.abs(claimRequestByCarrier[a][a])
            });
            // Add the sorted keys to the new object
            keys.forEach(key => {
                sortedData[key] = claimRequestByCarrier[key];
            });
            const sortedEntries = Object.entries(sortedData).sort(([keyA], [keyB]) => {
                if (keyA === "total") {
                    return 1; // move total to the end
                } else if (keyB === "total") {
                    return -1; // move total to the end
                } else {
                    return 1 // sort alphabetically
                }
            });
            const sortedObject = Object.fromEntries(sortedEntries)
            // render the table
            return (
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Carrier Name</Table.HeaderCell>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {Object.entries(sortedObject).map(([carrier, data]: any) => (
                            <Table.Row key={carrier}>
                                <Table.Cell>{carrier}</Table.Cell>
                                <Table.Cell content={(data[carrier] || 0).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                })}/>
                            </Table.Row>
                        ))}
    
    
                    </Table.Body>
                </Table>
            );
        }

    const renderClaimRequestTwo =(monthSelected:string) =>{
        const dataForMonth = {};
        for (const practice of Object.keys(claimReqState.claimRequestData)) {
            if(claimReqState.claimRequestData[practice][monthSelected]){
                dataForMonth[practice] = claimReqState.claimRequestData[practice][monthSelected];
            }
        }
        const claimReqByCarrierName: object = {};
        practices&&practices.forEach((prac: string) => {
            if (dataForMonth[prac]&&Object.keys(dataForMonth[prac]).length > 0) {
                Object.keys(dataForMonth[prac]).forEach((cname: string) => {
                    if (!claimReqByCarrierName[cname]) {
                        claimReqByCarrierName[cname] = { };
                        claimReqByCarrierName[cname][prac] = dataForMonth[prac];

                    }
                    else if(!claimReqByCarrierName[cname][prac] && dataForMonth[prac][cname]){
                        claimReqByCarrierName[cname][prac] = dataForMonth[prac];
                    }
                });
            }
        });


        let  claimReqCnameEntries={}

        // total productiondata for month
        const totalProductionData = Object.keys(claimReqState.productionData)&&claimReqState.productionData[monthSelected]?claimReqState.productionData[monthSelected].production:0;

        Object.entries(claimReqByCarrierName).forEach(([code,data]: [string,object]) => {

            practices&&practices.forEach((pracz: string) => {
                if (!data[pracz]) {
                    return;
                }
                if (!claimReqCnameEntries[code]) {
                    claimReqCnameEntries[code] = {}
                    claimReqCnameEntries[code]["cdeTotal"] = 0;
                }
                claimReqCnameEntries[code][pracz] = {}
                claimReqCnameEntries[code][pracz][code] = data[pracz][code];
                claimReqCnameEntries[code][pracz]['percentageOfTotal'] = ((data[pracz][code] / data[pracz]['total']) * 100).toFixed(2)
                claimReqCnameEntries[code][pracz]['percentageOfGrossProduction'] = ((data[pracz][code] / totalProductionData) * 100).toFixed(2);
                claimReqCnameEntries[code]['cdeTotal'] += Math.abs(Number(parseFloat(data[pracz][code]).toFixed(2)));

            });

            claimReqCnameEntries[code]["percentageofcdeGrossTotal"] = Number(((claimReqCnameEntries[code]["cdeTotal"]/totalProductionData)*100).toFixed(2));
        });

        const srtAdjustmentCode =Object.entries(claimReqCnameEntries).sort((obj1, obj2) => {
            if (obj1[1]["cdeTotal"] < obj2[1]["cdeTotal"]) {
                return -1
            }
        });

        const sortedObjectEntries ={}
        srtAdjustmentCode.forEach((arrypair) => {
            sortedObjectEntries[arrypair[0]] = arrypair[1]
        });

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell>{""}</Table.Cell>
                        {practices && practices.map((prac , idx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        key={idx} colspan="1">{prac} </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">Total</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Carrier Name</Table.HeaderCell>
                        {practices && practices.map((practice , indx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        colspan="1" key={indx}>
                                {showHideData.isDollars?"$":""}
                                {showHideData.isTTL ?" (%TTL)":""}
                                {showHideData.isGP ?"(%of GP)" : ""}
                            </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">
                            {showHideData.isDollars?"$":""}
                            {showHideData.isTTL ?" (%TTL)":""}
                            {showHideData.isGP ?"(%of GP)" : ""}

                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedObjectEntries&&Object.entries(sortedObjectEntries).map(([code,dataf]:any,indx:number) => (

                        <Table.Row key={indx}>
                            <Table.Cell key={indx}>{code}</Table.Cell>
                            {practices && practices.map((practize , index)=>
                                <Table.Cell colspan="1" key={indx-index}>
                                    {showHideData.isDollars?(Object.keys(dataf).indexOf(practize)>-1?dataf[practize][code]:0).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD"
                                    }):""} {showHideData.isTTL?"("+Math.abs(Object.keys(dataf).indexOf(practize)>-1?dataf[practize]["percentageOfTotal"]:0)+'%' +")" :""}
                                    {showHideData.isGP?"(" +Math.abs(Object.keys(dataf).indexOf(practize)>-1?dataf[practize]["percentageOfGrossProduction"]:0)+'%'+")":""}
                                </Table.Cell>
                            )}
                            <Table.Cell>{showHideData.isDollars?"$"+ dataf["cdeTotal"].toFixed(2):""}
                                {showHideData.isTTL?"("+((dataf["cdeTotal"]/sortedObjectEntries["total"]["cdeTotal"])*100).toFixed(2) + '%' +")":""}
                                {showHideData.isGP? "("+dataf["percentageofcdeGrossTotal"].toFixed(2) +'%' +")":""}
                            </Table.Cell>
                        </Table.Row>
                    ))}


                </Table.Body>
            </Table>
        );
    }

    const handleLevelChange =() => {
        setClaimReqState({...claimReqState,setLevelOne:!claimReqState.setLevelOne , setLevelTwo:!claimReqState.setLevelTwo});
    }
    const handleCheckBoxes =(name:string) => {
        if(name=="dollars"){
            setCheckBoxes({...showHideData , isDollars:!showHideData.isDollars})
        }
        if(name=="%ttl"){
            setCheckBoxes({...showHideData , isTTL:!showHideData.isTTL})
        }
        if(name=="%gp"){
            setCheckBoxes({...showHideData , isGP:!showHideData.isGP})
        }

    }

    return (
        <Modal
            open={true}
            onClose={closePopup}
            centered={true}
            closeIcon
        >
            <Modal.Header>Claims Request</Modal.Header>
            {claimReqState.setLevelOne && <Modal.Content>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell  colSpan='1'> Months/years</Table.HeaderCell>
                            <Table.HeaderCell colSpan='1'> Practices</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>{moment(claimReqState.selectedMonth + '-01').format('MMM YYYY')}</b></Table.Cell>
                            <Table.Cell width={3} content={practices&&practices.toString()} > {practices&&practices.toString()}</Table.Cell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        <Table.Row>
                        {renderClaimRequest(claimReqState.selectedMonth)}
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer>

                    </Table.Footer>
                </Table>
            </Modal.Content>}
            {
                claimReqState.setLevelTwo && <Modal.Content>
                    <div className="FreezeTable freezeHeight">
                        <Table celled width="100%">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell  colSpan='1'>
                                        Months/years  <Checkbox  label={showHideData.isDollars?"Hide $" : "Show $"}  toggle className="ml20" checked={showHideData.isDollars} onChange={() => handleCheckBoxes("dollars")} />
                                        <Checkbox  label={showHideData.isTTL ?"Hide %TTL" : "Show %TTL"} toggle  className="ml20" checked={showHideData.isTTL} onChange={() => handleCheckBoxes("%ttl")}/>
                                        <Checkbox  label={showHideData.isGP ?"Hide %of GP" : "Show %of GP"} toggle className="ml20" checked={showHideData.isGP} onChange={() => handleCheckBoxes("%gp")}/>
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><b>{moment(claimReqState.selectedMonth + '-01').format('MMM YYYY')}</b></Table.Cell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>

                                <Table.Row>
                                    {renderClaimRequestTwo(claimReqState.selectedMonth)}
                                </Table.Row>
                            </Table.Body>

                            <Table.Footer>

                            </Table.Footer>
                        </Table>
                    </div>
                </Modal.Content>
            }
          
            <Modal.Actions>
                {claimReqState.setLevelTwo &&<> <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L1
                </Button>
                    <Button className="mr10" primary disabled>
                        Details L3
                    </Button>
                </>}
                {claimReqState.setLevelOne && <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L2
                </Button> }
            <Button className="mr10" primary onClick={claimReqState.setLevelOne?()=>sendData('level1'):()=>sendData('level2',showHideData)} >
                      Send Email
                    </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ClaimRequestPopup;