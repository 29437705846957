import React, {useEffect, useState} from "react";
import "semantic-ui-css/semantic.min.css";
import {Grid, Card, Form, Tab, Button, Popup, Icon, Dimmer, Loader} from "semantic-ui-react";
import * as ActionSetup from "../redux/actions/setupActions"
import {useActions} from "../redux/actions";
import Template from "./Template";
import SetupTable from "./SetupTable"
import "react-datepicker/dist/react-datepicker.css";
import HelpMark from "./Help/helpMark";
import {HELP_PAGE} from "../constants";
import CountUpTimer from "./CountUpTimer";

interface ActionData {
    showForm: boolean,
    setupObj: object;
    setup: any[];
}


const SetupStatus: React.FC<any> = () => {
    const initialState: ActionData = {
        showForm: false,
        setupObj: {
            title: '',
            sequence: 0,
            type: 'setup_status'
        },
        setup: []
    };

    const setupActions = useActions(ActionSetup);
    const [state, setStates] = useState(initialState);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        setLoader(true);
        setupActions.getSetupData('setup_status').then((data: any) => {
            setStates({...state, setup: data, showForm: false});
            setLoader(false);
        })
    }

    const onChange = (value: any, name: string) => {
        let setupObj = state.setupObj;
        setupObj[name] = value;
        setStates({...state, setupObj});
    }

    const submitAddStatus = () => {
        const {setupObj} = state;
        setupActions.addSetupAction(setupObj).then((_data: any) => {
            loadData();

        });

    }


    return (
        <Template activeLink='setupStatus'>
            {loader && <CountUpTimer></CountUpTimer>}
            <Card>
                <Card.Content>
                    <h2 className="float-left">SETUP STATUS<HelpMark helpPage={HELP_PAGE.BILLING_SETUP_STATUS} application="Billing-PatientFinance-Setup-SetupStatus" /></h2>
                    <div className="float-right">
                        <Button primary onClick={() => setStates({...state, showForm: true})}>Add Status</Button>
                    </div>
                </Card.Content>
            </Card>

            {state.showForm &&
                <Card>
                    <Card.Content>
                        <h3>Add New Status</h3>
                        <Form className="formStyle">
                            <Form.Field>
                                <label>Enter Status</label>
                                <input placeholder='Title' onChange={(e) => onChange(e.target.value, 'title')}/>
                            </Form.Field>

                            <Form.Field>
                                <label>Sequence</label>
                                <input placeholder='Sequence' onChange={(e) => onChange(e.target.value, 'sequence')}/>
                            </Form.Field>

                            <Button primary className="float-right mr0" onClick={submitAddStatus}>Add Status</Button>
                            <Button secondary className="float-right mr10"
                                    onClick={() => setStates({...state, showForm: false})}>Cancel</Button>

                        </Form>
                    </Card.Content>
                </Card>
            }
            <Card>
                <Card.Content>
                    <SetupTable list={state.setup} titleHeader="Setup Status" loadData={loadData}/>
                </Card.Content>
            </Card>
        </Template>
    )

}

export default SetupStatus