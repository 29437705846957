import { Action, ActionType } from "../../model/actionTypes";
import { User } from "../../model/user";
import createReducer from "./createReducer";

export const auth = createReducer({}, {
	[ActionType.LOGIN_BEGIN](state: User, action: Action<User>) {
		return action.payload;
	},
	[ActionType.LOGIN_SUCCESS](state: User, action: Action<User>) {
		return action.payload;
	},
	[ActionType.LOGOUT](state: User, action: Action<any>) {
		return action.payload;
	},
	[ActionType.LOAD_SESSION](state: User, action: Action<any>) {
		return action.payload;
	},
	[ActionType.LOAD_MYCDP_URI](state: User, action: Action<any>) {
		return {...state,mycdp_uri:action.payload};
	},
	[ActionType.SET_PAGE_SUCCESS](state: User, action: Action<any>) {
		return {...state,...action.payload};
	},
	[ActionType.UPDATE_PAGE_SUCCESS](state: User, action: Action<any>) {
		return {...state,...action.payload};
	}
});
