import React from "react";
import { useState } from "react";
import { Modal, Table ,Button ,Checkbox} from "semantic-ui-react";
import moment from "moment/moment";

interface Props {
    data: { writeOffAppealData?:object,productionData?:object};
    closePopup: () => void;
    sendWATotal:Function;
}

const WOAppealGrandTotal: React.FC<Props> = ({ data ,closePopup,sendWATotal }) => {
    const [practices] = useState(Object.keys(data&&data.writeOffAppealData?data.writeOffAppealData:[]));
    const [state, setState] = useState({
        productionData:data.productionData,
        writeOffAppealData:data.writeOffAppealData,
        setLevel1:true,
        setLevel2:false
    })
    const [showhideData ,setCheckBoxes] = useState<any>({isDollars:true , isTTL:true ,isGP:true})
    const selectedMonths = Object.keys(data.productionData);
    const grossProdTotal=Object.values(state.productionData || {}).reduce((acc: number, value: any) => {
        return acc + value.production}, 0);
    const { writeOffAppealData}=state;
    const woAppealDataValues=Object.values(state.writeOffAppealData);

    const renderWOAppeal = () => {
        const woAppealGrandByCode: any = {};
        Object.values(woAppealDataValues).forEach((monthlyData: string) => {
                Object.values(monthlyData).forEach((mnthObjData) => {
                    Object.entries(mnthObjData).forEach(([code, amount]: any) => {
                        if (!woAppealGrandByCode[code] && code !== 'total') {
                            woAppealGrandByCode[code] = {
                                [code]: 0,
                                percentageOfTotal: 0,
                                percentageOfGrossProduction: 0,
                            };
                        }
                        if (woAppealGrandByCode[code] && code !== "total")
                            woAppealGrandByCode[code][code] += amount;
                        if (code == "total") {
                            if (!woAppealGrandByCode["total"]) {
                                woAppealGrandByCode[code] = {
                                    total: 0,
                                    percentageOfTotal: 0,
                                    percentageOfGrossProduction: 0,
                                }
                            }
                            woAppealGrandByCode[code]["total"] += amount
                        }
                    });
                });
        });

        Object.entries(woAppealGrandByCode).forEach(([code, data]: [string,object]) => {
            data["percentageOfTotal"] = ((data[code] / woAppealGrandByCode.total["total"]) * 100).toFixed(2);
            data["percentageOfGrossProduction"] = ((data[code] / grossProdTotal) * 100).toFixed(2);
        });

        let sortedData = {total:{}}
        const keys = Object.keys(woAppealGrandByCode);
        // Sort the keys based on absolute value of their corresponding values
        keys.sort((a, b) => {
            if (!woAppealGrandByCode[a] || !woAppealGrandByCode[b]) return 0; // check if properties exist
            return Math.abs(woAppealGrandByCode[b][b]) - Math.abs(woAppealGrandByCode[a][a])
        });
        // Add the sorted keys to the new object
        keys.forEach(key => {
            sortedData[key] = woAppealGrandByCode[key];
        });
        const sortedEntries = Object.entries(sortedData).sort(([keyA], [keyB]) => {
            if (keyA === "total") {
                return 1; // move total to the end
            } else if (keyB === "total") {
                return -1; // move total to the end
            } else {
                return 1 // sort alphabetically
            }
        });
        const woAppealGrandEntries = Object.fromEntries(sortedEntries)

        return (
            <Table celled size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>WriteOff Appeal Codes</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>% of Total WriteOff Appeal</Table.HeaderCell>
                        <Table.HeaderCell>% of Gross Production</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { Object.entries(woAppealGrandEntries).map(([woCode,data]:[string,object] , )=>
                        <Table.Row key={woCode} >
                            <Table.Cell>{woCode==="total"?<b>Grand Total</b>:woCode}</Table.Cell>
                            <Table.Cell> {"$"+ Math.abs(data[woCode]).toFixed(2)}</Table.Cell>
                            <Table.Cell>{Math.abs(data["percentageOfTotal"]).toFixed(2)+"%"}</Table.Cell>
                            <Table.Cell>{Math.abs(data["percentageOfGrossProduction"]).toFixed(2)+"%"}</Table.Cell>
                        </Table.Row>
                    )}

                </Table.Body>
            </Table>
        );
    }
    const renderL2WOAppeal = () => {

        const woAppealByCode2: any = {};
        const woAppealGrandEntries={};
        practices && practices.forEach((practice)=>{
            if (writeOffAppealData[practice]&&Object.keys(writeOffAppealData[practice]).length > 0) {
                Object.values(writeOffAppealData[practice]).forEach((monthYrDataObj: any) => {
                    Object.entries(monthYrDataObj).forEach(([code, amount],)=>{
                        if (!woAppealByCode2[code]) {
                            woAppealByCode2[code] = {};
                            woAppealByCode2[code][practice] = {
                                [code]:0
                            };

                        } else if (!woAppealByCode2[code][practice] ) {
                            woAppealByCode2[code][practice] = {[code]:0}
                        }
                        woAppealByCode2[code][practice][code]+=amount

                    });
                });
            }
        });

        Object.entries(woAppealByCode2).forEach(([code,data]: [string,object]) => {

            practices&&practices.forEach((pracz: any) => {
                if(!data[pracz]) {
                    return;
                }
                if(!woAppealGrandEntries[code]){
                    woAppealGrandEntries[code] = {}
                    woAppealGrandEntries[code]["cdeTotal"]=0;
                }
                woAppealGrandEntries[code][pracz]={}
                woAppealGrandEntries[code][pracz][code] = data[pracz][code];
                woAppealGrandEntries[code][pracz]['percentageOfTotal'] = ((data[pracz][code] / woAppealByCode2['total'][pracz]['total']) * 100).toFixed(2)
                woAppealGrandEntries[code][pracz]['percentageOfGrossProduction']=((data[pracz][code]/grossProdTotal)*100).toFixed(2);

                woAppealGrandEntries[code]['cdeTotal']+=Math.abs(Number(parseFloat(data[pracz][code]).toFixed(2)));
            });

            woAppealGrandEntries[code]["percentageofcdeGrossTotal"] = Number(((woAppealGrandEntries[code]["cdeTotal"]/grossProdTotal)*100).toFixed(2));
        });

        const srtWriteOffAppealCodes =Object.entries(woAppealGrandEntries).sort((obj1, obj2) => {
            if (obj1[1]["cdeTotal"] < obj2[1]["cdeTotal"]) {
                return -1
            }
        });

        const sortedObjectEntries ={}
        srtWriteOffAppealCodes.forEach((arrypair) => {
            sortedObjectEntries[arrypair[0]] = arrypair[1]
        });

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell/>
                        {practices && practices.map((prac , idx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        key={idx} colspan="1">{prac} </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">Total</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>WriteOff Appeal Code</Table.HeaderCell>
                        {practices && practices.map((practice )=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        colspan="1" key={practice}>
                                {showhideData.isDollars?"$":""}
                                {showhideData.isTTL ?" (%TTL)":""}
                                {showhideData.isGP ?"(%of GP)" : ""}
                            </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">
                            {showhideData.isDollars?"$":""}
                            {showhideData.isTTL ?" (%TTL)":""}
                            {showhideData.isGP ?"(%of GP)" : ""}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {srtWriteOffAppealCodes&&srtWriteOffAppealCodes.map(([code,objdata]) => (

                        <Table.Row key={code}>
                            <Table.Cell key={code}>{code=="total"?<b>Grand Total</b>:code}</Table.Cell>
                            {practices && practices.map((practize:string )=>
                                <Table.Cell colspan="1" key={practize+"-"+code}>
                                    {showhideData.isDollars?(Object.keys(objdata).indexOf(practize)>-1?objdata[practize][code]:0).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD"
                                    }):""} {showhideData.isTTL?"("+Math.abs(Object.keys(objdata).indexOf(practize)>-1?objdata[practize]["percentageOfTotal"]:0)+'%' +")" :""}
                                    {showhideData.isGP?"(" +Math.abs(Object.keys(objdata).indexOf(practize)>-1?objdata[practize]["percentageOfGrossProduction"]:0)+'%'+")":""}
                                </Table.Cell>
                            )}
                            <Table.Cell>
                                {showhideData.isDollars?"$"+ objdata["cdeTotal"].toFixed(2):""}
                                {showhideData.isTTL?"("+((objdata["cdeTotal"]/sortedObjectEntries["total"]["cdeTotal"])*100).toFixed(2) + '%' +")":""}
                                {showhideData.isGP? "("+objdata["percentageofcdeGrossTotal"].toFixed(2) +'%' +")":""}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    }

    const handleLevelChange =() => {
        setState({...state,setLevel1:!state.setLevel1 , setLevel2:!state.setLevel2});
    }

    const handleCheckBoxes =(e:any , name) => {
        if(name=="dollars"){
            setCheckBoxes({...showhideData , isDollars:!showhideData.isDollars})
        }
        if(name=="%ttl"){
            setCheckBoxes({...showhideData , isTTL:!showhideData.isTTL})
        }
        if(name=="%gp"){
            setCheckBoxes({...showhideData , isGP:!showhideData.isGP})
        }
    }



    return (
        <Modal
            open={true}
            onClose={closePopup}
            centered={true}
            closeIcon
        >
            <Modal.Header>WriteOff Appeal Grand Total</Modal.Header>
            {state.setLevel1 && <Modal.Content>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell> Months/years</Table.HeaderCell>
                            <Table.HeaderCell> Practices</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>{moment(selectedMonths[selectedMonths.length - 1] + '-01').format('MMM YYYY')} thru {moment(selectedMonths[0] + '-01').format('MMM YYYY')} </b></Table.Cell>
                            <Table.Cell width={3}
                                        content={practices && practices.toString()}> {practices && practices.toString()}</Table.Cell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        <Table.Row>
                            {renderWOAppeal()}
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer>

                    </Table.Footer>
                </Table>
            </Modal.Content>
            }
            {state.setLevel2 && <Modal.Content>
                <div className="FreezeTable freezeHeight">
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell  colSpan='1'>
                                    Months/years <Checkbox  label={showhideData.isDollars?"Hide $" : "Show $"}  toggle className="ml20" checked={showhideData.isDollars} onChange={(e) => handleCheckBoxes(e,"dollars")} />
                                    <Checkbox  label={showhideData.isTTL ?"Hide %TTL" : "Show %TTL"} toggle  className="ml20" checked={showhideData.isTTL} onChange={(e) => handleCheckBoxes(e,"%ttl")}/>
                                    <Checkbox  label={showhideData.isGP ?"Hide %of GP" : "Show %of GP"} toggle className="ml20" checked={showhideData.isGP} onChange={(e) => handleCheckBoxes(e,"%gp")}/>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><b>{moment(selectedMonths[selectedMonths.length-1] + '-01').format('MMM YYYY')}  thru  {moment(selectedMonths[0] + '-01').format('MMM YYYY')} </b></Table.Cell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                {renderL2WOAppeal()}
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </Modal.Content>}
            <Modal.Actions>
                {state.setLevel2 &&<> <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L1
                </Button>
                </>}
                {state.setLevel1 && <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L2
                </Button> }
                <Button className="mr10" primary onClick={state.setLevel1?()=>sendWATotal('level1'):()=>sendWATotal('level2',showhideData)} >
                    Send Email
                </Button>
            </Modal.Actions>
        </Modal>
    );
}


export default WOAppealGrandTotal;
