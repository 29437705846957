import {Action,ActionType} from "../../model/actionTypes"
import {QAISSUES} from "../../model/QAIssues"
import createReducer from "./createReducer"





export const qaissues = createReducer({},{
    [ActionType.LOAD_QAISSUES_SUCCES](state:QAISSUES,
                                          action:Action<QAISSUES>){
        return Object.assign({...state}, action.payload);},
})