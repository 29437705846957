export enum ActionType {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGOUT,
  LOAD_SESSION,
  ALERT,
  LOADER,
  SEND_FEEDBACK,
  SAVE_USAGE,
  SIDEBAR_OPEN,
  SET_PAGE_SUCCESS,
  UPDATE_PAGE_SUCCESS,
  //Claims
  LOAD_MEMBER_STATUS,
  LOAD_LAST_FETCHED,
  REVERSE_SEARCH,
  CLEAR_CLAIM_STATUS,
  GET_CLAIM_STATUS,
  GET_CLAIM_STATUS_COUNT,
  SAVE_NOTES,
  LOAD_NEW_FLOW_SUCCESS,
  LOAD_NEW_FLOW_FAIL,
  LOAD,
  //usage
  LOAD_SUCCESS,
  LOAD_FAIL,
  LOAD_USER_DETAILS,
  CLOSE_ERROR_MESSAGE,
  LOAD_WORK_DAYS,
  //setup
  LOAD_SETUP_SUCCESS,
  LOAD_SETUP_FAIL,
  SAVE_SETUP,
  SAVE_SETUP_QA_STATUS,
  LOAD_SETUP_QA_STATUS_SUCCESS,
  LOAD_SETUP_QA_STATUS_FAIL,
  DELETE_SETUP_QA_STATUS_FAIL,
  DELETE_SETUP_QA_STATUS_SUCCESS,
  EDIT_SETUP_QA_STATUS_SUCCESS,
  EDIT_SETUP_QA_STATUS_FAIL,
  LOAD_USER_AMOUNT_SUCCESS,
  LOAD_USER_AMOUNT_FAIL,
  LOAD_PRACTICE,
  LOAD_FAIL_PRACTICE,
  //setupActions
  SAVE_SETUP_ACTION,
  GET_SETUP,
  UPDATE_SETUP,
  SETUP_FAIL,
  DELETE_SETUP,
  //newFlowStatusAction
  SAVE_ACTIONS_STATUS,
  SAVE_SETUP_QA_STATUS_ISSUE,
  LOAD_QAISSUES_SUCCES,
  LOAD_QAISSUES_FAIL,
  LOAD_PAY_PLAN_SUCCESS,
  LOAD_PAY_PLAN_FAIL,
  LOAD_MYCDP_URI,
  LOAD_ALL_PAYPLAN_BY_PATNUM_SUCCESS,
  LOAD_ALL_PAYPLAN_BY_PATNUM_FAIL,
  //setupXcharge
  GET_SETUP_XCHARGE,
  SAVE_XCHARGE,
  UPDATE_XCHARGE,
  DELETE_XCHARGE,
  XCHARGE_FAIL,
  LOAD_ALLPRACTICES,
  //reports
  LOAD_PAYMENT_REPORT_SUCCESS,
  LOAD_PAYMENT_REPORT_FAIL,
  //help
  GET_HELP_SECTIONS,
  GET_HELP_SECTIONS_FAIL,
  LOAD_YEARLY_NEW_FLOW_SUCCESS,
  LOAD_YEARLY_NEW_FLOW_FAIL,
  LOAD_PRACTICE_LIST,
  //collections
  SAVE_LINKS,
  LOAD_DOCUMENTS_LINKS,
  LOAD_DOCLINKS_FAIL,
  LOAD_GROSSPROD_SUCCESS,
  PROVIDER_ACCOUNTS,
  //email
  SEND_DOCUMENTS,
  SEND_WO_DOCUMENTS,
  SEND_GP_DOCUMENTS,
  SEND_CR_DOCUMENTS,
  LOAD_DETAILS_SUCCESS,
  LOAD_DETAILS_FAIL,
  SEND_CP_DOCUMENTS,
  SEND_CS_DOCUMENTS,
  SEND_WOA_DOCUMENTS,
  CHECK_OPEN_ISSUES_FAIL,
  CHECK_OPEN_ISSUES_SUCCESS,
  LOAD_OPEN_ISSUES_FAIL,
  LOAD_OPEN_ISSUES_SUCCESS,
  LOAD_REGION_LIST
}


export interface Action<T> {
    type: ActionType;
    payload: T;
  }
