import React,{useState} from "react";
import { Modal, Table ,Button ,Checkbox} from "semantic-ui-react";
import moment from "moment/moment";

interface Props {
    data: { productionData?:object , adjustmentsData?:object };
    closePopup: () => void;
    sendTotal:Function;
}

const GrandTotalPopup: React.FC<Props> = ({ data ,closePopup,sendTotal }) => {
    const [adjustmentPractices] = useState(Object.keys(data&&data.adjustmentsData?data.adjustmentsData:[]));
     const [state,setState] = useState({
            productionData:data.productionData,
            adjustmentsData:data.adjustmentsData,
            level2:false,
            level1:true        
        })
    const [ showHideData ,setCheckBoxes] = useState<any>({isDollars:true , isTTL:true ,isGP:true});
    const grossProdTotal=Object.values(state.productionData || {}).reduce((acc: number, value: any) => {
        return acc + value.production}, 0);
    const selectedMonths = Object.keys(data.productionData);
    const { adjustmentsData}=state;
    const adjMentData=Object.values(state.adjustmentsData);

    const renderAdjustments = () => {

        const adjustGrandByCode: any = {};
        Object.values(adjMentData).forEach((monthData: string) => {
            if (monthData&&Object.keys(monthData).length > 0) {
                Object.values(monthData).forEach((mnthObjData) => {
                    Object.entries(mnthObjData).forEach(([code, amount]: any) => {
                        if (!adjustGrandByCode[code]) {

                            adjustGrandByCode[code] = {
                                [code]: 0,
                                percentageOfTotal: 0,
                                percentageOfGrossProduction: 0,
                            };
                        }
                            adjustGrandByCode[code][code] += amount;

                    });
                });

            }
        });

        Object.entries(adjustGrandByCode).forEach(([code, data]: [string,object]) => {
            data["percentageOfTotal"] = ((data[code] / adjustGrandByCode.total["total"]) * 100).toFixed(2);
            data["percentageOfGrossProduction"] = ((data[code] / grossProdTotal) * 100).toFixed(2);
        });

        let sortedData = {total:{}}
        const keys = Object.keys(adjustGrandByCode);
        // Sort the keys based on absolute value of their corresponding values
        keys.sort((a, b) => {
            if (!adjustGrandByCode[a] || !adjustGrandByCode[b]) return 0; // check if properties exist
            return Math.abs(adjustGrandByCode[b][b]) - Math.abs(adjustGrandByCode[a][a])
        });
        // Add the sorted keys to the new object
        keys.forEach(key => {
            sortedData[key] = adjustGrandByCode[key];
        });
        const sortedEntries = Object.entries(sortedData).sort(([keyA], [keyB]) => {
           if (keyB === "total") {
                return -1; // move total to the end
            } else {
                return 1 // sort alphabetically
            }
        });
        const sortedWOObject = Object.fromEntries(sortedEntries)

        return (
            <Table celled size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Adjustment Codes</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>% of Total Adjustment </Table.HeaderCell>
                        <Table.HeaderCell>% of Gross Production</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { Object.entries(sortedWOObject).map(([code,data]:[string,object] )=>
                        <Table.Row key={code} >
                            <Table.Cell>{code=="total"?<b>Grand Total</b>:code}</Table.Cell>
                            <Table.Cell> {"$"+ Math.abs(data[code]).toFixed(2)}</Table.Cell>
                            <Table.Cell>{Math.abs(data["percentageOfTotal"]).toFixed(2)+"%"}</Table.Cell>
                            <Table.Cell>{Math.abs(data["percentageOfGrossProduction"]).toFixed(2)+"%"}</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        );
    }

    const renderL2Adjustments = () => {

        const adjustmentsByCode2: any = {};
        const adjustmentsGrandEntries={};
        adjustmentPractices && adjustmentPractices.forEach((practice)=>{
            if (adjustmentsData[practice]&&Object.keys(adjustmentsData[practice]).length > 0) {
                Object.values(adjustmentsData[practice]).forEach((monthYrDataObj: any) => {
                    Object.entries(monthYrDataObj).forEach(([code, amount],)=>{
                        if (!adjustmentsByCode2[code]) {
                            adjustmentsByCode2[code] = {};
                            adjustmentsByCode2[code][practice] = {
                                [code]:0
                            };

                        } else if (!adjustmentsByCode2[code][practice] ) {
                            adjustmentsByCode2[code][practice] = {[code]:0}
                        }
                        adjustmentsByCode2[code][practice][code]+=amount

                    });
                });
            }
        });

        Object.entries(adjustmentsByCode2).forEach(([code,data]: [string,object]) => {

            adjustmentPractices&&adjustmentPractices.forEach((pracz: string) => {
                if(!data[pracz]) {
                    return;
                }
                if(!adjustmentsGrandEntries[code]){
                    adjustmentsGrandEntries[code] = {}
                    adjustmentsGrandEntries[code]["cdeTotal"]=0;
                }
                adjustmentsGrandEntries[code][pracz]={}
                adjustmentsGrandEntries[code][pracz][code] = data[pracz][code];
                adjustmentsGrandEntries[code][pracz]['percentageOfTotal'] = ((data[pracz][code] / adjustmentsByCode2['total'][pracz]['total']) * 100).toFixed(2)
                adjustmentsGrandEntries[code][pracz]['percentageOfGrossProduction']=((data[pracz][code]/grossProdTotal)*100).toFixed(2);

                adjustmentsGrandEntries[code]['cdeTotal']+=Math.abs(Number(parseFloat(data[pracz][code]).toFixed(2)));
            });

            adjustmentsGrandEntries[code]["percentageofcdeGrossTotal"] = Number(((adjustmentsGrandEntries[code]["cdeTotal"]/grossProdTotal)*100).toFixed(2));
        });

        const srtAdjustmentCodes =Object.entries(adjustmentsGrandEntries).sort((obj1, obj2) => {
            if (obj1[1]["cdeTotal"] < obj2[1]["cdeTotal"]) {
                return -1
            }
        });

        const sortedObjectEntries ={}
        srtAdjustmentCodes.forEach((arrypair) => {
            sortedObjectEntries[arrypair[0]] = arrypair[1]
        });

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell/>
                        {adjustmentPractices && adjustmentPractices.map((prac , idx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        key={idx} colspan="1">{prac} </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">Total</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Adjustment Codes</Table.HeaderCell>
                        {adjustmentPractices && adjustmentPractices.map((practice , indx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        colspan="1" key={indx}>
                                {showHideData.isDollars?"$":""}
                                {showHideData.isTTL ?" (%TTL)":""}
                                {showHideData.isGP ?"(%of GP)" : ""}
                            </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">
                            {showHideData.isDollars?"$":""}
                            {showHideData.isTTL ?" (%TTL)":""}
                            {showHideData.isGP ?"(%of GP)" : ""}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedObjectEntries&&Object.entries(sortedObjectEntries).map(([code,objdata]) => (

                        <Table.Row key={code}>
                            <Table.Cell key={code}>{code=="total"?<b>Grand Total</b>:code}</Table.Cell>
                            {adjustmentPractices && adjustmentPractices.map((practize:string )=>
                                Object.keys(objdata).indexOf(practize)>-1?
                                <Table.Cell colspan="1" key={practize+"-"+code}>
                                    {showHideData.isDollars?(objdata[practize][code]).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD"
                                    }):""} {showHideData.isTTL?"("+Math.abs(objdata[practize]["percentageOfTotal"])+"%)" :""}
                                    {showHideData.isGP?"(" +Math.abs(objdata[practize]["percentageOfGrossProduction"])+'%'+")":""}
                                </Table.Cell>:
                                    <Table.Cell colspan="1" key={practize+"-"+code}>
                                        {showHideData.isDollars?(0).toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }):""} {showHideData.isTTL?"(0.00%)" :""}{showHideData.isGP?"(0.00%)":""}
                                    </Table.Cell>

                            )}
                            <Table.Cell>
                                {showHideData.isDollars?"$"+ objdata["cdeTotal"].toFixed(2):""}
                                {showHideData.isTTL?"("+((objdata["cdeTotal"]/sortedObjectEntries["total"]["cdeTotal"])*100).toFixed(2) + '%' +")":""}
                                {showHideData.isGP? "("+objdata["percentageofcdeGrossTotal"].toFixed(2) +'%' +")":""}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    }
    

    const handleLevelChange =() => {
        setState({...state,level1:!state.level1 , level2:!state.level2});
  }

  const handleCheckBoxes =( name:string) => {
    if(name=="dollars"){
      setCheckBoxes({...showHideData , isDollars:!showHideData.isDollars})
    }
    if(name=="%ttl"){
        setCheckBoxes({...showHideData , isTTL:!showHideData.isTTL})
    }
    if(name=="%gp"){
        setCheckBoxes({...showHideData , isGP:!showHideData.isGP})
    }
  }

    return (
        <Modal
            open={true}
            onClose={closePopup}
            centered={true}
            closeIcon
        >
            <Modal.Header>Adjustments Grand Total</Modal.Header>
            {state.level1 && <Modal.Content>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell  colSpan='1'> Months/years</Table.HeaderCell>
                            <Table.HeaderCell colSpan='1'> Practices</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>{moment(selectedMonths[selectedMonths.length-1] + '-01').format('MMM YYYY')}  thru  {moment(selectedMonths[0] + '-01').format('MMM YYYY')} </b></Table.Cell>
                            <Table.Cell width={3} content={adjustmentPractices&&adjustmentPractices.toString()} > {adjustmentPractices&&adjustmentPractices.toString()}</Table.Cell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        <Table.Row>
                        {renderAdjustments()}
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer>

                    </Table.Footer>
                </Table>
            </Modal.Content>}
            {state.level2 && <Modal.Content>
                <div className="FreezeTable freezeHeight">
                <Table celled>
                <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell  colSpan='1'>
                                 Months/years <Checkbox  label={showHideData.isDollars?"Hide $" : "Show $"}  toggle className="ml20" checked={showHideData.isDollars} onChange={() => handleCheckBoxes("dollars")} />
                            <Checkbox  label={showHideData.isTTL ?"Hide %TTL" : "Show %TTL"} toggle  className="ml20" checked={showHideData.isTTL} onChange={() => handleCheckBoxes("%ttl")}/>
                            <Checkbox  label={showHideData.isGP ?"Hide %of GP" : "Show %of GP"} toggle className="ml20" checked={showHideData.isGP} onChange={() => handleCheckBoxes("%gp")}/>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                        <Table.Cell><b>{moment(selectedMonths[selectedMonths.length-1] + '-01').format('MMM YYYY')}  thru  {moment(selectedMonths[0] + '-01').format('MMM YYYY')} </b></Table.Cell>
                        </Table.Row>
                </Table.Header>
                    <Table.Body>
                    <Table.Row>
                        {renderL2Adjustments()}
                        </Table.Row>
                    </Table.Body>
                </Table>
                </div>
            </Modal.Content>}

            <Modal.Actions>
         
            {state.level2 &&<> <Button className="mr10" primary onClick={handleLevelChange}>
              Details L1
            </Button>
                <Button className="mr10" primary onClick={()=>sendTotal('level2',showHideData)} >
                    Send Email
                </Button>
            </>}  
            {state.level1 && <><Button className="mr10" primary onClick={handleLevelChange}>
              Details L2
            </Button>
                <Button className="mr10" primary onClick={()=>sendTotal('level1')} >
                    Send Email
                </Button>
            </>}
            </Modal.Actions>
        </Modal>
    );
}


export default GrandTotalPopup;
