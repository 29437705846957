import React from "react"
import Template from "./Template"
import * as usageAction from '../redux/actions/usage'
import {useActions} from '../redux/actions';
import { Button, Grid } from "semantic-ui-react";
import { RootState } from "../model";
import { connect } from "react-redux";
import { Account } from "../model/user";
import HelpMark from "./Help/helpMark";
import {HELP_PAGE} from "../constants";

interface Props{
    user: Account
}

const Test:React.FC<any>=(props)=>{
    const appUsageAction = useActions(usageAction)
    return (
        <Template activeLink="dashboard" >
            <div className="ui card">
                <div className="content">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h2 className="headingMain">Dashboard</h2>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
      user: state.auth.user,
    };
  }
export default connect(mapStateToProps)(Test)