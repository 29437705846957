import {ActionType} from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const getSummary = (practices: string[] = [], months: string[] = []) => {
    return async (dispatch: Function, getState: Function, api: any) => {
        dispatch({type: ActionType.LOAD});
        try {
            const data = await api.get(`collections/summary?practices=${practices.join(',')}&months=${months.join(',')}`);
            dispatch({type: ActionType.LOAD_SUCCESS});
            return data;
        } catch (err) {
            dispatch({type: ActionType.LOAD_FAIL, error: err.message});
            return false;
        }
    };
};

export const saveVidLink = (data:any)=> async (dispatch: Function, getState: Function, api: any) => {
    const url = `collections/savelinks`;
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.post(url, data);
        dispatch({ type: ActionType.SAVE_LINKS, payload: { success: resp } });
        dispatch({ type: ActionType.LOADER, payload: false });
    } catch (error) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(error));
    }
};

export const getDoclinks = () => async (dispatch:Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD });
    try {
        let res = await api.get(`collections/getlinks`);
        dispatch({
             type: ActionType.LOAD_DOCUMENTS_LINKS, payload: {dataVerification : res} });
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_DOCLINKS_FAIL, error: err.message });
        return false;
    }
};
export const sendDocumentsEmail =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp = await api.post("collections/sendEmail", data);
            dispatch({ type: ActionType.SEND_DOCUMENTS, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.LOADER, payload: false });
            return dispatch(errorHandler(error));
        }
    };

    export const sendWriteoffEmail =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp = await api.post("collections/sendwriteOffEmail", data);
            dispatch({ type: ActionType.SEND_WO_DOCUMENTS, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.LOADER, payload: false });
            return dispatch(errorHandler(error));
        }
    };

    export const getGrossProduction = (practices: string[] = [], months: string[] = []) => 
         async (dispatch: Function, getState: Function, api: any) => {
            dispatch({type: ActionType.LOAD});
            try {
                const data = await api.get(`collections/grossProduction?practices=${practices.join(',')}&months=${months.join(',')}`);
                dispatch({type: ActionType.LOAD_GROSSPROD_SUCCESS , payload:{grossproduction:data}});
                return data;
            } catch (err) {
                dispatch({type: ActionType.LOAD_FAIL, error: err.message});
                return false;
            }
        };

        export const sendgrossProdEmail =
        (data: any) => async (dispatch: Function, getState: Function, api: any) => {
            dispatch({ type: ActionType.LOADER, payload: true });
            try {
                const resp = await api.post("collections/sendgProdEmail", data);
                dispatch({ type: ActionType.SEND_GP_DOCUMENTS, payload: { success: resp } });
                dispatch({ type: ActionType.LOADER, payload: false });
            } catch (error) {
                dispatch({ type: ActionType.LOADER, payload: false });
                return dispatch(errorHandler(error));
            }
        };
    
    export const sendClaimRequestEmail =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp = await api.post("collections/sendClaimRequestEmail", data);
            dispatch({ type: ActionType.SEND_CR_DOCUMENTS, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.LOADER, payload: false });
            return dispatch(errorHandler(error));
        }
    };



export function getCellData(practices: string[] = [], months: string[] = [],method: string) {
    return async (dispatch: Function, getState: Function, api: any) => {
        dispatch({type: ActionType.LOAD});
        try {
            const data = await api.get(`collections/summary/details1?practices=${practices.join(',')}&months=${months.join(',')}&method=${method}`);
            dispatch({type: ActionType.LOAD_DETAILS_SUCCESS});
            return data;
        } catch (err) {
            dispatch({type: ActionType.LOAD_DETAILS_FAIL, error: err.message});
            return false;
        }
    };
}

export const sendPayPlanPayEmail =
(data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.post("collections/sendPayPlanPayEmail", data);
        dispatch({ type: ActionType.SEND_CR_DOCUMENTS, payload: { success: resp } });
        dispatch({ type: ActionType.LOADER, payload: false });
    } catch (error) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(error));
    }
};

 export const sendClaimPayEmail =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp = await api.post("collections/sendClaimPay", data);
            dispatch({ type: ActionType.SEND_CP_DOCUMENTS, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.LOADER, payload: false });
            return dispatch(errorHandler(error));
        }
    };

export const sendCashSplitEmail =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp = await api.post("collections/sendCashSplit", data);
            dispatch({ type: ActionType.SEND_CS_DOCUMENTS, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.LOADER, payload: false });
            return dispatch(errorHandler(error));
        }
    };

export const sendWOAppealEmail =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp = await api.post("collections/sendwoAppeal", data);
            dispatch({ type: ActionType.SEND_WOA_DOCUMENTS, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.LOADER, payload: false });
            return dispatch(errorHandler(error));
        }
    };
