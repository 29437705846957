import { ActionType } from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const getSetupData = () => async (dispatch:Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD });
    try {
        let res = await api.get(`patientFinance/patient-finance-setup-info`);
        dispatch({ type: ActionType.LOAD_SETUP_SUCCESS, payload: {
                setupData : res

            } });
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_SETUP_FAIL, error: err.message });
        return false;
    }
};
export const getPracticesList = (doctorId) => async (dispatch:Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD });

    try {
        dispatch({ type: ActionType.LOAD_PRACTICE });
        let res = await api.get(`patientFinance/getPractices?doctorId=`+doctorId);
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_FAIL_PRACTICE, error: err.message });
    }
};
export const getSetupQAList = () => async (dispatch:Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD });

    try {
        dispatch({ type: ActionType.LOAD_SETUP_QA_STATUS_SUCCESS });
        let res = await api.get(`patientFinance/getQAStatus`);
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_SETUP_QA_STATUS_FAIL, error: err.message });
    }
};
export const SubmitSetupData =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        const url = `patientFinance/saveSetup`;
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp = await api.post(url, data);
            dispatch({ type: ActionType.SAVE_SETUP, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.LOADER, payload: false });
            return dispatch(errorHandler(error));
        }
    };
export const SubmitSetupQAStatusData =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        const url = `patientFinance/saveQAStatus`;
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp = await api.post(url, data);
            dispatch({ type: ActionType.SAVE_SETUP_QA_STATUS, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.LOADER, payload: false });
            return dispatch(errorHandler(error));
        }
    };
export const SubmitQAStatusIssueData = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        const url = `patientFinance/saveQAStatusIssue`;
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp = await api.post(url, data);
            dispatch({ type: ActionType.SAVE_SETUP_QA_STATUS_ISSUE, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.LOADER, payload: false });
            return dispatch(errorHandler(error));
        }
    };
export const DeleteSetupQAStatus =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        const url = `patientFinance/deleteQAStatus`;
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp = await api.post(url, data);
            dispatch({ type: ActionType.DELETE_SETUP_QA_STATUS_SUCCESS, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.DELETE_SETUP_QA_STATUS_FAIL, payload: false });
            return dispatch(errorHandler(error));
        }
    };
export const EditSetupQAStatus =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        const url = `patientFinance/editQAStatus`;
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp = await api.post(url, data);
            dispatch({ type: ActionType.EDIT_SETUP_QA_STATUS_SUCCESS, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.EDIT_SETUP_QA_STATUS_FAIL, payload: false });
            return dispatch(errorHandler(error));
        }
    };

    export const getSetupXcharge = () => async (dispatch:Function, getState:Function, api:any) => {
        dispatch({ type: ActionType.LOAD });
        try {
            let res = await api.get(`patientFinance/getXcharge`);
            dispatch({ type: ActionType.GET_SETUP_XCHARGE, payload: { setupXcharge : res} });
            return res;
        } catch (err) {
            dispatch({ type: ActionType.XCHARGE_FAIL, error: err.message });
            return false;
        }
    };

    export const SubmitXcharge =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        const url = `patientFinance/addXcharge`;
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp = await api.post(url, data);
            dispatch({ type: ActionType.SAVE_XCHARGE, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.LOADER, payload: false });
            return dispatch(errorHandler(error));
        }
    };

    export const DeleteSetupXcharge =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        const url = `patientFinance/deleteSetupXcharge`;
        try {
            const resp = await api.put(url, data);
            dispatch({ type: ActionType.DELETE_XCHARGE, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.XCHARGE_FAIL, payload: false });
            return dispatch(errorHandler(error));
        }
    };

    export const UpdateXcharge =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        const url = `patientFinance/updatesetupXcharge`;
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp = await api.put(url, data);
            dispatch({ type: ActionType.UPDATE_XCHARGE, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.XCHARGE_FAIL, payload: false });
            return dispatch(errorHandler(error));
        }
    };
  
    export const fetchAllPractices = ()=>async (dispatch:Function, getState:Function, api:any) => {
        dispatch({ type: ActionType.LOAD });
        try {
            let res = await api.get(`patientFinance/fetchPractices`);
            dispatch({ type: ActionType.LOAD_ALLPRACTICES, payload: {allPractices : res} });
            return res;
        } catch (err) {
            dispatch({ type: ActionType.LOAD_ALLPRACTICES, error: err.message });
            return false;
        }
    };


   
    

