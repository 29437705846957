import { ActionType } from "../../model/actionTypes";

export const LogIn = (profileObj: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		dispatch({ type: ActionType.LOGIN_BEGIN, payload: {} });
        const data = {
            email: profileObj.email,
            firstName: profileObj.givenName,
            profilePicture:profileObj.imageUrl
        };
		const resp = await api.post("sessions?jwt=1",  data);
		dispatch({ type: ActionType.ALERT, payload: {} });
		dispatch({ type: ActionType.LOGIN_SUCCESS, payload: resp });

		return resp;
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
};

export const LogOut = () => async (dispatch: Function, getState: Function, api: any) => {
	api.delete("sessions");
	dispatch({ type: ActionType.LOGOUT, payload: {} });
	return;
};

export const Load = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const resp = await api.get("sessions");
		if(!resp || !resp.id) {
			return dispatch(LogOut());
		}
	} catch (err) {
		return dispatch(LogOut());
	}
};

export const getPractices = () => async (dispatch: Function, getState: Function, api:any) => {
    try {
        return await api.get("practicesInfo/getPractices");
    } catch (err) {
        return dispatch({ type: ActionType.ALERT, payload: err });
    }
};

export const clearError = ()=> (dispatch: Function) => {
		return dispatch({ type: ActionType.ALERT, payload: null });
};

export const errorHandler = (error: any) => (dispatch: Function) => {
	if (error.message === 'Session Expired' || error.statusCode === 401) {
		return dispatch(LogOut());
	}
	return dispatch({ type: ActionType.ALERT, payload: error });
};

export const LogInWithForm = (obj: any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({type: ActionType.LOADER, payload: true});
	try {
		dispatch({ type: ActionType.LOGIN_BEGIN, payload: {} });
		const resp = await api.post("sessions/login?jwt=1",  obj);
		dispatch({ type: ActionType.ALERT, payload: {} });
		dispatch({ type: ActionType.LOGIN_SUCCESS, payload: resp });
		dispatch({type: ActionType.LOADER, payload: false});
		return resp;
	} catch (err) {
		dispatch({type: ActionType.LOADER, payload: false});
		 dispatch({ type: ActionType.ALERT, payload: err });
		 return err;
	}
};
export const cdpLogin = (accessToken:any) => async (dispatch:Function, getState: Function, api:any) => {
	try {
		const res:any = await api.get(`sessions/cdpLogin/${accessToken}`);
		dispatch({ type: ActionType.LOGIN_SUCCESS, payload: res });
		return res;
	} catch (error) {
		dispatch({ type: ActionType.ALERT, payload: error });
		return error;
	}
};
export const getDentalPartners = (id:any) => async (dispatch:Function, getState: Function, api:any) => {
	try {
		const resp = await api.get(`getPartnersList/${id}`, {});
		dispatch({ type:ActionType.LOAD_MYCDP_URI, payload: resp.partners_uri });
		return resp;
	} catch (err) {
		return err;
	}
};

export const updateDefaultPage = (data:any) => async (dispatch:Function, getState: Function, api:any) => {
	try {
		const resp = await api.put("user/updateDefaultpage",  data);
		dispatch({ type: ActionType.ALERT, payload: {} });
		dispatch({ type: ActionType.UPDATE_PAGE_SUCCESS, payload: resp });
		return resp;
	} catch (err) {
		 dispatch({ type: ActionType.ALERT, payload: err });
		 return err;
	}
};

export const setDefaultPage = (data:any) => async (dispatch:Function, getState: Function, api:any) => {
	try {
		const res = await api.post("user/setDefaultpage",  data);
		dispatch({ type: ActionType.ALERT, payload: {} });
		dispatch({ type: ActionType.SET_PAGE_SUCCESS, payload: res });
		return res;
	} catch (err) {
		 dispatch({ type: ActionType.ALERT, payload: err });
		 return err;
	}
};