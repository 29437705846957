import React, {useEffect,useState} from "react";
import {Button, Modal, Icon, Header, Select, TextArea, Dropdown,Form} from "semantic-ui-react";
import {useFormik} from "formik"
import {Redirect} from "react-router";
import { useHistory } from 'react-router-dom';
import ViewNotesModal from "./ViewNotesModal";

const EditQAStatusNewFlowModal:React.FC<any>=(props)=>{
    const [QAStatus, setQAStatus] = useState('ok');
    const [viewNotes, setViewNotes ] = useState(false);
    const [patientData , setPatientData] = useState({});
    const formik=useFormik(
        {
            initialValues:{
                Category:props.fields.Category,
                ChargeAmt:props.fields.ChargeAmt ,
                ChargeDate:props.fields.ChargeDate,
                DatePay:props.fields.DatePay,
                DateStart:props.fields.DateStart,
                DateStop: props.fields.DateStop,
                Dentistry:props.fields.Dentistry,
                Interest:props.fields.Interest,
                IsClosed:props.fields.IsClosed,
                PP_Balance:props.fields.PP_Balance,
                PP_Status:props.fields.PP_Status,
                PP_line_note:props.fields.PP_line_note,
                PatNum:props.fields.PatNum,
                PayNote:props.fields.PayNote,
                PayPlanNum:props.fields.PayPlanNum,
                Practice:props.fields.Practice,
                Principal:props.fields.Principal,
                SplitAmt:props.fields.SplitAmt,
                UserName:props.fields.UserName,
                notelong:props.fields.notelong,
                QAStatus:QAStatus
            },
            onSubmit:values => {
                props.submitQAStatusIssueData(values)

            }
        }
    )

    const onChangeStatus = (value) => {        
        if (value.toString() !== formik.values.QAStatus ) {
            formik.values.QAStatus = value.toString();

        }
    }

    const openViewNotes = (notelong,PatNum)=>{
      
        setPatientData({notes:notelong , PatNum:PatNum})
        setViewNotes(true);
      }
  
      const closeViewNotes = () => {
          setViewNotes(false);
      }
        let history = useHistory();
        const redirect = () => {
            history.push('/patientfinance/qaissues')
        }

        return (

        <Modal
            open={true}
            onClose={props.hideSetupModal}
            centered={true}
            closeIcon
        >
            <Header content={"Pay Plan Loan QA"}/>
            <Modal.Content>
                <div className="table-responsive" style={{overflow:"initial"}}>
                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th>QA Status</th>
                            <th>Start Date</th>
                            <th>Practice</th>
                            <th>Patient Number</th>
                            <th>Amount</th>
                            <th>QA Issue</th>
                            <th>Pay Plan Note</th>

                        </tr>
                        </thead>
                        <tbody>
                        {
                            <tr key={props.fields.id}>



                                <td >

                                    {props.fields.QAStatus==="COMPLETED"?<span><Icon  name="check circle" color="green"/>{props.fields.QAStatus}</span>:(props.fields.notelong.includes('PPQA$: '))?<span><Icon  name="cancel" color="red"/></span>:!(props.fields.notelong.includes('PPQA$:'))?<span><Icon  name="cancel" color="red"/></span>:<span><Icon  name="check circle" color="green"/>{props.fields.QAStatus}</span>}

                                </td>
                                <td >
                                    {props.fields.ChargeDate}
                                </td>
                                <td >
                                    {props.fields.Practice}
                                </td>
                                <td >
                                    {props.fields.PatNum}
                                </td>
                                <td >
                                    {props.fields.ChargeAmt}
                                </td>
                                <td >

                                    {<Dropdown className='mr10'  selection
                                              multiple  defaultValue={(props.fields.notelong.includes('PPQA$:'))?props.QAStatusList[0].value:props.fields.QAStatus} options={props.QAStatusList}  onChange={(_e, { value }) => onChangeStatus(value)} />}

                                </td>
                                <td>                              
                                <span onClick={()=>openViewNotes(props.fields.notelong ,props.fields.PatNum)} className='cursorPointer'><a>View Note</a></span>
                                </td>
                            </tr>


                        }


                        </tbody>
                    </table>
                    {viewNotes && <ViewNotesModal patientData={patientData} closeViewNotes = {closeViewNotes}/>}
                </div>


            </Modal.Content>
            <Modal.Actions>
                <Button color="orange" onClick={redirect}>
                    View All QA Issues
                </Button>
                <Button className='ui primary button' onClick={formik.submitForm}>
                    Save
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default EditQAStatusNewFlowModal