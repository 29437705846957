import React ,{useState}from "react"
import {Menu, Dropdown,Image} from 'semantic-ui-react';
import FeedbackModal from "./FeedbackModal";
import DefaultPageModal from "./DefaultPageModal";
import {Account} from "../model/user"
import moment from "moment";
import {useActions} from "../redux/actions"
import * as AppActions from "../redux/actions/app"
import * as AuthActions from "../redux/actions/auth"
interface Props{
  user:Account
  logout:any;
  listenerFromParent:any;
  handleClick:Function;
  mycdp_uri:string;
}

const Header:React.FC<Props>=(props)=>{
    const [feedbackModal,setFeedbackPageModal]=useState(false)
    const [activeItem,setActiveItem]=useState("")
    const [state, setStates] = useState({formData: {}});
    const [defaultPageModal , setDefaultPageModal] = useState(false)
   const [selectedDefaultPage , setDefaultPage] = useState("") 
    const appActions=useActions(AppActions)
    const authActions=useActions(AuthActions)
    const openFeedback=()=>{
        setFeedbackPageModal(true)
    }
    const hideFeedbackModal=()=>{
        setFeedbackPageModal(false)
    }

    const onClickFeedbackPage = () => {
        const formData: any = {...state.formData}
        const feedback = {
            subject: formData.subject,
            message: formData.message,
            firstName: user.firstname,
            lastName: user.lastname,
            email: user.email,
            practice: formData.practice,
            file: formData.file,
            dateTime: moment().format('MM/DD/YYYY hh:mm A')
        };
        if (!formData.subject || !formData.message) {
            alert('Please enter subject and message');
            return;
        }
        appActions.sendFeedback(feedback)
        hideFeedbackModal()
    }

    const onChangeFeedback = (formData: any) => {
        setStates({...state, formData});
    };

   const openDefaultModal = ()=>{
       setDefaultPageModal(true);
   }

   const hideDefaultPageModal=()=>{
     setDefaultPageModal(false);
   }

   const onClickDefaultPage=()=>{


     if(props.user.defaultPage)
     {
        authActions.updateDefaultPage({
            defaultPage : selectedDefaultPage
        })
     } else {
        authActions.setDefaultPage({
            defaultPage : selectedDefaultPage
        });

      }
       hideDefaultPageModal();


   }

  const onChangeDefaultPage=(e:React.FormEvent<HTMLInputElement> , {value})=>{
     setDefaultPage(value)
   }

  const {user, logout, listenerFromParent, mycdp_uri } = props;
  
  let isAdmin=null
  let image=null
  if(user){
    isAdmin=user.isAdmin || false
    image=<span><Image avatar className='mr-1' src={user.profilePicture}/> <span className="userName">{user.firstname} {user.lastname}</span></span>
  }
  const helpUrl = mycdp_uri ? mycdp_uri.replace('?token', `admin/setup/manager-help?token`) : '#';
    return (
        <Menu secondary className='mb-0 mt-0'>
               <a onClick={() => setFeedbackPageModal(true)} className="btnFeedback">
        <small>FEEDBACK</small>
      </a>
        { feedbackModal &&
           <FeedbackModal hideFeedbackModal={hideFeedbackModal} onChangeFeedback={onChangeFeedback} onClickFeedbackPage={onClickFeedbackPage}/>
        }
         { defaultPageModal &&
           <DefaultPageModal defaultPage = {user.defaultPage} hideDefaultPageModal={hideDefaultPageModal} onChangeDefaultPage={onChangeDefaultPage} onClickDefaultPage={onClickDefaultPage}/>
        }
        <Menu.Item className='ml-0 mr-0 mr-lg-4 float-left float-lg-none toggle inverted' icon='content'
                   active={activeItem === 'home'}
                   onClick={listenerFromParent}/>
            <div className="clickable" onClick={(e)=>{props.handleClick(e)}}>
          <img src="/images/logoSmall.png" className="logoSmall" alt="Logo"></img>
            </div>
        <Menu.Menu position='right' className='float-right float-lg-none'>
          <Dropdown className="user" trigger={image} icon={null}/>
          <Dropdown className='setting inverted item mr-0' icon='ellipsis vertical'>
            <Dropdown.Menu>
              <Dropdown.Item text='Logout' onClick={logout}/>
                <Dropdown.Item text='Feedback' onClick={openFeedback} />
                <Dropdown.Item text='Default Page' onClick={openDefaultModal} />
                {isAdmin ? <Dropdown.Item>
                      <a style={{color:'rgba(0, 0, 0, 0.87)'}} href={helpUrl} target='_blank'>Manage Help</a>
                    </Dropdown.Item> : ""}
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu> 
    )
}

export default Header