import { ActionType } from "../../model/actionTypes";
import {errorHandler} from "./auth";
const moment = require('moment');

export const getNewFlowData = (filters:any) => async (dispatch:Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD });
    let filterDates='';
    const srtFilters=filters.sort(function(a,b){
        return new Date(moment(a).format('YYYY-MM-DD')).valueOf() - new Date(moment(b).format('YYYY-MM-DD')).valueOf();
      })
    srtFilters.forEach((dItem)=>{
      filterDates+='/'+ moment(dItem).format('YYYY-MM-DD');
    });

    try {
        let res = await api.get(`patientFinance/patient-finance-info${filterDates}`);
        dispatch({ type: ActionType.LOAD_NEW_FLOW_SUCCESS, payload: {
            newflowdata : res 

        } });
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_NEW_FLOW_FAIL, error: err.message });
        return false;
    }
};
export const getYearlyNewFlowData = (filters:any) => async (dispatch:Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD });
    let filterDates='';
    const srtFilters=filters.sort(function(a,b){
        return new Date(moment(a).format('YYYY-MM-DD')).valueOf() - new Date(moment(b).format('YYYY-MM-DD')).valueOf();
    })
    srtFilters.forEach((dItem)=>{
        filterDates+='/'+ moment(dItem).format('YYYY-MM-DD');
    });

    try {
        let res = await api.get(`report/yearlyPaymentReport${filterDates}`);
        dispatch({ type: ActionType.LOAD_YEARLY_NEW_FLOW_SUCCESS, payload: {
                newflowdata : res

            } });
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_YEARLY_NEW_FLOW_FAIL, error: err.message });
        return false;
    }
};
export const getAllPatientPlan = (PatNum:any) => async (dispatch:Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD });

    try {
        let res = await api.get(`patientFinance/all-payplans-by-patnum/${PatNum}`);
        dispatch({ type: ActionType.LOAD_ALL_PAYPLAN_BY_PATNUM_SUCCESS, payload: {
                payPlans : res

            } });
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_ALL_PAYPLAN_BY_PATNUM_FAIL, error: err.message });
        return false;
    }
};

export const addStatusAction =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        const url = `patientFinance/addstatusaction`;
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp = await api.post(url, data);
            dispatch({ type: ActionType.SAVE_ACTIONS_STATUS, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.LOADER, payload: false });
            return dispatch(errorHandler(error));
        }
    };
export const getPayPlanData = () => async (dispatch:Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD, payload: true });
    try {
        const plan={ytd:0,mtd:0,wtd:0,today:0};
        const today=moment().startOf('day').format('YYYY-MM-DD');
        const mtd=moment().subtract(1,"months").startOf('day').format('YYYY-MM-DD');
        const wtd=moment().subtract(7,"days").startOf('day').format('YYYY-MM-DD')
        let res = await api.get(`patientFinance/user-amount-info/${moment().subtract(12, 'months').format('YYYY-MM-DD')}/${today}`);
        let resValues:any=Object.values(res)
        resValues=resValues.flat();
        resValues.forEach(item=>{
            const splitAmt=isNaN(item.SplitAmt)?0:Number(item.SplitAmt)
            plan.ytd+=splitAmt;
            if(moment(item.DatePay).isSame(today)||moment(item.DatePay).isAfter(today)){
                plan.today+=splitAmt;
            }
            if(moment(item.DatePay).isSame(mtd)||moment(item.DatePay).isAfter(mtd)){
                plan.mtd+=splitAmt;
            }
            if(moment(item.DatePay).isSame(wtd)||moment(item.DatePay).isAfter(wtd)){
                plan.wtd+=splitAmt;
            }
        })
        dispatch({
            type: ActionType.LOAD_PAY_PLAN_SUCCESS,
            payload: {
                plan : plan
            } });
        dispatch({ type: ActionType.LOADER, payload: false });
        return plan;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_PAY_PLAN_FAIL, error: err.message });
        return false;
    }
};
