import React, {useEffect, useMemo, useState} from "react";
import "semantic-ui-css/semantic.min.css";
import {Button, Dropdown, Form, Grid, Icon, Input, Tab} from "semantic-ui-react";
import {RootState} from "../model";
import {connect} from "react-redux";
import * as ClaimsActions from "../redux/actions/claims";
import * as AppActions from "../redux/actions/app";
import * as openIssuesActions from "../redux/actions/openIssues";
import {useActions} from "../redux/actions";
import {ClaimsProps, LastFetched, MemberStatus} from "../model/claims";
import NotesModal from "./NotesModal";
import {
    accounts,
    brands,
    claimsSearchKeys,
    claimsTableHeadersMap,
    daysFilter,
    defautPracticeAbbr, emdeonStatus,
    HELP_PAGE,
    MCOTYPE
} from "../constants"
import {CSVLink} from "react-csv"
import moment from "moment"
import SummaryModal from "./SummaryModal";
import ViewNotesModal from "./ViewNotesModal";
import _ from "lodash";
import ClaimsTablePage from './ClaimsTable'
import HelpMark from "./Help/helpMark";
import {ProviderAccount} from "../model/providers";
import {mapDownloadData} from "../common";

const ClaimsForm = (props: ClaimsProps) => {


    const claimsActions = useActions(ClaimsActions)
    const appActions = useActions(AppActions);
    const openIssuesAction = useActions(openIssuesActions);
    const [state, setStates] = useState({
        column: "dateOfService",
        direction: "ascending",
        memberStatus: [],
        isThereOpenIssues: false
    });
    const [statusFilter, setStatusFilter] = useState("All");
    const [currentPractice, setCurrentPractice] = useState(defautPracticeAbbr);
    const [currentDuration, setCurrentDuration] = useState(3);
    const [currentBrand, setCurrentBrand] = useState([]);
    const [emdeonFilter, setEmdeonFilter] = useState("All");
    const [hiddenColumns, setHiddenColumns] = useState(['Notestimestamp']);
    const [columnOptions] = useState(Object.keys(claimsTableHeadersMap)
        .map(key => ({
                value: claimsTableHeadersMap[key],
                text: key
            })
        ));

    const [providerState, setProviderState] = useState({providerAccountList: []});
    const [searchQuery, setSearchQuery] = useState('')
    const [active, setActive] = useState("");
    const [filter, setFilter] = useState([]);
    const [showTableColumnsFilter, setShowTableColumnsFilter] = useState(false);

    let filterOption: any = [];

    const practiceNames: any = {};
    const practiceOptions: any = [{
        key: 'All',
        value: 'All',
        text: 'All'
    }];
    useEffect(() => {
        setTimeout(function () {
            claimsActions.GetLastFetched();
            appActions.FetchPracticeList();
            checkOpenIssues();
            if (providerState.providerAccountList.length === 0) {
                claimsActions.getProviderAccounts().then((res: any) => {
                    setProviderState({...providerState, providerAccountList: res})
                });
            }
        }, 0)
    }, []);


    const checkOpenIssues = async () => {
        openIssuesAction.checkOpenIssues()
            .then((result) => {
                setStates({...state, isThereOpenIssues: result });
            });
    }

    const filteredMembersData = (currentFilter, currentDuration, keyword, emdeonFilter) => {
        return state.memberStatus.filter(current => {
            const meetsKeywordCriteria = claimsSearchKeys.some(prop => current[prop].toLowerCase().includes(keyword.toLowerCase()));
            const meetsFilterCriteria = currentFilter === "All" ? true : (currentFilter ? currentFilter === current.status : !current.status);
            const emdeonFilterCriteria = emdeonFilter === 'Blank' ? !current.emdeonStatus : emdeonFilter === "All" ? true : (emdeonFilter && current.emdeonStatus && current.emdeonStatus.includes(emdeonFilter));
            const meetsDurationCriteria = currentDuration === 0 ? true : (moment().diff(current.dateSent, 'days') >= currentDuration);
            return meetsFilterCriteria && meetsDurationCriteria && meetsKeywordCriteria && emdeonFilterCriteria;
        });
    }


    const generateTableResults = useMemo(() => filteredMembersData(statusFilter, currentDuration, searchQuery, emdeonFilter), [searchQuery, statusFilter, currentDuration, state.memberStatus, emdeonFilter]);


    props.practiceList && props.practiceList.forEach((prac: any) => {
        const obj = {
            key: prac.id,
            value: prac.practiceabbr,
            text: prac.practice,
        };
        practiceNames[prac.practiceabbr] = prac.practice;

        if(currentBrand.length){
            if(currentBrand.indexOf(prac.UdaPracticeInfo.brand) >= 0){
                practiceOptions.push(obj);
            }
            return;
        }
        practiceOptions.push(obj);


    })

    const generateLastFetched = (dateValue: Array<LastFetched>) => {
        if (dateValue.length < 1) return "Fetching....";
        const date = new Date(dateValue[0].value);
        return moment(date).format("DD MMMM YYYY,h:mm:ss a")
    };

    const tabItems = () => {
        return accounts.map(item => {
            filterOption.push(item);
            return {
                menuItem: `${item.mcoName}`,
                render: () => (
                    <Tab.Pane attached={false}>
                        <ClaimsTable
                            memberStatus={props.memberStatus}
                            memberStatusCount={props.memberStatusCount}
                            lastFetched={props.lastFetched}
                            practiceList={props.practiceList}
                            currentIdentifier={item.identifier}
                            accountName={item.mcoName.toLocaleLowerCase()}
                            groupIdentifier={item.accountsIdentifier}
                            mcoType={item.mcoType}
                            setStates={setStates}
                            state={state}
                            generateTableResults={generateTableResults}
                            statusFilter={statusFilter}
                            setStatusFilter={setStatusFilter}
                            currentPractice={currentPractice}
                            setCurrentPractice={setCurrentPractice}
                            searchQuery={searchQuery}
                            emdeonFilter = {emdeonFilter}
                            setActive={setActive}
                            currentDuration={currentDuration}
                            currentBrand={currentBrand}
                            hiddenColumns={hiddenColumns}
                        />
                    </Tab.Pane>
                )
            }
        })
    }

    useEffect(() => {
        setTimeout(function () {
            filterOption.forEach((i) => {
                if (active === i.identifier) {
                    setFilter(i.filterOptions);
                }
            })
        }, 0)
    }, [active])

    const tabs = () => <Tab className="claimMenu" menu={{pointing: true}} panes={tabItems()}/>;

    const memberCount = generateTableResults.length;
    const getSegment = () => {
        let { providerAccountList } = providerState;

        let ErrorProviderAccounts:any = [];
        const foundOption = practiceOptions.find(option => option.value === currentPractice);
        const selectedPractice = foundOption ? foundOption.text : 'all';
        if (selectedPractice === 'all' || selectedPractice === '') {
            ErrorProviderAccounts = providerAccountList;
        } else {
            if(providerAccountList)
                providerAccountList.forEach((providerAccount: ProviderAccount) => {
                    const practice: string = providerAccount && providerAccount.practice ? providerAccount.practice : 'all';
                    if (selectedPractice.toLowerCase() === practice.toLowerCase()) {
                        ErrorProviderAccounts.push(providerAccount);
                    }
                });
        }

        if (ErrorProviderAccounts.length > 0) {
            const groupedByMCO = ErrorProviderAccounts.reduce((acc: any, obj: any) => {
                const key = obj.mco;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
            }, {});

            if (Object.keys(groupedByMCO).length > 0) {
                const segment:any = [];
                Object.keys(groupedByMCO).forEach((key: any) => {
                    let icon;
                    let name = key;
                    if(key==='DENTA'){
                        name = 'DentaQuest';
                    }else if(key=='UNITED HEALTH CARE'){
                        name ='UHC Medicaid'
                    }else if(key=='DENTAL HUB'){
                        name ='UHC'
                    }
                    if(groupedByMCO[key].filter((providerAccount: any) => providerAccount.lastLoginResult !== null && providerAccount.lastLoginResult === 'Failed').length > 0){
                        icon = {key : name, iconName: 'delete', iconColor: 'red'}
                    } else {
                        icon = {key : name, iconName: 'check circle', iconColor: 'green'}
                    }
                    if(key!=='SPORE'){
                        segment.push(icon);
                    }

                });

                return segment;
            }

        }

    }

    const segments = getSegment();

    function getAllSegment(segments: any) {
        return (
            <h4 className="mt0 inline">
                {segments.map((segment: any) => {
                    return (
                        <b className="no-wrap mr20 pb10 inline-block" key={segment.key}>{segment.key}  <Icon name={segment.iconName} color={segment.iconColor} /></b>
                    )
                })}
            </h4>)
    }

    return (
        <div>
            <div className="ui card widthFluid">
                <div className="content">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h2 className="headingMain">Claims <HelpMark
                                    helpPage={HELP_PAGE.BILLING_CLAIMS} application="Claims Team" />({memberCount})
                                </h2>
                                <span className="d-InlineBlock mt10 mb15 colorGray font-semibold f12">
                                    LAST FETCHED :
                                    <span
                                        className="bodyColor ml10 font-semibold f13">{generateLastFetched(props.lastFetched)}</span>
                                </span>

                                <div className="topFilters">
                                    <div className="filter d-InlineBlock float-left">
                                        <label className="labelStyle displayBlock">Filter By Brand
                                        </label>
                                        <Dropdown
                                            className="mb15 mr10"
                                            placeholder="Select Brand"
                                            search
                                            selection
                                            multiple={true}
                                            value={currentBrand}
                                            options={brands}
                                            onChange={(event, data:any) => {
                                                setCurrentBrand(data.value);
                                                if(data.value && data.value.length) {
                                                    setCurrentPractice('All');
                                                }

                                            }}
                                        />
                                    </div>
                                    <div className="filter d-InlineBlock float-left">
                                        <label className="labelStyle displayBlock">Filter By Duration
                                        </label>
                                        <Dropdown
                                            className="mb15 mr10"
                                            placeholder="Select Duration"
                                            search
                                            selection
                                            value={currentDuration}
                                            options={daysFilter}
                                            onChange={(event, data) => {
                                                setCurrentDuration(data.value as number);
                                            }}
                                        />
                                    </div>
                                    <div className="filter d-InlineBlock float-left">
                                        <label className="labelStyle displayBlock">Filter By Practice
                                        </label>
                                        <Dropdown
                                            className="mb15 mr10"
                                            placeholder="Select Practice"
                                            search
                                            selection
                                            value={currentPractice}
                                            options={practiceOptions}
                                            onChange={(event, data) => {
                                                setCurrentPractice(data.value as string);
                                            }}
                                        />
                                    </div>
                                    <div className="filter d-InlineBlock float-left">
                                        <label className="labelStyle displayBlock">FILTER BY STATUS</label>
                                        <Dropdown
                                            className="mb15 mr10"
                                            placeholder="Select Status"
                                            search
                                            selection
                                            value={statusFilter}
                                            text={statusFilter === null ? 'Null' : filterOption.find(option => option.value === statusFilter)?.text}
                                            options={[{key: 'All', value: 'All', text: 'All'}, ...filter]}
                                            onChange={(event, data) => {
                                                setStatusFilter(data.value as string)
                                            }}
                                        />
                                    </div>
                                    <div className="filter d-InlineBlock float-left">
                                        <label className="labelStyle displayBlock">DentalXChange STATUS</label>
                                        <Dropdown
                                            className="mb15 mr10"
                                            placeholder="Select Status"
                                            search
                                            selection
                                            value={emdeonFilter}
                                            options={[{key: 'All', value: 'All', text: 'All'}, ...emdeonStatus]}
                                            onChange={(event, data) => {
                                                setEmdeonFilter(data.value as string)
                                            }}
                                        />
                                    </div>
                                    <div className="filter d-InlineBlock float-left">
                                        <label className="labelStyle displayBlock">SEARCH</label>
                                        <Input className="mb15 mr10" focus placeholder="Search"
                                               onChange={(_, data) => {
                                                   setSearchQuery(data.value)
                                               }}
                                        />
                                    </div>
                                    <div className="filter d-InlineBlock float-left">
                                        <Button className="mt20"
                                                toggle
                                                active={showTableColumnsFilter}
                                                onClick={(event) => setShowTableColumnsFilter(!showTableColumnsFilter) }
                                        >
                                            Change Columns
                                        </Button>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        { showTableColumnsFilter &&
                            <Grid.Row className="pt0">
                                <Grid.Column>
                                    <Form.Field>
                                        <label className="labelStyle displayBlock">Hidden Columns</label>
                                        <Dropdown
                                            search={true}
                                            selection={true}
                                            className="mb15"
                                            placeholder="Hide Columns"
                                            fluid={true}
                                            multiple={true}
                                            value={hiddenColumns}
                                            options={columnOptions}
                                            onChange={(event, data) => {
                                                setHiddenColumns(data.value as any[]);
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        }
                    </Grid>
                </div>
            </div>
            <div className="ui card claims widthFluid">
                <div className="content">
                    {segments && getAllSegment(segments)} {tabs()}
                </div>
            </div>
        </div>
    );
};

interface ClaimsTableProps {
    memberStatus: Array<MemberStatus>;
    memberStatusCount?: any;
    practiceList: any;
    lastFetched: Array<LastFetched>;
    currentIdentifier: string;
    accountName: string
    groupIdentifier: string
    mcoType: MCOTYPE
    state: any
    setStates: Function
    generateTableResults: any
    setStatusFilter: Function
    setCurrentPractice: Function
    statusFilter: string
    currentPractice: any
    searchQuery: string
    emdeonFilter : string
    setActive: Function
    currentDuration : number
    currentBrand : Array<string>
    hiddenColumns: Array<string>
}

function ClaimsTable(props: ClaimsTableProps) {
    const claimsActions = useActions(ClaimsActions);
    const [canAddNotes, setCanAddNotes] = useState(false)
    const [showFullNote, setShowFullNote] = useState(false)
    const [patientData, setPatientData] = useState({})
    const [currentNotesItem, setCurrentNotesItem] = useState<MemberStatus>()
    const [summary, setSummary] = useState(false)
    const {
        state,
        setStates,
        generateTableResults,
        setStatusFilter,
        statusFilter,
        setCurrentPractice,
        currentPractice,
        currentBrand,
        emdeonFilter
    } = props;

    useEffect(() => {
        setTimeout(function () {
            props.setActive(props.currentIdentifier);
            claimsActions.FetchStatusCount({
                identifier: props.currentIdentifier,
            });
        }, 0)
    }, [props.currentIdentifier]);

    useEffect(() => {
        setTimeout(function () {
            loadMemberStatusData();
        }, 0)
    }, [currentBrand, currentPractice, props.currentIdentifier]);

    const loadMemberStatusData = () => {
        claimsActions.FetchMemberStatus({
            identifier: props.currentIdentifier,
            practice: currentPractice,
            brand : currentBrand
        })
            .then((res: any) => setStates({
                ...state,
                memberStatus: res.sort((a, b) => {
                    const dateA:any = Number( a.getStatus?moment(a.getStatus, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'):"0");
                    const dateB:any = Number( b.getStatus?moment(b.getStatus, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'):"0");
                    return dateB - dateA;
                })
            }));
    }

    const allPracticeNames: any = {};
    props.practiceList && props.practiceList.forEach((account: any) => {
        allPracticeNames[account.practiceabbr] = account.practice;
    })

    const hideSummaryModal = () => {
        setSummary(false)
    }

    const handleSort = (clickedColumn: any) => () => {
        const {column, memberStatus, direction} = state;
        if (column !== clickedColumn) {
            if ((clickedColumn === 'ptNum' || clickedColumn === 'paidAmount'
                || clickedColumn === 'amount' || clickedColumn === 'feeBilled' || clickedColumn === 'claimNum')) {
                setStates({
                    ...state,
                    column: clickedColumn,
                    memberStatus: memberStatus.sort((a, b) => Number((a[clickedColumn])) - Number((b[clickedColumn]))),
                    direction: 'ascending',
                });

                return;
            }
            if ((clickedColumn === 'dob' || clickedColumn === 'dateOfService' || clickedColumn === 'dateResent' || clickedColumn === 'dateSent')) {
                setStates({
                    ...state,
                    column: clickedColumn,
                    memberStatus: memberStatus.sort((a, b) => Number(new Date((a[clickedColumn]))) - Number(new Date((b[clickedColumn])))),
                    direction: 'ascending',
                });

                return;
            }
        }


        if (column !== clickedColumn) {
            setStates({
                ...state,
                column: clickedColumn,
                memberStatus: _.sortBy(memberStatus, [clickedColumn]) as any,
                direction: 'ascending',
            });

            return
        }

        setStates({
            ...state,
            memberStatus: memberStatus.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };

    const viewNote = (item: any) => {
        setPatientData({...item, notes: item.notes, PatNum: item.ptNum});
        setCurrentNotesItem(item)
        setShowFullNote(true);
    }

    const getEmdeonStatusHandler = () => {
        if (window.confirm("Are you sure?")) {
            const obj = {
                identifier: props.currentIdentifier,
                practice: currentPractice,
                status: statusFilter,
                emdeonFilter : emdeonFilter
            }
            claimsActions.GetEmdeonClaimsStatus(JSON.stringify(obj))
                .then(res => {
                    alert(res.msg)
                })
        }
    }
    const getMCOstatusHandler = () => {
        if (window.confirm("Are you sure?")) {
            const obj = {
                identifier: props.currentIdentifier,
                practice: currentPractice,
                status: statusFilter
            }
            claimsActions.GetClaimsStatus(JSON.stringify(obj))
                .then(res => {
                    alert(res.msg)
                })
        }
    }
    const GetOpenDentalHandler = () => {
        if (window.confirm("Are you sure?")) {
            claimsActions.GetOpenDentalData()
                .then(res => {
                    alert(res.msg)
                })
        }
    }

    const viewSummary = () => {
        setSummary(props.memberStatusCount)
    }

    const hideNotesModal = () => {
        setCanAddNotes(false)
    }

    const submitNotes = (notes: string) => {
        closeNotesModal();
        closeViewNotes();
        const payload = {
            statusId: currentNotesItem?.id,
            notes: notes
        }
        claimsActions.SubmitNotes(payload).then(() => {
            loadMemberStatusData();
        })

    }

    const openNotesModal = (currentItem: MemberStatus) => {
        setCurrentNotesItem(currentItem)
        setCanAddNotes(true)
    }

    const closeNotesModal = () => {
        setCanAddNotes(false)
    }
    const closeViewNotes = () => {
        setShowFullNote(false);
        setPatientData({})
    }

    const { memberStatus, hiddenColumns: hiddenColumns } = props;
    return (
        <>
            <Grid>
                <Grid.Row className="pb0" textAlign="right">
                    <Grid.Column width={16}>
                        {props.mcoType !== 5 ? <>
                            <Button primary className="mb10" onClick={GetOpenDentalHandler}>Get From OpenDental</Button>
                        </>: ''}
                        {props.mcoType !== 5 && props.mcoType !== 2 ? <>
                            <Button primary className="mb10" onClick={getMCOstatusHandler}>Get MCO Status</Button>
                        </>: ''}

                        <Button primary className="mb10" onClick={getEmdeonStatusHandler}>Get DentalXChange Status</Button>
                        <Button primary className="mb10">
                            <CSVLink
                                filename={`MyReport_${MCOTYPE[props.mcoType]}.csv`}
                                data={mapDownloadData(memberStatus, allPracticeNames)}
                            >
                                <span className="color-white">Download CSV</span>
                            </CSVLink>
                        </Button>
                        <Button primary className="mb10" onClick={viewSummary}>View Summary</Button>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {summary &&
                <SummaryModal
                    hideSummaryModal={hideSummaryModal}
                    summary={summary}
                    setStatusFilter={setStatusFilter}
                    setCurrentPractice={setCurrentPractice}
                />}
            {canAddNotes &&
                <NotesModal hideNotesModal={hideNotesModal} closeNotesModal={closeNotesModal}
                            submitNotes={submitNotes}/>}
            {showFullNote &&
                <ViewNotesModal
                    closeViewNotes={closeViewNotes}
                    patientData={patientData}
                    submitNotes={submitNotes}
                />}
            <Grid className="mt0">
                <Grid.Row>
                    <Grid.Column width={16}>
                        {state.memberStatus.length > 0 &&
                            <ClaimsTablePage
                                columns={claimsTableHeadersMap}
                                data={generateTableResults}
                                practiceNames={allPracticeNames}
                                openNotesModal={openNotesModal}
                                viewNote={viewNote}
                                handleSort={handleSort}
                                hiddenColumns={hiddenColumns}
                            />}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}

function mapStateToProps(state: RootState) {
    return {
        memberStatus: state.claims.memberStatus,
        memberStatusCount: state.claims.memberStatusCount,
        practiceList: state.app.practiceList,
        lastFetched: state.claims.lastFetched
    };
}

export default connect(mapStateToProps)(ClaimsForm);
