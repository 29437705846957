import React, {useEffect,useState} from "react";
import _ from 'lodash';
import * as ActionSetup from "../redux/actions/setupActions"
import { useActions } from "../redux/actions";
import {Table, Icon, Confirm, Button, Input, Form} from 'semantic-ui-react';


interface SetupTableProps {
    titleHeader:string;
    loadData: Function;
    list: any;
}
interface SetupData {
    confirm:boolean;
    deleteId:any;
    type:any;
    editItem:any;
    column:any;
    data:any;
    direction:any;

};
const SetupTable:React.FC<SetupTableProps>=(props)=>{
    const initialState: SetupData = {
        confirm: false,
        deleteId: false,
        type: false,
        editItem: false,
        column: null,
        data: [],
        direction: null,
    };

    const [state, setStates] = useState(initialState);
    const setupActions = useActions(ActionSetup);
 
    const  handleSort = (clickedColumn: any) => () => {
        const {column, data ,direction} = state;   
        if (column !== clickedColumn) {
         
          setStates({
              ...state,
            column: clickedColumn,
            data: _.sortBy(data, [clickedColumn]),
            direction: 'ascending',
          });
    
          return
        }

        setStates({
          ...state,
          data: data.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
      };

   const onChange = (e: any) => {
        let editItem = state.editItem;
        editItem[e.target.name] = e.target.value;
        setStates({...state,editItem});
      };

   const  openConfirm = (id: number, type: string) => {
        setStates({...state ,confirm: true , deleteId:id ,type:type});

      };
    
   const closeConfirm = () => {
        setStates({...state,confirm: false});
      };
    
    const  handleDelete = () => {
        setStates({...state,confirm: false});
        let obj = {
          id: state.deleteId,
          type:state.type
        };
        setupActions.deleteSetup(obj).then((_data: any) => {
            props.loadData();
          });
      };
   
    const submitEdit = () => {
        let obj = {
          id: state.editItem.id,
          title: state.editItem.title,
          type:state.editItem.type,
          sequence: state.editItem.sequence
        };
        setupActions.updateSetup(obj).then((_data: any) => {
            setStates({...state,editItem: false});
            props.loadData();
          });
      };
    
   const  handleEdit = (item: any) => {
        setStates({...state,editItem: item});
      };


    
    return (
        <div className="tableResponsive">
          <Table unstackable sortable striped className="customTable setupTable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell sorted={state.column === 'sequence' ? state.direction : null} onClick={()=>handleSort('sequence')}>
                  Sequence {state.column !== 'sequence' ? '' : ''}
                </Table.HeaderCell>
  
                <Table.HeaderCell sorted={state.column === props.titleHeader ? state.direction : null} onClick={()=>handleSort('title')}>
                  {props.titleHeader} {state.column !== props.titleHeader ? '' : ''}
                </Table.HeaderCell>

                <Table.HeaderCell width={8}>
                  <div>Action</div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
  
              {
                props.list &&  props.list.length > 0 &&  props.list.map((item: any, key: number) => {
                  return (
                    <Table.Row key={key} style={{borderLeft: '5px solid '}}>
                      <Table.Cell>
                          {state.editItem && state.editItem.id === item.id ? <Form.Field>
                            <Input style={{minWidth: '70px'}} name='sequence' fluid placeholder='Sequence'
                                   onChange={(e) => onChange(e)} defaultValue={state.editItem.sequence}/>
                          </Form.Field> : item.sequence}
                        </Table.Cell>                      
                      <Table.Cell>
                        {state.editItem && state.editItem.id === item.id ?                      
                                <Form.Field >
                                <Input style={{minWidth: '124px'}} name='title' fluid placeholder='Title'
                                       onChange={(e) => onChange(e)} defaultValue={state.editItem.title}/>
                                </Form.Field>

                          : item.title}
                      </Table.Cell>
                      <Table.Cell className="pb0">
                        {state.editItem && state.editItem.id === item.id ?
                          <span><Button primary className="mr10 mb10" onClick={submitEdit}>Update</Button>
                  <Button secondary className="mb10"
                        onClick={() => setStates({...state,editItem: false})}>Cancel</Button></span>
                                        : <span className="mb10 float-left"><Icon name='edit' className="mr15 cursorPointer"
                                                                    onClick={() => handleEdit(item)}/>
                    <Icon name='trash alternate' className="cursorPointer"
                          onClick={() => openConfirm(item.id, item.type)}/></span>}
                            </Table.Cell>
                    </Table.Row>
                  )
                })
              }
            </Table.Body>
  
            <Confirm
              cancelButton='No'
              confirmButton="Yes"
              className="confirmModal"
              content="Are you sure you want to delete?"
              open={state.confirm}
              onCancel={closeConfirm}
              onConfirm={handleDelete}
            />
          </Table>
        </div>
  
      );
}

export default SetupTable