import React from "react";
import {Button, Modal, Input, Header, TextArea, Form} from "semantic-ui-react";
import {useFormik} from "formik"

const NotesModal:React.FC<any>=(props)=>{
    const formik=useFormik(
        {
            initialValues:{
                notes:''
            },
            onSubmit:values => {
                props.submitNotes(values.notes)
            }
        }
    )
    return (
        <Modal
            open={true}
            onClose={props.hideNotesModal}
            centered={false}
            size='tiny'
            closeIcon
        >
            <Header content='Claim Notes'/>
            <Modal.Content>
                <Form className="formStyle">
                    <label>Add Note</label>
                    <TextArea rows={6} fluid name='notes' placeholder='Notes' onChange={formik.handleChange} value={formik.values.notes}/>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={props.closeNotesModal}>
                    Cancel
                </Button>
                <Button className='ui primary button' onClick={formik.submitForm}>
                    Submit
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default NotesModal