import React,{useState} from "react";
import {Button, Modal, Input, Header, Form, Dropdown} from "semantic-ui-react";
import {useFormik} from "formik";
import { brandNames } from "../constants";

const validate = values => {
    const errors:any = {};
    if (!values.Username) {
      errors.Username = 'Required**';
    }
  
    if (!values.Password) {
      errors.Password = 'Required**';
    } 
  
    if (!values.Practice) {
      errors.Practice = 'Required**';
    }
  
    return errors;
  };

const AddXchargeModal:React.FC<any>=(props)=>{
    const formik=useFormik(
        {
            initialValues:{
                Brand:props.editItem?props.data.brand:null,
                Username:props.editItem?props.data.username:null,
                Password:props.editItem?props.data.password:null,
                Practice:props.editItem?props.data.practiceId:null,
                PracticeAbbr:props.editItem?props.data.practiceabbr:null,
                Port:props.editItem?props.data.port:'',
                IPAddress:props.editItem?props.data.ipaddress:'',
            },
            validate,
            onSubmit:values => {
                props.submitXchargedata(values)
            }
        }
    )

    const [practiceExist , setPracticeExist] = useState(false)

    const handlePractice = (data)=>{  
     if(props.practicesIDs.indexOf(data.value)==-1){
        formik.values.Practice = data.value;
        const optionItem = data.options.filter((item)=> {return item.value==data.value?item : false});
          formik.values.PracticeAbbr = optionItem[0].key;
        brandNames.map((brandItm)=>{
            if(optionItem[0].key.startsWith(brandItm.key)){
                formik.values.Brand = brandItm.value;
            }
        })
       }
       else{
          setPracticeExist(true)
        }
       }



    return (
        <>
        <Modal
            open={true}
            onClose={props.hideAddModal}
            centered={false}
            size='large'
            closeIcon
        >
            <Header content={"Setup Xcharge "}/>
            <Modal.Content>
                <Form className="formStyle">
                  <Form.Field>
                    <label>Practice</label>
                    <Dropdown selection search options={props.practiceList} onChange={(e,data)=>handlePractice(data)} defaultValue={formik.values.Practice} />
                    {formik.errors.Practice || formik.errors.Practicez ? <div style={{color:'red'}} >{!props.editItem?formik.errors.Practicez:formik.errors.Practice}</div> : null}
                    </Form.Field>
                    <Form.Field>
                    <label>UserName</label>
                    <Input fluid name='Username'  onChange={formik.handleChange}  value={formik.values.Username} required/>
                    {formik.errors.Username ? <div style={{color:'red'}}>{formik.errors.Username}</div> : null}
                    </Form.Field>
                    <Form.Field>
                    <label>Password</label>
                    <Input fluid name='Password' onChange={formik.handleChange}  value={formik.values.Password} required/>
                    {formik.errors.Password ? <div style={{color:'red'}} >{formik.errors.Password}</div> : null}
                    </Form.Field>
                    <Form.Field>
                    <label>Brand</label>
                    <Dropdown selection options={brandNames} onChange={formik.handleChange} value={formik.values.Brand} disabled/>
                    </Form.Field>
                    <Form.Field>
                    <label>Practice Abbr</label>
                    <Input fluid name='PracticeAbbr' onChange={formik.handleChange}   value={formik.values.PracticeAbbr} readOnly/>
                    </Form.Field>                           
                    <Form.Field>
                    <label>Port #</label>
                    <Input fluid name='Port' onChange={formik.handleChange}  value={formik.values.Port} />         
                    </Form.Field>
                    <Form.Field>
                    <label>IP Address</label>
                    <Input fluid name='IPAddress' onChange={formik.handleChange}  value={formik.values.IPAddress}/>                  
                    </Form.Field>

               </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={props.hideAddModal}>
                    Cancel
                </Button>
                <Button className='ui primary button' onClick={formik.submitForm}>
                   { props.editItem? 'Update':'Submit'}
                </Button>
            </Modal.Actions>
        </Modal>
        
   {practiceExist && 
   <Modal
   open={true}
   onClose={()=>setPracticeExist(false)}
   centered={false}
   size='tiny'
   closeIcon
   >
     <Header content={"Alert"}/>
     <Modal.Content>
     <p>Practice Already exists </p>
     </Modal.Content>
      <Modal.Actions>
      <Button secondary onClick={()=>setPracticeExist(false)}>Ok</Button>
      </Modal.Actions>
    </Modal>}
         </>
    )
}

export default AddXchargeModal