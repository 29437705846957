import React from "react";
import {Button, Modal, Header, Table} from "semantic-ui-react";
const PayPlanModal:React.FC<any>=(props)=>{
    const  {ytd,mtd,wtd,today}=props.payPlanData

    const numberWithCommas=(x:number)=> {
        const parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
    return (
        <Modal
            open={true}
            onClose={props.hidePayPlanModal}
            centered={false}
            size='tiny'
            closeIcon
        >
            <Header>
                <Button primary onClick={props.refreshPayPlan}>
                    Refresh
                </Button>
                {`   Pay Plan Collection:(${props.lastRefresh})`}
            </Header>
            <Modal.Content>
                <div className="table-responsive FreezeTable">

                    <Table  className="table tableCustomStyle mb0">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>YTD</Table.HeaderCell>
                                <Table.HeaderCell>MTD</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Row>
                            <Table.Cell>${numberWithCommas(Math.round((ytd + Number.EPSILON) * 100) / 100)}</Table.Cell>
                            <Table.Cell>${numberWithCommas(Math.round((mtd + Number.EPSILON) * 100) / 100)}</Table.Cell>
                        </Table.Row>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>WTD</Table.HeaderCell>
                            <Table.HeaderCell>Today</Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Row>
                            <Table.Cell>${numberWithCommas(Math.round((wtd + Number.EPSILON) * 100) / 100)}</Table.Cell>
                            <Table.Cell>${numberWithCommas(Math.round((today + Number.EPSILON) * 100) / 100)}</Table.Cell>
                        </Table.Row>
                    </Table>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={props.hidePayPlanModal}>
                    Close
                </Button>

            </Modal.Actions>
        </Modal>
    )
}

export default PayPlanModal