import React,{useState} from "react";
import { Modal, Table ,Button ,Checkbox} from "semantic-ui-react";
import moment from "moment/moment";

interface Props {
    data: { claimPayData?:object, productionData?:object };
    closePopup: () => void;
    sendCPTotal:Function;
}

const ClaimPayGrandTotal: React.FC<Props> = ({ data ,closePopup,sendCPTotal }) => {
    const [practices] = useState(Object.keys(data&&data.claimPayData?data.claimPayData:[]));
    const [claimPayState, setClaimPayState] = useState({
        productionData:data.productionData,
        claimPayData:data.claimPayData,
        setLevelOne:true,
        setLevelTwo:false
    });
    const [showHideData ,setCheckBoxes] = useState<any>({isDollars:true , isTTL:true ,isGP:true})
    const selectedMonths = Object.keys(data.productionData);
    const claimPayDataValues=Object.values(claimPayState.claimPayData);
    const grossProdTotal=Object.values(claimPayState.productionData || {}).reduce((acc: number, value: any) => {
        return acc + value.production}, 0);

    const renderClaimReqOne = () =>{
        const claimPayGrandByCarrier: any = {};
        Object.values(claimPayDataValues).forEach((monthlyData: string) => {
            Object.values(monthlyData).forEach((mnthObjData) => {
                Object.entries(mnthObjData).forEach(([code, amount]: any) => {
                    if (!claimPayGrandByCarrier[code]) claimPayGrandByCarrier[code] = {[code]: 0};
                    claimPayGrandByCarrier[code][code] += amount;
                });
            });
        });

        Object.entries(claimPayGrandByCarrier).forEach(([code, data]: [string,object]) => {
            data["percentageOfTotal"] = ((data[code] / claimPayGrandByCarrier.total["total"]) * 100).toFixed(2);
            data["percentageOfGrossProduction"] = ((data[code] / grossProdTotal) * 100).toFixed(2);
        });


        let sortedData = {total:{}}
        const keys = Object.keys(claimPayGrandByCarrier);
        // Sort the keys based on absolute value of their corresponding values
        keys.sort((a, b) => {
            if (!claimPayGrandByCarrier[a] || !claimPayGrandByCarrier[b]) return 0; // check if properties exist
            return Math.abs(claimPayGrandByCarrier[b][b]) - Math.abs(claimPayGrandByCarrier[a][a])
        });
        // Add the sorted keys to the new object
        keys.forEach(key => {
            sortedData[key] = claimPayGrandByCarrier[key];
        });
        const sortedDataEntries = Object.entries(sortedData).sort(([keyA], [keyB]) => {
            if ( keyB=="total") return -1 ;// move total to the end
             else
                return 1 ;// sort alphabetically
        });

        return (
            <Table celled size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Carrier Name</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>% of Total Claim Pay</Table.HeaderCell>
                        <Table.HeaderCell>% of Gross Production</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedDataEntries&&sortedDataEntries.map(([carrier,data]:[string,object] , )=>
                        <Table.Row key={carrier} >
                            <Table.Cell>{carrier==="total"?<b>Grand Total</b>:carrier}</Table.Cell>
                            <Table.Cell> {"$"+ Math.abs(data[carrier]).toFixed(2)}</Table.Cell>
                            <Table.Cell>{Math.abs(data["percentageOfTotal"]).toFixed(2)+"%"}</Table.Cell>
                            <Table.Cell>{Math.abs(data["percentageOfGrossProduction"]).toFixed(2)+"%"}</Table.Cell>
                        </Table.Row>
                    )}

                </Table.Body>
            </Table>
        );
    }
    const renderClaimPayTwo= () =>{
        const {claimPayData}=claimPayState;
        const claimPayByCarrierName: any = {};
        const claimPayGrandEntries={};
        practices && practices.forEach((practice)=>{
            if (claimPayData[practice]&&Object.keys(claimPayData[practice]).length > 0) {
                Object.values(claimPayData[practice]).forEach((monthYrDataObj: any) => {
                    Object.entries(monthYrDataObj).forEach(([code, amount],)=>{
                        if (!claimPayByCarrierName[code]) {
                            claimPayByCarrierName[code] = {};
                            claimPayByCarrierName[code][practice] = {
                                [code]:0
                            };

                        } else if (!claimPayByCarrierName[code][practice] ) {
                            claimPayByCarrierName[code][practice] = {[code]:0}
                        }
                        claimPayByCarrierName[code][practice][code]+=amount

                    });
                });
            }
        });


        Object.entries(claimPayByCarrierName).forEach(([code,data]: [string,object]) => {

            Object.entries(data).forEach(([prac,codeData]: [string,object]) => {

                if(!claimPayGrandEntries[code]){
                    claimPayGrandEntries[code] = {}
                    claimPayGrandEntries[code]["cdeTotal"]=0;
                }
                claimPayGrandEntries[code][prac]={}
                claimPayGrandEntries[code][prac][code] = codeData[code];
                claimPayGrandEntries[code][prac]['percentageOfTotal'] = (( codeData[code] / claimPayByCarrierName['total'][prac]['total']) * 100).toFixed(2)
                claimPayGrandEntries[code][prac]['percentageOfGrossProduction']=(( codeData[code]/grossProdTotal)*100).toFixed(2);

                claimPayGrandEntries[code]['cdeTotal']+=Math.abs(Number(parseFloat(codeData[code]).toFixed(2)));
            });

            claimPayGrandEntries[code]["percentageofcdeGrossTotal"] = Number(((claimPayGrandEntries[code]["cdeTotal"]/grossProdTotal)*100).toFixed(2));
        });

        const srtClaimReqCarriers =Object.entries(claimPayGrandEntries).sort((obj1, obj2) => {
            if (obj1[1]["cdeTotal"] < obj2[1]["cdeTotal"]) {
                return -1
            }
        });

        const sortedObjectEntries ={}
        srtClaimReqCarriers.forEach((arrypair) => {
            sortedObjectEntries[arrypair[0]] = arrypair[1]
        });

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell/>
                        {practices && practices.map((prac)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        key={prac} colspan="1">{prac} </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">Total</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>WriteOff Appeal Code</Table.HeaderCell>
                        {practices && practices.map((practice )=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        colspan="1" key={practice}>
                                {showHideData.isDollars?"$":""}
                                {showHideData.isTTL ?" (%TTL)":""}
                                {showHideData.isGP ?"(%of GP)" : ""}
                            </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">
                            {showHideData.isDollars?"$":""}
                            {showHideData.isTTL ?" (%TTL)":""}
                            {showHideData.isGP ?"(%of GP)" : ""}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {srtClaimReqCarriers&&srtClaimReqCarriers.map(([cname,objData]) => (

                        <Table.Row key={cname}>
                            <Table.Cell key={cname}>{cname=="total"?<b>Grand Total</b>:cname}</Table.Cell>
                            {practices && practices.map((practize:string )=>
                                Object.keys(objData).indexOf(practize)>-1 ?  <Table.Cell colspan="1" key={practize+"-"+cname}>
                                        {showHideData.isDollars?(objData[practize][cname]).toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }):""} {showHideData.isTTL?"("+Math.abs(objData[practize]["percentageOfTotal"])+'%' +")" :""}
                                        {showHideData.isGP?"(" +Math.abs(objData[practize]["percentageOfGrossProduction"])+'%'+")":""}
                                    </Table.Cell>
                                    :
                                    <Table.Cell key={cname}>
                                        {showHideData.isDollars?(0).toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }):""} {showHideData.isTTL?"(0%)" :""} {showHideData.isGP?"(0%)":""}
                                    </Table.Cell>
                            )}
                            <Table.Cell>
                                {showHideData.isDollars?"$"+ objData["cdeTotal"].toFixed(2):""}
                                {showHideData.isTTL?"("+((objData["cdeTotal"]/sortedObjectEntries["total"]["cdeTotal"])*100).toFixed(2) + '%' +")":""}
                                {showHideData.isGP? "("+objData["percentageofcdeGrossTotal"].toFixed(2) +'%' +")":""}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    }

    const handleLevelChange =() => {
        setClaimPayState({...claimPayState,setLevelOne:!claimPayState.setLevelOne , setLevelTwo:!claimPayState.setLevelTwo});
    }

    const handleCheckBoxes =(name) => {
        if(name=="dollars"){
            setCheckBoxes({...showHideData , isDollars:!showHideData.isDollars})
        }
        if(name=="%ttl"){
            setCheckBoxes({...showHideData , isTTL:!showHideData.isTTL})
        }
        if(name=="%gp"){
            setCheckBoxes({...showHideData , isGP:!showHideData.isGP})
        }
    }

    return (
        <Modal  open={true} onClose={closePopup} centered={true} closeIcon>
            <Modal.Header>Claim Pay Grand Total</Modal.Header>
            {claimPayState.setLevelOne && <Modal.Content>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell> Months/years</Table.HeaderCell>
                            <Table.HeaderCell> Practices</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>{moment(selectedMonths[selectedMonths.length - 1] + '-01').format('MMM YYYY')} thru {moment(selectedMonths[0] + '-01').format('MMM YYYY')} </b></Table.Cell>
                            <Table.Cell width={3}
                                        content={practices && practices.toString()}> {practices && practices.toString()}</Table.Cell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        <Table.Row>
                            {renderClaimReqOne()}
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer>

                    </Table.Footer>
                </Table>


            </Modal.Content>}
            {claimPayState.setLevelTwo &&<Modal.Content>
                <div className="FreezeTable freezeHeight">
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell  colSpan='1'>
                                    Months/years <Checkbox  label={showHideData.isDollars?"Hide $" : "Show $"}  toggle className="ml20" checked={showHideData.isDollars} onChange={() => handleCheckBoxes("dollars")} />
                                    <Checkbox  label={showHideData.isTTL ?"Hide %TTL" : "Show %TTL"} toggle  className="ml20" checked={showHideData.isTTL} onChange={() => handleCheckBoxes("%ttl")}/>
                                    <Checkbox  label={showHideData.isGP ?"Hide %of GP" : "Show %of GP"} toggle className="ml20" checked={showHideData.isGP} onChange={() => handleCheckBoxes("%gp")}/>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><b>{moment(selectedMonths[selectedMonths.length-1] + '-01').format('MMM YYYY')}  thru  {moment(selectedMonths[0] + '-01').format('MMM YYYY')} </b></Table.Cell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                {renderClaimPayTwo()}
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </Modal.Content>}
            <Modal.Actions>
                {claimPayState.setLevelTwo &&<> <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L1
                </Button>
                    <Button className="mr10" primary onClick={()=>sendCPTotal('level2',showHideData)} >
                        Send Email
                    </Button>
                </>}
                {claimPayState.setLevelOne && <><Button className="mr10" primary onClick={handleLevelChange}>
                    Details L2
                </Button>
                    <Button className="mr10" primary onClick={()=>sendCPTotal('level1')}>
                        Send Email
                    </Button>
                </>}

            </Modal.Actions>
        </Modal>

    )}

export default  ClaimPayGrandTotal;