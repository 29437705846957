import {Action, ActionType} from "../../model/actionTypes"
import {Claims} from "../../model/claims"
import createReducer from "./createReducer"

const initialState = {
    memberStatus: [],
    memberStatusCount: {},
    lastFetched: [],
    scrapperSearchResults: []
}

export const claims = createReducer(initialState, {
    [ActionType.LOAD_MEMBER_STATUS](state: Claims, action: Action<Claims>) {
        return Object.assign({...state}, action.payload);
    },
    [ActionType.REVERSE_SEARCH](state: Claims, action: Action<Claims>) {
        return Object.assign({...state}, action.payload);
    },
    [ActionType.LOAD_LAST_FETCHED](state: Claims, action: Action<Claims>) {
        return Object.assign({...state}, action.payload);
    },
    [ActionType.CLEAR_CLAIM_STATUS](state: Claims, action: Action<Claims>) {
        return Object.assign({...state}, action.payload);
    },
    [ActionType.SAVE_NOTES](state: Claims, action: Action<Claims>) {
        return Object.assign({...state}, action.payload);
    },
    [ActionType.GET_CLAIM_STATUS_COUNT](state: Claims, action: Action<Claims>) {
        return Object.assign({...state}, action.payload);
    },
    [ActionType.GET_CLAIM_STATUS](state: Claims, action: Action<Claims>) {
        return Object.assign({...state}, action.payload);
    }
})


