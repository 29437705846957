import React, {Component, useEffect, useState} from "react";
import {Button, Modal, Dropdown, Header, Form} from "semantic-ui-react";
import {menusArray} from "../constants";

const DefaultPageModal:React.FC<any>=(props)=>{

  const [pageOptions, setPageOptions] = useState([])

  useEffect(() => {
    let menus = [];
    menusArray.forEach((menu: any) => {
      if(!menu.subMenus.length) {
        menus.push({value: `/${menu.name}`, text: menu.title})
      } else {
          menu.subMenus.forEach((subMenu: any) => {
            if(subMenu.subMenus === undefined) {
              menus.push({value: `/${menu.name}/${subMenu.name}`, text: subMenu.title})
            } else {
              subMenu.subMenus.forEach((sub: any) => {
                menus.push({value: `/${menu.name}/${subMenu.name}/${sub.name}`, text: sub.title})
              })
            }
          })
      }
    })

    setPageOptions(menus)

  }, [])


return (
    <Modal
    open={true}
    onClose={props.hideDefaultPageModal}
    centered={false}
    size='mini'
    className="modalOverflowUnset"
    closeIcon
>
  <Header content='Default Landing Page'/>
  <Modal.Content>
    <Form className="formStyle">
      <label className="mr10">Select Default Landing Page</label>
      <Dropdown defaultValue={props.defaultPage} className='mr10' fluid placeholder='Select Page' selection options={pageOptions}
                onChange={props.onChangeDefaultPage}/>
    </Form>
  </Modal.Content>
  <Modal.Actions>
    <Button className="ui primary button float-right" onClick={props.onClickDefaultPage}>
      Save
    </Button>
  </Modal.Actions>
</Modal>
)
}

export default DefaultPageModal
