import {Action,ActionType} from "../../model/actionTypes"
import {OpenIssues} from "../../model/openIssues"
import createReducer from "./createReducer"


const initialState={
    isThereOpenIssues: false,
    openIssuesList :[]
};

export const openIssues = createReducer(initialState,{
    [ActionType.CHECK_OPEN_ISSUES_SUCCESS](state:OpenIssues,
                      action:Action<OpenIssues>){
        return Object.assign({...state},action.payload);},
    [ActionType.LOAD_OPEN_ISSUES_SUCCESS](state:OpenIssues,action:Action<OpenIssues>){
        return Object.assign({...state},action.payload);
    }
})