import React from "react";
import Template from "../components/Template";
import QAIssues from "../components/QAISSUES";

function PayPlanQAIssues() {

    return (
        <Template activeLink='qaissues'>
            <QAIssues/>
        </Template>
    );

}

export default PayPlanQAIssues;