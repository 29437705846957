import  React,{useState, useEffect} from 'react';
import { Modal } from 'semantic-ui-react';

interface ViewFileModalProps{
    files: any,
    closeModal: any,
    type: any
  }

const ViewFileModal:React.FC<any> =(props:ViewFileModalProps)=> {

    const { files, closeModal, type} = props;
    let selectedFiles = (files && files !== '') ? files.split(',') : [];
    selectedFiles = selectedFiles.filter((file) => (file.toLowerCase().indexOf((type === 'Video' ? '.mp4' : '.pdf')) > 0));
    return (
      <Modal
        open={true}
        onClose={closeModal}
        aria-labelledby='ModalHeader'
        centered={false}
        style={{ overflow: 'auto' }}
        closeIcon
        size='large'
      >
        <Modal.Header>
          {type === 'Video' ? 'Watch Video' : 'View PDF'}
        </Modal.Header>
        <Modal.Content scrolling>
          <div className='row'>
            <div className='col-12 text-center'>
              {
                (selectedFiles && selectedFiles.length) ?
                  selectedFiles.map((file, key) => {
                    return (<div key={key}>
                      {(file.indexOf('.mp4') > 0) ?
                        <video className='helpVideo' controls>
                          <source src={file} type='video/mp4' />
                          Your browser does not support the video tag.
                        </video>
                        :
                        <div className='helpPdf'>
                          <a className='primaryColor mb-3 displayBlock' target='_blank' href={file}>Open in full
                            screen</a>
                          <iframe title={`pdf-${key}`} src={file} />
                        </div>
                      }
                    </div>);
                  })
                  :
                  <div>No file found.</div>
              }
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }



export default ViewFileModal
