import React, {useEffect, useMemo, useState} from "react";
import "semantic-ui-css/semantic.min.css";
import {Button, Dropdown, Grid, Tab, Table} from "semantic-ui-react";
import {RootState} from "../model";
import {connect} from "react-redux";
import * as ClaimsActions from "../redux/actions/claims";
import * as AppActions from "../redux/actions/app";
import * as AuthActions from "../redux/actions/auth"
import {useActions} from "../redux/actions";
import Template from "./Template";
import {
    agingBucketsRange,
    brands, carrierIdentifiers,
    daysFilter, identifiers, numberWithCommas
} from "../constants"
import MemberCountSummary from "./MemberCountSummary";
import {mapDownloadData} from "../common";
import {CSVLink} from "react-csv"
import moment from "moment";
import HelpMark from "./Help/helpMark";

interface ClaimsAgedSummaryProps {
    memberStatusCount: any,
    memberStatus : any,
    practiceList: any;
}


const ClaimsAgedSummary = (props: ClaimsAgedSummaryProps) => {


    const claimsActions = useActions(ClaimsActions)
    const authActions = useActions(AuthActions)
    const appActions = useActions(AppActions);
    const initialFilters = {
        newAgedSummary : true,
        identifier : [],
        practice : [],
        duration :  0,
        brand : []
    }
    const [filters, setFilters] = useState(initialFilters);
    const [agingData, setAgingData] = useState([]);

    const practiceNames: any = {};
    const practiceOptions: any = [];
    useEffect(() => {
        setTimeout(function () {
            appActions.FetchPracticeList();
        },0)
    }, []);

    useEffect(() => {
        setTimeout(function () {
        claimsActions.FetchMemberStatus({
            ...filters,
            identifier: filters.identifier.length > 0 ? filters.identifier : 'all'
        }).then((data: any) => {
            setAgingData(data)
        })
        claimsActions.FetchStatusCount(filters)
        },0)
    }, [filters]);


    props.practiceList && props.practiceList.forEach((prac: any) => {
        const obj = {
            key: prac.id,
            value: prac.practiceabbr,
            text: prac.practice,
        };
        practiceNames[prac.practiceabbr] = prac.practice;

        if(filters.brand.length){
                if(filters.brand.indexOf(prac.UdaPracticeInfo.brand) >= 0){
                    practiceOptions.push(obj);
                }
                return;
        }
        practiceOptions.push(obj);
    })

    const generateAgingTableHtml = (items: any) => {
        const sums: Record<string, Record<string, number>> = {};
        const columnTotals: Record<string, number> = {};

        items.forEach(item => {
            const currentDate = moment();
            const dateSent = moment(item.dateSent);
            const daysSinceSent = currentDate.diff(dateSent, 'days');
            let rangeLabel = "180+";

            for (const range of agingBucketsRange) {
                if (daysSinceSent >= range.min && daysSinceSent <= range.max) {
                    rangeLabel = range.label;
                    break;
                }
            }

            if (!sums[item.type]) {
                sums[item.type] = {};
            }

            if (!sums[item.type][rangeLabel]) {
                sums[item.type][rangeLabel] = 0;
            }

            sums[item.type][rangeLabel] += parseFloat(item.amount);

            // Update column totals
            if (!columnTotals[rangeLabel]) {
                columnTotals[rangeLabel] = 0;
            }
            columnTotals[rangeLabel] += parseFloat(item.amount);
        });

        const tableRows = [];

        for (const carrier in sums) {
            const row = (
                <tr key={carrier}>
                    <td>{carrierIdentifiers[carrier]}</td>
                    {agingBucketsRange.map(range => (
                        <td key={range.label}>
                            {sums[carrier][range.label] !== undefined
                                ? numberWithCommas((sums[carrier][range.label] as number).toFixed(2))
                                : numberWithCommas('0.00')}
                        </td>
                    ))}
                    <td>
                        {sums[carrier]["180+"] !== undefined
                            ? numberWithCommas((sums[carrier]["180+"] as number).toFixed(2))
                            : numberWithCommas('$0.00')}
                    </td>
                    <td className={'bold'}>
                        {numberWithCommas((
                            Object.values(sums[carrier]).reduce(
                                (total, value) => total + (typeof value === 'number' ? value : 0),
                                0
                            ) || 0
                        ).toFixed(2))}
                    </td>
                </tr>
            );

            tableRows.push(row);
        }

        // Calculate column totals row
        const columnTotalsRow = (
            <tr key="columnTotals" className={'bold'}>
                <td>Total</td>
                {agingBucketsRange.map(range => (
                    <td key={range.label}>
                        {columnTotals[range.label] !== undefined
                            ? numberWithCommas((columnTotals[range.label] as number).toFixed(2))
                            : numberWithCommas('$0.00')}
                    </td>
                ))}
                <td>
                    {columnTotals["180+"] !== undefined
                        ? numberWithCommas((columnTotals["180+"] as number).toFixed(2))
                        : numberWithCommas('$0.00')}
                </td>
                <td>
                    {numberWithCommas((
                        Object.values(columnTotals).reduce(
                            (total, value) => total + (typeof value === 'number' ? value : 0),
                            0
                        ) || 0
                    ).toFixed(2))}
                </td>
            </tr>
        );

        // Add column totals row
        tableRows.push(columnTotalsRow);

        return tableRows;
    }

    return (
        <Template activeLink="claimsAgedSummary">
            <div className="ui card">
                <div className="content pb0">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h2 className="headingMain">
                                    Claims Aged Summary 
                                    <HelpMark application="Billing-ClaimsAgedSummary" />
                                </h2>
                                <div className="topFilters">
                                    <div className="filter d-InlineBlock float-left">
                                    <Button primary className="mt20 mr10">
                                        <CSVLink
                                            filename={`MyReport_Summary.csv`}
                                            data={mapDownloadData(props.memberStatus, practiceNames)}
                                        >
                                            <span className="color-white">Download CSV</span>
                                        </CSVLink>
                                    </Button>
                                    </div>
                                    <div className="filter d-InlineBlock float-left">
                                        <label className="labelStyle displayBlock">Filter By MCO
                                        </label>
                                        <Dropdown
                                            className="mb15 mr10"
                                            placeholder="Select MCO"
                                            search
                                            selection
                                            multiple={true}
                                            value={filters.identifier}
                                            options={identifiers}
                                            onChange={(event, data:any) => {
                                                setFilters({...filters, identifier: data.value});
                                            }}
                                        />
                                    </div>

                                    <div className="filter d-InlineBlock float-left">
                                        <label className="labelStyle displayBlock">Filter By Brand
                                        </label>
                                        <Dropdown
                                            className="mb15 mr10"
                                            placeholder="Select Brand"
                                            search
                                            selection
                                            multiple={true}
                                            value={filters.brand}
                                            options={brands}
                                            onChange={(event, data:any) => {
                                                if(data.value && data.value.length) {
                                                    setFilters({...filters, practice: [], brand: data.value});
                                                } else {
                                                    setFilters({...filters, brand: data.value});
                                                }

                                            }}
                                        />
                                    </div>
                                    <div className="filter d-InlineBlock float-left">
                                        <label className="labelStyle displayBlock">Filter By Duration
                                        </label>
                                        <Dropdown
                                            className="mb15 mr10"
                                            placeholder="Select Duration"
                                            search
                                            selection
                                            value={filters.duration}
                                            options={daysFilter}
                                            onChange={(event, data) => {
                                                setFilters({...filters, duration: data.value as number});
                                            }}
                                        />
                                    </div>
                                        <div className="filter d-InlineBlock float-left">
                                        <label className="labelStyle displayBlock">Filter By Practice
                                        </label>
                                        <Dropdown
                                            className="mb15 mr10"
                                            placeholder="Select Practice"
                                            search
                                            multiple={true}
                                            selection
                                            value={filters.practice}
                                            options={practiceOptions}
                                            onChange={(event, data) => {
                                                setFilters({...filters, practice: data.value as any});
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
            <div className="ui card claims">
                <div className="content">
                        {props.memberStatusCount && props.memberStatusCount.length && <MemberCountSummary summary={props.memberStatusCount}/>}
                </div>
                <div className="content">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h2 className="headingMain">AR Aging for Claims </h2>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Carrier</Table.HeaderCell>
                                <Table.HeaderCell>0-30 Days</Table.HeaderCell>
                                <Table.HeaderCell>31-60 Days</Table.HeaderCell>
                                <Table.HeaderCell>61-90 Days</Table.HeaderCell>
                                <Table.HeaderCell>91-180 Days</Table.HeaderCell>
                                <Table.HeaderCell>180+ Days</Table.HeaderCell>
                                <Table.HeaderCell>Total Result</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        { agingData && agingData.length && generateAgingTableHtml(agingData)}
                    </Table>

                </div>

            </div>
        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        memberStatusCount: state.claims.memberStatusCount,
        memberStatus: state.claims.memberStatus,
        practiceList: state.app.practiceList,
    };
}

export default connect(mapStateToProps)(ClaimsAgedSummary);
