import React, {useEffect, useState} from "react";
import {Card, Header, Input, Modal, Grid, Table,Button,Icon, TableCell,Embed, Placeholder} from "semantic-ui-react"
import Template from "../Template";
import {API_URL} from "../../constants";
import {RootState} from "../../model"
import {useActions} from "../../redux/actions";
import * as collectionsActions from "../../redux/actions/collections"
import {Account} from "../../model/user"
import {Collections} from "../../model/collections"
import {connect} from "react-redux";
import CountUpTimer from "../CountUpTimer";
interface Props {
  user: Account,
  collections:Collections
}


const DataVerification: React.FC<any> = (props: any) => {

    const actions = useActions(collectionsActions);
    const initialState={
      videoclicked:'',
      progressCountVar:0,
      loading:false,
      isAdmin:props.user.isAdmin,
      isEditable:false
    };

    const [state, setStates] = useState<any>(initialState);
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [ editableData, setEditable] =useState({})

    const [tableContents , setTabledata] = useState<any>([
     {title: "Gross Production", name:"grossproduction"},
     {title: "Adjustments", name:"adjustments"},
     {title: "Write Off", name:"writeoff"},
     {title: "Write-Off Appeal", name:"writeoffappeal"},
     {title: "Claim Request", name:"claimreq"},
     {title: "Pay Plan Balance", name:"ppbalance"},
     {title: "Cash Split", name:"cashsplit"},
     {title: "Claim Pay", name:"claimpay"},
     {title: "Pay Plan Pay", name:"ppPay"},
     {title: "PayPlan Due", name:"ppdue"},
     {title: "Ins Pay Check Date", name:"inspaycheckdate"},
     {title: "Cash Split Date Entry", name:"cashsplitentry"},
     {title: "PayPlanPay Date Entry", name:"ppPayentry"},
     {title: "ins_pay_date_entry", name:"inspaydateentry"},
     {title: "Collected", name:"collected"},
     {title: "Collectable Production", name:"collectableprod"}    
    ])

    useEffect(()=>{
        loadData()
    } , [])

    useEffect(()=>{
      loadData()

    },[tableContents])

    const loadData =  () => {
      setLoader(true);
      let assgnData={}
      actions.getDoclinks().then((results)=>{
          setLoader(false);
          results.map((obdata,indx)=>{
           assgnData[obdata.name]={'video':obdata.videolink}
          })
         setStates({...state , ...assgnData});

      });

    }

    const handleClose = () => setShow(false);
    const handleShow = (e,name) =>{
     setStates({...state, videoclicked:name["video"]});
      setShow(true)
     } ;


    const handleURLchange = (e,name)=>{
      const {collections}=props;
      console.log(collections)
      const itemobj =collections.find(item => item.name === name);
      const obj = {
        id:itemobj?itemobj.id:null,
        trname:name,
        video:e.target.value          
      }
       if(name){
          setStates({...state ,[name]:{"video":e.target.value}})
          setEditable({...editableData ,[name]:{"video":e.target.value}})
          actions.saveVidLink(obj).then((_data: any) => {      
          });
       }   
    }
   

  const handleEdit =  (e: any)=>{
     setStates({...state , isEditable:true})
  }

 const handleCancel = (e:any) =>{
    setStates({...state, isEditable:false})
 }


  const{isAdmin , isEditable}=state;

    return(
        <Template activeLink="dataverification">
           {loader && <CountUpTimer/>}
         <Card>
            <Card.Content>
                <Grid>
                    <Grid.Row>
                      <Grid.Column width={3}>
                        <h2>Data Verification</h2>
                      </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card.Content>
         </Card>
         <Card>
           <Card.Content>
             <div className="FreezeTable">
               <Table unstackable celled={true}>
                <Table.Header>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Video</Table.HeaderCell>
                    <Table.HeaderCell>Document</Table.HeaderCell>
                    {isAdmin &&<Table.HeaderCell>Actions</Table.HeaderCell>}
                </Table.Header>
                <Table.Body>
                    { tableContents.map((tbdata , indx)=>
                    <Table.Row key={indx}>
                       <Table.Cell content={<b>{tbdata.title}</b>}/>
                       <Table.Cell> {state[tbdata.name]&&state[tbdata.name].video&& !isEditable?
                       <p onClick={(e)=>handleShow(e,state[tbdata.name])}>
                        <a href ='#myModal' data-target="#myModal" data-value={state[tbdata.name].video}>
                          {state[tbdata.name].video}</a>
                        </p>:isAdmin&&<Input type="url" name="file" onChange={(e)=>handleURLchange(e,tbdata.name)} placeholder="enter link "
                                      value={state[tbdata.name]?state[tbdata.name].video:null}/>}
                      </Table.Cell>
                       <Table.Cell>
                       <span className='cursorPointer d-inline-block'>
                        <div className="input-group">
                          <label>
                           <Icon size='large' name='cloud upload' className="primaryColor"/>
                           {/* <ReactS3Uploader 
                                 multiple                          
                                 signingUrl={`${API_URL}/collections/s3upload`}
                                 signingUrlMethod="GET"
                                 className='form-group'
                                 accept=".doc ,.docx ,.pdf"
                                 uploadRequestHeaders={{'x-amz-acl': 'public-read'}}
                                 preprocess={(file: any, next: any) => onUploadStart(file, next)}
                                 onProgress={onProgress}
                                 onError={onUploadError}
                                 onFinish={(res: any) => onFinish(res)}
							                  /> */}
                          </label>
                        </div>
                        {state.progressCountVar > 0 ? <CountUpTimer/> : ''}
                       </span>
                      </Table.Cell>
                      {isAdmin&&<TableCell> {!isEditable?<Button primary onClick={(e)=>handleEdit(e)}>Edit</Button>:
                                   <Button secondary onClick={(e)=>handleCancel(e)}>Cancel</Button>}</TableCell>}
                    </Table.Row>
                 )} 
                    
                 </Table.Body>
              </Table>
             </div>

             <Modal  id='myModal' open={show} onClose={handleClose}  size='large' closeIcon>
            <Header>Link Popup</Header>
          <Modal.Content>
            <video controls width='100%'>
            <source src={state.videoclicked} type="video/webm" />
            <source src={state.videoclicked} type="video/mp4"
               />
      Sorry, your browser doesn't support videos.
            </video>
          </Modal.Content>
          <Modal.Actions>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>

           </Card.Content>
         </Card>
        </Template>
    )
}

function mapStateToProps(state: RootState) {
  return {
    user: state.auth.user,
    collections:state.collections.dataVerification
  };
}
export default connect(mapStateToProps)(DataVerification)
