import React ,{useState}from "react";
import {Modal, Table, Button, Checkbox} from "semantic-ui-react";
import moment from "moment/moment";

interface Props {
    data: {month?:string,claimPayData?:object,productionData?:object};
    closePopup: () => void;
    sendData:Function;
}

const ClaimPayPopup: React.FC<Props> = ({ data ,closePopup ,sendData}) => {
    const [practices] = useState(Object.keys(data&&data.claimPayData?data.claimPayData:[]));
    const [claimPayState,setClaimPayState] = useState({
        claimPayData:data.claimPayData,
        productionData:data.productionData,
        selectedMonth:data.month,
        setLevelOne:true,
        setLevelTwo:false
    })
    const [ showHideData ,setCheckBoxes] = useState({isDollars:true , isTTL:true ,isGP:true});

    const renderClaimPay = (monthSelected:string) => {
        const dataForMonth = {};
        for (const practice of practices) {
            if(claimPayState.claimPayData[practice][monthSelected]){
                dataForMonth[practice] = claimPayState.claimPayData[practice][monthSelected];
            }
        }

        const claimPayByCarrierName: any = {};
        Object.values(dataForMonth).forEach((monthValue: any) => {
            if (monthValue && Object.keys(monthValue).length > 0) {
                Object.entries(monthValue).forEach(([code, data]: any) => {
                    if (!claimPayByCarrierName[code] && code!=='total') {

                        claimPayByCarrierName[code] = {
                            [code]:0,
                            percentageOfTotal: 0,
                            percentageOfGrossProduction: 0,
                        };
                    }
                    if(claimPayByCarrierName[code]&&code!=="total")
                        claimPayByCarrierName[code][code] += data;
                    if(code==="total"){
                        if(!claimPayByCarrierName["total"]) {
                            claimPayByCarrierName[code] = {
                                total:0,
                                percentageOfTotal: 0,
                                percentageOfGrossProduction: 0,
                            }
                        }

                        claimPayByCarrierName[code]["total"]+=data

                    }
                });

            }
        });

        //calculate total productiondata for month
        const totalProductionData = Object.keys(claimPayState.productionData)&&claimPayState.productionData[monthSelected]?claimPayState.productionData[monthSelected].production:0;

        Object.entries(claimPayByCarrierName).forEach(([code, data]: any) => {
            data.percentageOfTotal = ((data[code] / claimPayByCarrierName.total["total"]) * 100).toFixed(2);
            data.percentageOfGrossProduction = ((data[code] / totalProductionData) * 100).toFixed(2);

        });
        let sortedData = {total:{}}
        const keys = Object.keys(claimPayByCarrierName);
        // Sort the keys based on absolute value of their corresponding values
        keys.sort((a, b) => {
            if (!claimPayByCarrierName[a] || !claimPayByCarrierName[b]) return 0; // check if properties exist
            return Math.abs(claimPayByCarrierName[b][b]) - Math.abs(claimPayByCarrierName[a][a])
        });
        // Add the sorted keys to the new object
        keys.forEach(key => {
            sortedData[key] = claimPayByCarrierName[key];
        });
        const sortedEntries = Object.entries(sortedData).sort(([keyA], [keyB]) => {
            if (keyA === "total") {
                return 1; // move total to the end
            } else if (keyB === "total") {
                return -1; // move total to the end
            } else {
                return 1 // sort alphabetically
            }
        });
        const sortedObject = Object.fromEntries(sortedEntries)
        // render the table
        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>CarrierName</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>% of Total Claim_Pay</Table.HeaderCell>
                        <Table.HeaderCell>% of Gross Production</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {Object.entries(sortedObject).map(([code, data]: any) => (
                        <Table.Row key={code}>
                            <Table.Cell>{code}</Table.Cell>
                            <Table.Cell content={(data[code] || 0).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD"
                            })}/>
                            <Table.Cell>{Math.abs(data.percentageOfTotal)}%</Table.Cell>
                            <Table.Cell>{Math.abs(data.percentageOfGrossProduction)}%</Table.Cell>
                        </Table.Row>
                    ))}


                </Table.Body>
            </Table>
        );
    }

    const renderClaimPayTwo =(monthSelected:string) =>{
        const dataForMonth = {};
        for (const practice of Object.keys(claimPayState.claimPayData)) {
            if(claimPayState.claimPayData[practice][monthSelected]){
                dataForMonth[practice] = claimPayState.claimPayData[practice][monthSelected];
            }
        }
        const claimPayByCarrierNameTwo: object = {};
        practices&&practices.forEach((prac: string) => {
            if (dataForMonth[prac]&&Object.keys(dataForMonth[prac]).length > 0) {
                Object.keys(dataForMonth[prac]).forEach((cname: string) => {
                    if (!claimPayByCarrierNameTwo[cname]) {
                        claimPayByCarrierNameTwo[cname] = { };
                        claimPayByCarrierNameTwo[cname][prac] = dataForMonth[prac];

                    }
                    else if(!claimPayByCarrierNameTwo[cname][prac] && dataForMonth[prac][cname]){
                        claimPayByCarrierNameTwo[cname][prac] = dataForMonth[prac];
                    }
                });
            }
        });

        let  claimPayCnameEntries={}
        // total productiondata for month
        const totalProductionData = Object.keys(claimPayState.productionData)&&claimPayState.productionData[monthSelected]?claimPayState.productionData[monthSelected].production:0;

        Object.entries(claimPayByCarrierNameTwo).forEach(([code,data]: [string,object]) => {

            practices&&practices.forEach((pracz: string) => {
                if (!data[pracz]) {
                    return;
                }
                if (!claimPayCnameEntries[code]) {
                    claimPayCnameEntries[code] = {}
                    claimPayCnameEntries[code]["cdeTotal"] = 0;
                }
                claimPayCnameEntries[code][pracz] = {}
                claimPayCnameEntries[code][pracz][code] = data[pracz][code];
                claimPayCnameEntries[code][pracz]['percentageOfTotal'] = ((data[pracz][code] / data[pracz]['total']) * 100).toFixed(2);
                claimPayCnameEntries[code][pracz]['percentageOfGrossProduction'] = ((data[pracz][code] / totalProductionData) * 100).toFixed(2);
                claimPayCnameEntries[code]['cdeTotal'] += Math.abs(Number(parseFloat(data[pracz][code]).toFixed(2)));

            });

            claimPayCnameEntries[code]["percentageofcdeGrossTotal"] = Number(((claimPayCnameEntries[code]["cdeTotal"]/totalProductionData)*100).toFixed(2));
        });

        const srtClaimPay =Object.entries(claimPayCnameEntries).sort((obj1, obj2) => {
            if (obj1[1]["cdeTotal"] < obj2[1]["cdeTotal"]) {
                return -1
            }
        });

        const sortedObjectEntries ={}
        srtClaimPay.forEach((arrypair) => {
            sortedObjectEntries[arrypair[0]] = arrypair[1]
        });

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell>{""}</Table.Cell>
                        {practices && practices.map((prac , idx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        key={idx} colspan="1">{prac} </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">Total</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Carrier Name</Table.HeaderCell>
                        {practices && practices.map((practice , indx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        colspan="1" key={indx}>
                                {showHideData.isDollars?"$":""}
                                {showHideData.isTTL ?" (%TTL)":""}
                                {showHideData.isGP ?"(%of GP)" : ""}
                            </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">
                            {showHideData.isDollars?"$":""}
                            {showHideData.isTTL ?" (%TTL)":""}
                            {showHideData.isGP ?"(%of GP)" : ""}

                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedObjectEntries&&Object.entries(sortedObjectEntries).map(([code,dataf]:any,indx:number) => (

                        <Table.Row key={indx}>
                            <Table.Cell key={indx}>{code}</Table.Cell>
                            {practices && practices.map((practize , index)=>
                                <Table.Cell colspan="1" key={indx-index}>
                                    {showHideData.isDollars?(Object.keys(dataf).indexOf(practize)>-1?dataf[practize][code]:0).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD"
                                    }):""} {showHideData.isTTL?"("+Math.abs(Object.keys(dataf).indexOf(practize)>-1?dataf[practize]["percentageOfTotal"]:0)+'%' +")" :""}
                                    {showHideData.isGP?"(" +Math.abs(Object.keys(dataf).indexOf(practize)>-1?dataf[practize]["percentageOfGrossProduction"]:0)+'%'+")":""}
                                </Table.Cell>
                            )}
                            <Table.Cell>{showHideData.isDollars?"$"+ dataf["cdeTotal"].toFixed(2):""}
                                {showHideData.isTTL?"("+((dataf["cdeTotal"]/sortedObjectEntries["total"]["cdeTotal"])*100).toFixed(2) + '%' +")":""}
                                {showHideData.isGP? "("+dataf["percentageofcdeGrossTotal"].toFixed(2) +'%' +")":""}
                            </Table.Cell>
                        </Table.Row>
                    ))}


                </Table.Body>
            </Table>
        );
    }

    const handleLevelChange =() => {
        setClaimPayState({...claimPayState,setLevelOne:!claimPayState.setLevelOne , setLevelTwo:!claimPayState.setLevelTwo});
    }

    const handleCheckBoxes =(name:string) => {
        if(name==="dollars"){
            setCheckBoxes({...showHideData , isDollars:!showHideData.isDollars})
        }
        if(name==="%ttl"){
            setCheckBoxes({...showHideData , isTTL:!showHideData.isTTL})
        }
        if(name==="%gp"){
            setCheckBoxes({...showHideData , isGP:!showHideData.isGP})
        }

    }

    return (
        <Modal
            open={true}
            onClose={closePopup}
            centered={true}
            closeIcon
        >
            <Modal.Header>Claim_Pay by Carrier Name and Percentage</Modal.Header>
            {claimPayState.setLevelOne&&<Modal.Content>
                <div className="FreezeTable freezeHeight">
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell  colSpan='1'> Months/years</Table.HeaderCell>
                            <Table.HeaderCell colSpan='1'> Practices</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>{moment(claimPayState.selectedMonth + '-01').format('MMM YYYY')}</b></Table.Cell>
                            <Table.Cell width={3} content={practices&&practices.toString()} > {practices&&practices.toString()}</Table.Cell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        <Table.Row>
                            {renderClaimPay(claimPayState.selectedMonth)}
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer>

                    </Table.Footer>
                </Table>
                </div>
            </Modal.Content>}
            {
                claimPayState.setLevelTwo && <Modal.Content>
                    <div className="FreezeTable freezeHeight">
                        <Table celled width="100%">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell  colSpan='1'>
                                        Months/years  <Checkbox  label={showHideData.isDollars?"Hide $" : "Show $"}  toggle className="ml20" checked={showHideData.isDollars} onChange={() => handleCheckBoxes("dollars")} />
                                        <Checkbox  label={showHideData.isTTL ?"Hide %TTL" : "Show %TTL"} toggle  className="ml20" checked={showHideData.isTTL} onChange={() => handleCheckBoxes("%ttl")}/>
                                        <Checkbox  label={showHideData.isGP ?"Hide %of GP" : "Show %of GP"} toggle className="ml20" checked={showHideData.isGP} onChange={() => handleCheckBoxes("%gp")}/>
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><b>{moment(claimPayState.selectedMonth + '-01').format('MMM YYYY')}</b></Table.Cell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>

                                <Table.Row>
                                    {renderClaimPayTwo(claimPayState.selectedMonth)}
                                </Table.Row>
                            </Table.Body>

                            <Table.Footer>

                            </Table.Footer>
                        </Table>
                    </div>
                </Modal.Content>
            }
            <Modal.Actions>
                {claimPayState.setLevelTwo &&<> <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L1
                </Button>
                    <Button className="mr10" primary disabled>
                        Details L3
                    </Button>
                </>}
                {claimPayState.setLevelOne && <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L2
                </Button> }
            <Button className="mr10" primary onClick={claimPayState.setLevelOne?()=>sendData('level1'):()=>sendData('level2',showHideData)} >
                Send Email
            </Button>
            </Modal.Actions>
        </Modal>
    );
}


export default ClaimPayPopup;
