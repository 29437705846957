import React, {useEffect, useState} from "react";
import {Card, Dimmer, Dropdown, DropdownProps, Grid, Loader, Table,Button,Icon} from "semantic-ui-react"
import Template from "../Template";
import * as collectionsActions from "../../redux/actions/collections"
import {useActions} from "../../redux/actions";
import moment from "moment";
import * as SetupActions from "../../redux/actions/setup";
import GrandTotalPopup from "./GrandtotalPopup";
import AdjustmentsPopup from "./AdjustmentPopup";
import  WriteOffPopUp from "./Popups/WriteOffPopup"
import WriteOffGrandTotal from "./Popups/WriteOffGrandTotal"
import ProductionPopup from "./Popups/ProductionPopup";
import ProductionGrandTotal from "./Popups/ProductionGrandTotal";
import {brands, HELP_PAGE} from "../../constants";
import HelpMark from "../Help/helpMark";
import ClaimRequestPopUp from "./Popups/ClaimRequestPopUp";
import PayPlanPopUp from "./Popups/PayPlanPopUp";
import ClaimPayPopUp from "./Popups/ClaimPayPopup";
import CashSplitPopUp from "./Popups/CashSplitPopup";
import WriteOffAppealModal from "./Popups/WriteOffAppealModal";
import WOAppealGrandTotal from "./Popups/WOAppealGrandTotal";
import ClaimReqGrandTotal from "./Popups/ClaimReqGrandTotal"
import ClaimPayGrandTotal from "./Popups/ClaimPayGrandTotal";
import CashSplitGrandTotal from "./Popups/CashSplitGrandTotal";
import CountUpTimer from "../CountUpTimer";

const ProductionCollection: React.FC<any> = () => {

    const months = [...new Array(13)].map((a, i) => moment().add(i * -1, 'M').format('YYYY-MM'));
    const [monthsParam, setMonths] = useState(months);
    const generateMappedData = (months: string[]) => {
        return months.reduce((acc, cur) => ({...acc, [cur]: {production: 0}}), {} as any);
    }
    const [mappedData, setMappedData] = useState(generateMappedData(monthsParam));
    const actions: typeof collectionsActions = useActions(collectionsActions);
    const setupActions = useActions(SetupActions);
    const [state, setStates] = useState<any>({
        data: mappedData as any,
        adjustmentsData:{} as any,
        writeOffData:{} as any,
        claimRequestData:{} as any,
        payPlanPayData:{} as any,
        claimPayData:{} as any
    });
    const [loader, setLoader] = useState(false);
    const [paramsState, setParamsStates] = useState<any>({
        practiceOptions: [] as any,
        practices: [] as string[],
        brand: [] as any,
        practiceOriginal: [] as any,
        practiceParams: [] as any,
    });
    const [popupData, setPopupData] = useState({});
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const [grandttlData,setgrandTotal] = useState({});
    const [isGrandtotal, setIsGrandVisible] = useState(false);
    const [selectedBrands , setBrands] = useState<any>([]);
    const [writeoffModalData, setWriteOffModal] = useState({});
    const [claimRequestModalData, setClaimRequestModal] = useState({});
    const [payPlanPayModalData, setPayPlanPayModal] = useState({});
    const [iswriteOffVisible, setIsWriteOffVisible] = useState(false);
    const [isClaimRequestVisible, setIsClaimRequestVisible] = useState(false);
    const [isPayPlanPayVisible, setIsPayPlanPayVisible] = useState(false);
    const [writeOffgrandttl,setWOgrandTotal] = useState({});
    const [isWOGrandtotal, setWriteOffGrandVisible] = useState(false);
    const [grossProductionData, setGrossProduction] = useState({});
    const [isgrossProdVisible, setIsgrossProdVisible] = useState(false);
    const [grossProdgTotal,setProductionttl] = useState({});
    const [isProductionTotalVisible, setProductionsumVisible] = useState(false);
    const [claimPayModalData, setClaimPayModal] = useState({});
    const [isClaimPay , setClaimPayVisible]=useState(false)
    const [cashSplitModalData, setCashSplitModal] = useState({});
    const [isCashSplit , setCashSplitVisible]=useState(false);
    const [woAppealData , setWOAppeal] = useState({}) ;
    const [isWOAppealVisible, setIsWoAppealVisible] = useState(false);
    const [woAppealGrandTTLData , setWOAppealGrand] = useState({}) ;
    const [isWOAppealGrandTTKVisible, setIsWoAppealGrandVisible] = useState(false);
    const [claimReqGrandData , setClaimReqGrand] = useState({}) ;
    const [isClaimReqGrandVisible, setIsClaimReqGrand] = useState(false);
    const [isClaimPayGrandTotal , setIsClaimPayGrand] = useState(false);
    const [claimPayGrandData , setClaimPayGrandData] = useState({});
    const [cashSplitGrandData , setCashSplitGrand]=useState({});
    const [isCashSplitGrandVisible , setIsCashSplitGrandVisible]=useState(false);


    useEffect(() => {
        setMonths(months);
        setupActions.fetchAllPractices().then((result)=>{
            loadPractices(result);
            setBrands(["All"])
            let practiceOptions: any = result
            .filter(f => f.practiceDBName && f.practiceDBName.length)
            .map((practice, key) => ({
                text: practice.practice,
                value: practice.practice,
                key,
                practiceabbr: practice.practiceabbr
            }));  
            
            let practiceParams: string[] = [];
            practiceOptions = Array.from(new Set(practiceOptions));
            if (practiceOptions) {
                practiceOptions.forEach((practice: any) => {
                    practiceParams.push(practice.value);
                });
            }
            setParamsStates({...paramsState, practiceOptions,practiceOriginal: practiceOptions, practiceParams: practiceParams});
            loadData(practiceParams,monthsParam);
       })       
    }, []);

    const loadPractices = (practices: any[]) => {
        let practiceOptions: any = practices
            .filter(f => f.practiceDBName && f.practiceDBName.length)
            .map((practice, key) => ({
                text: practice.practice,
                value: practice.practice,
                key,
                practiceabbr: practice.practiceabbr
            }));
        setParamsStates({...state, practiceOptions, practiceOriginal: practiceOptions});
    }

    const onChange = ({value}: DropdownProps, name: string) => {
        let values = value as string[];
        if(name === 'practice'){
        setParamsStates({...paramsState, practices: value});
        loadData(value as any,monthsParam);
        }else if(name==='brand'){
             setBrands(value);
            let practiceOptions: any = [];
            let practiceParams: string[] = [];
            if (values.includes('All') || values.length === 0) {
                practiceOptions = paramsState.practiceOriginal;
            } else {
                values.forEach((brand: string) => {
                    const filteredPractices = paramsState.practiceOriginal.filter((practice: any) =>
                        practice.practiceabbr.includes(brand)
                    );
                    practiceOptions.push(...filteredPractices);
                });
            }
            practiceOptions = Array.from(new Set(practiceOptions));
            if (practiceOptions) {
                practiceOptions.forEach((practice: any) => {
                    practiceParams.push(practice.value);
                });
            }
            setParamsStates({...paramsState, practiceOptions, practiceParams});
            loadData(practiceParams,monthsParam);
        }
    }

    const loadData = async (practices: string[], months: string[]) => {
        setLoader(true);
        const data: any = await actions.getSummary(practices,months);
        const objmappedData={};
        months.forEach((month)=>objmappedData[month]=data.mappedData[month]?data.mappedData[month]:{})
        setStates({...state,data:objmappedData , adjustmentsData:{} , writeOffData:{}, claimRequestData:{}, payPlanPayData:{},claimPayData:{},cashSplitData:{}, grandTotalPractices:data.grandTotalPractices});
        setLoader(false);
    }

    const btnsmonthsData = (newMonths:string[])=>{
        setMonths(newMonths);
        setMappedData(generateMappedData(newMonths));
        let practiceParams: string[] = [];
        const practiceOptions = Array.from(new Set(paramsState.practiceOptions));
        if (practiceOptions) {
            practiceOptions.forEach((practice: any) => {
                practiceParams.push(practice.value);
            });
        }
        setParamsStates({...paramsState, practiceParams});
        loadData(practiceParams,newMonths);
    }
    const handlePreviousMonth = () => {
        const newMonths = monthsParam.map((month) =>
            moment(month).subtract(1, "month").format("YYYY-MM")
        );
        btnsmonthsData(newMonths)
    };

    const handleNextMonth = () => {
        const newMonths = monthsParam.map((month) =>
            moment(month).add(1, "month").format("YYYY-MM")
        );
        btnsmonthsData(newMonths)
    };

    const handleCellClick = async (month: string, method: string) => {
        setLoader(true);
        const practiceParams: string[] = [];
        if (paramsState.practiceOptions) {
            paramsState.practiceOptions.forEach((practice: any) => {
                practiceParams.push(practice.value);
            });
        }
        let practices = (paramsState.practices).length>0?paramsState.practices:practiceParams;
        const months=[month];
        const data = await actions.getCellData(practices,months, method);
        setStates({...state, adjustmentsData: data});
        setPopupData({
            month: month,
            adjustmentsData: data,
            productionData: state.data
        });
        setLoader(false);
        setIsPopupVisible(true);
    };
    const handlePopupClose = () => {
        setIsPopupVisible(false);
    }

    const grandPopupClose = () => {
        setIsGrandVisible(false)
    }

    const writeOffClose =()=>{
        setIsWriteOffVisible(false)
    }

    const claimRequestClose =()=>{
        setIsClaimRequestVisible(false)
    }

    const payPlanPayClose =()=>{
        setIsPayPlanPayVisible(false)
    }

    const sendModalData =  async (level:string ,toggleColumns={})=>{
        await actions.sendDocumentsEmail({...popupData,setLevel:level,setDataCol:toggleColumns});
        alert('Email sent');
        handlePopupClose();
        
    }

    const handleGrandTotal = async (method:string)=>{
        setLoader(true)
        const practiceParams: string[] = [];
        if (paramsState.practiceOptions) {
            paramsState.practiceOptions.forEach((practice: { value:string }) => {
                practiceParams.push(practice.value);
            });
        }
        let practices = (paramsState.practices).length>0?paramsState.practices:practiceParams;
        const data = await actions.getCellData(practices,monthsParam, method);
        setStates({...state, adjustmentsData: data});
        setgrandTotal({
            adjustmentsData: data,
            productionData: state.data
        });
        setIsGrandVisible(true);
        setLoader(false)
    }

    const sendWriteOff =  async (level:string ,toggleColumns={})=>{
        await actions.sendWriteoffEmail({...writeoffModalData,setLevel:level,setDataCol:toggleColumns});
        alert('Email sent');
        writeOffClose();   
    }

    const sendClaimRequest =  async (level:string ,toggleColumns={})=>{
        await actions.sendClaimRequestEmail({...claimRequestModalData,setLevel:level,setDataCol:toggleColumns});
        alert('Email sent');
        claimRequestClose();
        
    }

    const sendPayPlanPay =  async (level:string ,toggleColumns={})=>{
        await actions.sendPayPlanPayEmail({...payPlanPayModalData,setLevel:level,setDataCol:toggleColumns});
        alert('Email sent');
        payPlanPayClose();
        
    }

    const sendDataTotal = async (level:string , toggleColumns:object ={})=>{
        await actions.sendDocumentsEmail({...grandttlData,grandLevel:level,setgrandCol:toggleColumns});
        alert('Email sent');
        grandPopupClose();
     }

     const sendWOgTotal = async (level:string ,toggleColumns=0)=>{
        await actions.sendWriteoffEmail({...writeOffgrandttl,grandLevel:level,setgrandCol:toggleColumns});
        alert('Email sent');
        grandWOPopupClose();
     }

    const handleWriteoffClick=async(month: string,  method: string) => {
        setLoader(true);
        const data = await calculateData(month,method);
        setStates({...state, writeOffData: data});
        setWriteOffModal({
            month: month,
            writeOffData: data,
            productionData: state.data
        });
        setLoader(false);
        setIsWriteOffVisible(true);
    }

    const handleClaimRequestClick=async(month: string,  method: string) => {
        setLoader(true);
        const data = await actions.getCellData(paramsState.practiceParams,monthsParam, method);
        setStates({...state, claimRequestData: data});
        setClaimRequestModal({
            month: month,
            claimRequestData: data,
            productionData: state.data
        });
        setLoader(false);
        setIsClaimRequestVisible(true);
    }

     const closeClaimReqGrand = ()=>{
        setIsClaimReqGrand(false)
      }


    const handleClaimReqGrandTotal = async (method:string) => {
        setLoader(true);
        const practiceParams: string[] = [];
        if (paramsState.practiceOptions) {
            paramsState.practiceOptions.forEach((practice: { value:string }) => {
                practiceParams.push(practice.value);
            });
        }
        let practices = (paramsState.practices).length>0?paramsState.practices:practiceParams;
        let data :object;
            if(Object.keys(state.claimRequestData).length>0){
                data=state.claimRequestData
            }
            else{
                data =await actions.getCellData(practices,monthsParam,method);
            }
        setClaimReqGrand({claimRequestData:data ,productionData:state.data })
        setIsClaimReqGrand(true);
        setLoader(false);

    }

    const handlePayPlanPayClick=async(month: string,  method: string) => {
        setLoader(true);
        const data = await actions.getCellData(paramsState.practiceParams,monthsParam, method);
        setStates({...state, claimRequestData: data});
        setPayPlanPayModal({
            month: month,
            payPlanPayData: data,
            productionData: state.data
        });
        setLoader(false);
        setIsPayPlanPayVisible(true);
    }

    const grandWOPopupClose = () => {
        setWriteOffGrandVisible(false)
    }

    const handleWOGrandTotal = async (method:string)=>{
        setLoader(true)
        const practiceParams: string[] = [];
        if (paramsState.practiceOptions) {
            paramsState.practiceOptions.forEach((practice: { value:string }) => {
                practiceParams.push(practice.value);
            });
        }
        let practices = (paramsState.practices).length>0?paramsState.practices:practiceParams;
            const data = await actions.getCellData(practices,monthsParam, method);
            setStates({...state, writeOffData: data});
            setWOgrandTotal({
                writeOffData: data,
                productionData: state.data,
                grandTotalPractices:state.grandTotalPractices
            });

        setWriteOffGrandVisible(true);
        setLoader(false);
    }

    const grossProdClose =()=>{
        setIsgrossProdVisible(false)
    }

    const handleProductionCell = async (month:string)=>{
        setLoader(true);  
        const practiceParams: string[] = [];
        if (paramsState.practiceOptions) {
            paramsState.practiceOptions.forEach((practice: any) => {
                practiceParams.push(practice.value);
            });
         }
        let practices = (paramsState.practices).length>0?paramsState.practices:practiceParams;
        const months=[month];
        const data: any = await actions.getGrossProduction(practices,months);
        setGrossProduction({month:month ,grossProdData:data ,productionData:state.data })
        setIsgrossProdVisible(true);
        setLoader(false);
    }

    const sendGProduction =  async (level:string,toggleColumns={} )=>{
        await actions.sendgrossProdEmail({...grossProductionData,setLevel:level,setDataCol:toggleColumns});
        alert('Email sent');
        grossProdClose();      
    }

    const grossProdTotalClose =()=>{
        setProductionsumVisible(false)
    }

    const handleProductionTotal = async () =>{
        setLoader(true);  
        const practiceParams: string[] = [];
        if (paramsState.practiceOptions) {
            paramsState.practiceOptions.forEach((practice: any) => {
                practiceParams.push(practice.value);
            });
         }
        let practices = (paramsState.practices).length>0?paramsState.practices:practiceParams;
        const data: any = await actions.getGrossProduction(practices,monthsParam);
        setProductionttl({grossProdData:data ,productionData:state.data , grandTotalPractices:state.grandTotalPractices })
        setProductionsumVisible(true);
        setLoader(false);
    }

    const sendProductionTotal =  async (level:string,toggleColumns={} )=>{
        await actions.sendgrossProdEmail({...grossProdgTotal,grandLevel:level,setgrandCol:toggleColumns});
        alert('Email sent');
        grossProdTotalClose();      
    }
    const calculateData =async (month:string ,method)=>{
        const practiceParams: string[] = [];
        if (paramsState.practiceOptions) {
            paramsState.practiceOptions.forEach((practice: any) => {
                practiceParams.push(practice.value);
            });
        }
        let practices = (paramsState.practices).length>0?paramsState.practices:practiceParams;
        const months=[month];
        return actions.getCellData(practices, months, method);
    }


    const handleClaimPayClick=async(month: string,  method: string) => {
        setLoader(true);
         const data = await calculateData(month,method);
        setStates({...state, claimPayData: data});
        setClaimPayModal({
            month: month,
            claimPayData: data,
            productionData: state.data
        });
        setLoader(false);
        setClaimPayVisible(true);
    }

    const claimPayClose = () =>{
        setClaimPayVisible(false)
    }

    const sendClaimPay =  async (level:string,toggleColumns={} )=>{
        await actions.sendClaimPayEmail({...claimPayModalData,setLevel:level,setDataCol:toggleColumns});
        alert('Email sent');
        claimPayClose();

    }

    const handleCashSplitCell = async (month: string,  method: string)=>{
        setLoader(true);
        const data = await calculateData(month,method);
        setStates({...state, cashSplitData: data});
        setCashSplitModal({
            month: month,
            cashSplitData: data,
            productionData: state.data
        });
        setLoader(false);
        setCashSplitVisible(true);
    }

    const cashSplitClose =()=>{
        setCashSplitVisible(false);
    }

    const sendCashSplit =  async (level:string,toggleColumns={} )=>{
        await actions.sendCashSplitEmail({...cashSplitModalData,setLevel:level,setDataCol:toggleColumns});
        alert('Email sent');
        cashSplitClose();
    }

    const handleWOAppealCell = async (month: string,  method: string)=>{
        setLoader(true);
        const data = await calculateData(month,method);
        setStates({...state, writeOffAppealData: data});
        setWOAppeal({
            month: month,
            writeOffAppealData: data,
            productionData: state.data
        });
        setLoader(false);
        setIsWoAppealVisible(true);
    }

    const woAppealClose =()=>{
        setIsWoAppealVisible(false);
    }

    const sendWriteOffAppeal =  async (level:string,toggleColumns={} )=>{
        await actions.sendWOAppealEmail({...woAppealData,setLevel:level,setDataCol:toggleColumns});
        alert('Email sent');
        woAppealClose();
    }

    const handleWOAppealTotal= async (method:string)=>{
        setLoader(true);
        const practiceParams: string[] = [];
        if (paramsState.practiceOptions) {
            paramsState.practiceOptions.forEach((practice: any) => {
                practiceParams.push(practice.value);
            });
        }
        let practices = (paramsState.practices).length>0?paramsState.practices:practiceParams;
        const data: any = await actions.getCellData(practices,monthsParam,method);
        setWOAppealGrand({writeOffAppealData:data ,productionData:state.data,grandTotalPractices:state.grandTotalPractices })
        setIsWoAppealGrandVisible(true);
        setLoader(false);
    }

    const woAppealGrandTTLClose =()=>{
        setIsWoAppealGrandVisible(false);
    }

    const sendWriteOffAppealTotal =  async (level:string ,toggleColumns={})=>{
        await actions.sendWOAppealEmail({...woAppealGrandTTLData,grandLevel:level ,setgrandCol:toggleColumns});
        alert('Email sent');
        woAppealGrandTTLClose();
    }
     const sendClaimReqGrand = async (level:string , toggleColumns={})=>{
         await actions.sendClaimRequestEmail({...claimReqGrandData,grandLevel:level,setgrandCol:toggleColumns});
         alert('Email sent');
         closeClaimReqGrand();
     }

   const handleCPayGrandTotal = async (method:string)=> {
        setLoader(true);
        const practiceParams: string[] = [];
        if (paramsState.practiceOptions) {
            paramsState.practiceOptions.forEach((practice: { value:string }) => {
                practiceParams.push(practice.value);
            });
        }
        let practices = (paramsState.practices).length>0?paramsState.practices:practiceParams;
        let cPayData :object;
        if(Object.keys(state.claimPayData).length>0){
            cPayData=state.claimPayData
        }
        else{
            cPayData =await actions.getCellData(practices,monthsParam,method);
        }
        setClaimPayGrandData({claimPayData:cPayData ,productionData:state.data })
        setIsClaimPayGrand(true);
        setLoader(false);
    }
    const claimPayGrandClose = ()=>{
        setIsClaimPayGrand(false)
    }

    const sendClaimPayGrand = async (level:string , toggleColumns={})=>{
        await actions.sendClaimPayEmail({...claimPayGrandData,grandLevel:level,setgrandCol:toggleColumns});
        alert('Email sent');
        claimPayGrandClose();
    }

    const handleCashSplitTotal= async (method:string)=>{
        setLoader(true);
        const practiceParams: string[] = [];
        if (paramsState.practiceOptions) {
            paramsState.practiceOptions.forEach((practice: { value:string }) => {
                practiceParams.push(practice.value);
            });
        }
        let practices = (paramsState.practices).length>0?paramsState.practices:practiceParams;
        let csplitData :object;
        if(Object.keys(state.cashSplitData).length>0){
            csplitData=state.cashSplitData;
        }
        else{
            csplitData =await actions.getCellData(practices,monthsParam,method);
        }
        setCashSplitGrand({cashSplitData:csplitData ,productionData:state.data })
        setIsCashSplitGrandVisible(true);
        setLoader(false);
    }
    const cashSplitCloseGrand=()=>{
        setIsCashSplitGrandVisible(false);
    }

    const sendCashSplitGTotal =  async (level:string,toggleColumns={} )=>{
        await actions.sendCashSplitEmail({...cashSplitGrandData,grandLevel:level,setgrandCol:toggleColumns});
        alert('Email sent');
        cashSplitCloseGrand();
    }

    return (
        <Template activeLink="production_collection">
            
            {loader && <CountUpTimer></CountUpTimer>}

            <Card>
                <Card.Content>
                    <Grid>
                        <Grid.Row>

                            <Grid.Column>
                                <h2 className="headingMain">Production v Collection <HelpMark helpPage={HELP_PAGE.BILLING_PRODUCTION_COLLECTION} application="Billing-Collection-ProductvCollection"/></h2>
                                <div className="topFilters">
                                    <Button.Group size='small'>
                                        <Button size='small' attached='left' onClick={handlePreviousMonth}><Icon className='left chevron'/></Button>
                                        <Button size='small' attached='right' onClick={handleNextMonth}><Icon className='right chevron'/></Button>
                                    </Button.Group>

                                <Dropdown
                                    search={true}
                                    name="practice"
                                    placeholder="Practice"
                                    selection={true}
                                    multiple={true}
                                    options={paramsState.practiceOptions}
                                    onChange={(e, v) => onChange(v,'practice')}
                                />

                                <Dropdown
                                    search={true}
                                    name="brand"
                                    placeholder="Brand"
                                    selection={true}
                                    multiple={true}
                                    options={[{text:'ALL' , value:'All' , key:'All'}].concat(brands)}
                                    onChange={(e, v) => onChange(v,'brand')}
                                    value={selectedBrands}
                                />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <div className="FreezeTable">
                        <Table size="small" unstackable striped={true} celled={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    {
                                        Object.keys(state.data || {}).reverse().map(key => <Table.HeaderCell
                                            content={<b>{moment(key + '-01').format('MMM YYYY')}</b>}/>)
                                    }
                                    <Table.HeaderCell content="Grand Total"/>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell positive={true} content={<b>Gross Production</b>}/>
                                    {
                                        Object.entries(state.data || {}).reverse()
                                            .map(([key,value]: [string,any]) => <Table.Cell
                                            onClick={() => handleProductionCell(key)}
                                                content={(value.production || 0 as any).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD"
                                                })}/>)
                                    }
                                    <Table.Cell
                                    onClick={()=>handleProductionTotal()}
                                    content={<b>{
                                        (Object.values(state.data || {}).reduce((acc: number, value: any) => {
                                            return acc + value.production
                                        }, 0) || 0 as any)
                                            .toLocaleString("en-US", {style: "currency", currency: "USD"})
                                    }</b>}/>
                                </Table.Row>
                                <Table.Row><Table.Cell content={<b>Adjustments</b>}/>
                                    {
                                        Object.entries(state.data || {}).reverse()
                                            .map(([key, value]: [string, any]) => (
                                                <Table.Cell
                                                    key={key}
                                                    onClick={() => handleCellClick(key, 'adjustmentData')}
                                                    content={(value.adjustment || 0).toLocaleString("en-US", {
                                                        style: "currency",
                                                        currency: "USD"
                                                    })}
                                                />
                                            ))
                                    }
                                    <Table.Cell 
                                    onClick={()=>handleGrandTotal('adjustmentData')}
                                    content={<b>{
                                        (Object.values(state.data || {}).reduce((acc: number, value: any) => {
                                            return acc + Math.abs(value.adjustment)
                                        }, 0) || 0 as any)
                                            .toLocaleString("en-US", {style: "currency", currency: "USD"})
                                    }</b>}/>

                                </Table.Row>
                                <Table.Row><Table.Cell content={<b>Write Off</b>}/>
                                    {
                                        Object.entries(state.data || {}).reverse()
                                            .map(([key,value]: [string,any]) => <Table.Cell
                                                onClick={()=>handleWriteoffClick(key, 'writeOffData')}
                                                content={(value.writeoff || 0 as any).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD"
                                                })}/>)
                                    }
                                    <Table.Cell
                                    onClick={()=>handleWOGrandTotal("writeOffData")}
                                    content={<b>{
                                        (Object.values(state.data || {}).reduce((acc: number, value: any) => {
                                            return acc + value.writeoff
                                        }, 0) || 0 as any)
                                            .toLocaleString("en-US", {style: "currency", currency: "USD"})
                                    }</b>}/>

                                </Table.Row>

                                <Table.Row><Table.Cell content={<b>Write-Off Appeal</b>}/>
                                    {
                                        Object.entries(state.data || {}).reverse()
                                            .map(([key,value]: [string,any]) => <Table.Cell
                                                onClick={()=>handleWOAppealCell(key,"writeOffAppealData")}
                                                content={(value.woappl || 0 as any).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD"
                                                })}/>)
                                    }
                                    <Table.Cell
                                        onClick={()=>handleWOAppealTotal("writeOffAppealData")}
                                        content={<b>{
                                        (Object.values(state.data || {}).reduce((acc: number, value: any) => {
                                            return acc + value.woappl
                                        }, 0) || 0 as any)
                                            .toLocaleString("en-US", {style: "currency", currency: "USD"})
                                    }</b>}/>


                                </Table.Row>
                                <Table.Row><Table.Cell content={<b>Claim Request</b>}/>
                                    {
                                        Object.entries(state.data || {}).reverse()
                                        .map(([key,value]: [string,any]) => <Table.Cell
                                            onClick={()=>handleClaimRequestClick(key, 'claimRequestData')}
                                            content={(value.claim_request || 0 as any).toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD"
                                            })}/>)
                                    }
                                    <Table.Cell
                                        onClick={()=>handleClaimReqGrandTotal('claimRequestData')}
                                        content={<b>{
                                        (Object.values(state.data || {}).reduce((acc: number, value: any) => {
                                            return acc + value.claim_request
                                        }, 0) || 0 as any)
                                            .toLocaleString("en-US", {style: "currency", currency: "USD"})
                                    }</b>}/>


                                </Table.Row>

                                <Table.Row><Table.Cell positive={true} content={<b>Pay Plan Balance</b>}/>
                                    {
                                        Object.values(state.data || {}).reverse()
                                            .map((value: any) => <Table.Cell
                                                content={(value.PP_balance || 0 as any).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD"
                                                })}/>)
                                    }
                                    <Table.Cell content={<b>{
                                        (Object.values(state.data || {}).reduce((acc: number, value: any) => {
                                            return acc + value.PP_balance
                                        }, 0) || 0 as any)
                                            .toLocaleString("en-US", {style: "currency", currency: "USD"})
                                    }</b>}/>
                                </Table.Row>
                                <Table.Row><Table.Cell positive={true} content={<b>Cash Split</b>}/>
                                    {
                                        Object.entries(state.data || {}).reverse()
                                            .map(([key,value]: [string,any]) => <Table.Cell
                                                onClick={()=>handleCashSplitCell(key ,"cashSplitData")}
                                                content={(value.Cash_Split || 0 as any).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD"
                                                })}/>)
                                    }
                                    <Table.Cell
                                        onClick={()=>handleCashSplitTotal("cashSplitData")}
                                        content={<b>{
                                        (Object.values(state.data || {}).reduce((acc: number, value: any) => {
                                            return acc + value.Cash_Split
                                        }, 0) || 0 as any)
                                            .toLocaleString("en-US", {style: "currency", currency: "USD"})
                                    }</b>}/>
                                </Table.Row>
                                <Table.Row><Table.Cell positive={true} content={<b>Claim Pay</b>}/>
                                    {
                                        Object.entries(state.data || {}).reverse()
                                            .map(([key,value]: [string,any]) => <Table.Cell
                                                onClick={()=>handleClaimPayClick(key,"claimPayData")}
                                                content={(value.Claim_Pay || 0 as any).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD"
                                                })}/>)
                                    }
                                    <Table.Cell
                                        onClick={()=>handleCPayGrandTotal("claimPayData")}
                                        content={<b>{
                                        (Object.values(state.data || {}).reduce((acc: number, value: any) => {
                                            return acc + value.Claim_Pay
                                        }, 0) || 0 as any)
                                            .toLocaleString("en-US", {style: "currency", currency: "USD"})
                                    }</b>}/>

                                </Table.Row>
                                <Table.Row><Table.Cell positive={true} content={<b>Pay Plan Pay</b>}/>
                                    {
                                        Object.entries(state.data || {}).reverse()
                                        .map(([key,value]: [string,any]) => <Table.Cell
                                        onClick={()=>handlePayPlanPayClick(key, 'payPlanPayData')}
                                        content={(value.PayPlanPay || 0 as any).toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD"
                                            })}/>)
                                    }
                                    <Table.Cell content={<b>{
                                        (Object.values(state.data || {}).reduce((acc: number, value: any) => {
                                            return acc + value.PayPlanPay
                                        }, 0) || 0 as any)
                                            .toLocaleString("en-US", {style: "currency", currency: "USD"})
                                    }</b>}/>
                                </Table.Row>

                                <Table.Row><Table.Cell content={<b>PayPlan Due</b>}/>
                                    {
                                        Object.values(state.data || {}).reverse()
                                            .map((value: any) => <Table.Cell
                                                content={(value.PP_Due || 0 as any).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD"
                                                })}/>)
                                    }
                                    <Table.Cell content={<b>{
                                        (Object.values(state.data || {}).reduce((acc: number, value: any) => {
                                            return acc + value.ins_pay_check_date
                                        }, 0) || 0 as any)
                                            .toLocaleString("en-US", {style: "currency", currency: "USD"})
                                    }</b>}/>
                                </Table.Row>
                                <Table.Row><Table.Cell content={<b>Ins Pay Check Date</b>}/>
                                    {
                                        Object.values(state.data || {}).reverse()
                                            .map((value: any) => <Table.Cell
                                                content={(value.ins_pay_check_date || 0 as any).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD"
                                                })}/>)
                                    }
                                    <Table.Cell content={<b>{
                                        (Object.values(state.data || {}).reduce((acc: number, value: any) => {
                                            return acc + value.ins_pay_check_date
                                        }, 0) || 0 as any)
                                            .toLocaleString("en-US", {style: "currency", currency: "USD"})
                                    }</b>}/>
                                </Table.Row>
                                <Table.Row><Table.Cell content={<b>Cash Split Date Entry</b>}/>
                                    {
                                        Object.values(state.data || {}).reverse()
                                            .map((value: any) => <Table.Cell
                                                content={(value.Cash_Split_Date_Entry || 0 as any).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD"
                                                })}/>)
                                    }
                                    <Table.Cell content={<b>{
                                        (Object.values(state.data || {}).reduce((acc: number, value: any) => {
                                            return acc + value.Cash_Split_Date_Entry
                                        }, 0) || 0 as any)
                                            .toLocaleString("en-US", {style: "currency", currency: "USD"})
                                    }</b>}/>
                                </Table.Row>
                                <Table.Row><Table.Cell content={<b>PayPlanPay Date Entry</b>}/>
                                    {
                                        Object.values(state.data || {}).reverse()
                                            .map((value: any) => <Table.Cell
                                                content={(value.PayPlanPay_Date_Entry || 0 as any).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD"
                                                })}/>)
                                    }
                                    <Table.Cell content={<b>{
                                        (Object.values(state.data || {}).reduce((acc: number, value: any) => {
                                            return acc + value.PayPlanPay_Date_Entry
                                        }, 0) || 0 as any)
                                            .toLocaleString("en-US", {style: "currency", currency: "USD"})
                                    }</b>}/>
                                </Table.Row>
                                <Table.Row><Table.Cell content={<b>ins_pay_date_entry</b>}/>
                                    {
                                        Object.values(state.data || {}).reverse()
                                            .map((value: any) => <Table.Cell
                                                content={(value.ins_pay_date_entry || 0 as any).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD"
                                                })}/>)
                                    }
                                    <Table.Cell content={<b>{
                                        (Object.values(state.data || {}).reduce((acc: number, value: any) => {
                                            return acc + value.ins_pay_date_entry
                                        }, 0) || 0 as any)
                                            .toLocaleString("en-US", {style: "currency", currency: "USD"})
                                    }</b>}/>
                                </Table.Row>
                                <Table.Row><Table.Cell positive={true} content={<b>Collected</b>}/></Table.Row>
                                <Table.Row><Table.Cell content={<b>Collectable Production</b>}/></Table.Row>


                            </Table.Body>
                        </Table>
                    </div>
                    {isPopupVisible && <AdjustmentsPopup data={popupData} closePopup={handlePopupClose}  sendData={sendModalData}/>}
                     {isGrandtotal&&<GrandTotalPopup data={grandttlData} closePopup={grandPopupClose} sendTotal={sendDataTotal}/>}
                     {iswriteOffVisible && <WriteOffPopUp data={writeoffModalData} closePopup={writeOffClose} sendData={sendWriteOff}/>}
                     {isClaimRequestVisible && <ClaimRequestPopUp data={claimRequestModalData} closePopup={claimRequestClose} sendData={sendClaimRequest}/>}
                     {isPayPlanPayVisible && <PayPlanPopUp data={payPlanPayModalData} closePopup={payPlanPayClose} sendData={sendPayPlanPay}/>}
                     {isWOGrandtotal && <WriteOffGrandTotal data={writeOffgrandttl} closePopup={grandWOPopupClose} sendwoTotal={sendWOgTotal}/>}
                     {isgrossProdVisible && <ProductionPopup data={grossProductionData} closePopup={grossProdClose} send={sendGProduction} />}
                     {isProductionTotalVisible&& <ProductionGrandTotal data={grossProdgTotal} closePopup={grossProdTotalClose} sendTotal={sendProductionTotal}/>}
                     {isClaimPay&&<ClaimPayPopUp data={claimPayModalData} closePopup={claimPayClose} sendData={sendClaimPay}/>}
                     {isCashSplit&&<CashSplitPopUp data={cashSplitModalData} closePopup={cashSplitClose} send={sendCashSplit}/>}
                     {isWOAppealVisible&&<WriteOffAppealModal data={woAppealData} closePopup={woAppealClose} sendData={sendWriteOffAppeal}/>}
                     {isWOAppealGrandTTKVisible&&<WOAppealGrandTotal data={woAppealGrandTTLData} closePopup={woAppealGrandTTLClose} sendWATotal={sendWriteOffAppealTotal}/>}
                     {isClaimReqGrandVisible&& <ClaimReqGrandTotal data={claimReqGrandData} closePopup={closeClaimReqGrand} sendCRTotal={sendClaimReqGrand} />}
                     {isClaimPayGrandTotal && <ClaimPayGrandTotal data={claimPayGrandData} closePopup={claimPayGrandClose} sendCPTotal={sendClaimPayGrand}/>}
                     {isCashSplitGrandVisible&&<CashSplitGrandTotal data={cashSplitGrandData} closePopup={cashSplitCloseGrand} sendCSplitTotal={sendCashSplitGTotal}/>}
                </Card.Content>
            </Card>
        </Template>
    )
}

export default ProductionCollection
