import React, { useEffect, useState } from "react";
import "semantic-ui-css/semantic.min.css";
import {Button, Dropdown, Grid,  Modal, Table} from "semantic-ui-react";
import { RootState } from "../model";
import { connect } from "react-redux";
import * as ClaimsActions from "../redux/actions/claims";
import * as AppActions from "../redux/actions/app";
import { useActions } from "../redux/actions";
import Template from "./Template";
import HelpMark from "./Help/helpMark";
import {DentalXchangePopUpTable}  from "../constants"
import {CSVLink} from "react-csv"
const dentalXchangeBrands = ['SDD', 'SMD','FSD'];

interface DentalXchangeStatusReportProps {
    report: any;
    practiceList: any;
    regions: any;
}

const DentalXchangeStatusReport = (props: DentalXchangeStatusReportProps) => {
    const claimsActions = useActions(ClaimsActions);
    const appActions = useActions(AppActions);
    const initialFilters = {
        practice: [],
        region: [],
    };
    const [filters, setFilters] = useState(initialFilters);
    const initialModalData = {
        practice: [],
        status: "",
        data:[]
    };
    const [lastRunTime,setLastRunTime]= useState("");
    const [modalData,setModalData]= useState(initialModalData);
    const practiceOptions: any = [];
    const regionOptions: any = [];

    useEffect(() => {
        setTimeout(function () {
            const defaultFillter = {brands : dentalXchangeBrands }
            appActions.FetchPracticeList();
            appActions.fetchRegions();
            claimsActions.FetchDentalXchangeReport(defaultFillter);
            getLastRunTime()
        }, 0);
    }, []);

    const { regions, practiceList } = props;

    regions.regions &&
    regions.regions.forEach((region: any) => {
        const obj = {
            key: region.id,
            value: region.id,
            text: region.name,
        };
        regionOptions.push(obj);
    });
    
    practiceList &&
    practiceList.forEach((prac: any) => {
        if(dentalXchangeBrands.includes(prac.UdaPracticeInfo.brand)){
            const obj = {
                key: prac.id,
                value: prac.practice,
                text: prac.practice,
            };
            practiceOptions.push(obj);
        }

    });

    const handleSendEmail = () => {
        const data = getCSVData(props.report, filters, regions);
        claimsActions.sendEmailWithAttachment({title: 'DentalXchange Status Report', reportData: data });
    }

    const processData = (data: any[], filters: any, regions: any) => {
        const result = {};
        const totals = {};

        const filteredData = data.filter((item) => {
            const practice = item.practiceInfo.practice;
            if (filters.practice.length && !filters.practice.includes(practice)) {
                return false;
            }
            if (filters.region.length) {
                const regionPractices = filters.region.flatMap(
                    (reg) => regions.regionPracticeList[reg] || []
                );
                if (!regionPractices.includes(practice)) {
                    return false;
                }
            }
            return true;
        });

        filteredData.forEach((item) => {
            const { emdeonStatus, count,practice ,practiceInfo } = item;
            const practiceName = practiceInfo.practice;

            if (!result[practiceName]) {
                result[practiceName] = { practiceName ,practice};
            }

            const statuses = emdeonStatus ? emdeonStatus.split(",") : ["Null"];

            statuses.forEach((status) => {
                if (result[practiceName][status]) {
                    result[practiceName][status] += count;
                } else {
                    result[practiceName][status] = count;
                }

                if (totals[status]) {
                    totals[status] += count;
                } else {
                    totals[status] = count;
                }
            });
        });

        return { result, totals };
    };
    const  moveElementToEnd=(arr, element)=> {
        let index = arr.indexOf(element);

        if (index !== -1) {
            let removedElement = arr.splice(index, 1)[0];
            arr.unshift(removedElement);
        }

        return arr;
    }

    const openViewDetails =(practice:any,status:string)=>{
        claimsActions.FetchMemberStatusByDentaXStatus({practice,  status}).then((resp:any)=>{

            resp.forEach((item: any) => {
                item.type = item.type && item.type.includes("Members")
                            ? item.type.replace('Members', '') 
                            : item.type;
            });

            setModalData({practice,status,data:resp})
        })

    }
    const getFilterdPracticesABR=(processedData)=>{
        const filteredData = Object.values(processedData);
        return filteredData.map((item:any)=>item.practice)
    }
    const renderTable = (data, filters, regions) => {
        const { result: processedData, totals } = processData(data, filters, regions);

        let filteredPractices = Object.keys(processedData);

        if (filters.region.length) {
            const regionPractices = filters.region.flatMap(
                (reg) => regions.regionPracticeList[reg] || []
            );
            filteredPractices = filteredPractices.filter((practice) =>
                regionPractices.includes(practice)
            );
        }

        if (filters.practice.length) {
            filteredPractices = filteredPractices.filter((practice) =>
                filters.practice.includes(practice)
            );
        }

        let statuses = Array.from(
            new Set(
                data.flatMap((item) =>
                    item.emdeonStatus ? item.emdeonStatus.split(",") : ["Null"]
                )
            )
        );

        statuses = moveElementToEnd(statuses, "Processed")
        statuses = moveElementToEnd(statuses, "Received")

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Practice</Table.HeaderCell>
                        {statuses.map((status:any) => (
                            <Table.HeaderCell key={status} className={status === 'Processed' || status === 'Received' ? 'cell-green' : 'cell-yellow'}>{status}</Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {filteredPractices.map((practice) => (
                        <Table.Row key={practice}>
                            <Table.Cell>{processedData[practice].practiceName}</Table.Cell>
                            {statuses.map((status: string) => (
                                <Table.Cell key={status}>
                                    {status === 'Processed' || status === 'Received' || !processedData[practice][status] ?
                                        processedData[practice][status] || 0 :
                                        <span onClick={() => openViewDetails([processedData[practice].practice],status)}
                                              className='cursorPointer'>
                           <a>{processedData[practice][status] || 0}</a></span>
                                    }
                                </Table.Cell>
                            ))}
                        </Table.Row>
                    ))}
                    <Table.Row>
                        <Table.Cell>
                            <strong>Total</strong>
                        </Table.Cell>
                        {statuses.map((status: string) => (
                            <Table.Cell key={status}>
                                <strong>
                                {status === 'Processed' || status === 'Received' || !totals[status] ?
                                    totals[status] || 0 :
                                    <span onClick={() => openViewDetails(getFilterdPracticesABR(processedData),status)}
                                          className='cursorPointer'>
                           <a>{totals[status] || 0}</a></span>
                                }
                                </strong>
                            </Table.Cell>
                        ))}
                    </Table.Row>
                </Table.Body>
            </Table>
        );
    };

    const getCSVData = (data, filters, regions) => {
        const { result: processedData, totals } = processData(data, filters, regions);

        const filteredPractices = Object.keys(processedData);


        let statuses = Array.from(
            new Set(
                data.flatMap((item) =>
                    item.emdeonStatus ? item.emdeonStatus.split(",") : ["Null"]
                )
            )
        );
        statuses = moveElementToEnd(statuses, "Processed")
        statuses = moveElementToEnd(statuses, "Received")
        const csvData = [
            ["Practice", ...statuses],
            ...filteredPractices.map((practice) => [
                processedData[practice].practiceName,
                ...statuses.map((status) => processedData[practice][status] || 0),
            ]),
            ["Total", ...statuses.map((status:any) => totals[status] || 0)],
        ];
        return csvData;
    };
    const handleClose=()=>{
        setModalData(initialModalData)
    }

    const getPopUpCSVData = () => {
        const {headers,keys }=DentalXchangePopUpTable;
        const { data,status }=modalData;
        const csv= [headers];
        data.map((row) => {
                const arr:any=[];
                keys.map((key: string) => {
                    arr.push(row[key])
                })
            csv.push(arr);
        })
        return csv;
    }
    const handleSendEmailForPopup = () => {
        const { status }=modalData;
        const data = getPopUpCSVData();
        claimsActions.sendEmailWithAttachment({title: 'DentalXchange Status Report. For Status :'+status, reportData: data });
    }
    const getLastRunTime=()=>{
        claimsActions.GetDentalXChangeStatusLastScrapeTime().then((r:any)=>{
            setLastRunTime(r.lastRunTime)
        })
    }
    function canLambdaRerun(startTime) {
        if(!startTime){
            return true
        }
        const endTime = new Date();
        const difference = endTime.getTime() - new Date(startTime).getTime();
        const minutes = Math.floor(difference / 60000);
        return minutes>=20;
    }
    const getEmdeonStatusHandler = () => {

        if (window.confirm("Are you sure?")) {
            claimsActions.GetDentalXChangeStatusLastScrapeTime().then((r:any)=>{
                setLastRunTime(r.lastRunTime)
                const canRerun = canLambdaRerun(lastRunTime);
                if(canRerun) {
                    const obj = {
                        identifier: "All",
                        practice: "All",
                        status: "All",
                        emdeonFilter: "All"
                    }

                    claimsActions.GetEmdeonClaimsStatus(JSON.stringify(obj))
                        .then(res => {
                            alert(res.msg)
                        })
                }else{
                    window.alert('Can not rerun now, wait few minutes and try again ');
                }
            })
        }
    }
const renderPopUpTable=()=>{
    const {headers,keys }=DentalXchangePopUpTable;
    const { data,status }=modalData;
        return(
            <Modal
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={!!modalData.practice}
                onClose={handleClose}
            >
                <Modal.Header>
                    <strong>{status} ({data.length||0}) </strong>
                    <div className="ui top right attached label production_right_label">
                        <Button onClick={handleClose} inverted>
                            Close
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    {headers.map((header:any) => (
                                        <Table.HeaderCell key={header} >{header}</Table.HeaderCell>
                                    ))}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {data&&data.map((row) => (
                                    <Table.Row key={row.id}>
                                        {keys.map((key: string) => (
                                            <Table.Cell key={key}>
                                                {(row[key] || "")}
                                            </Table.Cell>
                                        ))}
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>

                    </Modal.Description>
                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={handleSendEmailForPopup} inverted
                            color='orange'>
                        Send Mail
                    </Button>
                </Modal.Actions>
            </Modal>
        )
}
    return (
        <Template activeLink="dentalxchange-status-report">
            <div className="ui card">
                <div className="content pb0">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h2 className="headingMain">
                                    DentalXchange Status Report
                                    <HelpMark application="Billing-dentalxchange-status-report" />
                                </h2>

                                <div className="topFilters">
                                    <div className="filter d-InlineBlock float-left mt20 mr10">
                                        <Button positive={true} onClick={() => getEmdeonStatusHandler()}>
                                            Rerun DentalXChange
                                        </Button>
                                    </div>
                                    <div className="filter d-InlineBlock float-left mt20 mr10">
                                        {lastRunTime?  "Scrapped : "+lastRunTime:""}
                                    </div>
                                    <div className="filter d-InlineBlock float-left mt20 mr10">
                                        <Button positive={true} onClick={() => handleSendEmail()}>
                                            Send Email
                                        </Button>
                                    </div>
                                    <div className="filter d-InlineBlock float-left">
                                        <label className="labelStyle displayBlock">Filter By Region</label>
                                        <Dropdown
                                            className="mb15 mr10"
                                            placeholder="Select Region"
                                            search
                                            multiple={true}
                                            selection
                                            value={filters.region}
                                            options={regionOptions}
                                            onChange={(event, data) => {
                                                setFilters({ ...filters, region: data.value as any});
                                            }}
                                        />
                                    </div>

                                    <div className="filter d-InlineBlock float-left">
                                        <label className="labelStyle displayBlock">Filter By Practice</label>
                                        <Dropdown
                                            className="mb15 mr10"
                                            placeholder="Select Practice"
                                            search
                                            multiple={true}
                                            selection
                                            value={filters.practice}
                                            options={practiceOptions}
                                            onChange={(event, data) => {
                                                setFilters({ ...filters, practice: data.value as any});
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
            <div className="ui card claims">
                <div className="content">
                    {modalData.practice.length&&renderPopUpTable()}
                    { props.report && renderTable(props.report, filters, regions)}
                </div>
            </div>

        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        report: state.claims.dentalXchange,
        practiceList: state.app.practiceList,
        regions: state.app.regions,
    };
}

export default connect(mapStateToProps)(DentalXchangeStatusReport);
