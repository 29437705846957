import React, {useEffect, useState} from "react";
import Template from "./Template";
import * as reportActions from "../redux/actions/usage";
import {useActions} from "../redux/actions";
import {RootState} from "../model";
import {connect} from "react-redux";
import {UsageData} from "../model/usage";
import _ from "lodash";
import {allowDeleteUserId, HELP_PAGE, udaModules} from '../constants' ;
import {Grid, Button, Checkbox, Input, Icon, Dropdown, Dimmer, Loader} from "semantic-ui-react";
import {User} from "../model/user";
import HelpMark from "./Help/helpMark"
import CountUpTimer from "./CountUpTimer";

interface Props {
    auth: User,
}

function EmployeeUsageTable(props: Props) {
    const reportAction = useActions(reportActions);
    const [currentModules, setCurrentModules] = useState([]);
    const [loader, setLoader] = useState(false);
    const initialState: UsageData = {
        direction: 'sort',
        TableData: [[{title: 'No Records Found'}]],
        TableHead: [{title: ''}],
        actualData: [],
        showHidden: false,
        column: -1,
        searchKeyword: ''
    };
    let timeout: any = 0;
    const [state, setStates] = useState(initialState);
    const user = props.auth.user;
    const allowDelete = (user && allowDeleteUserId.indexOf(user.id) >= 0)

    useEffect(() => {
        getUsageData();
    }, []);


    const getUsageData = () => {
        setLoader(true)
        reportAction.getEmployeeUsageData()
            .then((res: any) => {
                const actualData = Object.values(res);
                let TableData = pushTableData(res, state.showHidden);
                TableData = initialSort(3, TableData)
                const TableHead = TableHeadData();
                setStates({...state, TableHead, TableData, actualData});
                setLoader(false)
            });
    }

    const initialSort = (clickedColumn, TableData) => {
        return _.orderBy(TableData, o => o[clickedColumn].title, 'desc');
    }
    const TableHeadData = () => {
        const TableHead = new Array();
        TableHead[0] = {title: 'Hide'};
        TableHead[1] = {title: 'NAME'};
        TableHead[2] = {title: 'PRACTICE'};
        TableHead[3] = {title: 'LAST LOGIN DATE'};
        TableHead[4] = {title: 'MODULE'};
        TableHead[5] = {title: 'TOTAL ACCESS'};
        TableHead[6] = {title: 'MOBILE COUNT'};
        TableHead[7] = {title: 'DESKTOP COUNT'};
        TableHead[8] = {title: 'RECENT MOBILE OS'};
        return TableHead;
    };

    const handleCheckBox = (hideUsage: any, userId: number) => {
        reportAction.hideUsage(userId, +hideUsage)
            .then((res: any) => {
                getUsageData();
            });
    };

    const filterByModules = (data) => {
        let { TableData } = state
        let tempDataContainer = [];
        const modules = data.value

        setCurrentModules(data.value)

        if (!modules.length) {
            reportAction.getEmployeeUsageData()
                .then((res: any) => {
                    const actualData = Object.values(res);
                    let TableData = pushTableData(res, state.showHidden);
                    TableData = initialSort(3, TableData)
                    const TableHead = TableHeadData();
                    setStates({...state, TableHead, TableData, actualData});
                });
            return;
        }

        for (let i = 0; i < TableData.length; i++) {
            const userModules = TableData[i][4] && TableData[i][4].title ? TableData[i][4].title.toString().split(',') : []
            if (userModules.some(mod => modules.indexOf(mod) >= 0)) {
                let userData = TableData[i];

                userData[4].title = modules.join(',')

                tempDataContainer.push(userData);
            }
        }
        setStates({...state, TableData: tempDataContainer })
    }

    const onSearchChange = (e: any) => {
        const searchKeyword = e.target.value;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            const data = state.actualData && state.actualData.filter((element: any) => {
                return (element.firstName.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0
                    || element.lastName.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0
                )
            });
            const TableData = pushTableData(data, state.showHidden);
            setStates({...state, searchKeyword: searchKeyword, TableData: TableData});
            clearTimeout(timeout);
        }, 500);
    };


    const pushTableData = (res: any, showHidden: any = false) => {
        let TableData = new Array();
        res && Object.values(res).map((item: any) => {
            if (item.hideUsage && !showHidden) {
                return true;
            }
            TableData.push([
                {
                    title: <Checkbox
                        onChange={(e, {checked}) => handleCheckBox(checked, item.userId)}
                        checked={item.hideUsage}
                    />
                },
                {title: item.firstName + ' ' + item.lastName},
                {title: item.practice ? item.practice : 'all'},
                {title: item.Date},
                {title: item.module.split("-").filter(r=>!(r==="billing"||r==="")).join(",")},
                {title: item.total},
                {title: item.Cell},
                {title: item.Desktop},
                {title: item.deviceOS},
            ])
        })

        return TableData;
    };

    const showHiddenReports = (showHidden: any) => {
        const TableData = pushTableData(state.actualData, showHidden);
        setStates({...state, TableData, showHidden: showHidden});
    }

    const flushData = () => {
        if (window.confirm("Are you sure you want to clear all usage data? Action will not undo.")) {
            const {id} = user;
            reportAction.flushUsageData(id)
                .then((res: any) => {
                    getUsageData();
                });
        }
    };

    const handleSort = (clickedColumn: any) => () => {
        let {column, TableData, direction} = state;

        if (column !== clickedColumn) {
            column = clickedColumn;
            TableData = _.orderBy(TableData, o => o[clickedColumn].title);
            direction = 'sort ascending';
            setStates({...state, TableData, column, direction});
            return;
        }
        TableData = TableData.reverse();
        direction = direction === 'sort ascending' ? 'sort descending' : 'sort ascending';
        setStates({...state, TableData, direction});
    };
    const getTableHeadings = () => {
        let returnArray: any = [];
        if (state.TableHead.length) {
            state.TableHead.forEach((item, key) => {
                let index = -1;
                const icon = state.column ? (state.column === (item.key || key) ? <Icon name={state.direction}/> :
                    <Icon name={'sort'}/>) : null;
                returnArray.push(
                    <th
                        className={item.className}
                        id={item.id}
                        style={item.style}
                        key={item.key || key}
                        onClick={handleSort(key)}
                    >
                        {<div>{item.title} {state.TableData.length !== 0 ? icon : ''} </div>}
                        {index > -1 && <div>{item.title} </div>}
                    </th>)
            })
        }
        return (<thead>
        <tr>{returnArray}</tr>
        </thead>)
    };
    const getTableBody = () => {
        let tableRows: any = [];
        if (state.TableData.length) {
            state.TableData.forEach((itemArray, key) => {
                if (itemArray.length) {
                    let tableColumns: any = [];
                    itemArray.forEach((item, key2) => {
                        tableColumns.push(<td className={item.className} id={item.id} style={item.style}
                                              key={item.key || key2}>{item.title}</td>)
                    });
                    tableRows.push(<tr key={key}>{tableColumns}</tr>)
                }
            })
        }
        return (<tbody>{tableRows}</tbody>)
    };

    return (
        <Template activeLink='usage-report-employee'>
             {loader && <CountUpTimer></CountUpTimer>}
            <div className="ui card">
                <div className="content pb0">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h2 className="headingMain"> Employee Usage Report <HelpMark
                                    helpPage={HELP_PAGE.BILLING_USAGE}
                                    application="Billing-Usage" /></h2>
                                <div className="topFilters">
                                    {allowDelete &&
                                        <Checkbox
                                            label={'Show Hidden Reports'}
                                            className='mt10 mr10'
                                            onChange={(e, {checked}) => showHiddenReports(checked)}
                                            checked={state.showHidden}
                                        />
                                    }
                                    {allowDelete && <Button primary={true} onClick={flushData}>Flush Data</Button>}
                                    <Dropdown
                                        className="mb15 mr10"
                                        placeholder="Select Module"
                                        search
                                        selection
                                        multiple={true}
                                        value={currentModules}
                                        options={udaModules}
                                        onChange={(event, data:any)=> {
                                            filterByModules(data)

                                        }}
                                    />
                                    <Input className='mb15' icon='search' placeholder="Search"
                                           onChange={onSearchChange}/>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
            {
                <div className="ui card">

                    <div className="content">
                        <div className="table-responsive FreezeTable">
                            <table
                                className="ui table striped table-hover unstackable customTable adjustment" >
                                {getTableHeadings()}
                                {getTableBody()
                                }

                            </table>
                        </div>
                    </div>
                </div>
            }
        </Template>
    );

}

function mapStateToProps(state: RootState) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(EmployeeUsageTable);

