import React, {useEffect,useState} from "react";
import {Modal, Icon,Loader,Dimmer} from "semantic-ui-react";
import {RootState} from "../../model";
import {connect} from "react-redux";
import * as HelpActions from "../../redux/actions/help";
import {useActions} from "../../redux/actions";
import { HELP_PAGE, HelpPages } from '../../constants';
import ViewFileModal from './viewFileModal'

interface helpPageModalProps {
    hideHelpPageModal: any,
    helpPage: HELP_PAGE
  }

    const HelpPageModal:React.FC<any>=(props:helpPageModalProps)=>{
        
        const initialState={
           openFileModal: false,
            files: null 
        };
        const helpActions = useActions(HelpActions);
        const [state , setStates] = useState(initialState)
        const [loader , setLoader] =useState(false);
        const [data , setData]= useState<any>([])

       useEffect(()=>{
          fetchData();
       },[])
        
          const fetchData = async () => {
              const {helpPage} = props;
            helpActions.getSections(helpPage).then((res:any )=>{
                if (res && res.length) {
                    res.forEach(item => {
                      item.page = (
                        (item.parentPageId && HelpPages[item.parentPageId] && HelpPages[item.helpPage]) ?
                          `${HelpPages[item.parentPageId].name} > ${HelpPages[item.helpPage].name}` :
                          HelpPages[item.helpPage] ? HelpPages[item.helpPage].name : ''
                      );
                    });
                  }
                  setData(res)
            });          
          }
        
         const openFileModal = (type, files) => {
            setStates({...state, openFileModal: type, files: files });
          };
        
         const closeFilesModal = () => {
            setStates({...state, openFileModal: false, files: null });
          };    

      return(
        <Modal
        open={true}
        onClose={props.hideHelpPageModal}
        centered={false}
        size='large'
        closeIcon
      >
     {loader && <Dimmer active={true}>
        <Loader>Loading</Loader>
        </Dimmer>}
        <Modal.Content>
          <h3 className='text-center mb20'>HELP</h3>
          <ul className='helpList mb-0'>
            {(data && data.length) ?
              data.map((item, key) => {
                return (
                  <li key={key}>
                    <strong>{item.heading}</strong>
                    <div>
                      {item.description && <p className='mb-2'>{item.description}</p>}
                      {(item.fileLinks && item.fileLinks.toLowerCase().indexOf('.mp4') > 0) &&
                      <span onClick={() => openFileModal('Video', item.fileLinks)}
                            className='cursorPointer'><Icon className='primaryColor mr-2'
                                                            name='play circle' /><span
                        className='linkText'>Watch Video</span></span>}
                      {(item.fileLinks && item.fileLinks.toLowerCase().indexOf('.pdf') > 0) &&
                      <span onClick={() => openFileModal('PDF', item.fileLinks)}
                            className='cursorPointer'><Icon className='primaryColor mr-2'
                                                            name='file pdf' /><span
                        className='linkText'>View PDF</span></span>}
                    </div>
                  </li>
                );
              })
              :
              <div>No record found.</div>
            }
          </ul>
        </Modal.Content>
        {state.openFileModal && <ViewFileModal closeModal={closeFilesModal} files={state.files} type={state.openFileModal} />}
      </Modal>
      )

    }

export default HelpPageModal