import React from "react";
import {Button, Modal, Input, Header,Select, TextArea, Form} from "semantic-ui-react";
import {useFormik} from "formik"

const AddSetupQAModal:React.FC<any>=(props)=>{
    const formik=useFormik(
        {
            initialValues:{
                QAStatus:"",
            },
            onSubmit:values => {
                props.submitSetupData(values)
            }
        }
    )

    return (

        <Modal
            open={true}
            onClose={props.hideSetupModal}
            centered={false}
            size='tiny'
            closeIcon
        >
            <Header content={"Add QA Status "}/>
            <Modal.Content>
                <Form className="formStyle">
                    <label>Setup PPL QA</label>
                    <Input fluid name='QAStatus'  onChange={formik.handleChange} value={formik.values.QAStatus}/>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={props.hideSetupModal}>
                    Cancel
                </Button>
                <Button className='ui primary button' onClick={formik.submitForm}>
                    Submit
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default AddSetupQAModal