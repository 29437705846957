import { ActionType } from "../../model/actionTypes";
import { errorHandler } from "./auth";
export const hideErrorModal = () => async (dispatch: Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.CLOSE_ERROR_MESSAGE, action: null });
}

export const getUsageData = (userId:any , role ='Dentist') => async (dispatch: Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD,payload: true  });
    if(!userId){
        userId ='All';
    }
    if(role) {
        role = '/'+role;
    } else {
        role = '/All';
    }
    try {
        let res = await api.get(`usage/getUsage/${userId}${role}`);
        dispatch({ type: ActionType.LOAD_SUCCESS, payload:{usage: res} });
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_FAIL, error: err.message });
        return false;
    }
};

export const getEmployeeUsageData = (userId:any , role ='Dentist') => async (dispatch: Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD,payload: true  });
    if(!userId){
        userId ='All';
    }
    if(role) {
        role = '/'+role;
    } else {
        role = '/All';
    }
    try {
        let res = await api.get(`usage/getEmployeeUsage/${userId}${role}`);
        dispatch({ type: ActionType.LOAD_SUCCESS, payload:{usage: res} });
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_FAIL, error: err.message });
        return false;
    }
};

export const getNonEmployeeUsageData = (userId:any , role ='Dentist') => async (dispatch: Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD,payload: true  });
    if(!userId){
        userId ='All';
    }
    if(role) {
        role = '/'+role;
    } else {
        role = '/All';
    }
    try {
        let res = await api.get(`usage/getNonEmployeeUsage/${userId}${role}`);
        dispatch({ type: ActionType.LOAD_SUCCESS, payload:{usage: res} });
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_FAIL, error: err.message });
        return false;
    }
};



export const getUserList = () => async (dispatch: Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD });
    try {
        let res = await api.get(`/usage/getUser`);
        dispatch({ type: ActionType.LOAD_USER_DETAILS, payload:{  userList: res } });
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_FAIL, error: err.message });
        return false;
    }
};

export const getLastNextWorkDays = () => async (dispatch: Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD });
    try {
        let res = await api.get(`/usage/getLastNextWorkDays`);
        dispatch({ type: ActionType.LOAD_WORK_DAYS, res: res });
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_FAIL, error: err.message });
        return false;
    }
};

export const flushUsageData = (obj:any) => async (dispatch: Function, getState:Function, api:any) => {
    try {
        let res = await api.put(`/usage/flushUsageData`, {data: obj});
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_FAIL, error: err.message });
        return false;
    }
};

export const hideUsageReport = (obj:any) => async (dispatch: Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD });
    try {
        let res = await api.put(`/usage/hideUsageReport`, {data: obj});
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_FAIL, error: err.message });
    }
};

export const saveUdaUsageReport = (module:any, loginVia:any, deviceOS:any,activeLink:any,dispatch: Function, getState:Function, api:any) => {
    const user= getState().auth.user
    if(!user || !user.id) {
        setTimeout(function() { saveUdaUsageReport(module, loginVia, deviceOS,activeLink, dispatch, getState, api) }, 1000);
        return false;
    }
    let udaRole:any =[];
    if (user && user.UdrUsersPermissions  && user.UdrUsersPermissions.length) {
        user.UdrUsersPermissions.forEach(item=>{
            udaRole.push(item.UdrPermission.role);
        });
    }
    try {
        api.post('usage/saveUdaUsages', {
           
                userId: user.id,
                firstName: user.firstname,
                lastName: user.lastname,
                udaRole: udaRole.join(","),
                loginVia:loginVia,
                module: module,
                deviceOS: deviceOS,
                activeLink:activeLink
            }
        );
        return true;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_FAIL, error: err.message });
    }
};
export const saveUsage = (usage : any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const resp =  await api.post("usage/saveUdaUsages", usage);
		dispatch({ type: ActionType.SAVE_USAGE, payload: resp });
		return resp;
	} catch (err) {
		return dispatch(errorHandler(err));
	}
};

export const saveApplicationUsage = (defaultPracticeId: any, visitedUrl: any, userId: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const applicationUsageData = {
            defaultPracticeId,
            token: 'xxxxxx',
            userId,
            visitedUrl
        };

		const resp =  await api.post("usage/saveApplicationUsage", applicationUsageData);
		dispatch({ type: ActionType.SAVE_USAGE, payload: resp });
		return resp;
	} catch (err) {
		return dispatch(errorHandler(err));
	}
};

export const saveUdaUsage = (module:any, loginVia:any, deviceOS:any,activeLink:any) => async (dispatch: Function, getState:Function, api:any) => {
   const user= getState().auth.user
    if(!user || !user.id) {
        setTimeout(function() { saveUdaUsageReport(module, loginVia, deviceOS, activeLink,dispatch, getState, api) }, 1000);
    } else {
        saveUdaUsageReport(module, loginVia, deviceOS,activeLink, dispatch, getState, api);
    }

};