import React from "react";
import { useState } from "react";
import {Modal, Table, Button, Checkbox} from "semantic-ui-react";
import moment from "moment/moment";

interface Props {
    data: any;
    closePopup: () => void;
    send:any;

}

const ProductionPopup: React.FC<Props> = ({ data ,closePopup,send }) => {
    const [practices, setPractices] = useState(Object.keys(data&&data.grossProdData?data.grossProdData:[]));
    const [state,setState] = useState({
        grossProdData:data.grossProdData,
        selectedMonth:data.month,
        setLevel2:false,
        setLevel1:true
    })
    const [ showHideData ,setCheckBoxes] = useState<any>({isDollars:true , isTTL:true})

    const renderProduction = (month:string) => {
        const dataForMonth = {};
       const practizes=Object.keys(state.grossProdData?state.grossProdData:{});
        for (const practice of practizes) {
                dataForMonth[practice] = Object.values(data.grossProdData[practice])[0];
        }

        const productionByDate: any = {};
        Object.values(dataForMonth).forEach((value: any) => {
            if (value&&Object.keys(value).length > 0) {
            Object.entries(value).forEach(([code, data]: any) => {
                if (!productionByDate[code] && code!=='total') {
                    productionByDate[code] = {
                        [code]:0,
                        percentageOfTotal: 0,
                        percentageOfGrossProduction: 0,
                    };
                      
                }
               if(productionByDate[code] && code!=='total')           
                 productionByDate[code][code] += data;
               
                if(code=="total"){
                    if(!productionByDate["total"]) {                       
                    productionByDate[code] = {
                        total:0,
                        percentageOfTotal: 0,
                        percentageOfGrossProduction: 0,
                    }
                } 

                     productionByDate[code]["total"]+=data           
                }
            });
            
            }
        });
        //calculate total productiondata for month
        const totalProductionData = Object.keys(data.productionData)&&data.productionData[month]?data.productionData[month].production:0;

        Object.entries(productionByDate).forEach(([code, data]: any) => {

                data.percentageOfTotal = ((data[code] / productionByDate.total["total"]) * 100).toFixed(2);
                data.percentageOfGrossProduction = ((data[code] / totalProductionData) * 100).toFixed(2);

        });
        let sortedData = {total:{}}
        const keys = Object.keys(productionByDate);
        // Sort the keys based on absolute value of their corresponding values
        keys.sort((a, b) => {
            if (a==="total"||b ==="total") return 0; // check if properties exist         
            return Date.parse(a)-Date.parse(b)
        });

       // Add the sorted keys to the new object
        keys.forEach(key => {
            sortedData[key] = productionByDate[key];
        });
        const sortedEntries = Object.entries(sortedData).sort(([keyA], [keyB]) => {
            if (keyA === "total") {
                return 1; // move total to the end
            } else if (keyB === "total") {
                return -1; // move total to the end
            } else {
                return 1 // sort alphabetically
            }
        });
        const sortedObject = Object.fromEntries(sortedEntries)
        //render the table
        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Month Date</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>% of Total Production</Table.HeaderCell>
                         <Table.HeaderCell>% of Gross Production</Table.HeaderCell> 
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {Object.entries(sortedObject).map(([date, data]: any) => (
                        <Table.Row key={date}>
                            <Table.Cell>{date}</Table.Cell>
                            <Table.Cell content={(data[date] || 0).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD"
                            })}/>
                            <Table.Cell>{Math.abs(data.percentageOfTotal ||0)}%</Table.Cell>
                            <Table.Cell>{Math.abs(data.percentageOfGrossProduction)}%</Table.Cell> 
                        </Table.Row>
                    ))}


                </Table.Body>
            </Table>
        );
    }

    const renderL2Production = (monthSelected:string) => {
        const dataForMonth = {};
        for (const practice of Object.keys(state.grossProdData)) {
            if(state.grossProdData[practice][monthSelected]){
                dataForMonth[practice] = state.grossProdData[practice][monthSelected];
            }
        }
        const productionByDate2: any = {};
        practices&&practices.forEach((prac: any) => {
            if (dataForMonth[prac]&&Object.keys(dataForMonth[prac]).length > 0) {
                Object.keys(dataForMonth[prac]).forEach((code: any) => {
                    if (!productionByDate2[code]) {
                        productionByDate2[code] = { };
                        productionByDate2[code][prac] = dataForMonth[prac];

                    }
                    else if(!productionByDate2[code][prac] && dataForMonth[prac][code]){
                        productionByDate2[code][prac] = dataForMonth[prac];
                    }
                });
            }
        });


        let  productionbyDateEntries={}


        Object.entries(productionByDate2).forEach(([code,data]: any) => {

            practices&&practices.forEach((pracz: any) => {
                if(data[pracz]){
                    if(!productionbyDateEntries[code]){
                        productionbyDateEntries[code] = {}
                        productionbyDateEntries[code]["cdeTotal"]=0;
                    }
                    productionbyDateEntries[code][pracz]={}
                    productionbyDateEntries[code][pracz][code] = data[pracz][code];
                    productionbyDateEntries[code][pracz]['percentageOfTotal'] = ((data[pracz][code] / data[pracz]['total']) * 100).toFixed(2)

                    productionbyDateEntries[code]['cdeTotal']+=Math.abs(Number(parseFloat(data[pracz][code]).toFixed(2)));
                }

            });


        });

        let sortedData = {total:{}}
        const keys = Object.keys(productionbyDateEntries);
        // Sort the keys based on absolute value of their corresponding values
        keys.sort((a, b) => {
            if (a==="total"||b ==="total") return 0; // check if properties exist
            return Date.parse(a)-Date.parse(b)
        });

        // Add the sorted keys to the new object
        keys.forEach(key => {
            sortedData[key] = productionbyDateEntries[key];
        });
        const sortedEntries = Object.entries(sortedData).sort(([keyA], [keyB]) => {
            if (keyA === "total") {
                return 1; // move total to the end
            } else if (keyB === "total") {
                return -1; // move total to the end
            } else {
                return 1 // sort alphabetically
            }
        });
        const sortedObjectEntries = Object.fromEntries(sortedEntries)

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell>{""}</Table.Cell>
                        {practices && practices.map((prac , idx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        key={idx} colspan="1">{prac} </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">Total</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Month Date</Table.HeaderCell>
                        {practices && practices.map((practice , indx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        colspan="1" key={indx}>
                                {showHideData.isDollars?"$":""}
                                {showHideData.isTTL ?" (%TTL)":""}
                            </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">
                            {showHideData.isDollars?"$":""}
                            {showHideData.isTTL ?" (%TTL)":""}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedObjectEntries&&Object.entries(sortedObjectEntries).map(([code,dataf]:any,indx:number) => (

                        <Table.Row key={indx}>
                            <Table.Cell key={indx}>{code}</Table.Cell>
                            {practices && practices.map((practize , index)=>
                                <Table.Cell colspan="1" key={indx-index}>
                                    {showHideData.isDollars?(Object.keys(dataf).indexOf(practize)>-1?dataf[practize][code]:0).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD"
                                    }):""} {showHideData.isTTL?"("+Math.abs(Object.keys(dataf).indexOf(practize)>-1?dataf[practize]["percentageOfTotal"]:0)+'%' +")" :""}
                                </Table.Cell>
                            )}
                            <Table.Cell>{showHideData.isDollars?"$"+ dataf["cdeTotal"].toFixed(2):""}
                                {showHideData.isTTL?"("+((dataf["cdeTotal"]/sortedObjectEntries["total"]["cdeTotal"])*100).toFixed(2) + '%' +")":""}
                            </Table.Cell>
                        </Table.Row>
                    ))}


                </Table.Body>
            </Table>
        );
    }

    const handleLevelChange =() => {
        setState({...state,setLevel1:!state.setLevel1 , setLevel2:!state.setLevel2});
    }
    const handleCheckBoxes =(e:any , name) => {
        if(name=="dollars"){
            setCheckBoxes({...showHideData , isDollars:!showHideData.isDollars})
        }
        if(name=="%ttl"){
            setCheckBoxes({...showHideData , isTTL:!showHideData.isTTL})
        }
    }

    return (
        <Modal
            open={true}
            onClose={closePopup}
            centered={true}
            closeIcon
        >
            <Modal.Header>{state.setLevel1?"Gross Production by Date and Percentage":"Gross Production by Practice, Date and Percentage"}</Modal.Header>
            {state.setLevel1 && <Modal.Content>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell  colSpan='1'> Months/years</Table.HeaderCell>
                            <Table.HeaderCell colSpan='1'> Practices</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>{moment(data.month + '-01').format('MMM YYYY')}</b></Table.Cell>
                            <Table.Cell width={2} content={practices&&practices.toString()} > {practices&&practices.toString()}</Table.Cell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        <Table.Row>
                        {renderProduction(data.month)}
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer>

                    </Table.Footer>
                </Table>
            </Modal.Content>}
            {state.setLevel2 && <Modal.Content>
                <div className="FreezeTable freezeHeight">
                    <Table celled width="100%">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell  colSpan='1'>
                                    Months/years  <Checkbox  label={showHideData.isDollars?"Hide $" : "Show $"}  toggle className="ml20" checked={showHideData.isDollars} onChange={(e) => handleCheckBoxes(e,"dollars")} />
                                    <Checkbox  label={showHideData.isTTL ?"Hide %TTL" : "Show %TTL"} toggle  className="ml20" checked={showHideData.isTTL} onChange={(e) => handleCheckBoxes(e,"%ttl")}/>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><b>{moment(state.selectedMonth + '-01').format('MMM YYYY')}</b></Table.Cell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>

                            <Table.Row>
                                {renderL2Production(data.month)}
                            </Table.Row>
                        </Table.Body>

                        <Table.Footer>

                        </Table.Footer>
                    </Table>
                </div>
            </Modal.Content>}
          
            <Modal.Actions>
                {state.setLevel2 &&<> <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L1
                </Button>
                    <Button className="mr10" primary disabled>
                        Details L3
                    </Button>
                </>}
                {state.setLevel1 && <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L2
                </Button> }
            <Button className="mr10" primary onClick={state.setLevel1?()=>send('level1'):()=>send('level2',showHideData)} >
                      Send Email
                    </Button>
            </Modal.Actions>
        </Modal>
    );
}


export default ProductionPopup;
