import React,{ useState } from "react";
import {Modal, Table, Button, Checkbox} from "semantic-ui-react";
import moment from "moment/moment";

interface Props {
    data: {month?:string,cashSplitData?:object ,productionData?:object};
    closePopup: () => void;
    send:Function;

}

const CashSplitPopUp: React.FC<Props> = ({ data ,closePopup,send }) => {
    const [practices] = useState(Object.keys(data&&data.cashSplitData?data.cashSplitData:[]));
    const [cashSplitState,setCashSplitState] = useState({
        cashSplitData:data.cashSplitData,
        productionData:data.productionData,
        selectedMonth:data.month,
        setLevel1:true,
        setLevel2:false
    })

    const [ showHideData ,setCheckBoxes] = useState<any>({isDollars:true , isTTL:true ,isGP:true})

    const renderCashSplit = (month:string) => {
        const dataForMonth = {};
        const practizes=Object.keys(data&&data.cashSplitData?data.cashSplitData:{});
        for (const practice of practizes) {
            dataForMonth[practice] = Object.values(data.cashSplitData[practice])[0];
        }

        const cashSplitByDate:any = {};
        Object.values(dataForMonth).forEach((value: object) => {
            if (value&&Object.keys(value).length > 0) {
                Object.entries(value).forEach(([code, data]: [string,object]) => {
                    if (!cashSplitByDate[code] && code!=='total') {
                        cashSplitByDate[code] = {
                            [code]:0,
                            percentageOfTotal: 0,
                            percentageOfGrossProduction: 0,
                        };

                    }
                    if(cashSplitByDate[code] && code!=='total')
                        cashSplitByDate[code][code] += data;

                    if(code=="total"){
                        if(!cashSplitByDate["total"]) {
                            cashSplitByDate[code] = {
                                total:0,
                                percentageOfTotal: 0,
                                percentageOfGrossProduction: 0,
                            }
                        }

                        cashSplitByDate[code]["total"]+=data
                    }
                });

            }
        });
        //calculate total productiondata for month
        const totalProductionData = Object.keys(data.productionData)&&data.productionData[month]?data.productionData[month].production:0;

        Object.entries(cashSplitByDate).forEach(([code, data]: [string,object]) => {

            data["percentageOfTotal"] = ((data[code] / cashSplitByDate.total["total"]) * 100).toFixed(2);
            data["percentageOfGrossProduction"] = ((data[code] / totalProductionData) * 100).toFixed(2);

        });
        let sortedData = {total:{}}
        const keys = Object.keys(cashSplitByDate);
        // Sort the keys based on absolute value of their corresponding values
        keys.sort((a, b) => {
            if (a==="total"||b ==="total") return 0; // check if properties exist
            return Date.parse(a)-Date.parse(b)
        });

        // Add the sorted keys to the new object
        keys.forEach(key => {
            sortedData[key] = cashSplitByDate[key];
        });
        const sortedEntries = Object.entries(sortedData).sort(([keyA], [keyB]) => {
            if (keyA === "total") {
                return 1; // move total to the end
            } else if (keyB === "total") {
                return -1; // move total to the end
            } else {
                return 1 // sort alphabetically
            }
        });
        const sortedObject = Object.fromEntries(sortedEntries)
        //render the table
        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Proc Date</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>% of Total Cash Split</Table.HeaderCell>
                        <Table.HeaderCell>% of Gross Production</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {Object.entries(sortedObject).map(([date, data]:[string,object]) => (
                        <Table.Row key={date}>
                            <Table.Cell>{date}</Table.Cell>
                            <Table.Cell content={(data[date] || 0).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD"
                            })}/>
                            <Table.Cell>{Math.abs(data["percentageOfTotal"] ||0)}%</Table.Cell>
                            <Table.Cell>{Math.abs(data["percentageOfGrossProduction"])}%</Table.Cell>
                        </Table.Row>
                    ))}


                </Table.Body>
            </Table>
        );
    }
    const renderL2CashSplit = (monthSelected:string) => {
        const dataForMonth = {};
        for (const practice of Object.keys(cashSplitState.cashSplitData)) {
            if(cashSplitState.cashSplitData[practice][monthSelected]){
                dataForMonth[practice] = cashSplitState.cashSplitData[practice][monthSelected];
            }
        }
        const cashSplitByDate2: object = {};
        practices&&practices.forEach((prac: string) => {
            if (dataForMonth[prac]&&Object.keys(dataForMonth[prac]).length > 0) {
                Object.keys(dataForMonth[prac]).forEach((code: string) => {
                    if (!cashSplitByDate2[code]) {
                        cashSplitByDate2[code] = { };
                        cashSplitByDate2[code][prac] = dataForMonth[prac];

                    }
                    else if(!cashSplitByDate2[code][prac] && dataForMonth[prac][code]){
                        cashSplitByDate2[code][prac] = dataForMonth[prac];
                    }
                });
            }
        });


        let  cashSplitDateEntries={}

        // total productiondata for month
        const totalProductionData = Object.keys(cashSplitState.productionData)&&cashSplitState.productionData[monthSelected]?cashSplitState.productionData[monthSelected].production:0;

        Object.entries(cashSplitByDate2).forEach(([code,data]: [string,object]) => {

            practices&&practices.forEach((pracz: string) => {
                if(data[pracz]){
                    if(!cashSplitDateEntries[code]){
                        cashSplitDateEntries[code] = {}
                        cashSplitDateEntries[code]["cdeTotal"]=0;
                    }
                    cashSplitDateEntries[code][pracz]={}
                    cashSplitDateEntries[code][pracz][code] = data[pracz][code];
                    cashSplitDateEntries[code][pracz]['percentageOfTotal'] = ((data[pracz][code] / data[pracz]['total']) * 100).toFixed(2)
                    cashSplitDateEntries[code][pracz]['percentageOfGrossProduction']=((data[pracz][code]/totalProductionData)*100).toFixed(2);

                    cashSplitDateEntries[code]['cdeTotal']+=Math.abs(Number(parseFloat(data[pracz][code]).toFixed(2)));
                }

            });

            cashSplitDateEntries[code]["percentageofcdeGrossTotal"] = Number(((cashSplitDateEntries[code]["cdeTotal"]/totalProductionData)*100).toFixed(2));
        });

        let sortedData = {total:{}}
        const keys = Object.keys(cashSplitDateEntries);
        // Sort the keys based on absolute value of their corresponding values
        keys.sort((a, b) => {
            if (a==="total"||b ==="total") return 0; // check if properties exist
            return Date.parse(a)-Date.parse(b)
        });

        // Add the sorted keys to the new object
        keys.forEach(key => {
            sortedData[key] = cashSplitDateEntries[key];
        });
        const sortedEntries = Object.entries(sortedData).sort(([keyA], [keyB]) => {
            if (keyA === "total") {
                return 1; // move total to the end
            } else if (keyB === "total") {
                return -1; // move total to the end
            } else {
                return 1 // sort alphabetically
            }
        });
        const sortedObjectEntries = Object.fromEntries(sortedEntries)

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell>{""}</Table.Cell>
                        {practices && practices.map((prac , idx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        key={idx} colspan="1">{prac} </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">Total</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Proc Date</Table.HeaderCell>
                        {practices && practices.map((practice , indx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        colspan="1" key={indx}>
                                {showHideData.isDollars?"$":""}
                                {showHideData.isTTL ?" (%TTL)":""}
                                {showHideData.isGP ?"(%of GP)" : ""}
                            </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">
                            {showHideData.isDollars?"$":""}
                            {showHideData.isTTL ?" (%TTL)":""}
                            {showHideData.isGP ?"(%of GP)" : ""}

                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedObjectEntries&&Object.entries(sortedObjectEntries).map(([code,dataf]:[string,object],indx:number) => (

                        <Table.Row key={indx}>
                            <Table.Cell key={indx}>{code}</Table.Cell>
                            {practices && practices.map((practize , index)=>
                                <Table.Cell colspan="1" key={indx-index}>
                                    {showHideData.isDollars?(Object.keys(dataf).indexOf(practize)>-1?dataf[practize][code]:0).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD"
                                    }):""} {showHideData.isTTL?"("+Math.abs(Object.keys(dataf).indexOf(practize)>-1?dataf[practize]["percentageOfTotal"]:0)+'%' +")" :""}
                                    {showHideData.isGP?"(" +Math.abs(Object.keys(dataf).indexOf(practize)>-1?dataf[practize]["percentageOfGrossProduction"]:0)+'%'+")":""}
                                </Table.Cell>
                            )}
                            <Table.Cell>{showHideData.isDollars?"$"+ dataf["cdeTotal"].toFixed(2):""}
                                {showHideData.isTTL?"("+((dataf["cdeTotal"]/sortedObjectEntries["total"]["cdeTotal"])*100).toFixed(2) + '%' +")":""}
                                {showHideData.isGP? "("+dataf["percentageofcdeGrossTotal"].toFixed(2) +'%' +")":""}
                            </Table.Cell>
                        </Table.Row>
                    ))}


                </Table.Body>
            </Table>
        );
    }

    const handleLevelChange =() => {
        setCashSplitState({...cashSplitState,setLevel1:!cashSplitState.setLevel1 , setLevel2:!cashSplitState.setLevel2});
    }
    const handleCheckBoxes =( name:string) => {
        if(name=="dollars"){
            setCheckBoxes({...showHideData , isDollars:!showHideData.isDollars})
        }
        if(name=="%ttl"){
            setCheckBoxes({...showHideData , isTTL:!showHideData.isTTL})
        }
        if(name=="%gp"){
            setCheckBoxes({...showHideData , isGP:!showHideData.isGP})
        }

    }



    return (
        <Modal
            open={true}
            onClose={closePopup}
            centered={true}
            closeIcon
        >
            <Modal.Header>{cashSplitState.setLevel1?"Cash Split by Date and Percentage":"Cash Split by Practice ,Date and Percentage"}</Modal.Header>
            {cashSplitState.setLevel1&&<Modal.Content>
                <div className="FreezeTable freezeHeight">
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell  colSpan='1'> Months/years</Table.HeaderCell>
                            <Table.HeaderCell colSpan='1'> Practices</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>{moment(data.month + '-01').format('MMM YYYY')}</b></Table.Cell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        <Table.Row>
                            {renderCashSplit(data.month)}
                            <Table.Cell width={3} content={practices&&practices.toString()} >
                                {practices&&practices.map((practice,indx)=>
                                  indx%2==0?<p key={indx}>{practice} ,{practices[indx+1]}</p>:false
                                )}</Table.Cell>
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer>

                    </Table.Footer>
                </Table>
                </div>
            </Modal.Content>
            }
            {cashSplitState.setLevel2 && <Modal.Content>
                <div className="FreezeTable freezeHeight">
                    <Table celled width="100%">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell  colSpan='1'>
                                    Months/years  <Checkbox  label={showHideData.isDollars?"Hide $" : "Show $"}  toggle className="ml20" checked={showHideData.isDollars} onChange={() => handleCheckBoxes("dollars")} />
                                    <Checkbox  label={showHideData.isTTL ?"Hide %TTL" : "Show %TTL"} toggle  className="ml20" checked={showHideData.isTTL} onChange={() => handleCheckBoxes("%ttl")}/>
                                    <Checkbox  label={showHideData.isGP ?"Hide %of GP" : "Show %of GP"} toggle className="ml20" checked={showHideData.isGP} onChange={() => handleCheckBoxes("%gp")}/>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><b>{moment(cashSplitState.selectedMonth + '-01').format('MMM YYYY')}</b></Table.Cell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>

                            <Table.Row>
                                {renderL2CashSplit(cashSplitState.selectedMonth)}
                            </Table.Row>
                        </Table.Body>

                        <Table.Footer>

                        </Table.Footer>
                    </Table>
                </div>
            </Modal.Content>}
            <Modal.Actions>
                {cashSplitState.setLevel2 &&<> <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L1
                </Button>
                    <Button className="mr10" primary disabled>
                        Details L3
                    </Button>
                </>}
                {cashSplitState.setLevel1 && <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L2
                </Button> }
                <Button className="mr10" primary onClick={cashSplitState.setLevel1?()=>send('level1'):()=>send('level2',showHideData)} >
                    Send Email
                </Button>
            </Modal.Actions>
        </Modal>
    );
}


export default CashSplitPopUp;
