import React ,{ useState }from "react";
import {Modal, Table, Button, Checkbox} from "semantic-ui-react";
import moment from "moment/moment";

interface Props {
    data: any;
    closePopup: () => void;
    sendData:any;
}


const WriteOffPopup: React.FC<Props> = ({ data ,closePopup,sendData }) => {
    const [practices] = useState(Object.keys(data&&data.writeOffData?data.writeOffData:[]));
     const [state,setState] = useState({
            writeOffData:data.writeOffData,
            productionData:data.productionData,
            selectedMonth:data.month,
           setLevel2:false,
           setLevel1:true
        })

    const [ showhideData ,setCheckBoxes] = useState<any>({isDollars:true , isTTL:true ,isGP:true})

    const renderWriteoff = (monthSelected:string) => {
        const dataForMonth = {};
        for (const practice of practices) {
            if(state.writeOffData[practice][monthSelected]){
                dataForMonth[practice] = state.writeOffData[practice][monthSelected];
            }
        }
        
        const adjustmentsByCode: any = {};
        Object.values(dataForMonth).forEach((value: any) => {
            if (value&&Object.keys(value).length > 0) {
            Object.entries(value).forEach(([code, data]: any) => {
                if (!adjustmentsByCode[code] && code!=='total') {

                    adjustmentsByCode[code] = {
                        [code]:0,
                        percentageOfTotal: 0,
                        percentageOfGrossProduction: 0,
                    };               
                }
                if(adjustmentsByCode[code]&&code!=="total")
                    adjustmentsByCode[code][code] += data;
                if(code=="total"){
                    if(!adjustmentsByCode["total"]) {                       
                    adjustmentsByCode[code] = {
                        total:0,
                        percentageOfTotal: 0,
                        percentageOfGrossProduction: 0,
                    }
                    } 

                     adjustmentsByCode[code]["total"]+=data
               
                }
            });
            
            }
        });

        //calculate total productiondata for month
        const totalProductionData = Object.keys(state.productionData)&&state.productionData[monthSelected]?state.productionData[monthSelected].production:0;

        Object.entries(adjustmentsByCode).forEach(([code, data]: any) => {

                data.percentageOfTotal = ((data[code] / adjustmentsByCode.total["total"]) * 100).toFixed(2);
                data.percentageOfGrossProduction = ((data[code] / totalProductionData) * 100).toFixed(2);

        });
        let sortedData = {total:{}}
        const keys = Object.keys(adjustmentsByCode);
        // Sort the keys based on absolute value of their corresponding values
        keys.sort((a, b) => {
            if (!adjustmentsByCode[a] || !adjustmentsByCode[b]) return 0; // check if properties exist
            return Math.abs(adjustmentsByCode[b][b]) - Math.abs(adjustmentsByCode[a][a])
        });
        // Add the sorted keys to the new object
        keys.forEach(key => {
            sortedData[key] = adjustmentsByCode[key];
        });
        const sortedEntries = Object.entries(sortedData).sort(([keyA], [keyB]) => {
            if (keyA === "total") {
                return 1; // move total to the end
            } else if (keyB === "total") {
                return -1; // move total to the end
            } else {
                return 1 // sort alphabetically
            }
        });
        const sortedObject = Object.fromEntries(sortedEntries)
        // render the table
        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>WriteOff Codes</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>% of Total WriteOff</Table.HeaderCell>
                        <Table.HeaderCell>% of Gross Production</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {Object.entries(sortedObject).map(([code, data]: any) => (
                        <Table.Row key={code}>
                            <Table.Cell>{code}</Table.Cell>
                            <Table.Cell content={(data[code] || 0).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD"
                            })}/>
                            <Table.Cell>{Math.abs(data.percentageOfTotal)}%</Table.Cell>
                            <Table.Cell>{Math.abs(data.percentageOfGrossProduction)}%</Table.Cell>
                        </Table.Row>
                    ))}


                </Table.Body>
            </Table>
        );
    }

    const renderL2Writeoff = (monthSelected:string) => {
        const dataForMonth = {};
        for (const practice of Object.keys(state.writeOffData)) {
            if(state.writeOffData[practice][monthSelected]){
                dataForMonth[practice] = state.writeOffData[practice][monthSelected];
            }
        }
        const writeOffByCode2: any = {};
        practices&&practices.forEach((prac: any) => {
            if (dataForMonth[prac]&&Object.keys(dataForMonth[prac]).length > 0) {
                Object.keys(dataForMonth[prac]).forEach((code: any) => {
                    if (!writeOffByCode2[code]) {
                        writeOffByCode2[code] = { };
                        writeOffByCode2[code][prac] = dataForMonth[prac];

                    }
                    else if(!writeOffByCode2[code][prac] && dataForMonth[prac][code]){
                        writeOffByCode2[code][prac] = dataForMonth[prac];
                    }
                });
            }
        });


        let  adjustmentCodeEntries={}

        // total productiondata for month
        const totalProductionData = Object.keys(state.productionData)&&state.productionData[monthSelected]?state.productionData[monthSelected].production:0;

        Object.entries(writeOffByCode2).forEach(([code,data]: any) => {

            practices&&practices.forEach((pracz: any) => {
                if(data[pracz]){
                    if(!adjustmentCodeEntries[code]){
                        adjustmentCodeEntries[code] = {}
                        adjustmentCodeEntries[code]["cdeTotal"]=0;
                    }
                    adjustmentCodeEntries[code][pracz]={}
                    adjustmentCodeEntries[code][pracz][code] = data[pracz][code];
                    adjustmentCodeEntries[code][pracz]['percentageOfTotal'] = ((data[pracz][code] / data[pracz]['total']) * 100).toFixed(2)
                    adjustmentCodeEntries[code][pracz]['percentageOfGrossProduction']=((data[pracz][code]/totalProductionData)*100).toFixed(2);

                    adjustmentCodeEntries[code]['cdeTotal']+=Math.abs(Number(parseFloat(data[pracz][code]).toFixed(2)));
                }

            });

            adjustmentCodeEntries[code]["percentageofcdeGrossTotal"] = Number(((adjustmentCodeEntries[code]["cdeTotal"]/totalProductionData)*100).toFixed(2));
        });

        const srtAdjustmentCode =Object.entries(adjustmentCodeEntries).sort((obj1, obj2) => {
            if (obj1[1]["cdeTotal"] < obj2[1]["cdeTotal"]) {
                return -1
            }
        });

        const sortedObjectEntries ={}
        srtAdjustmentCode.forEach((arrypair) => {
            sortedObjectEntries[arrypair[0]] = arrypair[1]
        });

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell>{""}</Table.Cell>
                        {practices && practices.map((prac , idx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        key={idx} colspan="1">{prac} </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">Total</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>WriteOff Codes</Table.HeaderCell>
                        {practices && practices.map((practice , indx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        colspan="1" key={indx}>
                                {showhideData.isDollars?"$":""}
                                {showhideData.isTTL ?" (%TTL)":""}
                                {showhideData.isGP ?"(%of GP)" : ""}
                            </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">
                            {showhideData.isDollars?"$":""}
                            {showhideData.isTTL ?" (%TTL)":""}
                            {showhideData.isGP ?"(%of GP)" : ""}

                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedObjectEntries&&Object.entries(sortedObjectEntries).map(([code,dataf]:any,indx:number) => (

                        <Table.Row key={indx}>
                            <Table.Cell key={indx}>{code}</Table.Cell>
                            {practices && practices.map((practize , index)=>
                                <Table.Cell colspan="1" key={indx-index}>
                                    {showhideData.isDollars?(Object.keys(dataf).indexOf(practize)>-1?dataf[practize][code]:0).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD"
                                    }):""} {showhideData.isTTL?"("+Math.abs(Object.keys(dataf).indexOf(practize)>-1?dataf[practize]["percentageOfTotal"]:0)+'%' +")" :""}
                                    {showhideData.isGP?"(" +Math.abs(Object.keys(dataf).indexOf(practize)>-1?dataf[practize]["percentageOfGrossProduction"]:0)+'%'+")":""}
                                </Table.Cell>
                            )}
                            <Table.Cell>{showhideData.isDollars?"$"+ dataf["cdeTotal"].toFixed(2):""}
                                {showhideData.isTTL?"("+((dataf["cdeTotal"]/sortedObjectEntries["total"]["cdeTotal"])*100).toFixed(2) + '%' +")":""}
                                {showhideData.isGP? "("+dataf["percentageofcdeGrossTotal"].toFixed(2) +'%' +")":""}
                            </Table.Cell>
                        </Table.Row>
                    ))}


                </Table.Body>
            </Table>
        );
    }

    const handleLevelChange =() => {
        setState({...state,setLevel1:!state.setLevel1 , setLevel2:!state.setLevel2});
    }
    const handleCheckBoxes =(e:any , name) => {
        if(name=="dollars"){
            setCheckBoxes({...showhideData , isDollars:!showhideData.isDollars})
        }
        if(name=="%ttl"){
            setCheckBoxes({...showhideData , isTTL:!showhideData.isTTL})
        }
        if(name=="%gp"){
            setCheckBoxes({...showhideData , isGP:!showhideData.isGP})
        }

    }

    return (
        <Modal
            open={true}
            onClose={closePopup}
            centered={true}
            closeIcon
        >
            <Modal.Header>{state.setLevel1?"WriteOff by Code and Percentage":"WriteOff by Practice, Code and Percentage"}</Modal.Header>
            {state.setLevel1 &&<Modal.Content>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='1'> Months/years</Table.HeaderCell>
                            <Table.HeaderCell colSpan='1'> Practices</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>{moment(state.selectedMonth + '-01').format('MMM YYYY')}</b></Table.Cell>
                            <Table.Cell width={3}
                                        content={practices && practices.toString()}> {practices && practices.toString()}</Table.Cell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        <Table.Row>
                            {renderWriteoff(state.selectedMonth)}
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer>

                    </Table.Footer>
                </Table>
            </Modal.Content>
            }
            {state.setLevel2 && <Modal.Content>
                <div className="FreezeTable freezeHeight">
                    <Table celled width="100%">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell  colSpan='1'>
                                    Months/years  <Checkbox  label={showhideData.isDollars?"Hide $" : "Show $"}  toggle className="ml20" checked={showhideData.isDollars} onChange={(e) => handleCheckBoxes(e,"dollars")} />
                                    <Checkbox  label={showhideData.isTTL ?"Hide %TTL" : "Show %TTL"} toggle  className="ml20" checked={showhideData.isTTL} onChange={(e) => handleCheckBoxes(e,"%ttl")}/>
                                    <Checkbox  label={showhideData.isGP ?"Hide %of GP" : "Show %of GP"} toggle className="ml20" checked={showhideData.isGP} onChange={(e) => handleCheckBoxes(e,"%gp")}/>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><b>{moment(state.selectedMonth + '-01').format('MMM YYYY')}</b></Table.Cell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>

                            <Table.Row>
                                {renderL2Writeoff(state.selectedMonth)}
                            </Table.Row>
                        </Table.Body>

                        <Table.Footer>

                        </Table.Footer>
                    </Table>
                </div>
            </Modal.Content>}
          
            <Modal.Actions>
                {state.setLevel2 &&<> <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L1
                </Button>
                    <Button className="mr10" primary disabled>
                        Details L3
                    </Button>
                </>}
                {state.setLevel1 && <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L2
                </Button> }

            <Button className="mr10" primary onClick={state.setLevel1?()=>sendData('level1'):()=>sendData('level2',showhideData)} >
                      Send Email
                    </Button>
            </Modal.Actions>
        </Modal>
    );
}


export default WriteOffPopup;
