import { ActionType } from "../../model/actionTypes";

export const checkOpenIssues = (application: string) => async(dispatch, getState, api) => {
    dispatch({ type: ActionType.LOAD });

    try {
        let res = await api.get(`openIssues/checkOpenIssues/${application}`);
        dispatch({ 
            type: ActionType.CHECK_OPEN_ISSUES_SUCCESS, 
            payload: {isThereOpenIssue: res }
        });

        return res;
    } catch (err) {

        dispatch({ 
            type: ActionType.CHECK_OPEN_ISSUES_FAIL, 
            error: err.message 
        });
    }
}

export const fetchOpenIssues = (application: string) => async(dispatch, getState, api) => {
    dispatch({ type: ActionType.LOAD });

    try {
        let res = await api.get(`openIssues/fetchOpenIssues/${application}`);
        dispatch({ 
            type: ActionType.LOAD_OPEN_ISSUES_SUCCESS, 
            payload:{ openIssuesList: res }
        });

        return res;
    } catch (err) {

        dispatch({ 
            type: ActionType.LOAD_OPEN_ISSUES_FAIL, 
            error: err.message 
        });
    }
}