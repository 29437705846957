import React, {useEffect,useState} from "react";
import {
    Button,
    Modal,
    Segment,
    Icon,
    Header,
    Select,
    TextArea,
    Dropdown,
    Form,
    Grid,
    Dimmer,
    Loader
} from "semantic-ui-react";
import {useFormik} from "formik"
import _ from "lodash";
import ViewNotesModal from "./ViewNotesModal";

const PatientNumberViewModal:React.FC<any>=(props)=>{
    const [QAStatus, setQAStatus] = useState('ok');
    const [showPlanNum , setPlanNumView ] = useState(false)
    const [listPlans, setListPlans ] = useState([])
    const [viewNotes, setViewNotes ] = useState(false);
    const [patientData , setPatientData] = useState({});
    const formik=useFormik(
        {
            initialValues:{
                Category:props.fields.Category,
                ChargeAmt:props.fields.ChargeAmt ,
                ChargeDate:props.fields.ChargeDate,
                DatePay:props.fields.DatePay,
                DateStart:props.fields.DateStart,
                DateStop: props.fields.DateStop,
                Dentistry:props.fields.Dentistry,
                Interest:props.fields.Interest,
                IsClosed:props.fields.IsClosed,
                PP_Balance:props.fields.PP_Balance,
                PP_Status:props.fields.PP_Status,
                PP_line_note:props.fields.PP_line_note,
                PatNum:props.fields.PatNum,
                PayNote:props.fields.PayNote,
                PayPlanNum:props.fields.PayPlanNum,
                Practice:props.fields.Practice,
                Principal:props.fields.Principal,
                SplitAmt:props.fields.SplitAmt,
                UserName:props.fields.UserName,
                notelong:props.fields.notelong,
                QAStatus:QAStatus
            },
            onSubmit:values => {
                props.submitQAStatusIssueData(values)

            }
        }
    )

    const onChangeStatus = (e, {value}, storedata=null) => {
        if (value !== formik.values.QAStatus ) {
            formik.values.QAStatus = value  ;

        }
    }
    const onChangePlanView =(payplannum , payplan)=>{

        let totalCost = payplan['PP_Total_Amount'].toFixed(2);
        let amtDue;
       
     props.payplans[payplannum].map((pp_item:any , index)=>{
          amtDue = totalCost - parseFloat(pp_item.ChargeAmt)
          pp_item['AmountDue']=amtDue.toFixed(2);
          totalCost=amtDue;
         return pp_item;
       })
   
       setListPlans(props.payplans[payplannum])
       setPlanNumView(!showPlanNum)
    }
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const openViewNotes = (notelong,PatNum)=>{
      
        setPatientData({notes:notelong , PatNum:PatNum})
        setViewNotes(true);
      }
  
      const closeViewNotes = () => {
          setViewNotes(false);
      }

    return (

        <Modal
            open={true}
            onClose={props.closePatientPlansModal}
            centered={true}

            closeIcon
        >
            <Segment clearing>

                <Header as='h3' textAlign='center'>
                    {`Patient Payment Plan:  ${listPlans.length>0?listPlans[0].PayPlanNum:'' }`} &nbsp;&nbsp; &nbsp;&nbsp;     {`    Practice:  ${listPlans.length>0?listPlans[0].practice:props.payplans && props.payplans[props.fields.PayPlanNum]&&props.payplans[props.fields.PayPlanNum][0].practice}`}    
                </Header>


            </Segment>
            <Dimmer.Dimmable as={Segment} dimmed={props.payPlanLoader }>
                <Dimmer active={props.payPlanLoader}>

                    <Loader>Loading</Loader>

                </Dimmer>
            <Modal.Content>
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Patient Number</th>
                            <th>Total Costs</th>
                            <th>Paid</th>
                            <th>Due</th>
                            <th>Notes</th>
                            <th>Pay Plan Details</th>

                        </tr>
                        </thead>
                        <tbody>

                            {

                                props.payplans&&props.payplans&&Object.keys(props.payplans).map((pp_num,indx)=>{
                                    props.payplans[pp_num].sort((dte1 , dte2)=>{
                                        return  dte1.ChargeDate > dte2 .ChargeDate ? 1 : -1 ;
                                    })

                                    return parseInt(pp_num) === props.fields.PayPlanNum ?<tr key={indx} >
                                        <td>
                                            {props.payplans[pp_num][0].ChargeDate}
                                        </td>
                                        <td>
                                            {props.payplans[pp_num][0].PatNum}
                                        </td>
                                        <td>
                                            {`$ ${numberWithCommas((props.payplans[pp_num][0].PP_Total_Amount).toFixed(2)) }  `}
                                        </td>
                                        <td>
                                            {`$ ${numberWithCommas((props.payplans[pp_num][0].PP_Total_Amount - props.payplans[pp_num][0].PP_Balance).toFixed(2)) }  `}
                                        </td>
                                        <td>
                                            {`$ ${ numberWithCommas((props.payplans[pp_num][0].PP_Balance).toFixed(2))}`}
                                        </td>
                                        <td>
                                            <span onClick={()=>openViewNotes(props.payplans[pp_num][0].notelong ,props.payplans[pp_num][0].PatNum)} className='cursorPointer'><a>View Note</a></span>
                                        </td>
                                        <td>
                                            {<Button className="primary " onClick={()=>onChangePlanView(props.payplans[pp_num][0].PayPlanNum , props.payplans[pp_num][0])}><span>View Pay Plan</span></Button>}
                                        </td>
                                    </tr> : ""
                                })


                            }





                        </tbody>
                    </table>
                    {viewNotes && <ViewNotesModal patientData={patientData} closeViewNotes = {closeViewNotes}/>}
                    <div>
                        {listPlans.length>1?
                        <div className="table-responsive" style={{fontSize: '3px !important'}}>
                            <table className="table table-striped table-hover mb02">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Provider</th>
                                    <th>Description</th>
                                    <th>Principal</th>
                                    <th>Interest</th>
                                    <th>Amount Due</th>
                                    <th>Payment</th>
                                    <th>Adjust</th>
                                    <th>Balance</th>

                                </tr>
                                </thead>
                                <tbody>
                                {listPlans.map((payplan,index)=>{
                                    return <tr key={index}>
                                        <td>
                                            {payplan.ChargeDate}
                                        </td>
                                        <td>
                                            {payplan.ProviderName}
                                        </td>
                                        <td>
                                            <span onClick={()=>openViewNotes(payplan.notelong ,payplan.PatNum)} className='cursorPointer'><a>View Note</a></span>
                                        </td>
                                        <td>
                                            {`$${parseFloat(payplan.Principal).toFixed(2)}`}
                                        </td>
                                        <td>
                                            {`$${parseFloat(payplan.Interest).toFixed(2)}`}
                                        </td>
                                        <td>
                                            {`$${parseFloat(payplan.Current_Balance).toFixed(2)}`}
                                        </td>
                                        <td>
                                            {`$${parseFloat(payplan.ChargeAmt).toFixed(2)}`}
                                        </td>
                                        <td>
                                            {''}
                                        </td>
                                        <td>
                                            {new Date(payplan.ChargeDate).valueOf() <= new Date().valueOf()?`$${payplan.AmountDue}`:`$${payplan.PP_Balance.toFixed(2)} `  }
                                        </td>

                                    </tr>


                                })}
                                </tbody>
                            </table>
                            {viewNotes && <ViewNotesModal patientData={patientData} closeViewNotes = {closeViewNotes}/>}
                        </div>:''

                        }



                    </div>
                </div>



            </Modal.Content>
        </Dimmer.Dimmable>
            <Modal.Actions>
                <Button color="orange" onClick={props.closePatientPlansModal}>
                    Close
                </Button>

            </Modal.Actions>
        </Modal>
    )
}

export default PatientNumberViewModal