import React,{useState} from "react";
import { Modal, Table ,Button ,Checkbox} from "semantic-ui-react";
import moment from "moment/moment";

interface Props {
    data: { claimRequestData?:object, productionData?:object };
    closePopup: () => void;
    sendCRTotal:Function;
}

const ClaimReqGrandTotal: React.FC<Props> = ({ data ,closePopup,sendCRTotal }) => {
    const [practices] = useState(Object.keys(data&&data.claimRequestData?data.claimRequestData:[]));
    const [claimReqState, setClaimReqState] = useState({
        productionData:data.productionData,
        claimRequestData:data.claimRequestData,
        setLevelOne:true,
        setLevelTwo:false
    });
    const [showhideData ,setCheckBoxes] = useState<any>({isDollars:true , isTTL:true ,isGP:true})
    const selectedMonths = Object.keys(data.productionData);
    const claimReqDataValues=Object.values(claimReqState.claimRequestData);
    const grossProdTotal=Object.values(claimReqState.productionData || {}).reduce((acc: number, value: any) => {
        return acc + value.production}, 0);

    const renderClaimReqOne = () =>{
        const claimReqGrandByCarrier: any = {};
        Object.values(claimReqDataValues).forEach((monthlyData: string) => {
            Object.values(monthlyData).forEach((mnthObjData) => {
                Object.entries(mnthObjData).forEach(([code, amount]: any) => {
                    if (!claimReqGrandByCarrier[code]) {
                        claimReqGrandByCarrier[code] = {
                            [code]: 0
                        };
                    }
                        claimReqGrandByCarrier[code][code] += amount;
                });
            });
        });

        Object.entries(claimReqGrandByCarrier).forEach(([code, data]: [string,object]) => {
            data["percentageOfTotal"] = ((data[code] / claimReqGrandByCarrier.total["total"]) * 100).toFixed(2);
            data["percentageOfGrossProduction"] = ((data[code] / grossProdTotal) * 100).toFixed(2);
        });


        let sortedData = {total:{}}
        const keys = Object.keys(claimReqGrandByCarrier);
        // Sort the keys based on absolute value of their corresponding values
        keys.sort((a, b) => {
            if (!claimReqGrandByCarrier[a] || !claimReqGrandByCarrier[b]) return 0; // check if properties exist
            return Math.abs(claimReqGrandByCarrier[b][b]) - Math.abs(claimReqGrandByCarrier[a][a])
        });
        // Add the sorted keys to the new object
        keys.forEach(key => {
            sortedData[key] = claimReqGrandByCarrier[key];
        });
        const sortedDataEntries = Object.entries(sortedData).sort(([keyA], [keyB]) => {
            if (keyA === "total" || keyB=="total") return keyA==="total" ? 1:-1 ;// move total to the end
               else  return 1 ;// sort alphabetically
        });

        return (
            <Table celled size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Carrier Name</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>% of Total Claim Request</Table.HeaderCell>
                        <Table.HeaderCell>% of Gross Production</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedDataEntries&&sortedDataEntries.map(([carrier,data]:[string,object] , )=>
                        <Table.Row key={carrier} >
                            <Table.Cell>{carrier==="total"?<b>Grand Total</b>:carrier}</Table.Cell>
                            <Table.Cell> {"$"+ Math.abs(data[carrier]).toFixed(2)}</Table.Cell>
                            <Table.Cell>{Math.abs(data["percentageOfTotal"]).toFixed(2)+"%"}</Table.Cell>
                            <Table.Cell>{Math.abs(data["percentageOfGrossProduction"]).toFixed(2)+"%"}</Table.Cell>
                        </Table.Row>
                    )}

                </Table.Body>
            </Table>
        );
    }
    const renderClaimReqTwo= () =>{
        const {claimRequestData}=claimReqState;
        const claimReqByCarrierName: any = {};
        const claimReqGrandEntries={};
        practices && practices.forEach((practice)=>{
            if (claimRequestData[practice]&&Object.keys(claimRequestData[practice]).length > 0) {
                Object.values(claimRequestData[practice]).forEach((monthYrDataObj: any) => {
                    Object.entries(monthYrDataObj).forEach(([code, amount],)=>{
                        if (!claimReqByCarrierName[code]) {
                            claimReqByCarrierName[code] = {};
                            claimReqByCarrierName[code][practice] = {
                                [code]:0
                            };

                        } else if (!claimReqByCarrierName[code][practice] ) {
                            claimReqByCarrierName[code][practice] = {[code]:0}
                        }
                        claimReqByCarrierName[code][practice][code]+=amount

                    });
                });
            }
        });


        Object.entries(claimReqByCarrierName).forEach(([code,data]: [string,object]) => {

            Object.entries(data).forEach(([pracz,codeData]: [string,object]) => {

                if(!claimReqGrandEntries[code]){
                    claimReqGrandEntries[code] = {}
                    claimReqGrandEntries[code]["cdeTotal"]=0;
                }
                claimReqGrandEntries[code][pracz]={}
                claimReqGrandEntries[code][pracz][code] = codeData[code];
                claimReqGrandEntries[code][pracz]['percentageOfTotal'] = (( codeData[code] / claimReqByCarrierName['total'][pracz]['total']) * 100).toFixed(2)
                claimReqGrandEntries[code][pracz]['percentageOfGrossProduction']=(( codeData[code]/grossProdTotal)*100).toFixed(2);

                claimReqGrandEntries[code]['cdeTotal']+=Math.abs(Number(parseFloat(codeData[code]).toFixed(2)));
            });

            claimReqGrandEntries[code]["percentageofcdeGrossTotal"] = Number(((claimReqGrandEntries[code]["cdeTotal"]/grossProdTotal)*100).toFixed(2));
        });

        const srtClaimReqCarriers =Object.entries(claimReqGrandEntries).sort((obj1, obj2) => {
            if (obj1[1]["cdeTotal"] < obj2[1]["cdeTotal"]) {
                return -1
            }
        });

        const sortedObjectEntries ={}
        srtClaimReqCarriers.forEach((arrypair) => {
            sortedObjectEntries[arrypair[0]] = arrypair[1]
        });

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell/>
                        {practices && practices.map((prac)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        key={prac} colspan="1">{prac} </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">Total</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>WriteOff Appeal Code</Table.HeaderCell>
                        {practices && practices.map((practice )=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        colspan="1" key={practice}>
                                {showhideData.isDollars?"$":""}
                                {showhideData.isTTL ?" (%TTL)":""}
                                {showhideData.isGP ?"(%of GP)" : ""}
                            </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">
                            {showhideData.isDollars?"$":""}
                            {showhideData.isTTL ?" (%TTL)":""}
                            {showhideData.isGP ?"(%of GP)" : ""}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {srtClaimReqCarriers&&srtClaimReqCarriers.map(([cname,objdata]) => (

                        <Table.Row key={cname}>
                            <Table.Cell key={cname}>{cname=="total"?<b>Grand Total</b>:cname}</Table.Cell>
                            {practices && practices.map((practize:string )=>
                            Object.keys(objdata).indexOf(practize)>-1 ?  <Table.Cell colspan="1" key={practize+"-"+cname}>
                                    {showhideData.isDollars?(objdata[practize][cname]).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD"
                                    }):""} {showhideData.isTTL?"("+Math.abs(objdata[practize]["percentageOfTotal"])+'%' +")" :""}
                                    {showhideData.isGP?"(" +Math.abs(objdata[practize]["percentageOfGrossProduction"])+'%'+")":""}
                                </Table.Cell>
                                    :
                                <Table.Cell>
                                {showhideData.isDollars?(0).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }):""} {showhideData.isTTL?"(0%)" :""} {showhideData.isGP?"(0%)":""}
                                </Table.Cell>
                            )}
                            <Table.Cell>
                                {showhideData.isDollars?"$"+ objdata["cdeTotal"].toFixed(2):""}
                                {showhideData.isTTL?"("+((objdata["cdeTotal"]/sortedObjectEntries["total"]["cdeTotal"])*100).toFixed(2) + '%' +")":""}
                                {showhideData.isGP? "("+objdata["percentageofcdeGrossTotal"].toFixed(2) +'%' +")":""}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    }

    const handleLevelChange =() => {
        setClaimReqState({...claimReqState,setLevelOne:!claimReqState.setLevelOne , setLevelTwo:!claimReqState.setLevelTwo});
    }

    const handleCheckBoxes =(name) => {
        if(name=="dollars"){
            setCheckBoxes({...showhideData , isDollars:!showhideData.isDollars})
        }
        if(name=="%ttl"){
            setCheckBoxes({...showhideData , isTTL:!showhideData.isTTL})
        }
        if(name=="%gp"){
            setCheckBoxes({...showhideData , isGP:!showhideData.isGP})
        }
    }

    return (
        <Modal  open={true} onClose={closePopup} centered={true} closeIcon>
            <Modal.Header>Claim Request Grand Total</Modal.Header>
           {claimReqState.setLevelOne && <Modal.Content>
               <Table celled>
                   <Table.Header>
                       <Table.Row>
                           <Table.HeaderCell> Months/years</Table.HeaderCell>
                           <Table.HeaderCell> Practices</Table.HeaderCell>
                       </Table.Row>
                       <Table.Row>
                           <Table.Cell><b>{moment(selectedMonths[selectedMonths.length - 1] + '-01').format('MMM YYYY')} thru {moment(selectedMonths[0] + '-01').format('MMM YYYY')} </b></Table.Cell>
                           <Table.Cell width={3}
                                       content={practices && practices.toString()}> {practices && practices.toString()}</Table.Cell>
                       </Table.Row>
                   </Table.Header>
                   <Table.Body>

                       <Table.Row>
                           {renderClaimReqOne()}
                       </Table.Row>
                   </Table.Body>

                   <Table.Footer>

                   </Table.Footer>
               </Table>


           </Modal.Content>}
           {claimReqState.setLevelTwo &&<Modal.Content>
               <div className="FreezeTable freezeHeight">
                   <Table celled>
                       <Table.Header>
                           <Table.Row>
                               <Table.HeaderCell  colSpan='1'>
                                   Months/years <Checkbox  label={showhideData.isDollars?"Hide $" : "Show $"}  toggle className="ml20" checked={showhideData.isDollars} onChange={() => handleCheckBoxes("dollars")} />
                                   <Checkbox  label={showhideData.isTTL ?"Hide %TTL" : "Show %TTL"} toggle  className="ml20" checked={showhideData.isTTL} onChange={() => handleCheckBoxes("%ttl")}/>
                                   <Checkbox  label={showhideData.isGP ?"Hide %of GP" : "Show %of GP"} toggle className="ml20" checked={showhideData.isGP} onChange={() => handleCheckBoxes("%gp")}/>
                               </Table.HeaderCell>
                           </Table.Row>
                           <Table.Row>
                               <Table.Cell><b>{moment(selectedMonths[selectedMonths.length-1] + '-01').format('MMM YYYY')}  thru  {moment(selectedMonths[0] + '-01').format('MMM YYYY')} </b></Table.Cell>
                           </Table.Row>
                       </Table.Header>
                       <Table.Body>
                           <Table.Row>
                               {renderClaimReqTwo()}
                           </Table.Row>
                       </Table.Body>
                   </Table>
               </div>
           </Modal.Content>}
          <Modal.Actions>
              {claimReqState.setLevelTwo &&<> <Button className="mr10" primary onClick={handleLevelChange}>
                  Details L1
              </Button>
                  <Button className="mr10" primary onClick={()=>sendCRTotal('level2',showhideData)} >
                      Send Email
                  </Button>
              </>}
              {claimReqState.setLevelOne && <><Button className="mr10" primary onClick={handleLevelChange}>
                  Details L2
              </Button>
                  <Button className="mr10" primary onClick={()=>sendCRTotal('level1')}>
                      Send Email
                  </Button>
              </>}

         </Modal.Actions>
    </Modal>

    )}

export default  ClaimReqGrandTotal;