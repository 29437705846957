import React, {useEffect, useState} from "react";
import "semantic-ui-css/semantic.min.css";
import {Grid, Card, Button, Icon, Loader, Table, Input, Confirm, TableCell} from "semantic-ui-react";
import {useActions} from "../redux/actions";
import * as SetupActions from "../redux/actions/setup"
import Template from "./Template";
import "react-datepicker/dist/react-datepicker.css";
import AddXchargeModal from './AddXchargeModal';
import HelpMark from "./Help/helpMark"
import {HELP_PAGE} from "../constants";
import CountUpTimer from "./CountUpTimer";

interface XchargeData {
    editItem: any;
    deleteId: number;
    showForm: boolean;
    confirm: boolean;
}

const SetupXcharge: React.FC<any> = () => {
    const initialState: XchargeData = {
        editItem: null,
        deleteId: 0,
        showForm: false,
        confirm: false
    }

    const setupActions = useActions(SetupActions);
    const [state, setStates] = useState(initialState);
    const [xchargeData, setXcharge] = useState<any>([])
    const [submitted, setSubmit] = useState(false);
    const [upDateItem, setUpdateItem] = useState(null);
    const [practicesList, setPractices] = useState<any>([]);
    const [practicesIDs, setPracticesIds] = useState<any>([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {

        setLoader(true);
        setupActions.getSetupXcharge().then((res) => {
            if (res) {
                setXcharge(res);
                setPracticesIds(res.map((itemxc) => {
                    return itemxc.practiceId
                }));
                setSubmit(false)
            }

            setLoader(false);
        })
    }, [submitted])

    useEffect(() => {
        setupActions.fetchAllPractices().then((pracList) => {
            let allPractices = new Array();
            pracList && pracList.map((practice) => {
                allPractices.push({key: practice.practiceabbr, value: practice.id, text: practice.practice})
            });
            setPractices(allPractices);
        })
    }, [])

    const hideAddModal = () => {
        setStates({...state, showForm: false, editItem: null})
    }

    const submitXchargedata = (values) => {
        let xObj = {
            brand: values.Brand,
            username: values.Username,
            password: values.Password,
            practiceId: values.Practice,
            practiceabbr: values.PracticeAbbr,
            practice: '',
            port: values.Port ? values.Port : '26',
            ipaddress: values.IPAddress,
            isDeleted: false
        }

        if (!state.editItem) {
            setupActions.SubmitXcharge(xObj).then((res) => {
                setStates({...state, showForm: false});
                setSubmit(true);
            });
        } else {
            xObj['id'] = state.editItem;
            setupActions.UpdateXcharge(xObj).then((res) => {
                setStates({...state, showForm: false, editItem: null});
                setSubmit(true);
            });
        }
    }

    const onEdit = (itemData) => {
        setUpdateItem(itemData);
        setStates({...state, editItem: itemData.id, showForm: true})
    }

    const handleDelete = () => {
        setStates({...state, confirm: false});
        const obj = {
            id: state.deleteId
        };
        setupActions.DeleteSetupXcharge(obj).then((_data: any) => {
            setSubmit(true);
            setStates({...state, deleteId: 0, confirm: false});
        });
    };

    const openConfirm = (id: number) => {
        setStates({...state, confirm: true, deleteId: id});

    };

    const closeConfirm = () => {
        setStates({...state, confirm: false});
    }

    const getTableRows = () => {
        let list = new Array();
        let sq = 0;
        xchargeData && xchargeData.map((xdta: any, index) => {
            list.push(
                <Table.Row key={index}>
                    <Table.Cell>{sq++}</Table.Cell>
                    <Table.Cell>{xdta.brand}</Table.Cell>
                    <Table.Cell>{xdta.username}</Table.Cell>
                    <Table.Cell>{xdta.password}</Table.Cell>
                    <Table.Cell>{xdta.Practices ? xdta.Practices.practice : xdta.practice}</Table.Cell>
                    <Table.Cell>{xdta.practiceabbr}</Table.Cell>
                    <Table.Cell>{xdta.port}</Table.Cell>
                    <Table.Cell>{xdta.ipaddress}</Table.Cell>
                    <Table.Cell>
                 <span className="mb10 float-left"><Icon name='edit' className="mr15 cursorPointer"
                                                         onClick={() => onEdit(xdta)}/>
                    <Icon name='trash alternate' className="cursorPointer" onClick={() => openConfirm(xdta.id)}/></span>
                    </Table.Cell>
                </Table.Row>
            )

        })

        return list;

    }

    return (
        <Template activeLink='setupxcharge'>
             {loader && <CountUpTimer></CountUpTimer>}
            <Card>
                <Card.Content className="pb0">
                    <h2 className="float-left">SETUP XCHARGE<HelpMark helpPage={HELP_PAGE.BILLING_SETUP_XCHARGE}
                     application="Billing-PatientFinance-Setup-SetupXCharge"/></h2>
                    <div className="float-right">
                        <Button primary className="mb15" onClick={() => setStates({...state, showForm: true})}>Add New</Button>
                    </div>
                </Card.Content>
            </Card>

            <Card>
                <Card.Content>
                    <div className="table-responsive FreezeTable">
                        <Table striped sortable className="customTable" unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell><div>Sequence</div></Table.HeaderCell>
                                    <Table.HeaderCell><div>Brand</div></Table.HeaderCell>
                                    <Table.HeaderCell><div>UserName</div></Table.HeaderCell>
                                    <Table.HeaderCell><div>Password</div></Table.HeaderCell>
                                    <Table.HeaderCell><div>Practice</div></Table.HeaderCell>
                                    <Table.HeaderCell><div>Practice Abbr</div></Table.HeaderCell>
                                    <Table.HeaderCell><div>Port #</div></Table.HeaderCell>
                                    <Table.HeaderCell><div>IP Address</div></Table.HeaderCell>
                                    <Table.HeaderCell><div>Action</div></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {getTableRows() && getTableRows().map(data => {
                                    return data
                                })}
                            </Table.Body>
                        </Table>
                    </div>
                </Card.Content>
            </Card>

            {state.showForm &&
                <AddXchargeModal hideAddModal={hideAddModal} submitXchargedata={submitXchargedata}
                                 editItem={state.editItem} data={upDateItem} practiceList={practicesList}
                                 practicesIDs={practicesIDs}/>}

            <Confirm
                cancelButton='No'
                confirmButton="Yes"
                className="confirmModal"
                content="Are you sure you want to delete?"
                open={state.confirm}
                onCancel={closeConfirm}
                onConfirm={handleDelete}
            />
        </Template>
    )

}

export default SetupXcharge