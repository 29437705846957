import {Action,ActionType} from "../../model/actionTypes"
import {NewFlow} from "../../model/newflow"
import createReducer from "./createReducer"


const initialState={
    newflowdata:[],
    payPlans:[]
};


export const newflow = createReducer({},{
    [ActionType.LOAD_NEW_FLOW_SUCCESS](state:NewFlow,
                                          action:Action<NewFlow>){
        return Object.assign({...state}, action.payload);},
   [ActionType.SAVE_ACTIONS_STATUS](state:NewFlow,action:Action<NewFlow>){
            return Object.assign({...state},action.payload);
    },
    [ActionType.LOAD_ALL_PAYPLAN_BY_PATNUM_SUCCESS](state:NewFlow,
                                       action:Action<NewFlow>){
        return Object.assign({...state}, action.payload);},
    [ActionType.LOAD_YEARLY_NEW_FLOW_SUCCESS](state:NewFlow,
                                                    action:Action<NewFlow>){
        return Object.assign({...state}, action.payload);}
})