import {Action,ActionType} from "../../model/actionTypes"
import {Rtp1} from "../../model/rtp1"
import createReducer from "./createReducer"
import {Alert} from "../../model";




export const rtp1 = createReducer({},{
    [ActionType.LOAD_USER_AMOUNT_SUCCESS](state:Rtp1,
                      action:Action<Rtp1>){
        return Object.assign({...state}, action.payload);},
})