import {Action,ActionType} from "../../model/actionTypes"
import {Usage} from "../../model/usage"
import createReducer from "./createReducer"


const initialState={
    isLoad: false,
    loadErr: null,
    loading: false,
    usage: null,
    userList:[],
    workDaysData:{}
};

export const usage = createReducer(initialState,{
    [ActionType.LOAD](state:Usage,
                                    action:Action<Usage>){
        return Object.assign({...state},action.payload);
    },[ActionType.LOAD_SUCCESS](state:Usage,
                        action:Action<Usage>){
        return Object.assign({...state},action.payload);
    },
    [ActionType.LOAD_FAIL](state:Usage,
                      action:Action<Usage>){
        return Object.assign({...state},action.payload);
    },
    [ActionType.LOAD_USER_DETAILS](state:Usage,
                      action:Action<Usage>){
        return Object.assign({...state},action.payload);
    },
    [ActionType.CLOSE_ERROR_MESSAGE](state:Usage,
                      action:Action<Usage>){
        return Object.assign({...state},action.payload);
    },
    [ActionType.LOAD_WORK_DAYS](state:Usage,
                      action:Action<Usage>){
        return Object.assign({...state},action.payload);
    },


})