import { ActionType } from "../../model/actionTypes";

export const getUserPaymentAmountData = (filters:any) => async (dispatch:Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD });
    try {
        let res = await api.get(`report/paymentReport/${filters.start}/${filters.end}`);
        dispatch({ type: ActionType.LOAD_USER_AMOUNT_SUCCESS, payload: {
                userPaymentAmountData : res
            } });
        dispatch({ type: ActionType.LOADER, payload: false });
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_USER_AMOUNT_FAIL, error: err.message });
        return false;
    }
};
