import React from "react";
import Template from "../components/Template";
import CollectionReports from "../components/CollectionReports";

function CollectionReportsContainer() {

    return (
        <Template activeLink='collectionReports'>
            <CollectionReports/>
        </Template>
    );

}

export default CollectionReportsContainer;