import React, {useState} from "react"
import {Sidebar, Menu, Icon, Accordion} from 'semantic-ui-react'
import {Link} from "react-router-dom"
import {IMenu, menusArray} from '../constants';
import {useActions} from '../redux/actions';
import * as appActions from '../redux/actions/app';

interface SideBarMainProps {
    visible: boolean;
    user: any;
    handleClick: Function;
}

const SideBarMain: React.FC<SideBarMainProps> = (props) => {
    const appAction = useActions(appActions);
    const [state, setState] = useState({toggleUrl: ""});
    const roles: string[] = (props.user.role || []).map(r => r.toLowerCase());
    const pathname = window.location.pathname.toLowerCase();

    const navigationClicked = () => {
        setState({toggleUrl: ''});
        appAction.SidebarOpen(false)
    }

    const getMenuItem = (menu: IMenu, i = '0', parentUrl: string = '') => {
        const hasSubMenu = menu.subMenus && menu.subMenus.length;
        const menuUrl = `${parentUrl}/${menu.name}`;
        const active = pathname.includes(menu.name.toLowerCase()) || state.toggleUrl == menuUrl || state.toggleUrl.startsWith(menuUrl);

        const title = <span className={active ? 'menu-item active' : 'menu-item'}>
            <Icon name={menu.icon as any || 'list ul'}/>
            {props.visible && menu.title || ''}
            {hasSubMenu && props.visible && <Icon name="angle down" className="float-right"/> || ''}
        </span>
        return <Accordion>
            <Accordion.Title active={active}
                             onClick={() => hasSubMenu && setState({toggleUrl: menuUrl == state.toggleUrl ? '' : menuUrl})}>
                {
                    hasSubMenu ? title :
                        <Link to={menuUrl} onClick={(e) => navigationClicked()}> {title}</Link>
                }
            </Accordion.Title>
            {
                menu.subMenus && menu.subMenus.length &&
                <Accordion.Content className={props.visible ? 'pl-1' : ''} active={active}>
                    {
                        menu.subMenus
                            .filter(f => !f.role || !f.role.length || f.role.some(role => roles.includes(role)))
                            .map((sub, j) => getMenuItem({
                                ...sub,
                                icon: sub.icon || menu.icon
                            }, `${i}${j}`, menuUrl)) || ''
                    }
                </Accordion.Content> || ''
            }
        </Accordion>;
    }

    const menuItems = menusArray
        .filter(f => !f.role || !f.role.length || f.role.some(role => roles.includes(role)))
        .map((menu, i) => getMenuItem(menu, i + ''));

    const onHandleClick = () => {
        props.handleClick();
        appAction.SidebarOpen(false);
    }

    return (
        <Sidebar
            as={Menu}
            animation='push'
            width='thin'
            visible={props.visible}
            icon='labeled'
            vertical
            inverted
        >
            <div className="clickable" onClick={() => onHandleClick()}>
                <img src="/images/logoMain.png" className="logo align-self-center" alt="Logo"/>
            </div>
            <Accordion className={props.visible ? "text-left" : ''} panels={menuItems}/>
        </Sidebar>
    )

}

export default SideBarMain
