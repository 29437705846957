import React, {useEffect, useState} from "react";
import "semantic-ui-css/semantic.min.css";
import "react-datepicker/dist/react-datepicker.css";
import * as setupActions from "../redux/actions/setup"
import {useActions} from "../redux/actions";
import {Table, Button, Icon, Select, Grid, Header, Modal, Label, Card} from "semantic-ui-react";
import EditSetupModal from "./EditSetupModal";
import {MemberStatus} from "../model/claims";
import {patientFinanceRole, HELP_PAGE} from "../constants";
import HelpMark from "./Help/helpMark"
import CountUpTimer from "./CountUpTimer";

interface SetupData {
    setupData: any
    editForm: boolean
    formData: object
    practiceList: any
}


function Setup(props: any) {
    const initialState: SetupData = {
        setupData: [],
        editForm: false,
        formData: {},
        practiceList: []
    };

    const setupAction = useActions(setupActions);
    const [state, setStates] = useState(initialState);
    const [canAddNotes, setCanAddNotes] = useState(false);
    const [currentNotesItem, setCurrentNotesItem] = useState<MemberStatus>()
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const getItems = async () => {
            setupAction.getSetupData().then((data: any) => {
                setStates({...state, setupData: data})
            });
        }
        getItems()
    }, []);
    useEffect(() => {
        const getItems = async () => {
            setLoader(true);
            setupAction.getPracticesList(0).then((data: any) => {
                state.practiceList.push({
                    key: 0,
                    value: 'All',
                    text: 'All'
                })
                data && data.length > 0 && data.map((res) => {
                    state.practiceList.push({
                        key: res.id,
                        value: res.practice,
                        text: res.practice
                    });
                })

                setLoader(false);
            })


        }

        getItems()
    }, []);


    const submitSetupData = (formInputFeilds) => {
        setCanAddNotes(false)
        const payload = {
            id: formInputFeilds.userId,
            practice: formInputFeilds.practice,
            specialitstInitials: formInputFeilds.specialitstInitials,
            odUserName: formInputFeilds.odUserName,
            goal: formInputFeilds.goal
        }
        const AsyncSubmit = async () => {
            setupAction.SubmitSetupData(payload)
        }
        AsyncSubmit().then()

    }

    const openSetupModal = (currentItem: MemberStatus, id, index) => {
        const setupDataEdit = state.setupData.map(item => {
            if (item.id === id) {
                return item
            }
        })
        setStates({...state, formData: setupDataEdit[index]})
        setCurrentNotesItem(currentItem)
        setCanAddNotes(true)

    }

    const closeSetupModal = () => {
        setCanAddNotes(false)
    }
    const getTableRows = () => {

        const listRows = [];

        state.setupData && state.setupData.map((data, index) => {
            listRows.push(
                <tr key={data.id}>


                    <td>
                        <Button primary onClick={() => {
                            openSetupModal(data, data.id, index)
                        }}>Edit</Button>
                    </td>
                    <td>
                        {data.practice}
                    </td>

                    <td>
                        {data.firstname + ' ' + data.lastname}
                    </td>
                    <td>
                        {data.specialitstInitials}
                    </td>
                    <td>
                        {data.odUserName}
                    </td>
                    <td>
                        {data.goal}
                    </td>
                    <td>
                        {
                            data && data.roleid.split(',').map(role => {
                                return role !== '' ? <div>


                                    {role === patientFinanceRole["Patient Finance"] ? "Patient Finance" : role === patientFinanceRole["Patient Finance Manager"] ? "Patient Finance Manager" : ""}
                                    <br/></div> : " "
                            })
                        }
                    </td>


                </tr>
            )
        })

        return listRows;
    }

    const handleChange = (e: any) => {
        if (e.target.value) {
        }
    }
    const hideSetupModal = () => {
        setCanAddNotes(false)
    }

    return (
        <>
            
            {loader && <CountUpTimer></CountUpTimer>}
            <Card>
                <Card.Content>
                    <h2 className="float-left mr10 mb10">PATIENT FINANCE SETUP ASSIGNMENTS<HelpMark
                        helpPage={HELP_PAGE.BILLING_SETUP_ASSINGNMENTS}
                        application="Billing-PatientFinance-Setup-PatientFinanceSetupAssignments"/></h2>
                    <div className="search-bar float-right">
                        <section className="section">
                            <div>
                                <div className="ui icon input">
                                    <input type="text" className="form-control" placeholder="Search Option..."
                                           onChange={handleChange}/>
                                    <i className="inverted search link icon"></i>
                                </div>
                            </div>
                        </section>
                    </div>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <div className="table-responsive FreezeTable">
                        <table className="ui striped table customTable unstackable">
                            <thead>
                            <tr>
                                <th><div>Action</div></th>
                                <th><div>Practice</div></th>
                                <th><div>Specialists</div></th>
                                <th><div>Specialists Initials</div></th>
                                <th><div>Od User</div></th>
                                <th><div>Goal</div></th>
                                <th><div>Role</div></th>

                            </tr>
                            </thead>
                            <tbody>
                            {
                                getTableRows()

                            }


                            </tbody>
                        </table>
                    </div>
                </Card.Content>
            </Card>
            {canAddNotes &&
                <EditSetupModal fields={state.formData} practices={state.practiceList} hideSetupModal={hideSetupModal}
                                closeSetupModal={closeSetupModal} submitSetupData={submitSetupData}/>}

        </>
    )
}

export default Setup