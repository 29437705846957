import React from "react";
import Template from "../components/Template";
import Setup from "../components/Setup";

function PatientFinanceSetup() {

    return (
        <Template activeLink='setup'>
                <Setup/>
        </Template>
    );

}

export default PatientFinanceSetup;