import React, {useEffect, useState} from "react"
import "semantic-ui-css/semantic.min.css"
import {Grid, Dropdown, Button, Icon, Dimmer, Loader, SemanticICONS, Radio, Card} from "semantic-ui-react"
import * as userPayAmmountActions from "../redux/actions/collectionReports"
import * as setupActions from "../redux/actions/setup"
import {useActions} from "../redux/actions"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment";
import {CSVLink} from "react-csv";
import _ from "lodash";
import * as newflowActions from "../redux/actions/newflow";
import ViewNotesModal from "./ViewNotesModal";
import HelpMark from "./Help/helpMark";
import {HELP_PAGE} from "../constants";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import CountUpTimer from "./CountUpTimer";

const CsvHeaders = [
    {key: 'PayPlanNum', label: 'PayPlanNum'},
    {key: 'DatePay', label: 'DatePay'},
    {key: 'SplitAmt', label: 'SplitAmt'},
    {key: 'PatNum', label: 'PatNumt'},
    {key: 'Category', label: 'Category'},
    {key: 'IsClosed', label: 'IsClosed'},
    {key: 'PP_line_note', label: 'PP_line_note'},
    {key: 'UserName', label: 'UserName'},
    {key: 'PayNote', label: 'PayNote'},
    {key: 'Practice', label: 'Practice'},
];

interface UserPayData {
    userPaymentAmountData: any;
    tempuserPaymentAmountData: any;
    specialistView: string;
    practiceTemp: any;
    specialityTemp: any;
    startDate: Date,
    endDate: Date,
    reportData: any,
    expandableColumn: any,
    expandableRowLevel2: any,
    reportDataBySpecialist: any,

}

enum TableToShow {
    SPECIALIST = 'specialist',
    PRACTICE = 'practice'
}

enum SortDirection {
    ASC = 'ascending',
    DESC = 'descending'
}

const CollectionReports: React.FC<any> = () => {
    const initialState: UserPayData | [] = {
        userPaymentAmountData: [],
        tempuserPaymentAmountData: [],
        specialistView: 'all',
        practiceTemp: [],
        specialityTemp: [],
        startDate: new Date(),
        endDate: new Date(),
        reportData: [],
        expandableColumn: null,
        expandableRowLevel2: null,
        reportDataBySpecialist: {}
    };


    const userPayAmmountAction = useActions(userPayAmmountActions);
    const [state, setStates] = useState(initialState);
    const [startDate, setStartDate] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().clone().endOf('month').format('YYYY-MM-DD'))
    const [loader, setLoader] = useState(false);
    const [tableToShow, setTableToShow] = useState(TableToShow.PRACTICE)
    const [filters, setFilters] = useState({
        start: moment().clone().startOf('month').format('YYYY-MM-DD'),
        end: moment(endDate).format('YYYY-MM-DD')
    })
    const [dates, setDates] = useState<Date[] | null>([new Date(moment(startDate).format('YYYY-MM-DD')), new Date(moment(endDate).format('YYYY-MM-DD'))]);
    const [practiceList, setPracticeList] = useState([])
    const [specialistList, setSpecialistList] = useState([])
    const [filterPractice, setFilterPractice] = useState('All')
    const [specialistFilter, setSpeciallistFilter] = useState('All')
    const [masterUserPaymentData, setMasterUserPayment] = useState({})
    const [rawUserDataObject, setRawUserDataObject] = useState({})
    const [sortObject, setSortObject] = useState({
        PayPlanNum: {sort: false, direction: SortDirection.ASC},
        DatePay: {sort: false, direction: SortDirection.ASC},
        SplitAmt: {sort: false, direction: SortDirection.ASC},
        PatNum: {sort: false, direction: SortDirection.ASC},
        Category: {sort: false, direction: SortDirection.ASC},
        IsClosed: {sort: false, direction: SortDirection.ASC},
        PP_line_note: {sort: false, direction: SortDirection.ASC},
        UserName: {sort: false, direction: SortDirection.ASC},
        PayNote: {sort: false, direction: SortDirection.ASC},
        Practice: {sort: false, direction: SortDirection.ASC},
        specialistSplitAmt: {sort: false, direction: SortDirection.ASC},
        specialistUserName: {sort: false, direction: SortDirection.ASC}
    })
    const [viewMorePracticeColumns, setPracticeColumns] = useState(false);
    const [viewMoreSpecialistColumns, setSpecialistColumns] = useState(false);
    const [viewNotes, setViewNotes] = useState(false);
    const [patientData, setPatientData] = useState({});
    const getItems = async (date: any) => {

        setLoader(true);
        userPayAmmountAction.getUserPaymentAmountData({
            start: moment(date[0]).format('YYYY-MM-DD'),
            end: moment(date[1]).format('YYYY-MM-DD')
        }).then((reportData: any) => {
            setStates({...state, reportData: reportData.data, reportDataBySpecialist: reportData.dataGroupedByUser})
            let tempPracticeList: any = []
            let tempKeyExpand: any = []
            Object.keys(reportData.data).forEach(key => {
                const practice = key;
                tempPracticeList.push({practiceDBName: practice, practice})
                tempKeyExpand.push({key, expand: false})
            });
            tempPracticeList && tempPracticeList.sort((a, b) => {
                return a.practice > b.practice ? 1 : -1
            });
            let spclists = []
            spclists.push({key: 0, value: 'All', text: 'All'});
            Object.keys(reportData.dataGroupedByUser) && Object.keys(reportData.dataGroupedByUser).length > 0 && Object.keys(reportData.dataGroupedByUser).map((item) => {
                spclists.push({
                    key: 0,
                    value: item,
                    text: item
                })
            });
            setSpecialistList(spclists);
            setPracticeList(tempPracticeList)
            setLoader(false);

        })


    }
    useEffect(() => {

        getItems(dates)

    }, [filters])
    useEffect(() => {
        let result: string[] = applyPracticeFilter(filterPractice)
        if (filterPractice === "All") {
            setMasterUserPayment(rawUserDataObject)
            setStates({
                ...state, userPaymentAmountData: masterUserPaymentData
            })
        }
        const filtered = Object.keys(masterUserPaymentData)
            .filter(key => result.includes(key))
            .reduce((obj, key) => {
                obj[key] = masterUserPaymentData[key];
                return obj;
            }, {});
        setStates(
            {
                ...state, userPaymentAmountData: filtered
            }
        )

    }, [filterPractice])
    useEffect(() => {
        applySpecialistFilter(specialistFilter)
    }, [specialistFilter])
    const toggleTable = (view: TableToShow) => {
        setStates({...state, specialityTemp: [], practiceTemp: []});
        setTableToShow(view)
    }
    const applyPracticeFilter = (value: string) => {
        if (filterPractice === "All") {
            return Object.keys(rawUserDataObject)
        }
        const currentKeys = Object.keys(rawUserDataObject)
        const filteredKeys = currentKeys.filter((key: any) => fomartPractice(key) === value)
        return filteredKeys
    }
    const applySpecialistFilter = (value: string) => {
        if (specialistFilter === "All") {
            setStates({
                ...state, userPaymentAmountData: rawUserDataObject
            })

            return
        }
        const currentKeys = applyPracticeFilter(filterPractice)
        const tempFilteredObject = {}
        currentKeys.forEach((practice: any) => {
            let filtered = masterUserPaymentData[practice].filter(data => data.UserName.includes(value))
            tempFilteredObject[practice] = filtered
        })
        setStates({...state, userPaymentAmountData: tempFilteredObject})

    }

    const fomartPractice = (practice: any) => {
        return practice.split("-")[0].charAt(0).toUpperCase() + practice.split("-")[0].slice(1)
    }

    const handleRadioChange = (e, {value}) => {

        const specialstsNamesArr = specialistList.map(obj => {
            return obj.value != 'All' ? obj.value : null
        });

        if (value == 'specialists') {
            let listData = state.userPaymentAmountData;
            const practicesKys = Object.keys(state.userPaymentAmountData);
            let specialistsData = {}
            practicesKys.forEach((practice) => {
                const tempData = listData[practice].filter(dataobj => {
                    const separateName = dataobj.UserName.trim().split(' ');
                    const spName = separateName[0] + ' ' + separateName[1];
                    return specialstsNamesArr.indexOf(spName) > -1 && dataobj;
                });

                specialistsData[practice] = tempData

            })

            setStates({
                ...state,
                specialityTemp: [],
                practiceTemp: [],
                specialistView: value,
                userPaymentAmountData: specialistsData
            });
        } else {
            setStates({
                ...state,
                specialityTemp: [],
                practiceTemp: [],
                specialistView: value,
                userPaymentAmountData: state.tempuserPaymentAmountData
            });
        }


    }

    const openViewNotes = (notelong, PatNum) => {

        setPatientData({notes: notelong, PatNum: PatNum})
        setViewNotes(true);
    }

    const closeViewNotes = () => {
        setViewNotes(false);
    }


    const checkIsNaN = (value: any) => {
        if (isNaN(value)) {
            return 0
        } else {
            return value
        }
    }

    const expandRow = (rowId) => {
        const {expandableColumn} = state
        if (expandableColumn === rowId) {
            setStates({...state, expandableColumn: null, expandableRowLevel2: null})
            return
        }

        setStates({...state, expandableColumn: rowId})

    }

    const expandRowLevelTwo = (rowId) => {
        const {expandableRowLevel2} = state
        if (expandableRowLevel2 === rowId) {
            setStates({...state, expandableRowLevel2: null})
            return
        }

        setStates({...state, expandableRowLevel2: rowId})

    }


    const makeRowsSpecialist = (dataObject: any) => {
        let tableRows: any = []
        let grandTotal = 0;
        const specialistKeys = Object.keys(dataObject);

        specialistKeys.forEach(key => {
            let levelOneIcon = 'plus'
            if (state.expandableColumn === key) {
                levelOneIcon = 'minus'
            }
            grandTotal = grandTotal + dataObject[key]['totalAmount']
            if (dataObject[key]) {
                tableRows.push(
                    <tr>
                        <td> {key}</td>
                        <td>
                            <td>{<Icon onClick={() => expandRow(key)} className={`${levelOneIcon} red`} size='small'/>}
                                {Object.keys(dataObject[key]['data']).map(practice => fomartPractice(practice)).join(',').slice(0, 60)}
                            </td>

                        </td>
                        <td>
                            {moment(startDate).format('YYYY-MM-DD')} to {moment(endDate).format('YYYY-MM-DD')}
                        </td>
                        <td>
                            {`$${_.round(dataObject[key]['totalAmount'])}`}
                        </td>
                        <td>{`${dataObject[key]['percentCollected']}`}</td>
                    </tr>
                )

                if (key === state.expandableColumn) {
                    if (dataObject[key]['data'] && Object.keys(dataObject[key]['data'])) {
                        Object.keys(dataObject[key]['data']).forEach((practice, i) => {
                            let dataForCurrentPractice = dataObject[key]['data'] [practice]
                            const levelTwoKey = key + practice;
                            let levelTwoIcon = 'plus'
                            if (state.expandableRowLevel2 === levelTwoKey) {
                                levelTwoIcon = 'minus'
                            }

                            tableRows.push(
                                <tr>
                                    <td>___</td>
                                    <td>

                                        <td className={"expandedLevelOneCell"}>{<Icon size='small'
                                                                                      className={`${levelTwoIcon} red`}
                                                                                      onClick={() => expandRowLevelTwo(levelTwoKey)}/>}{practice}</td>

                                    </td>
                                    <td>
                                        {moment(startDate).format('YYYY-MM-DD')} to {moment(endDate).format('YYYY-MM-DD')}
                                    </td>
                                    <td>
                                        {`$${_.round(dataForCurrentPractice.reduce(function (sum, item) {
                                            const currentSum = checkIsNaN(parseFloat(sum)) + checkIsNaN(parseFloat(item.SplitAmt))
                                            return currentSum
                                        }, 0), 2)}`}
                                    </td>
                                    <td></td>

                                </tr>
                            )
                            if (levelTwoKey === state.expandableRowLevel2) {
                                if (dataForCurrentPractice && dataForCurrentPractice) {
                                    dataForCurrentPractice.forEach((record, index) => {
                                        tableRows.push(
                                            <tr>
                                                <td>
                                                    __
                                                </td>
                                                <td>
                                                    {practice}
                                                </td>
                                                <td>
                                                    {record.DatePay}
                                                </td>
                                                <td> {`$${_.round(checkIsNaN(parseFloat(record.SplitAmt)), 2)}`}</td>
                                                <td> _</td>
                                                <td> {record.PatNum}</td>
                                                <td>
                                                    <span onClick={() => openViewNotes(record.PayNote, record.PatNum)}
                                                          className='cursorPointer'><a>View </a></span>
                                                </td>
                                                {viewMoreSpecialistColumns && <td> {record.PayPlanNum}</td>}
                                                {viewMoreSpecialistColumns && <td>{record.Category}</td>}
                                                {viewMoreSpecialistColumns && <td>{record.PP_line_note}</td>}
                                                {viewMoreSpecialistColumns && <td> {record.IsClosed}</td>}
                                            </tr>
                                        )

                                    })


                                }
                            }

                        })
                    }

                }

            }

        })

        tableRows.push(
            <tr>
                <td></td>
                <td></td>
                <td><b>Total Collected:</b></td>
                <td><b>  {`$${_.round(grandTotal, 2)}`} </b></td>
            </tr>
        )
        return tableRows
    }


    const makeRowsPractice = (dataObject: any) => {
        const currentKeys = Object.keys(dataObject)
        let tableRows: any = []
        let grandTotal = 0;
        currentKeys.forEach(key => {
            const keyPractice = key
            let levelOneIcon = 'plus'
            if (state.expandableColumn === key) {
                levelOneIcon = 'minus'
            }
            grandTotal = grandTotal + dataObject[key]['totalAmount']
            if (dataObject[key]) {
                tableRows.push(
                    <tr>
                        <td>
                            <td>{<Icon onClick={() => expandRow(key)} className={`${levelOneIcon} red`}
                                       size='small'/>}{key}</td>


                        </td>
                        <td>
                            {moment(startDate).format('YYYY-MM-DD')} to {moment(endDate).format('YYYY-MM-DD')}

                        </td>
                        <td>
                            {`${dataObject[key]['totalRecords']} Records`}
                        </td>
                        <td>{`$${_.round(dataObject[key]['totalAmount'])}`}</td>
                        <td>{` ${dataObject[key]['percentCollected']}`}</td>

                        <td>_</td>
                        <td>_</td>
                        <td>_</td>
                        {state.expandableColumn && <td colSpan={3}>_</td>}
                        {viewMorePracticeColumns && <td>_</td>}
                        {viewMorePracticeColumns && <td>_</td>}
                        {viewMorePracticeColumns && <td>_</td>}

                    </tr>
                )


                if (key === state.expandableColumn) {
                    if (dataObject[key]['data'] && Object.keys(dataObject[key]['data'])) {
                        Object.keys(dataObject[key]['data']).forEach((userName, i) => {
                            let dataForCurrentUser = dataObject[key]['data'] [userName]
                            const levelTwoKey = key + userName

                            let levelTwoIcon = 'plus'
                            if (state.expandableRowLevel2 === levelTwoKey) {
                                levelTwoIcon = 'minus'
                            }
                            tableRows.push(
                                <tr>
                                    <td>{key}</td>
                                    <td>
                                        {moment(startDate).format('YYYY-MM-DD')} to {moment(endDate).format('YYYY-MM-DD')}
                                    </td>
                                    <td>
                                        <td className={"expandedLevelOneCell"}>{<Icon size='small'
                                                                                      className={`${levelTwoIcon} red`}
                                                                                      onClick={() => expandRowLevelTwo(levelTwoKey)}/>}{userName}</td>


                                    </td>
                                    <td>
                                        {`$${_.round(dataForCurrentUser.reduce(function (sum, item) {
                                            const currentSum = checkIsNaN(parseFloat(sum)) + checkIsNaN(parseFloat(item.SplitAmt))
                                            return currentSum
                                        }, 0), 2)}`}
                                    </td>
                                    <td>
                                        _
                                    </td>

                                    <td>
                                        _
                                    </td>
                                    {state.expandableColumn && <td colSpan={3}>_</td>}
                                    {viewMorePracticeColumns && <td>_</td>}
                                    {viewMorePracticeColumns && <td>_</td>}
                                    {viewMorePracticeColumns && <td>_</td>}

                                </tr>
                            )
                            if (levelTwoKey === state.expandableRowLevel2) {
                                if (dataForCurrentUser && dataForCurrentUser) {

                                    dataForCurrentUser.forEach((recordsForUser, index) => {
                                        tableRows.push(
                                            <tr>
                                                <td>{key}</td>
                                                <td>
                                                    {recordsForUser.DatePay}
                                                </td>
                                                <td>
                                                    {recordsForUser.UserName}
                                                </td>
                                                <td>
                                                    {`$${_.round(checkIsNaN(parseFloat(recordsForUser.SplitAmt)), 2)}`}
                                                </td>
                                                <td> _</td>
                                                <td>
                                                    {recordsForUser.PatNum}
                                                </td>
                                                <td>
                                                    <span
                                                        onClick={() => openViewNotes(recordsForUser.PayNote, recordsForUser.PatNum)}
                                                        className='cursorPointer'><a>View</a></span>
                                                </td>
                                                {viewMorePracticeColumns && <td>{recordsForUser.PayPlanNum}</td>}
                                                {viewMorePracticeColumns && <td>{recordsForUser.Category}</td>}
                                                {viewMorePracticeColumns && <td>{recordsForUser.PP_line_note}</td>}
                                                {viewMorePracticeColumns && <td> {recordsForUser.IsClosed}</td>}

                                            </tr>
                                        )


                                    })
                                }
                            }


                        })
                    }
                }


            }

        })

        tableRows.push(
            <tr>
                <td></td>
                <td></td>
                <td><b>Total Collected:</b></td>
                <td><b>{`$${_.round(grandTotal)}`}</b></td>
                <td>_</td>
                <td>_</td>
                <td>_</td>
                <td>_</td>
                <td>_</td>
                <td>_</td>

            </tr>
        )
        return tableRows
    }

    const sortResults = (sortKey: string, sortDirection: SortDirection) => {

        let tempData = state.userPaymentAmountData
        const keys = Object.keys(state.userPaymentAmountData)
        let splitAscSort;
        let practiceSort;
        if (sortKey == 'specialistSplitAmt') {
            sortDirection === SortDirection.ASC ? splitAscSort = state.specialityTemp && state.specialityTemp.sort((a, b) => a.totalSum - b.totalSum) :
                splitAscSort = state.specialityTemp && state.specialityTemp.sort((a, b) => b.totalSum - a.totalSum)
        } else if (sortKey == 'specialistUserName') {
            sortDirection === SortDirection.ASC ? splitAscSort = state.specialityTemp && state.specialityTemp.sort((a, b) => a.specialist > b.specialist ? 1 : -1) :
                splitAscSort = state.specialityTemp && state.specialityTemp.sort((a, b) => a.specialist < b.specialist ? 1 : -1)
        }

        if (sortKey == "Practice") {
            sortDirection === SortDirection.ASC ? practiceSort = state.practiceTemp && state.practiceTemp.sort((a, b) => a.practice > b.practice ? 1 : -1) :
                practiceSort = state.practiceTemp && state.practiceTemp.sort((a, b) => a.practice < b.practice ? 1 : -1)
        } else if (sortKey == "SplitAmt") {
            sortDirection === SortDirection.ASC ? practiceSort = state.practiceTemp && state.practiceTemp.sort((a, b) => a.totalCollected - b.totalCollected) :
                practiceSort = state.practiceTemp && state.practiceTemp.sort((a, b) => b.totalCollected - a.totalCollected)
        } else {
            keys.forEach((key) => {
                if (sortDirection === SortDirection.ASC)
                    tempData[key].sort(
                        (a, b) => (a[sortKey] > b[sortKey]) ? 1 : -1)
                if (sortDirection === SortDirection.DESC)
                    tempData[key].sort(
                        (a, b) => (a[sortKey] < b[sortKey]) ? 1 : -1)
            })
        }
        const currentSortObject = sortObject
        currentSortObject[sortKey].sort = true
        currentSortObject[sortKey].direction = sortDirection
        Object.keys(sortObject).forEach((item) => {
            currentSortObject[item].sort = false
        })
        currentSortObject[sortKey].sort = true
        setSortObject(currentSortObject)
        setStates({...state, userPaymentAmountData: tempData, specialityTemp: splitAscSort, practiceTemp: practiceSort})

    }

    const sortHandler = (key: string) => {
        const {direction} = sortObject[key]
        if (direction === SortDirection.ASC)
            sortResults(key, SortDirection.DESC)
        if (direction === SortDirection.DESC)
            sortResults(key, SortDirection.ASC)
    }
    const sortIcon = (key: string): SemanticICONS | undefined => {
        if (sortObject[key].sort) {
            if (sortObject[key].direction === SortDirection.ASC)
                return "sort ascending"
            if (sortObject[key].direction === SortDirection.DESC)
                return "sort descending"
        }
        return "sort"
    }
    const makeSpecialistTable = () => {
        return (
            <table className="table tableCustomStyle mb0">
                <thead>
                <tr>
                    <th><Icon name={sortIcon('specialistUserName')}
                              color={sortObject.specialistUserName.sort ? "orange" : "black"} onClick={() => {
                        sortHandler('specialistUserName')
                    }}/>Specialist
                    </th>
                    <th><Icon name={sortIcon('Practice')} color={sortObject.Practice.sort ? "orange" : "black"}
                              onClick={() => {
                                  sortHandler('Practice')
                              }}/>Practice
                    </th>
                    <th><Icon name={sortIcon('DatePay')} color={sortObject.DatePay.sort ? "orange" : "black"}
                              onClick={() => {
                                  sortHandler('DatePay')
                              }}/>DatePay
                    </th>
                    <th style={{minWidth: '140px'}}><Icon name={sortIcon('specialistSplitAmt')}
                                                          color={sortObject.specialistSplitAmt.sort ? "orange" : "black"}
                                                          onClick={() => {
                                                              sortHandler('specialistSplitAmt')
                                                          }}/>Collected
                    </th>
                    <th style={{minWidth: '140px'}}>Percentage Collected</th>
                    <th><Icon name={sortIcon('PatNum')} color={sortObject.PatNum.sort ? "orange" : "black"}
                              onClick={() => {
                                  sortHandler('PatNum')
                              }}/>Patient Number
                    </th>
                    <th><Icon name={sortIcon('PayNote')} color={sortObject.PayNote.sort ? "orange" : "black"}
                              onClick={() => {
                                  sortHandler('PayNote')
                              }}/>PayNote
                    </th>
                    {viewMoreSpecialistColumns &&
                        <th><Icon name={sortIcon('PayPlanNum')} color={sortObject.PayPlanNum.sort ? "orange" : "black"}
                                  onClick={() => {
                                      sortHandler('PayPlanNum')
                                  }}/>PayPlanNum</th>}
                    {viewMoreSpecialistColumns &&
                        <th><Icon name={sortIcon('Category')} color={sortObject.Category.sort ? "orange" : "black"}
                                  onClick={() => {
                                      sortHandler('Category')
                                  }}/>Category</th>}
                    {viewMoreSpecialistColumns && <th><Icon name={sortIcon('PP_line_note')}
                                                            color={sortObject.PP_line_note.sort ? "orange" : "black"}
                                                            onClick={() => {
                                                                sortHandler('PP_line_note')
                                                            }}/>PP_line_note</th>}
                    {viewMoreSpecialistColumns &&
                        <th><Icon name={sortIcon('IsClosed')} color={sortObject.IsClosed.sort ? "orange" : "black"}
                                  onClick={() => {
                                      sortHandler('IsClosed')
                                  }}/>Is Closed</th>}
                    <th style={{minWidth: '100px'}}><span style={{textDecoration: 'underline'}}
                                                          onClick={() => setSpecialistColumns(!viewMoreSpecialistColumns)}>
                  <a>{!viewMoreSpecialistColumns ? "View More" : "View less"} </a> </span></th>
                </tr>
                </thead>
                <tbody>

                {
                    makeRowsSpecialist(state.reportDataBySpecialist).map(data => {
                        return data
                    })

                }
                </tbody>
            </table>)
    }
    const makePracticesTable = () => {
        return (
            <table className="table tableCustomStyle mb0">
                <thead>
                <tr>
                    <th><Icon name={sortIcon('Practice')} color={sortObject.Practice.sort ? "orange" : "black"}
                              onClick={() => {
                                  sortHandler('Practice')
                              }}/>Practice
                    </th>
                    <th><Icon name={sortIcon('DatePay')} color={sortObject.DatePay.sort ? "orange" : "black"}
                              onClick={() => {
                                  sortHandler('DatePay')
                              }}/>DatePay
                    </th>
                    <th><Icon name={sortIcon('UserName')} color={sortObject.UserName.sort ? "orange" : "black"}
                              onClick={() => {
                                  sortHandler('UserName')
                              }}/>Specialist
                    </th>
                    <th style={{minWidth: '140px'}}><Icon name={sortIcon('SplitAmt')}
                                                          color={sortObject.SplitAmt.sort ? "orange" : "black"}
                                                          onClick={() => {
                                                              sortHandler('SplitAmt')
                                                          }}/>Collected
                    </th>
                    <th style={{minWidth: '140px'}}>Percentage Collected</th>
                    <th><Icon name={sortIcon('PatNum')} color={sortObject.PatNum.sort ? "orange" : "black"}
                              onClick={() => {
                                  sortHandler('PatNum')
                              }}/>Patient Number
                    </th>
                    <th><Icon name={sortIcon('PayNote')} color={sortObject.PayNote.sort ? "orange" : "black"}
                              onClick={() => {
                                  sortHandler('PayNote')
                              }}/>PayNote
                    </th>
                    {viewMorePracticeColumns &&
                        <th><Icon name={sortIcon('PayPlanNum')} color={sortObject.PayPlanNum.sort ? "orange" : "black"}
                                  onClick={() => {
                                      sortHandler('PayPlanNum')
                                  }}/>PayPlanNum</th>}
                    {viewMorePracticeColumns &&
                        <th><Icon name={sortIcon('Category')} color={sortObject.Category.sort ? "orange" : "black"}
                                  onClick={() => {
                                      sortHandler('Category')
                                  }}/>Category</th>}
                    {viewMorePracticeColumns && <th><Icon name={sortIcon('PP_line_note')}
                                                          color={sortObject.PP_line_note.sort ? "orange" : "black"}
                                                          onClick={() => {
                                                              sortHandler('PP_line_note')
                                                          }}/>PP_line_note</th>}
                    {viewMorePracticeColumns &&
                        <th><Icon name={sortIcon('IsClosed')} color={sortObject.IsClosed.sort ? "orange" : "black"}
                                  onClick={() => {
                                      sortHandler('IsClosed')
                                  }}/>Is Closed</th>}
                    <th style={{minWidth: '100px'}}><span style={{textDecoration: 'underline'}}
                                                          onClick={() => setPracticeColumns(!viewMorePracticeColumns)}>
                  <a>{!viewMorePracticeColumns ? "View More" : "View less"} </a> </span></th>
                </tr>
                </thead>
                <tbody>

                {
                    makeRowsPractice(state.reportData && state.reportData).map(data => {
                        return data
                    })
                }
                </tbody>
            </table>)
    }
    const buildView = () => {
        switch (tableToShow) {
            case TableToShow.PRACTICE:
                return makePracticesTable()
            case TableToShow.SPECIALIST:
                return makeSpecialistTable()
        }
    }
    const arrayReportToCSV = () => {
        let toCSV = [];
        var result = Object.keys(state.userPaymentAmountData && state.userPaymentAmountData).map((key) => {
                state.userPaymentAmountData && state.userPaymentAmountData[key].map(userData => {
                    toCSV.push(userData)
                })
            }
        );
        return toCSV

    }
    const onChangeStartDate = async (date: any) => {
        let data = Object.assign({}, moment(date).format('YYYY-MM-DD'))
        setFilters({...filters, start: data})
        setStartDate(date)
        getItems([date, endDate])

    }
    const onChangeEndDate = async (date: any) => {
        let data = Object.assign({}, moment(date).format('YYYY-MM-DD'))
        setFilters({...filters, start: data})
        setEndDate(date)
        getItems([startDate, date])

    }
    const filterBySpecialist = (specialist: string) => {
        const filteredUsers = Object.keys(state.reportDataBySpecialist)
            .filter(key => specialist)
            .reduce((obj, key) => {
                obj[key] = state.reportDataBySpecialist[key];
                return obj;
            }, {});
        setStates({...state, reportDataBySpecialist: filteredUsers})
    }
    return (
        <>
            {loader && <CountUpTimer></CountUpTimer>}
            <Card>
                <Card.Content className="pb0">
                    <h2 className="headingMain float-left">Collections Report<HelpMark
                        helpPage={HELP_PAGE.BILLING_COLLECTION_REPORTS}
                        application="Billing-PatienceFinance-CollectionReports"/></h2>
                    <div className="topFilters">
                        <div className="datePicker float-right">
                            <label className="labelStyle displayBlock">Filter By Date: Start date</label>
                            <DatePicker
                                placeholderText={"yyyy/mm/dd"}
                                name="date"
                                className='mr10 mb15'
                                dateFormat="yyyy-MM-dd"
                                selected={new Date(startDate)}
                                onChange={(date) => onChangeStartDate(date)
                                }
                            />
                            <DatePicker
                                placeholderText={"yyyy/mm/dd"}
                                name="date"
                                className='mr10 mb15'
                                dateFormat="yyyy-MM-dd"
                                selected={new Date(endDate)}
                                onChange={(date) => onChangeEndDate(date)
                                }
                            />
                        </div>
                        <div className="float-right">
                            <label className="labelStyle displayBlock">Filter By Practice</label>
                            <Dropdown
                                placeholder="Select Practice"
                                selection
                                className='mr10 mb15'
                                search
                                options={[{
                                    key: 'All',
                                    value: 'All',
                                    text: 'All'
                                },
                                    ...practiceList.map((practice: any) => {
                                        return {
                                            key: practice.practiceDBName,
                                            value: practice.practice,
                                            text: practice.practice,
                                        }
                                    })]
                                }
                                onChange={(_, data) => {
                                    setFilterPractice(value => value = data.value as string)
                                }}
                            />
                        </div>
                        <div className="float-right">
                            <label className="labelStyle displayBlock">Filter By Specialist</label>
                            <Dropdown
                                placeholder="Select Specialist"
                                selection
                                className='mr10 mb15'
                                options={specialistList}
                                onChange={(event, data) => {
                                    filterBySpecialist(data.value as string)
                                }}
                            />
                        </div>
                    </div>
                </Card.Content>
            </Card>

            <Card>
                <Card.Content className="pb0">
                    <div className=" mr10 float-left ">

                        <Radio
                            label='View All'
                            name='radioGroup'
                            value='all'
                            checked={state.specialistView === 'all'}
                            onChange={handleRadioChange}
                        />
                        <Radio
                            label='View Specialists'
                            name='radioGroup'
                            value='specialists'
                            checked={state.specialistView === 'specialists'}
                            onChange={handleRadioChange}
                        />
                    </div>
                    <div className=" mr10 mb7">
                        <Button.Group>
                            <Button className={tableToShow === TableToShow.SPECIALIST ? 'bg-color-primary' : ''}
                                    onClick={() => toggleTable(TableToShow.SPECIALIST)}>By Specialist</Button>
                            <Button.Or/>
                            <Button className={tableToShow === TableToShow.PRACTICE ? 'bg-color-primary' : ''}
                                    onClick={() => toggleTable(TableToShow.PRACTICE)}>By Practice</Button>
                        </Button.Group>
                        <CSVLink data={arrayReportToCSV()} headers={CsvHeaders} filename={"CollectionReport.csv"}
                                 className="ui secondary button btnPrint">
                            <label>Export CSV</label>
                        </CSVLink>
                    </div>
                    <div className="table-responsive FreezeTable">
                        {
                            (
                                buildView()
                            )
                        }
                        {viewNotes && <ViewNotesModal patientData={patientData} closeViewNotes={closeViewNotes}/>}
                    </div>
                </Card.Content>
            </Card>

        </>

    )
}

function mapStateToProps(state: RootState) {
    return {};
}

export default connect(mapStateToProps)(CollectionReports);
