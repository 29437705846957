import {Action,ActionType} from "../../model/actionTypes"
import {Setup,SetupQA} from "../../model/setup"
import createReducer from "./createReducer"


const initialState={
    setupData:[],
    setupQAData:[]
};

export const setup = createReducer(initialState,{
    [ActionType.LOAD](state:Setup,
                      action:Action<Setup>){
        return Object.assign({...state},action.payload);
        },
    [ActionType.SAVE_SETUP](state:Setup,action:Action<Setup>){
        return Object.assign({...state},action.payload);
    },
    [ActionType.LOAD_SETUP_QA_STATUS_SUCCESS](state:SetupQA,action:Action<SetupQA>){
        return Object.assign({...state},action.payload);
    },
    [ActionType.DELETE_SETUP_QA_STATUS_SUCCESS](state:SetupQA,action:Action<SetupQA>){
        return Object.assign({...state},action.payload);
    },
    [ActionType.GET_SETUP_XCHARGE](state:Setup,action:Action<Setup>){
        return Object.assign({...state},action.payload);
    },
    [ActionType.SAVE_XCHARGE](state:Setup,action:Action<Setup>){
        return Object.assign({...state},action.payload);
    },
    [ActionType.DELETE_XCHARGE](state:Setup,action:Action<Setup>){
        return Object.assign({...state},action.payload);
    },
    [ActionType.UPDATE_XCHARGE](state:Setup,action:Action<Setup>){
        return Object.assign({...state},action.payload);
    },
    [ActionType.LOAD_ALLPRACTICES](state:Setup,action:Action<Setup>){
        return Object.assign({...state},action.payload);
    }
})