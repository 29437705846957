import React, {useEffect, useState} from "react";
import "semantic-ui-css/semantic.min.css";
import {Grid, Button, Dimmer, Input, Form, Confirm, Loader, Icon, Table, Popup, Card} from "semantic-ui-react";
import {useActions} from "../redux/actions";
import "react-datepicker/dist/react-datepicker.css";
import AddSetupQAModal from "./AddSetupQAModal";
import * as setupActions from "../redux/actions/setup";
import HelpMark from "./Help/helpMark";
import {HELP_PAGE} from "../constants";
import CountUpTimer from "./CountUpTimer";

interface SetupQAData {
    editForm: boolean
    setupQAData: any,
    editItem: any,
    confirm: any,
    deleteId: any,
    type: any

}


const SetupQA: React.FC<any> = () => {
    const initialState: SetupQAData = {
        setupQAData: [],
        editForm: false,
        editItem: false,
        confirm: false,
        deleteId: false,
        type: false,

    };

    const setupAction = useActions(setupActions);
    const [loader, setLoader] = useState(false);
    const [state, setStates] = useState(initialState);
    const [confirm, setConfirm] = useState(false)
    useEffect(() => {
        const getItems = async () => {

            setLoader(true);
            setupAction.getSetupQAList().then((data: any) => {
                setStates({...state, setupQAData: data})

            });
            
            setLoader(false);
        }
        getItems()
    }, []);

    const handleEdit = (item: any) => {
        setStates({...state, editItem: item});
    };

    const submitEdit = () => {
        let payload = {
            id: state.editItem.id,
            QAStatus: state.editItem.QAStatus
        }
        const AsyncSubmit = async () => {
            setupAction.EditSetupQAStatus(payload)
        }
        AsyncSubmit().then()


    }

    const onChange = (e: any) => {
        let editItem = state.editItem;
        editItem[e.target.name] = e.target.value;
        setStates({...state, editItem: editItem});
    };
    const getTableRows = () => {
        const {editItem} = state;
        const listRows = [];

        state.setupQAData && state.setupQAData.map((data, index) => {
            listRows.push(
                <Table.Row key={data.id}>
                    <Table.Cell>
                        {data.id}
                    </Table.Cell>
                    <Table.Cell>
                        {
                            editItem && editItem.id === data.id ?
                                <Form>
                                    <Form.Group widths='equal' className="mb0">
                                        <Form.Field>
                                            <Input style={{minWidth: '124px'}} name='QAStatus' fluid
                                                   placeholder='QAStatus'
                                                   onChange={(e) => onChange(e)} defaultValue={editItem.QAStatus}/>
                                        </Form.Field>
                                    </Form.Group>
                                </Form>

                                : data.QAStatus}
                    </Table.Cell>
                    <Table.Cell>
                        {editItem && editItem.id === data.id ? <span>
                                <Button primary className="mr10 mb10" onClick={() => submitEdit()}>Update</Button>
                                <Button secondary className="mb10"
                                        onClick={() => setStates({...state, editItem: false})}>Cancel</Button></span>
                            : <span className="mb10 float-left">
                                <Icon name='edit' className="mr15 cursorPointer" onClick={() => handleEdit(data)}/>
                                <Icon name='trash alternate' className="cursorPointer"
                                      onClick={() => openConfirm(data.id)}/>
                            </span>}
                    </Table.Cell>

                </Table.Row>
            )
        })

        return listRows;
    }
    const submitSetupData = (formInputFeilds) => {
        setStates({...state, editForm: false})
        const payload = {

            QAStatus: formInputFeilds.QAStatus,

        }
        const AsyncSubmit = async () => {
            setupAction.SubmitSetupQAStatusData(payload)
        }
        AsyncSubmit()
    }
    const openSetupModal = () => {
        setStates({...state, editForm: true})

    }

    const hideSetupModal = () => {
        setStates({...state, editForm: false})
    }
    const handleDelete = () => {
        setConfirm(false);
        let payload = {
            id: state.deleteId,
            IsDeleted: 1
        };
        const AsyncSubmit = async () => {
            setupAction.DeleteSetupQAStatus(payload);
        }
        AsyncSubmit()

    };
    const closeConfirm = () => {
        setConfirm(false);
    };
    const openConfirm = (id: number) => {

        setConfirm(true);
        setStates({...state, deleteId: id});
    };


    return (
        <>
            {loader && <CountUpTimer></CountUpTimer>}
            <Card>
                <Card.Content className="pb0">
                    <h2 className="headingMain float-left">SETUP PPL QA<HelpMark
                        helpPage={HELP_PAGE.BILLING_SETUP_QA_STATUS}
                        application="Billing-PatientFinance-Setup-SetupPPLQA"/></h2>
                    <div className="topFilters">
                        <Button primary className="mb15" onClick={() => {
                            openSetupModal()
                        }}>Add QA Status</Button>
                    </div>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <div className="table-responsive FreezeTable">
                        <Table striped sortable className="customTable" unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell><div>Sequence</div></Table.HeaderCell>
                                    <Table.HeaderCell><div>QA Status</div></Table.HeaderCell>
                                    <Table.HeaderCell><div>Actions</div></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>

                                {
                                    getTableRows().map(data => {
                                        return data
                                    })

                                }
                            </Table.Body>

                        </Table>
                    </div>
                </Card.Content>
            </Card>
            {state.editForm &&
                <AddSetupQAModal hideSetupModal={hideSetupModal} submitSetupData={submitSetupData}/>
            }
            <Confirm
                cancelButton='No'
                confirmButton="Yes"
                className="confirmModal"
                content="Are you sure you want to delete?"
                open={confirm}
                onCancel={closeConfirm}
                onConfirm={handleDelete}
            />
        </>


    )
}

export default SetupQA