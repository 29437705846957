import {useTable, usePagination, useSortBy} from "react-table";
import "semantic-ui-css/semantic.min.css";
import React, {useMemo} from "react";
import {Button, Icon} from "semantic-ui-react";
import moment from "moment"
import {numberWithCommas} from "../constants";


export default function ClaimsTablePage(props: any) {
    const columns = useMemo(() => Object.keys(props.columns)
        .map((r: string) => ({ Header: r, accessor: props.columns[r] }))
        .filter(col => !props.hiddenColumns.includes(col.accessor)),
        [props.columns, props.hiddenColumns]
    );
    const data = useMemo(
        () => props.data.map((r: any) => {
            const notesLen = r.notes.length;
            return {
                ...r, practice: props.practiceNames ? props.practiceNames[r.practice] : "",
                amount: numberWithCommas(r.amount),
                paidAmount: numberWithCommas(r.paidAmount),
                feeBilled: numberWithCommas(r.feeBilled),
                Notes: notesLen  === 0? (
                    <Button primary style={{minWidth: '108px'}} onClick={() => {
                        props.openNotesModal(r)
                    }}>Add Notes</Button>
                ) : (
                    <span className="clickable" onClick={() => props.viewNote(r)}>{r.notes[notesLen - 1] && r.notes[notesLen - 1].notes.slice(0, 27) + "...."}</span>
                ),
                Notestimestamp: notesLen  === 0 ? "No Notes Added" :  r.notes && r.notes[notesLen - 1].createdAt,
                getStatus: moment(r.getStatus).format("dddd, MMMM Do YYYY, h:mm:ss a")
            }
        }),
        [props.data]
    );
    const table = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: 20,
                pageIndex: 0
            }
        },
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize}
    } = table;

    return (
        <>
            <div className="claims-table table-responsive FreezeTable">
                <table {...getTableProps()} className="ui table striped table-hover customTable mt0 unstackable">
                    <thead>
                    {

                        headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map((column) => {
                                        return (

                                            <th {...{...column.getHeaderProps(), ...column.getHeaderProps(column.getSortByToggleProps())}} >
                                                <div>
                                                    {
                                                        column.render("Header")

                                                    }
                                                </div>

                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        ))
                    }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {
                        page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {
                                        row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {

                                                        cell.render("Cell")
                                                    }
                                                </td>
                                            );
                                        })
                                    }
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
            </div>
            <div className="pagination float-right mt15">
        <span>
          Page&nbsp;
            <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>

                <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    <Icon name={"angle double left"}></Icon>
                </Button>{" "}
                <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <Icon name={"angle left"}></Icon>
                </Button>{" "}
                <Button onClick={() => {
                    nextPage()
                }} disabled={!canNextPage}>
                    <Icon name={"angle right"}></Icon>
                </Button>{" "}
                <Button
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                >
                    <Icon name={"angle double right"}></Icon>
                </Button>{" "}

                <select

                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                >
                    {[20, 30, 40, 50, 100].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize !== 15 ? `Show ${pageSize}` : `Show all`}
                        </option>
                    ))}
                </select>

            </div>
        </>
    );
}
