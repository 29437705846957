import { ActionType } from "../../model/actionTypes";
const moment = require('moment');

export const getUserQAStatusIssuesData = (filters:any) => async (dispatch:Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD, payload: true });
    try {
        let res = await api.get(`patientFinance/getQAStatusIssues`);
        dispatch({
            type: ActionType.LOAD_QAISSUES_SUCCES,
            payload: {
                userQAIssuesData : res
            } });
        dispatch({ type: ActionType.LOADER, payload: false });
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_QAISSUES_FAIL, error: err.message });
        return false;
    }
};
