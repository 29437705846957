import { ActionType } from "../../model/actionTypes";
import {errorHandler} from "./auth";
const moment = require('moment');

export const getPaymentReport = (filters:any) => async (dispatch:Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD });
    let filterDates='';
    const srtFilters=filters.sort(function(a,b){
        return new Date(moment(a).format('YYYY-MM-DD')).valueOf() - new Date(moment(b).format('YYYY-MM-DD')).valueOf();
      })
    srtFilters.forEach((dItem)=>{
      filterDates+='/'+ moment(dItem).format('YYYY-MM-DD');
    });

    try {
        let res = await api.get(`report/yearlyPaymentReport${filterDates}`);
        dispatch({ type: ActionType.LOAD_PAYMENT_REPORT_SUCCESS, payload: {
            dailyPaymentreport : res 

        } });
        return res;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_PAYMENT_REPORT_FAIL, error: err.message });
        return false;
    }
};