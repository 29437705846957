import React, {useEffect, useState} from "react";
import {useActions} from "../redux/actions";
import {Grid, Table, Dropdown, Icon, Dimmer, Loader, Button, Card} from "semantic-ui-react"
import Template from "./Template";
import _ from 'lodash';
import * as setupActions from "../redux/actions/setup"
import * as reportsActions from "../redux/actions/reports"
import MultipleDatePicker from "react-multiple-datepicker";
import {dentistryOptions, booleanOptionsArrays, loanTypeOptions, HELP_PAGE} from "../constants"
import {CSVLink} from "react-csv";
import HelpMark from "./Help/helpMark"
import CountUpTimer from "./CountUpTimer";

const csvHeaders = [
    {key: 'practice', label: 'Practice'},
    {key: 'specialist', label: 'Specialists'},
    {key: 'notcollected', label: 'Not Collected'},
    {key: 'collectedzero', label: 'Collected Zero'},
    {key: 'collectednotzero', label: 'Collected Not Zero'},
    {key: 'total', label: 'Total'}
];
type PaymentDataItem = {
    UserName: string;
    SplitAmt: string;
};

const DailyPaymentsReport: React.FC<any> = (props: any) => {

    const setupAction = useActions(setupActions);
    const reportsAction = useActions(reportsActions)
    const [dates, setDates] = useState<Date[] | null>([new Date()]);
    const [practiceList, setPracticeList] = useState<any>([]);
    const [specialists, setSpecialists] = useState<any>([]);
    const [loader, setLoader] = useState(false);
    const [dailyPaymentdata, setPaymentReport] = useState([])
    const [tempdata, setTempData] = useState([])
    const [column, setColumn] = useState(null);
    const [direction, setDirection] = useState(null);
    const [currPractice, setPractice] = useState(null);
    const [currSpecialist, setSpecialist] = useState(null);
    const [currDentistry, setDentistry] = useState(null);
    const [currIsClosed, setIsClosed] = useState(null);
    const [currLoantype, setLoantype] = useState(null);
    const [totalSum, setTotalSum] = useState<any>(0)


    useEffect(() => {
        fetchFiltersData();
    }, [])

    useEffect(() => {
        setLoader(true)
        reportsAction.getPaymentReport(dates)
            .then(({data}) => {
                let pracSpecialist: {[key: string]: any} = {};
                setTempData(data);
                Object.entries(data as Record<string, PaymentDataItem[]>).forEach(([practiceName, items]) => {
                    items.forEach((item: PaymentDataItem) => {
                        let key = practiceName + item.UserName;

                        if (!(key in pracSpecialist)) {
                            pracSpecialist[key] = {
                                practice: practiceName,
                                specialist: item.UserName,
                                notcollected: 0,
                                collectedzero: 0,
                                collectednotzero: 0
                            };
                        }

                        if (item.SplitAmt === "Not Collected") {
                            pracSpecialist[key]["notcollected"]++;
                        } else if (item.SplitAmt === "0.00") {
                            pracSpecialist[key]["collectedzero"]++;
                        } else {
                            pracSpecialist[key]["collectednotzero"]++;
                        }

                        pracSpecialist[key]["total"] = pracSpecialist[key]["notcollected"] +
                            pracSpecialist[key]["collectedzero"] + pracSpecialist[key]["collectednotzero"];
                    });
                });

                let pracArry = Object.values(pracSpecialist);
                let totalTtl = pracArry.reduce((accumulator, item) => {
                    return accumulator + item.total;
                }, 0);

                setTotalSum(totalTtl);
                setPaymentReport(Object.values(pracSpecialist));
                setLoader(false);
            });


    }, [dates])

    useEffect(() => {
        setLoader(true)
        let pracSpecialist = {};
        let storedata = tempdata;
        if (currPractice) {
            let result = [];
            if (currPractice !== 'All') {
                result = _.filter(storedata, function (item) {
                    return item.Practice === currPractice;
                });
            } else {
                result = storedata;
            }
            storedata = result
        }
        if (currSpecialist) {
            let result = [];
            if (currSpecialist !== 'All') {
                result = _.filter(storedata, function (item) {
                    return item.UserName === currSpecialist;
                });
            } else {
                result = storedata;
            }
            storedata = result;
        }
        if (currDentistry) {
            let result = [];
            if (currDentistry[0] !== 'All') {
                result = _.filter(storedata, function (item) {

                    if (currDentistry) {
                        let dentistry = currDentistry.filter(function (item2) {
                            return item2 === item.Dentistry
                        })
                        return dentistry[0]
                    }

                });
            } else {
                result = storedata;
            }
            if (currDentistry.indexOf('All') !== -1) {
                result = storedata;
            }
            if (currDentistry.length == 0) {
                result = storedata;
            }

            storedata = result;
        }
        if (currIsClosed) {
            let result = [];
            if (currIsClosed !== '99') {
                result = _.filter(storedata, function (item) {
                    return item.IsClosed === parseInt(currIsClosed);
                });
            } else {
                result = storedata;
            }
            storedata = result;
        }
        if (currLoantype) {
            let result = [];
            if (currLoantype === "PPCC$:YES") {
                result = _.filter(storedata, function (item) {
                    return item.notelong.includes('PPCC$:YES');
                });
            } else if (currLoantype === 'PPCC$:NO') {
                result = _.filter(storedata, function (item) {
                    return item.notelong.includes('PPCC$:NO');
                });
            } else if (currLoantype === "PPL$INH:PAYSIM") {
                result = _.filter(storedata, function (item) {
                    return item.notelong.includes('PPL$INH:PAYSIM');
                });
            } else {
                result = storedata;
            }

            storedata = result;

        }
        storedata.map((itemr) => {

            if (Object.keys(pracSpecialist).indexOf(itemr.Practice + itemr.UserName) == -1) {
                pracSpecialist[itemr.Practice + itemr.UserName] = {
                    practice: itemr.Practice,
                    specialist: itemr.UserName,
                    notcollected: 0,
                    collectedzero: 0,
                    collectednotzero: 0
                }
            }
            if (itemr.SplitAmt === "Not Collected") {
                pracSpecialist[itemr.Practice + itemr.UserName]["notcollected"]++
            }
            if (itemr.SplitAmt === "0.00") {
                pracSpecialist[itemr.Practice + itemr.UserName]["collectedzero"]++
            }
            if (itemr.SplitAmt !== "Not Collected" && itemr.SplitAmt !== "0.00") {
                pracSpecialist[itemr.Practice + itemr.UserName]["collectednotzero"]++
            }

            pracSpecialist[itemr.Practice + itemr.UserName]["total"] = pracSpecialist[itemr.Practice + itemr.UserName]["notcollected"] +
                pracSpecialist[itemr.Practice + itemr.UserName]["collectedzero"] + pracSpecialist[itemr.Practice + itemr.UserName]["collectednotzero"]
        })
        let pracArry = Object.values(pracSpecialist);
        let totalTtl = pracArry.reduce(function (accumulator, item: any) {
            return accumulator + item.total;
        }, 0);
        setTotalSum(totalTtl);
        setPaymentReport(Object.values(pracSpecialist));
        setLoader(false);

    }, [currPractice, currSpecialist, currDentistry, currIsClosed, currLoantype])

    const fetchFiltersData = async () => {
        setLoader(true)
        setupAction.fetchAllPractices().then((pracList) => {
            let allPractices = [{key: 'All', value: 'All', text: 'All'}];
            pracList && pracList.map((practice) => {
                if (practice.schedule == true)
                    allPractices.push({key: practice.practiceabbr, value: practice.practice, text: practice.practice})
            });
            setPracticeList(allPractices);
        })

        setupAction.getSetupData().then((data: any) => {
            specialists.push({key: 0, value: 'All', text: 'Select specialist'});
            data && data.length > 0 && data.map((item) => {
                specialists.push({
                    key: item.id,
                    value: item.odUserName,
                    text: item.odUserName
                })
            });
        });
        setLoader(false);
    }

    const getTableRows = () => {

        let listingReport = [];
        let totalNtcollected = 0, totalCollectedzero = 0,
            totalCollectedNtzero = 0, totalTotal = 0;
        dailyPaymentdata.map((rprtItem, index) => {
            listingReport.push(<Table.Row key={index}>
                    <Table.Cell>{rprtItem.practice}</Table.Cell>
                    <Table.Cell>{rprtItem.specialist}</Table.Cell>
                    <Table.Cell>{rprtItem.notcollected}</Table.Cell>
                    <Table.Cell>{rprtItem.collectedzero}</Table.Cell>
                    <Table.Cell>{rprtItem.collectednotzero}</Table.Cell>
                    <Table.Cell>{rprtItem.total}</Table.Cell>
                    <Table.Cell>{''}</Table.Cell>
                    <Table.Cell>{''}</Table.Cell>
                </Table.Row>
            )
            totalNtcollected += rprtItem.notcollected;
            totalCollectedzero += rprtItem.collectedzero;
            totalCollectedNtzero += rprtItem.collectednotzero;
            totalTotal += rprtItem.total;
        })

        listingReport.push(<Table.Row>
            <Table.Cell>{''}</Table.Cell>
            <Table.Cell><b>{'Total'}</b></Table.Cell>
            <Table.Cell><b>{totalNtcollected}</b></Table.Cell>
            <Table.Cell><b>{totalCollectedzero}</b></Table.Cell>
            <Table.Cell><b>{totalCollectedNtzero}</b></Table.Cell>
            <Table.Cell><b>{totalTotal}</b></Table.Cell>
            <Table.Cell>{''}</Table.Cell>
            <Table.Cell>{''}</Table.Cell>
        </Table.Row>)

        return listingReport
    }

    const arrayReportToCSV = () => {
        let toCSV = [];
        var result = dailyPaymentdata.length > 0 && dailyPaymentdata.map(userData => {
            toCSV.push(userData)
        })
        return toCSV
    }

    const handleSort = (clickedColumn: any) => () => {
        if (column !== clickedColumn) {
            let srtData;
            srtData = dailyPaymentdata.sort((a: any, b: any) => {
                if (clickedColumn === 'practice' || clickedColumn === 'specialist')
                    return a[clickedColumn] > b[clickedColumn] ? 1 : -1
                else
                    return a[clickedColumn] - b[clickedColumn]
            });

            setPaymentReport(srtData)
            setColumn(clickedColumn);
            setDirection('ascending')
            return
        }

        dailyPaymentdata.reverse();
        //setDescending(rvdata)
        setDirection(direction === 'ascending' ? 'descending' : 'ascending');
    };

    return (
        
        <Template activeLink="dailypaymentsReport">
            
            {loader && <CountUpTimer></CountUpTimer>}
            <Card>
                <Card.Content className="pb0">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h2 className="headingMain float-left">Daily Payments Report({totalSum}) <HelpMark
                                    helpPage={HELP_PAGE.BILLING_DAILY_PAYMENTS_REPORT}
                                    application="Billing-PatientFinance-Reports-DailyPaymentReports"/></h2>
                                <div className="topFilters">
                                    <div className="datePicker mb15 mr10">
                                        <MultipleDatePicker
                                            className='newFlow datePicker'
                                            selected={dates[0]}
                                            onSubmit={dateObjects => setDates(dateObjects)}
                                        />
                                    </div>
                                    <Dropdown className='mb15 mr10' placeholder='Practice Filter' search selection
                                              options={practiceList} onChange={(e, {value}) => setPractice(value)}/>
                                    <Dropdown className='mb15 mr10' placeholder='Default Specialist' selection
                                              options={specialists} onChange={(e, {value}) => setSpecialist(value)}/>
                                    <Dropdown className='mb15 mr10' placeholder='Dentistry' selection multiple
                                              options={dentistryOptions}
                                              onChange={(e, {value}) => setDentistry(value)}/>
                                    <Dropdown className='mb15 mr10' placeholder='IsClosed' selection
                                              options={booleanOptionsArrays}
                                              onChange={(e, {value}) => setIsClosed(value)}/>
                                    <Dropdown className='mb15 mr10' placeholder='Loan Type' selection
                                              options={loanTypeOptions} onChange={(e, {value}) => setLoantype(value)}/>
                                    <CSVLink data={arrayReportToCSV()} headers={csvHeaders}
                                             filename={"DailyPaymentsReport.csv"}
                                             className="ui primary button btnPrint mb15 mr-0">
                                        <label> EXPORT </label>
                                    </CSVLink>
                                    {/* <Button primary >SEND</Button> */}

                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
            </Card>

            <Card>
                <Card.Content>

                    <div className="table-responsive FreezeTable">
                        <Table sortable striped unstackable className="customTable">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{'whiteSpace': 'unset'}}
                                                      sorted={column === 'practice' ? direction : null}
                                                      onClick={handleSort('practice')}>
                                        <div>Practice {column !== 'practice' ? <Icon name="sort"/> : ''}</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{'whiteSpace': 'unset'}}
                                                      sorted={column === 'specialist' ? direction : null}
                                                      onClick={handleSort('specialist')}>
                                        <div>Specialists {column !== 'specialist' ? <Icon name="sort"/> : ''}</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{'whiteSpace': 'unset'}}
                                                      sorted={column === 'notcollected' ? direction : null}
                                                      onClick={handleSort('notcollected')}>
                                        <div>Not Collected {column !== 'notcollected' ? <Icon name="sort"/> : ''}</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{'whiteSpace': 'unset'}}
                                                      sorted={column === 'collectedzero' ? direction : null}
                                                      onClick={handleSort('collectedzero')}>
                                        <div>Collected Zero {column !== 'collectedzero' ? <Icon name="sort"/> : ''}</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{'whiteSpace': 'unset'}}
                                                      sorted={column === 'collectednotzero' ? direction : null}
                                                      onClick={handleSort('collectednotzero')}>
                                        <div>Collected Not Zero {column !== 'collectednotzero' ? <Icon name="sort"/> : ''}</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{'whiteSpace': 'unset'}}
                                                      sorted={column === 'total' ? direction : null}
                                                      onClick={handleSort('total')}>
                                        <div>Total {column !== 'total' ? <Icon name="sort"/> : ''}</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell><div># of QA</div></Table.HeaderCell>
                                    <Table.HeaderCell><div># of QA's with Issue</div></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {getTableRows() && getTableRows().map(data => {
                                    return data
                                })}
                            </Table.Body>
                        </Table>
                    </div>
                </Card.Content>
            </Card>

        </Template>
    )

}

export default DailyPaymentsReport