import React from "react";
import {Button, Modal, Input, Header,Select, TextArea, Form} from "semantic-ui-react";
import {useFormik} from "formik"

const SetupModal:React.FC<any>=(props)=>{
    const formik=useFormik(
        {
            initialValues:{
                practice:props.fields.practice,
                specialitstInitials:props.fields.specialitstInitials ,
                odUserName:props.fields.odUserName,
                goal:props.fields.goal,
                userId: props.fields.id


            },
            onSubmit:values => {
                props.submitSetupData(values)
            }
        }
    )
   const  changePractice = (e, a) => {
        if (a.value !== formik.values.practice ) {
            formik.values.practice = a.value && a.value.length > 0 ? a.value : 0 ;

        }
    }
    return (

        <Modal
            open={true}
            onClose={props.hideSetupModal}
            centered={false}
            size='tiny'
            closeIcon
        >
            <Header content={props.fields.firstname +"  "+props.fields.lastname}/>
            <Modal.Content>

                <Form className="formStyle">
                    <label>Practice</label>
                    {props.practices.length ? <Select
                        fluid
                        search multiple selection
                        className="mb10"
                        onChange={(e, a) => changePractice(e, a)}
                        placeholder='Select Practice'
                        options={props.practices}/> : ''}
                    {/*<Input fluid name='practice'    defaultValue={props.fields.practice || ""} onChange={formik.handleChange} value={formik.values.practice}/>*/}
                    <label>Specialists Initial</label>
                    <Input fluid name='specialitstInitials' defaultValue={props.fields.specialitstInitials || ""} onChange={formik.handleChange} value={formik.values.specialitstInitials}/>
                    <label>Od User</label>
                    <Input fluid name='odUserName' defaultValue={props.fields.odUserName || ""} onChange={formik.handleChange} value={formik.values.odUserName}/>
                    <label>Goal</label>
                    <Input fluid name='goal' defaultValue={props.fields.goal || ""} onChange={formik.handleChange} value={formik.values.goal}/>

                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={props.closeSetupModal}>
                    Cancel
                </Button>
                <Button className='ui primary button' onClick={formik.submitForm}>
                    Submit
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default SetupModal