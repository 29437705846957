import React from "react";
import {Button, Modal, Header, Comment, Form, TextArea} from "semantic-ui-react";
import moment from "moment";
import {useFormik} from "formik";


const ViewNotesModal:React.FC<any>=(props)=>{
    const { PatNum, notes} = props.patientData;
    const formik=useFormik(
        {
            initialValues:{
                notes:''
            },
            onSubmit:values => {
                props.submitNotes(values.notes)
            }
        }
    )
    return (
        <Modal
        open={true}
        onClose={props.closeViewNotes}
        centered={true}
        size='tiny'
        closeIcon
    >
      <Header content={`View Notes for Patient Number ${PatNum} `}/>
            <Modal.Content>
                <Comment.Group>
                    {Array.isArray(notes) ? notes.map(item => {
                            return(
                                <Comment>
                                    <Comment.Content>
                                        <Comment.Author>{item.user ? item.user.firstname + ' ' + item.user.lastname : ''}</Comment.Author>
                                        <Comment.Metadata>
                                            <div>{moment(item.createdAt).fromNow()}{}</div>
                                        </Comment.Metadata>
                                        <Comment.Text>{item.notes}</Comment.Text>
                                    </Comment.Content>
                                </Comment>
                            )
                        }) :
                        <Comment>
                            <Comment.Content>
                                <Comment.Text>{notes}</Comment.Text>
                            </Comment.Content>
                        </Comment>
                    }
                </Comment.Group>
                {Array.isArray(notes) &&
                    <Form className="formStyle">
                        <label>Add Note</label>
                        <TextArea rows={6} fluid name='notes' placeholder='Notes' onChange={formik.handleChange} value={formik.values.notes}/>
                    </Form>
                }
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={props.closeViewNotes}>
                    Cancel
                </Button>
                {Array.isArray(notes) &&
                    <Button className='ui primary button' onClick={formik.submitForm}>
                        Submit
                    </Button>
                }
            </Modal.Actions>

        </Modal>
    )
}

export default ViewNotesModal