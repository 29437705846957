import React ,{useState}from "react";
import { Modal, Table ,Button ,Checkbox} from "semantic-ui-react";
import moment from "moment/moment";

interface Props {
    data: any;
    closePopup: () => void;
    sendCSplitTotal:Function;
}

const CSplitGrandTotal: React.FC<Props> = ({ data ,closePopup,sendCSplitTotal }) => {
    const [practices] = useState(Object.keys(data&&data.cashSplitData?data.cashSplitData:[]));
    const [cashSplitState,setProductionState] = useState({
        productionData:data.productionData,
        cashSplitData:data.cashSplitData,
        levelOneModal:true,
        levelTwoModal:false
    })

    const selectedMonths = Object.keys(data.productionData);
    const [ showHideData ,setCheckBoxes] = useState<any>({isDollars:true , isTTL:true ,isGP:true});
    const grossProdTotal=Object.values(cashSplitState.productionData || {}).reduce((acc: number, value: any) => {
        return acc + value.production}, 0);
    let grandSum = 0;
    const grandValues = Object.values(cashSplitState.productionData)
    for(let initialNum = 0 ; initialNum < Object.keys(cashSplitState.productionData).length ;initialNum++) {
        const obj = Object.assign({Cash_Split:0},grandValues[initialNum])
        const absAdjValue =Math.abs(obj.Cash_Split)
        grandSum+=absAdjValue

    }
    const renderCSplitGrand = ()=>{
            return (
                <Table celled size="small">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Month</Table.HeaderCell>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.HeaderCell>% of Total Cash_Split</Table.HeaderCell>
                            <Table.HeaderCell>% of Gross Production</Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { Object.entries(cashSplitState.productionData).map(([month,data]:[string,{Cash_Split:number}] )=>
                            <Table.Row key={month} >
                                <Table.Cell>{moment(month + '-01').format('MMM YYYY')}</Table.Cell>
                                <Table.Cell> {"$"+ Math.abs(data.Cash_Split).toFixed(2)}</Table.Cell>
                                <Table.Cell>{Math.abs(data.Cash_Split/grandSum *100).toFixed(2)+"%"}</Table.Cell>
                                <Table.Cell>{Math.abs(data.Cash_Split/Number(grossProdTotal)*100).toFixed(2)+"%"}</Table.Cell>
                            </Table.Row>
                        )}
                        <Table.Row>
                            <Table.Cell><b> Grand Total</b></Table.Cell>
                            <Table.Cell><b>$ {grandSum.toFixed(2)}</b></Table.Cell>
                            <Table.Cell>{"100%"}</Table.Cell>
                            <Table.Cell>{Math.abs(grandSum/Number(grossProdTotal)*100).toFixed(2)+"%"}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            )
    }

    const renderCashSplitL2GT=()=>{
        const {cashSplitData,productionData}=cashSplitState;
        let objTotals={}
        practices && practices.map((practice)=>{
            const adjTotal = Object.keys(cashSplitData[practice]).length;
            const adjValues =Object.values(cashSplitData[practice]);
            let practotal = 0 ;
            for(let init = 0 ; init < adjTotal ;init++) {
                const obj = Object.assign({total:0},adjValues[init])
                const absAdjValue =Math.abs(obj.total)
                practotal+=absAdjValue

            }
            objTotals[practice]={}
            objTotals[practice]["total"]=practotal;
            selectedMonths.forEach((month)=>{
                if(!objTotals[practice[month]]){
                    objTotals[practice][month]={}
                }

                objTotals[practice][month]["total"]=cashSplitData[practice][month]?cashSplitData[practice][month].total:0;
                objTotals[practice][month]["percentageOfTotal"]=cashSplitData[practice][month]?(cashSplitData[practice][month].total/practotal *100).toFixed(2):0;
                objTotals[practice][month]["percentageOfGrossProduction"]=cashSplitData[practice][month]?(cashSplitData[practice][month].total/Number(grossProdTotal) *100).toFixed(2):0;
            })

        });

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell/>
                        {practices && practices.map((prac)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        key={prac} colspan="1">{prac} </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">Total</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Months</Table.HeaderCell>
                        {practices && practices.map((practice , )=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        colspan="1" key={practice}>
                                {showHideData.isDollars?"$":""}
                                {showHideData.isTTL ?" (%TTL)":""}
                                {showHideData.isGP?"(%GP)":""}
                            </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">
                            {showHideData.isDollars?"$":""}
                            {showHideData.isTTL ?" (%TTL)":""}
                            {showHideData.isGP?"(%GP)":""}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {selectedMonths.map((mnth:string) => (

                        <Table.Row key={mnth}>
                            <Table.Cell key={mnth}>{moment(mnth + '-01').format('MMM YYYY')}</Table.Cell>
                            {practices && practices.map((practize)=>
                                objTotals[practize] && objTotals[practize][mnth]?
                                <Table.Cell colspan="1" key={practize}>
                                    {showHideData.isDollars?(objTotals[practize][mnth].total).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD"
                                    }):""}  {showHideData.isTTL?"("+Math.abs(objTotals[practize][mnth]["percentageOfTotal"])+"%"+")":""}
                                    {showHideData.isTTL?"("+Math.abs(objTotals[practize][mnth]["percentageOfGrossProduction"])+"%"+")":""}
                                </Table.Cell>:
                                    <Table.Cell colspan="1" key={practize}>
                                        {showHideData.isDollars?(0).toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }):""} {showHideData.isTTL?"(0.00%)":""}{showHideData.isGP?"(0.00%)":""}
                                    </Table.Cell>

                            )}
                            <Table.Cell>{showHideData.isDollars?"$"+Math.abs(productionData[mnth].Cash_Split).toFixed(2):""}
                                {showHideData.isTTL?"("+Math.abs(productionData[mnth].Cash_Split/grandSum * 100).toFixed(2)+"%)":""}
                                {showHideData.isGP?"("+Math.abs(productionData[mnth].Cash_Split/Number(grossProdTotal) * 100).toFixed(2)+"%)":""}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    <Table.Row>
                        <Table.Cell><b>Grand Total</b></Table.Cell>
                        {practices && practices.map((practize)=>
                            objTotals[practize]?
                            <Table.Cell colspan="1" key={practize}>
                                {showHideData.isDollars?(objTotals[practize].total).toLocaleString("en-US", { style: "currency",currency: "USD"}):""}
                                <b>{showHideData.isTTL?"("+Math.abs(objTotals[practize].total/grandSum*100).toFixed(2)+"%)":""}
                                    {showHideData.isGP?"("+Math.abs(objTotals[practize].total/Number(grossProdTotal)*100).toFixed(2)+"%)":""}
                                </b>
                            </Table.Cell>:
                                <Table.Cell colspan="1" key={practize}>
                                    {showHideData.isDollars?(0).toLocaleString("en-US", { style: "currency",currency: "USD"}):""}
                                    <b>{showHideData.isTTL?"(0.00%)":""} {showHideData.isGP?"(0.00%)":""}</b>
                                </Table.Cell>

                        )}
                        <Table.Cell>{showHideData.isDollars?"$"+grandSum.toFixed(2):""}
                            <b>{showHideData.isTTL?"(100%)":""}  </b>
                            <b>{showHideData.isGP?"("+Math.abs(grandSum/Number(grossProdTotal)*100).toFixed(2)+"%)":""}</b>
                        </Table.Cell>

                    </Table.Row>
                    {}

                </Table.Body>
            </Table>
        );
    }


    const handleLevelChange =() => {
        setProductionState({...cashSplitState,levelOneModal:!cashSplitState.levelOneModal , levelTwoModal:!cashSplitState.levelTwoModal});
    }

    const handleCheckBoxes =(name:string) => {
        if(name==="dollars"){
            setCheckBoxes({...showHideData , isDollars:!showHideData.isDollars})
        }
        if(name==="%ttl"){
            setCheckBoxes({...showHideData , isTTL:!showHideData.isTTL})
        }
        if(name==="%gp"){
            setCheckBoxes({...showHideData,isGP:!showHideData.isGP})
        }
    }



    return (
        <Modal
            open={true}
            onClose={closePopup}
            centered={true}
            closeIcon
        >
            <Modal.Header>Cash Split Grand Total</Modal.Header>
            {cashSplitState.levelOneModal&&<Modal.Content>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell  colSpan='1'> Months/years</Table.HeaderCell>
                            <Table.HeaderCell colSpan='1'> Practices</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>{moment(selectedMonths[selectedMonths.length-1] + '-01').format('MMM YYYY')}  thru  {moment(selectedMonths[0] + '-01').format('MMM YYYY')} </b></Table.Cell>
                            <Table.Cell width={3} content={practices&&practices.toString()} > {practices&&practices.toString()}</Table.Cell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        <Table.Row>
                            {renderCSplitGrand()}
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer>

                    </Table.Footer>
                </Table>
            </Modal.Content>}
            {cashSplitState.levelTwoModal&&<Modal.Content>
                <div className="FreezeTable freezeHeight">
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell  colSpan='1'>
                                    Months/years <Checkbox  label={showHideData.isDollars?"Hide $" : "Show $"}  toggle className="ml20" checked={showHideData.isDollars} onChange={() => handleCheckBoxes("dollars")} />
                                    <Checkbox  label={showHideData.isTTL ?"Hide %TTL" : "Show %TTL"} toggle  className="ml20" checked={showHideData.isTTL} onChange={() => handleCheckBoxes("%ttl")}/>
                                    <Checkbox  label={showHideData.isGP ?"Hide %GP" : "Show %GP"} toggle  className="ml20" checked={showHideData.isGP} onChange={() => handleCheckBoxes("%gp")}/>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><b>{moment(selectedMonths[selectedMonths.length-1] + '-01').format('MMM YYYY')}  thru  {moment(selectedMonths[0] + '-01').format('MMM YYYY')} </b></Table.Cell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                {renderCashSplitL2GT()}
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </Modal.Content>}

            <Modal.Actions>
                {cashSplitState.levelTwoModal &&<> <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L1
                </Button>
                    <Button className="mr10" primary onClick={()=>sendCSplitTotal('level2',showHideData)} >
                        Send Email
                    </Button>
                </>}
                {cashSplitState.levelOneModal &&<><Button className="mr10" primary onClick={handleLevelChange}>
                    Details L2
                </Button>
                    <Button className="mr10" primary onClick={()=>sendCSplitTotal('level1')} >
                        Send Email
                    </Button>
                </>}

            </Modal.Actions>
        </Modal>
    );
}

export default CSplitGrandTotal;
