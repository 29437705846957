import React, {useEffect, useState} from "react";
import "semantic-ui-css/semantic.min.css";
import {Grid, Dropdown, Input, Table, Tab, Button, Popup, Icon, Dimmer, Loader, Card} from "semantic-ui-react";
import * as userQAIssuesActions from "../redux/actions/QAIssues"
import {useActions} from "../redux/actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {RootState} from "../model";
import {connect} from "react-redux";
import Template from "./Template";
import _ from "lodash";
import moment from "moment";
import ViewNotesModal from "./ViewNotesModal";
import * as newflowActions from "../redux/actions/newflow";
import EditQAStatusNewFlowModal from "./EditQAStatusNewFlowModal";
import * as setupActions from "../redux/actions/setup"
import {MemberStatus} from "../model/claims";
import HelpMark from "./Help/helpMark"
import {HELP_PAGE} from "../constants";
import CountUpTimer from "./CountUpTimer";

const customMoment = moment;

interface QAIssuesData {
    userQAIssuesData: any,
    column: string,
    direction: any,
    storedata: any,
    practiceList: any,
    selectedPrac: any,
    filters: any,
    QAStatusList: any,
    formData: any,
    editForm: any,
    tempData: any

}

const QAIssues: React.FC<any> = () => {
    const initialState: QAIssuesData = {
        userQAIssuesData: {},
        column: null,
        direction: null,
        storedata: [],
        practiceList: [],
        selectedPrac: null,
        QAStatusList: [],
        formData: null,
        editForm: false,
        tempData: {},
        filters: {ChargeDate: [Date.now()], searchPatNum: ''},

    };
    const setupAction = useActions(setupActions);
    const newflowAction = useActions(newflowActions);
    const userQAIssuesAction = useActions(userQAIssuesActions);
    const [CurrentNewflowdataItem, setCurrentNewflowdataItem] = useState({})
    const [state, setStates] = useState(initialState);
    const [CanQAStatus, setCanQAStatus] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [loader, setLoader] = useState(false);
    const [dates, setDates] = useState<Date[] | null>([new Date(moment(startDate).format("YYYY-MM-DD")), new Date(moment(startDate).subtract(6, 'months').format("YYYY-MM-DD"))]);
    const [viewNotes, setViewNotes] = useState(false);
    const [patientData, setPatientData] = useState({});

    useEffect(() => {
        const getItems = async () => {

            newflowAction.getYearlyNewFlowData(dates)
                .then(({data, practiceListArray}) => {
                    let practices = [{key: 'All', value: 'All', text: 'All'}]
                    practiceListArray && practiceListArray.length > 0 && practiceListArray.map((practice: any) => {
                        practices.push({
                            key: practice,
                            value: practice,
                            text: practice
                        });
                    });
                    setStates({
                        ...state,
                        userQAIssuesData: data,
                        tempData: data,
                        storedata: data,
                        practiceList: practices
                    });
                    setLoader(false);
                });
        }

        setTimeout(function () {
            getItems()
            fetchData();
        }, 1000)
    }, [])
    const fetchData = async () => {
        setLoader(true)

    }
    const submitQAStatusIssueData = (formInputFeilds) => {
        setStates({...state, editForm: false})
        const payload = {

            QAStatus: formInputFeilds.QAStatus,
            Category: formInputFeilds.Category,
            ChargeAmt: formInputFeilds.ChargeAmt,
            ChargeDate: formInputFeilds.ChargeDate,
            DatePay: formInputFeilds.DatePay,
            DateStart: formInputFeilds.DateStart,
            DateStop: formInputFeilds.DateStop,
            Dentistry: formInputFeilds.Dentistry,
            Interest: formInputFeilds.Interest,
            IsClosed: formInputFeilds.IsClosed,
            PP_Balance: formInputFeilds.PP_Balance,
            PP_Status: formInputFeilds.PP_Status,
            PP_line_note: formInputFeilds.PP_line_note,
            PatNum: formInputFeilds.PatNum,
            PayNote: formInputFeilds.PayNote,
            PayPlanNum: formInputFeilds.PayPlanNum,
            Practice: formInputFeilds.Practice,
            Principal: formInputFeilds.Principal,
            SplitAmt: formInputFeilds.SplitAmt,
            UserName: formInputFeilds.UserName,
            notelong: formInputFeilds.notelong,

        }
        const AsyncSubmit = async () => {

            setupAction.SubmitQAStatusIssueData(payload)
            setCanQAStatus(false)
        }
        AsyncSubmit()
    }
    const handleDateChange = async (date: any) => {
        const filters = state.filters;
        setStartDate(date)
        setDates(date)
        filters['searchPatNum'] = '';
        setStates({...state, filters: filters});
        setLoader(true);
        const asyncSubmit = async () => {
            setLoader(true);
            newflowAction.getYearlyNewFlowData([new Date(moment(date).format("YYYY-MM-DD")), new Date(moment(date).subtract(1, 'months').format("YYYY-MM-DD"))])
                .then(({data, practiceListArray}) => {
                    let practices = [{key: 'All', value: 'All', text: 'All'}]
                    practiceListArray && practiceListArray.length > 0 && practiceListArray.map((practice: any) => {
                        practices.push({
                            key: practice,
                            value: practice,
                            text: practice
                        });
                    });
                    setStates({...state, userQAIssuesData: data, storedata: data, practiceList: practices});
                    setLoader(false);
                });
        }
        setTimeout(function () {
            asyncSubmit().then()
        }, 1000)

    }
    const onchangePractice = (e, {value}, storedata = null) => {
        let returnFlag = true;
        if (!storedata) {
            storedata = state.userQAIssuesData;
            returnFlag = false;
        }

        let result = {};
        if (value && value != 'All') {
            if (state.selectedPrac !== value) {
                result[value] = state.tempData[value]
            } else {
                result[value] = state.userQAIssuesData;
            }

        } else {
            result = state.tempData
        }

        if (returnFlag) {
            return result;
        }
        setStates({...state, userQAIssuesData: result, selectedPrac: value})
    }

    const handleSort = (clickedColumn: any) => () => {

    };
    const Search = (e) => {
        onChangeFilter({}, {searchPatNum: 'searchPatNum', value: e.target.value});
    }
    const onChangeFilter = (e, element) => {
        let filters = state.filters;
        filters[element.searchPatNum] = element.value;
        applyFilter(filters);
    }
    const applyFilter = (filters) => {
        let data = state.userQAIssuesData;
        let keyPractices = data && Object.keys(data).length > 0 && Object.keys(data);
        if (filters && Object.keys(filters).length) {
            Object.keys(filters).forEach(filter => {
                keyPractices.forEach(practiceName => {
                    data[practiceName] = data[practiceName].filter(function (cardObject) {
                        return (cardObject.PatNum.toString().indexOf(filters['searchPatNum']) >= 0)
                    })

                })
            })

        }
        setStates({...state, filters: filters, userQAIssuesData: data})


    }

    const hideSetupModal = () => {
        setCanQAStatus(false)
    }

    const closeSetupModal = () => {
        setCanQAStatus(false)
    }

    const openSetupModal = (currentItem: MemberStatus, id, index) => {
        const newflowdataEdit = state.userQAIssuesData.map(item => {
            if (item.id === id) {
                return item
            }
        })
        setStates({...state, formData: newflowdataEdit[index]})
        setCurrentNewflowdataItem(currentItem)
        setCanQAStatus(true)

    }
    const openViewNotes = (notelong, PatNum) => {

        setPatientData({notes: notelong, PatNum: PatNum})
        setViewNotes(true);
    }

    const closeViewNotes = () => {
        setViewNotes(false);
    }

    const getTableRows = () => {
        const listRows = [];
        let keyPractices = state.userQAIssuesData && Object.keys(state.userQAIssuesData).length > 0 && Object.keys(state.userQAIssuesData)
        keyPractices && keyPractices.map(praticeName => {
            if (state.userQAIssuesData[praticeName].length) {
                state.userQAIssuesData[praticeName].map((data, index) => {
                    if ((data['QAStatus'] !== undefined) ? data['QAStatus'].includes('COMPLETED') ? false : true : true) {
                        listRows.push(
                            <Table.Row>
                                <Table.Cell>
                                    {praticeName}
                                </Table.Cell>
                                <Table.Cell>
                                    {data['DatePay']}
                                </Table.Cell>
                                <Table.Cell>
                                    {data['PayPlanNum']}
                                </Table.Cell>
                                <Table.Cell>
                                    {data['PatNum']}
                                </Table.Cell>
                                <Table.Cell>
                                    {data['PayAmt']}
                                </Table.Cell>
                                <Table.Cell>
                                    {data['SplitAmt']}
                                </Table.Cell>
                                <Table.Cell>
                                    {data['UserName']}
                                </Table.Cell>
                                <Table.Cell>
                                <span onClick={() => openViewNotes(data['PayNote'], data['PatNum'])}
                                      className='cursorPointer'>
                        <a>view</a></span>
                                </Table.Cell>
                                <Table.Cell>
                                    {data['PP_line_note']}
                                </Table.Cell>
                                <Table.Cell>
                                    {data['IsClosed']}
                                </Table.Cell>
                                <Table.Cell>
                                    {data['Category']}
                                </Table.Cell>
                                <Table.Cell>
                                    {<span>{(data['QAStatus'] !== undefined) ? data['QAStatus'].includes('COMPLETED') ?
                                            <Icon name="check circle" color="green"/> : <Icon name="cancel" color="red"/> :
                                        <Icon name="cancel" color="red"/>}</span>}

                                </Table.Cell>
                            </Table.Row>)
                    }


                })
            }

        })

        return listRows;
    }

    return (
        <div className="calendar-container">
            {loader && <CountUpTimer></CountUpTimer>}
            <Card>
                <Card.Content className="pb0">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h2 className="headingMain float-left">PAY PLAN QA ISSUES
                                    (<span>{getTableRows() && getTableRows().length}</span>)<HelpMark
                                        helpPage={HELP_PAGE.BILLING_QA_ISSUES} 
                                        application="Billing-PatientFinance-PayPlanQAIssues"/></h2>
                                <div className="topFilters">
                                    <Dropdown className='mb15 mr10' placeholder='Practice Filter' search
                                              selection
                                              options={state.practiceList} onChange={onchangePractice}/>
                                    <Input className='mb15' onChange={(e) => Search(e)}
                                           placeholder="Search Patient Number"
                                           value={state.filters.searchPatNum || ''}/>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <div className="table-responsive FreezeTable">
                        <Table sortable striped unstackable className="customTable">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell sorted={state.column === 'Practice' ? state.direction : null}
                                                      onClick={handleSort('Practice')}>
                                        <div>
                                        Practice {state.column !== 'Practice' ? <Icon name="sort"/> : ''}
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>      <div>Charge Date</div></Table.HeaderCell>
                                    <Table.HeaderCell sorted={state.column === 'PayPlanNum' ? state.direction : null}
                                                      onClick={handleSort('PayPlanNum')}>
                                        <div>Pay Plan Number</div> {state.column !== 'PayPlanNum' ? <Icon name="sort"/> : ''}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell sorted={state.column === 'PatNum' ? state.direction : null}
                                                      onClick={handleSort('PatNum')}>
                                        <div>Patient Number</div> {state.column !== 'PatNum' ? <Icon name="sort"/> : ''}
                                    </Table.HeaderCell>

                                    <Table.HeaderCell sorted={state.column === 'PayAmt' ? state.direction : null}
                                                      onClick={handleSort('PayAmt')}>
                                        <div>Pay Amount</div> {state.column !== 'PayAmt' ? <Icon name="sort"/> : ''}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell sorted={state.column === 'SplitAmt' ? state.direction : null}
                                                      onClick={handleSort('SplitAmt')}>
                                        <div>Split Amount</div> {state.column !== 'SplitAmt' ? <Icon name="sort"/> : ''}
                                    </Table.HeaderCell>

                                    <Table.HeaderCell>      <div>User Name</div></Table.HeaderCell>
                                    <Table.HeaderCell sorted={state.column === 'PayNote' ? state.direction : null}
                                                      onClick={handleSort('PayNote')}>
                                        <div>Pay Note</div> {state.column !== 'PayNote' ? <Icon name="sort"/> : ''}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell sorted={state.column === 'PP_line_note' ? state.direction : null}
                                                      onClick={handleSort('PP_line_note')}>
                                        <div>Pay Plan Note</div> {state.column !== 'PP_line_note' ? <Icon name="sort"/> : ''}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell><div>Is Closed</div></Table.HeaderCell>
                                    <Table.HeaderCell><div>Category</div></Table.HeaderCell>
                                    <Table.HeaderCell><div>QA Status</div></Table.HeaderCell>

                                </Table.Row>
                            </Table.Header>
                            <Table.Body>


                                {
                                    getTableRows() && getTableRows().map(data => {
                                        return data
                                    })

                                }
                            </Table.Body>
                        </Table>
                        {CanQAStatus &&
                            <EditQAStatusNewFlowModal QAStatusList={state.QAStatusList} fields={state.formData}
                                                      hideSetupModal={hideSetupModal} closeSetupModal={closeSetupModal}
                                                      submitQAStatusIssueData={submitQAStatusIssueData}/>}

                        {viewNotes && <ViewNotesModal patientData={patientData} closeViewNotes={closeViewNotes}/>}
                    </div>
                </Card.Content>
            </Card>
        </div>
    )
}

function mapStateToProps(state: RootState) {
    return {
        userPaymentAmountData: state.rtp1.userPaymentAmountData,

    };
}

export default connect(mapStateToProps)(QAIssues);
