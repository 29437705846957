
import { ActionType } from "../../model/actionTypes";

  export const getSections = (pageId:any) => async (dispatch:Function, getState:Function, api:any) => {
    try {
      const res = await api.get(`help/sections/${pageId}`);
      if (res) {
        dispatch({
            type: ActionType.GET_HELP_SECTIONS,
            payload: { result : res} });
        return res;
      }
    } catch (err) {
        dispatch({ type: ActionType.GET_HELP_SECTIONS_FAIL, error: err.message });
        return false;
    }
  };

