import React from "react";
import "semantic-ui-css/semantic.min.css";
import {RootState} from "../model";
import {connect} from "react-redux";
import Template from "./Template";

import ClaimsForm from "./ClaimsForm";

const Claims = () => {
    return (
        <Template activeLink="claims">
          <ClaimsForm key="main-claims"  />
        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        memberStatus: state.claims.memberStatus,
        memberStatusCount: state.claims.memberStatusCount,
        practiceList: state.app.practiceList,
        lastFetched: state.claims.lastFetched
    };
}

export default connect(mapStateToProps)(Claims);
