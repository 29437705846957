import { ActionType } from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const addSetupAction =
    (data: any) => async (dispatch: Function, getState: Function, api: any) => {
        const url = `patientFinance/addSetupAction`;
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp = await api.post(url, data);
            dispatch({ type: ActionType.SAVE_SETUP_ACTION, payload: { success: resp } });
            dispatch({ type: ActionType.LOADER, payload: false });
        } catch (error) {
            dispatch({ type: ActionType.LOADER, payload: false });
            return dispatch(errorHandler(error));
        }
    };

export const getSetupData = (type:any) => async (dispatch:Function, getState:Function, api:any) => {
        dispatch({ type: ActionType.LOADER });
        try {
            let res = await api.get(`patientFinance/getSetup/${type}`);
            dispatch({ type: ActionType. GET_SETUP,payload: {
                setupAData : res 
    
            } });
            return res;
        } catch (err) {
            dispatch({ type: ActionType.SETUP_FAIL, error: err.message });
            return false;
        }
 };

 export const updateSetup = (obj:any) => async (dispatch: Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD });    
    try {
        let res = await api.put('patientFinance/updateSetup', obj);
        dispatch({ type: ActionType.UPDATE_SETUP, payload: { success: res } });
        return res;
    } catch (err) {
        dispatch({ type: ActionType.SETUP_FAIL, error: err.message });
    }
};

export const deleteSetup = (obj:any) => async (dispatch: Function, getState:Function, api:any) => {
    dispatch({ type: ActionType.LOAD });    
    try {
        let res = await api.put('patientFinance/deleteSetup', obj);
        dispatch({ type: ActionType.DELETE_SETUP, payload: { success: res } });
        return res;
    } catch (err) {
        dispatch({ type: ActionType.SETUP_FAIL, error: err.message });
    }
};