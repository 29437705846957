import React from "react";
import {Button, Modal, Header, Table} from "semantic-ui-react";
import MemberCountSummary from "./MemberCountSummary";

const SummaryModal:React.FC<any>=(props)=>{

    const handleStatusClick = (status) => {
        props.setStatusFilter(status);
        props.setCurrentPractice("All");
        props.hideSummaryModal();
    }
    return (
        <Modal
            open={true}
            onClose={props.hideSummaryModal}
            centered={false}
            size='tiny'
            closeIcon
        >
            <Header content='Claim Status Summary'/>
            <Modal.Content>
               <MemberCountSummary
                   summary = {props.summary}
                   handleStatusClick = {handleStatusClick}
               />
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={props.hideSummaryModal}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default SummaryModal