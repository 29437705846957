import React from "react"
import {Redirect,Route} from "react-router"
import {PrivateRoute, PrivateRouteProps} from "./components/PrivateRoute"
import Home from "./container/Home"
import SignIn from "./components/SignIn"
import LoginForm from "./components/LoginForm";
import Test from "./components/Test"
import Claims from "./components/Claims"
import ClaimsAgedSummary from "./components/ClaimsAgedSummary"
import NewFlow from "./components/Newflow"
import UsageTable from "./components/UsageTable";
import EmployeeUsageTable from "./components/EmployeeUsageTable";
import NonEmployeeUsageTable from "./components/NonEmployeeUsageTable";
import PatientFinanceSetup from "./container/Setup";
import CollectionReportsContainer from "./container/CollectionReportsContainer"
import SetupPPLQA from "./container/SetupPPLQA";
import SetupActions from "./components/SetupActions";
import PayPlanQAIssues from "./container/QAIssue";
import SetupStatus from "./components/SetupStatus";
import SetupXcharge from "./components/SetupXcharge"
import DailyPaymentsReport from "./components/DailyPaymentsReport"
import ProductionCollection from "./components/Collections/ProductionCollection";
import DataVerification from "./components/Collections/DataVerification"
import DentalXchangeStatusReport from "./components/DentalXchangeStatusReport";
import ClaimsForm from "./components/ClaimsForm";


const AppRoutes:React.FC<any>=(props)=>{
    const isAuthenticated = !!(props.auth && props.auth.user && props.auth.user.id);
    const defaultProtectedRouteProps: PrivateRouteProps = {
        isAuthenticated: isAuthenticated,
        authenticationPath: "/"
    };
    const queryParams :any= new URLSearchParams(window.location.search);
    const page = queryParams.get("page")?"/"+queryParams.get("page"):'';
    const defaultPage =props.auth&&props.auth.user&&props.auth.user.defaultPage||page;
    const RedirectTo=(props:any)=>isAuthenticated?<Redirect to = {defaultPage?defaultPage:"/dashboard"}/> : <SignIn {...props} />
    return (
        <>
          <Route
            exact={true}
            path="/"
            render={props=>RedirectTo(props)}
            />
            <Route
                exact={true}
                path="/cdpLogin/:token"
                component={Home}
            />
               <Route
                exact={true}
                path="/login"
                component={LoginForm}
            />
                <PrivateRoute
               {...defaultProtectedRouteProps}
               exact={true}
               path="/claims"
               component={Claims}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/claims-form"
                component={ClaimsForm}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/claims-aged-summary"
                component={ClaimsAgedSummary}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/dentalxchange-status-report"
                component={DentalXchangeStatusReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/dashboard"
                component={Test}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/patientfinance/newflow"
                component={NewFlow}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/patientfinance/qaissues"
                component={PayPlanQAIssues}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/patientfinance/setup/actions"
                component={SetupActions}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/patientfinance/setup/status"
                component={SetupStatus}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/patientfinance/setup/pplqa"
                component={SetupPPLQA}
            />
             <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/patientfinance/setup/xcharge"
                component={SetupXcharge}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/patientfinance/setup/assignments"
                component={PatientFinanceSetup}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/patientfinance/collectionReports"
                component={CollectionReportsContainer}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/usage/universal"
                component={UsageTable}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/usage/employee"
                component={EmployeeUsageTable}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/usage/non"
                component={NonEmployeeUsageTable}
            />
            <PrivateRoute
            {...defaultProtectedRouteProps}
            exact={true}
            path="/patientfinance/reports/dailypayments"
            component={DailyPaymentsReport}
            />

            <PrivateRoute
            {...defaultProtectedRouteProps}
            exact={true}
            path="/collections/production_collection"
            component={ProductionCollection}
            />

            <PrivateRoute
            {...defaultProtectedRouteProps}
            exact={true}
            path="/collections/dataverification"
            component={DataVerification}
            />
        </>
    )
}
export default AppRoutes
