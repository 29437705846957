import React from "react";
import { useState } from "react";
import { Modal, Table ,Button ,Checkbox} from "semantic-ui-react";
import moment from "moment/moment";

interface Props {
    data: any;
    closePopup: () => void;
    sendTotal:Function;
}

const GrandTotalPopup: React.FC<Props> = ({ data ,closePopup,sendTotal }) => {
    const [practices] = useState(Object.keys(data&&data.grossProdData?data.grossProdData:[]));
    const [gProductionState,setProductionState] = useState({
            productionData:data.productionData,
            grossProdData:data.grossProdData,
            grandTotalPractices:data.grandTotalPractices,
            levelOneModal:true,
            levelTwoModal:false
        })

     const selectedMonths = Object.keys(data.productionData);
    const [ showhideData ,setCheckBoxes] = useState<any>({isDollars:true , isTTL:true });

    const renderWriteoff = () => {
        let grandSum = 0
        const totalObjects = Object.keys(gProductionState.productionData).length;
        const grandValues = Object.values(gProductionState.productionData)
         for(let initialnum = 0 ; initialnum < totalObjects ;initialnum++) {
            const obj = Object.assign({production:0},grandValues[initialnum])
            const absAdjValue =Math.abs(obj.production)
            grandSum+=absAdjValue  
            
         }
        return (
            <Table celled size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Month</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>

                    </Table.Row>
                </Table.Header>
                <Table.Body>
                  { Object.entries(gProductionState.productionData).map(([month,data]:any , indx)=>
                     <Table.Row key={indx} >
                         <Table.Cell>{moment(month + '-01').format('MMM YYYY')}</Table.Cell>
                         <Table.Cell> {"$"+ Math.abs(data.production).toFixed(2)}</Table.Cell>
                       </Table.Row>
                  )}
                  <Table.Row>
                    <Table.Cell><b> Grand Total</b></Table.Cell>
                    <Table.Cell><b>$ {grandSum.toFixed(2)}</b></Table.Cell>
                  </Table.Row>



                </Table.Body>
            </Table>
        );
    }

    const renderProductionGT=()=>{
        const {productionData , grossProdData}=gProductionState;
        let grandSum = 0
        const totalObjects = Object.keys(productionData).length;
        const grandValues = Object.values(productionData)
        for(let initialnum = 0 ; initialnum < totalObjects ;initialnum++) {
            const obj = Object.assign({production:0},grandValues[initialnum])
            const absAdjValue =Math.abs(obj.production)
            grandSum+=absAdjValue

        }

        let objTotals={}
        practices && practices.map((practice)=>{
            const adjTotal = Object.keys(grossProdData[practice]).length;
            const adjValues =Object.values(grossProdData[practice])
            let practotal = 0 ;
            for(let init = 0 ; init < adjTotal ;init++) {
                const obj = Object.assign({total:0},adjValues[init])
                const absAdjValue =Math.abs(obj.total)
                practotal+=absAdjValue

            }

            objTotals[practice]={}
            objTotals[practice]["total"]=practotal;
            selectedMonths.forEach((month)=>{
                if(!objTotals[practice[month]]){
                    objTotals[practice][month]={}
                }

                objTotals[practice][month]["total"]=grossProdData[practice][month]?grossProdData[practice][month].total:0;
                objTotals[practice][month]["percentageOfTotal"]=grossProdData[practice][month]?(grossProdData[practice][month].total/practotal *100).toFixed(2):0;

            })
        });

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell/>
                        {practices && practices.map((prac , idx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        key={idx} colspan="1">{prac} </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">Total</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Months</Table.HeaderCell>
                        {practices && practices.map((practice , indx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        colspan="1" key={indx}>
                                {showhideData.isDollars?"$":""}
                                {showhideData.isTTL ?" (%TTL)":""}
                            </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">
                            {showhideData.isDollars?"$":""}
                            {showhideData.isTTL ?" (%TTL)":""}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {selectedMonths.map((mnth:string,indx:number) => (

                        <Table.Row key={indx}>
                            <Table.Cell key={indx}>{moment(mnth + '-01').format('MMM YYYY')}</Table.Cell>
                            {practices && practices.map((practize , indx)=>
                                <Table.Cell colspan="1" key={indx}>
                                    {showhideData.isDollars?(objTotals[practize] && objTotals[practize][mnth]?objTotals[practize][mnth].total:0).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD"
                                    }):""}  {showhideData.isTTL?objTotals[practize] && objTotals[practize][mnth]?"("+Math.abs(objTotals[practize][mnth]["percentageOfTotal"])+"%"+")":"(0.00%)":""}
                                </Table.Cell>
                            )}
                            <Table.Cell>{showhideData.isDollars?"$"+Math.abs(productionData[mnth].production).toFixed(2):""}
                                {showhideData.isTTL?"("+Math.abs(productionData[mnth].production/grandSum * 100).toFixed(2)+"%"+")":""}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    <Table.Row>
                        <Table.Cell><b>Grand Total</b></Table.Cell>
                        {practices && practices.map((practize , indx)=>
                            <Table.Cell colspan="1" key={indx}>
                                {showhideData.isDollars?(objTotals[practize]?objTotals[practize].total:0).toLocaleString("en-US", { style: "currency",currency: "USD"}):""}
                                <b>{showhideData.isTTL?objTotals[practize]?"("+Math.abs(objTotals[practize].total/grandSum*100).toFixed(2)+"%)":"0.00%":""}
                                    </b>
                            </Table.Cell>
                        )}
                        <Table.Cell>{showhideData.isDollars?"$"+grandSum.toFixed(2):""}
                            <b>{showhideData.isTTL?"(100.00%)":""}  </b>
                        </Table.Cell>

                    </Table.Row>
                    {}

                </Table.Body>
            </Table>
        );
    }

    const handleLevelChange =() => {
        setProductionState({...gProductionState,levelOneModal:!gProductionState.levelOneModal , levelTwoModal:!gProductionState.levelTwoModal});
    }

    const handleCheckBoxes =(e:object , name:string) => {
        if(name=="dollars"){
            setCheckBoxes({...showhideData , isDollars:!showhideData.isDollars})
        }
        if(name=="%ttl"){
            setCheckBoxes({...showhideData , isTTL:!showhideData.isTTL})
        }
    }



    return (
        <Modal
            open={true}
            onClose={closePopup}
            centered={true}
            closeIcon
        >
            <Modal.Header>Gross Production Grand Total</Modal.Header>
            {gProductionState.levelOneModal&&<Modal.Content>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell  colSpan='1'> Months/years</Table.HeaderCell>
                            <Table.HeaderCell colSpan='1'> Practices</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>{moment(selectedMonths[selectedMonths.length-1] + '-01').format('MMM YYYY')}  thru  {moment(selectedMonths[0] + '-01').format('MMM YYYY')} </b></Table.Cell>
                            <Table.Cell width={3} content={practices&&practices.toString()} > {practices&&practices.toString()}</Table.Cell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        <Table.Row>
                        {renderWriteoff()}
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer>

                    </Table.Footer>
                </Table>
            </Modal.Content>}
            {gProductionState.levelTwoModal&&<Modal.Content>
                <div className="FreezeTable freezeHeight">
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell  colSpan='1'>
                                    Months/years <Checkbox  label={showhideData.isDollars?"Hide $" : "Show $"}  toggle className="ml20" checked={showhideData.isDollars} onChange={(e) => handleCheckBoxes(e,"dollars")} />
                                    <Checkbox  label={showhideData.isTTL ?"Hide %TTL" : "Show %TTL"} toggle  className="ml20" checked={showhideData.isTTL} onChange={(e) => handleCheckBoxes(e,"%ttl")}/>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><b>{moment(selectedMonths[selectedMonths.length-1] + '-01').format('MMM YYYY')}  thru  {moment(selectedMonths[0] + '-01').format('MMM YYYY')} </b></Table.Cell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                {renderProductionGT()}
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </Modal.Content>}

            <Modal.Actions>
                {gProductionState.levelTwoModal &&<> <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L1
                </Button>
                </>}
                {gProductionState.levelOneModal && <Button className="mr10" primary onClick={handleLevelChange}>
                    Details L2
                </Button> }
            <Button className="mr10" primary onClick={gProductionState.levelOneModal?()=>sendTotal('level1'):()=>sendTotal('level2',showhideData)} >
                      Send Email
                    </Button>
            </Modal.Actions>
        </Modal>
    );
}


export default GrandTotalPopup;
