export const API_URL: string = process.env.NODE_ENV === "development"
  ? "http://localhost:5006"
  : "https://apibill.cdp.dental"

export const claimsSearchKeys = ["practice",
    "doctor",
    "ptNum",
    "dob",
    "subscriberId",
    "dateSent"]
export interface IMenu{
    name: string,
    icon?: string,
    title: string,
    subMenus?: IMenu[],
    role?: string[]
}

export const menusArray: IMenu[] = [
    {
        name: "dashboard",
        icon: "dashboard",
        title: "DASHBOARD",
        subMenus: [],
        role: []
    },
    {
        name: "patientFinance", icon: "dollar", title: "PATIENT FINANCE", subMenus: [
            { name: "newflow", icon: "credit card", title: "CC TO RUN"},
            {
                name: "setup", title: "SETUP", icon: "wrench", subMenus: [
                    {name: "actions" , title: "SETUP ACTIONS"},
                    {name: "status", title: "SETUP STATUS"},
                    {name: "assignments", title: "SETUP ASSIGNMENTS"},
                    {name: "pplqa", title: "SETUP QA STATUS"},
                    {name: "xcharge", title: "SETUP XCHARGE"}
                ]
            },
            {
                name: "reports", title: "REPORTS", icon: "file alternate", subMenus: [
                    {
                        name: "dailypayments",
                        title: "DAILY PAYMENTS REPORT"
                    },
                ]
            },
            { name: "collectionReports", title: "COLLECTION REPORTS", icon: "file alternate outline"},
            { name: "qaissues", title: "QA ISSUES", icon: "info circle"},

        ]
    },
    {
        name: "collections", icon: "archive", title: "COLLECTIONS", subMenus: [
            {icon: 'file alternate outline', name: "production_collection", title: "PRODUCTION v COLLECTION"},
            {icon: 'file alternate outline', name: "dataverification", title: "DATA VERIFICATION"}
        ]
    },
    {name: "claims", title: "CLAIMS", subMenus: [], role: []},
    {name: "claims-aged-summary", icon: "building outline", title: "Claims Aged Summary", subMenus: [], role: []},
    {name: "dentalxchange-status-report", icon: "suitcase", title: "DentalXchange Status Report", subMenus: [], role: []},
    {
        name: "usage",
        icon: "pie chart",
        title: "Usage Report",
        subMenus: [
            {icon: 'pie chart', name: "universal", title: "Usage"},
            {icon: 'pie chart', name: "employee", title: "Employee Usage"},
            {icon: 'pie chart', name: "non", title: "Non Employee Usage"}
        ]
    }
];

  export enum MCOTYPE{
    DENTA=1,
    MCNA=2,
    TMHP=3,
    UHC=4,
    PPO=5
 }
 
 export interface MCOACCOUNT {
     mcoType:MCOTYPE;
     identifier:string;
     groupIdentifier:string;
     accountsIdentifier:string;
     mcoName:string;
     filterOptions:Array<{key:string,value:string,text:string}>
 }
 
 
export const claimsTableHeaders: Array<string> = [
  "Practice",
  "Patient #",
  "DOB",
  "Subscriber ID",
  "Date Of Service",
  "Carrier",
  "Doctor",
  "Amount",
  "Paid Amount",
  "Fee Billed",
  "Claim Num",
  "Claim Type",
  "Date Sent",
  "Date Resent",
  "Status",
  "Notes",
  "Notes Timestamp",
  "Last Get Status",
];

export const claimsTableHeadersMap= {
    "Practice":"practice",
    "Patient #":"ptNum",
    "DOB":"dob",
    "Subscriber ID":"subscriberId",
    "Date Of Service":"dateOfService",
    "Carrier":"carrier",
    "Doctor":"doctor",
    "Amount":"amount",
    "Paid Amount":"paidAmount",
    "Fee Billed":"feeBilled",
    "Claim Type":"claimType",
    "Date Sent":"dateSent",
    "Date Resent":"dateResent",
    "Status Date" : 'statusDate',
    "Status":"status",
    "DentalXChange Status" : "emdeonStatus",
    "Notes":"Notes",
    "Notes Timestamp":"Notestimestamp",
    "Last Get Status":"getStatus",
}

 export const accounts:MCOACCOUNT[]=[
    { 
   mcoType:MCOTYPE.DENTA,
   identifier:"dentaMembers",
   groupIdentifier:"groupedDentaMembers",
   accountsIdentifier:"siteScrapperDentaAccountList",
   mcoName:"DENTA",
   filterOptions:[
    {
      key: "Accepted",
      text: "Accepted",
      value: "Accepted",
    },
    {
      key: "Adjudicated",
      text: "Adjudicated",
      value: "Adjudicated",
    },
    {
      key: "Finalized",
      text: "Finalized",
      value: "Finalized",
    },
    {
      key: "In Progress",
      text: "In Progress",
      value: "In Progress",
    },
    {
      key: "Not Found",
      text: "Not Found",
      value: "Not Found",
    },
    {
      key: "null",
      text: "Null",
      value: null,
    },
    {
      key: "Successfully Entered",
      text: "Successfully Entered",
      value: "Successfully Entered",
    },
   ]
   },
    {
   mcoType:MCOTYPE.MCNA,
   identifier:"mcnaMembers",
   groupIdentifier:"groupedMcnaMembers",
   accountsIdentifier:"siteScrapperMcnaAccountList",
   mcoName:"MCNA",
   filterOptions:[
       {
           key: "null",
           text: "Null",
           value: null,
       },
    {
      key: "Processed",
      text: "Processed",
      value: "Processed",
    },
    { key: "Pending", text: "Pending", value: "Pending" },
    { key: "Posted", text: "Posted", value: "Posted" },
    {
      key: "Invalid Response",
      text: "Invalid Response",
      value: "Invalid Response",
    },
    {
      key: "INVALID SUBSCRIBER ID",
      text: "INVALID SUBSCRIBER ID",
      value: "INVALID SUBSCRIBER ID",
    },
    {
      key: "NO CLAIMS WERE FOUND FOR THIS SEARCH",
      text: "NO CLAIMS WERE FOUND FOR THIS SEARCH",
      value: "NO CLAIMS WERE FOUND FOR THIS SEARCH",
    },
    { key: "Not Found", text: "Not Found", value: "Not Found" },
  ]
    },
    {
     
   mcoType:MCOTYPE.TMHP,
   identifier:"tmhpMembers",
   groupIdentifier:"groupedTmhpMembers",
   accountsIdentifier:"siteScrapperTmhpAccountList",
   mcoName:"TMHP",
   filterOptions:[
       {
           key: "null",
           text: "Null",
           value: null,
       },
    {
      key: "Denied",
      text: "Denied",
      value: "Denied",
    },
    {
      key: "Financial Adjustment",
      text: "Financial Adjustment",
      value: "Financial Adjustment",
    },

    {
      key: "Not Found",
      text: "Not Found",
      value: "Not Found",
    },
    {
      key: "Paid",
      text: "Paid",
      value: "Paid",
    },
    {
      key: "Suspended",
      text: "Suspended",
      value: "Suspended",
    },
  ]
    },
     {
         mcoType:MCOTYPE.UHC,
         identifier:"uhcMembers",
         groupIdentifier:"groupedUhcMembers",
         accountsIdentifier:"siteScrapperUhcAccountList",
         mcoName:"UHC",
         filterOptions:[
             {
                 key: "null",
                 text: "Null",
                 value: null,
             },
             {
                 key: "Received",
                 text: "Received",
                 value: "Received",
             },
             {
                 key: "In Process",
                 text: "In Process",
                 value: "In Process",
             },
             {
                 key: "Processed",
                 text: "Processed",
                 value: "Processed",
             },
             {
                 key: "Not Found",
                 text: "Not Found",
                 value: "Not Found",
             },
             {
                 key: "Submitted",
                 text: "Submitted",
                 value: "Submitted",
             }

         ]
     },
     {
         mcoType:MCOTYPE.PPO,
         identifier:"ppoMembers",
         groupIdentifier:"groupedPpoMembers",
         accountsIdentifier:"siteScrapperPpoAccountList",
         mcoName:"PPO",
         filterOptions:[
         ]
     },
    // {
       
  //  mcoType:MCOTYPE.DELTADENTAL,
  //  identifier:"deltaMembers",
  //  groupIdentifier:"groupedDeltaMembers",
  //  accountsIdentifier:"siteScrapperDeltaAccountList"
  //   }
 ]
 

// export const claimsStatusOptions = [
// { 'MCNA': [
//     {
//       key: "Processed",
//       text: "Processed",
//       value: "Processed",
//     },
//     { key: "Pending", text: "Pending", value: "Pending" },
//     { key: "Posted", text: "Posted", value: "Posted" },
//     {
//       key: "Invalid Response",
//       text: "Invalid Response",
//       value: "Invalid Response",
//     },
//     {
//       key: "INVALID SUBSCRIBER ID",
//       text: "INVALID SUBSCRIBER ID",
//       value: "INVALID SUBSCRIBER ID",
//     },
//     {
//       key: "NO CLAIMS WERE FOUND FOR THIS SEARCH",
//       text: "NO CLAIMS WERE FOUND FOR THIS SEARCH",
//       value: "NO CLAIMS WERE FOUND FOR THIS SEARCH",
//     },
//     { key: "Not Found", text: "Not Found", value: "Not Found" },
//   ],
// },
//   {'DENTA': [
//     {
//       key: "Accepted",
//       text: "Accepted",
//       value: "Accepted",
//     },
//     {
//       key: "Adjudicated",
//       text: "Adjudicated",
//       value: "Adjudicated",
//     },
//     {
//       key: "Finalized",
//       text: "Finalized",
//       value: "Finalized",
//     },
//     {
//       key: "In Process",
//       text: "In Process",
//       value: "In Process",
//     },
//     {
//       key: "Not Found",
//       text: "Not Found",
//       value: "Not Found",
//     },
//     {
//       key: "null",
//       text: "null",
//       value: "null",
//     },
//     {
//       key: "Successfully Entered",
//       text: "Successfully Entered",
//       value: "Successfully Entered",
//     },
//   ],},
//  { 'TMHP': [
//     {
//       key: "Denied",
//       text: "Denied",
//       value: "Denied",
//     },
//     {
//       key: "Financial Adjustment",
//       text: "Financial Adjustment",
//       value: "Financial Adjustment",
//     },

//     {
//       key: "Not Found",
//       text: "Not Found",
//       value: "Not Found",
//     },
//     {
//       key: "Paid",
//       text: "Paid",
//       value: "Paid",
//     },
//     {
//       key: "Suspended",
//       text: "Suspended",
//       value: "Suspended",
//     },
//   ],}
// ];
export const brands = [
    {text: 'SMD', value: 'SMD', key: 'SMD'},
    {text: 'FSD', value: 'FSD', key: 'FSD'},
    {text:'OTD',value:'OTD',key:'OTD'},
    {text:'SDD',value:'SDD',key:'SDD'},
    {text:'3CD',value:'3CD',key:'3CD'}
];

export const emdeonStatus = [
    {text: 'Blank', value: 'Blank', key: 'Blank'},
    {text: 'Duplicate Claim', value: 'Duplicate Claim', key: 'Duplicate Claim'},
    {text: 'Not Found', value: 'Not Found', key: 'Not Found'},
    {text: 'Attachment(s) Needed', value: 'Attachment(s) Needed', key: 'Attachment(s) Needed'},
    {text: 'Processed', value: 'Processed', key: 'Processed'},
    {text: 'Received', value: 'Received', key: 'Received'},
    {text: 'Rejected', value: 'Rejected', key: 'Rejected'},
    {text: 'Suspended', value: 'Suspended', key: 'Suspended'},
    {text: 'Validation Errors', value: 'Validation Errors', key: 'Validation Errors'},
];

export const identifiers = [
    {text: 'Denta', value: 'dentaMembers', key: 'denta'},
    {text: 'MCNA', value: 'mcnaMembers', key: 'mcna'},
    {text:'TMHP',value:'tmhpMembers',key:'tmhp'},
    {text:'UHC',value:'uhcMembers',key:'uhc'},
    {text:'PPO',value:'ppoMembers',key:'ppo'}
];

export const carrierIdentifiers = {
    dentaMembers : 'DentaQuest',
    mcnaMembers : 'MCNA',
    tmhpMembers : 'TMHP',
    uhcMembers : 'UHC',
    ppoMembers : 'PPO'
}

export const udaModules = [
    {text: 'All', value: 'all', key: 0},
    {
        text: 'Patient Finance',
        value: 'patient finance',
        key: 1
    },
    {
        text: 'Collections',
        value: 'collections',
        key: 2
    },
    {
        text: 'Claims',
        value: 'claims',
        key: 3
    }
]
  
  export const drPracticeListOptions = [
    {text:'1 Dr', value:'1', key:'1'},
    {text:'2 Dr', value:'2', key:'2'},
    {text:'Denovo', value:'3', key:'3'}
  ];
  
  export const DifferPracticeNames = {
    montana: "epmontana",
    nolana: "mcallen",
    carrier: "grandprairie",
    laspalmas: "sanantonio",
    zaragosa: "epzaragosa"
  };
  
  export const PracticeInfoTimeArray = [
    { value:'0', text: "CLOSED" },
    { value:'21600000', text: " 6:00 AM"},
    { value:'22500000', text: " 6:15 AM"},
    { value:'23400000', text: " 6:30 AM"},
    { value:'24300000', text: " 6:45 AM"},
    { value:'25200000', text: " 7:00 AM"},
    { value:'26100000', text: " 7:15 AM"},
    { value:'27000000', text: " 7:30 AM"},
    { value:'27900000', text: " 7:45 AM"},
    { value:'28800000', text: " 8:00 AM"},
    { value:'29700000', text: " 8:15 AM"},
    { value:'30600000', text: " 8:30 AM"},
    { value:'31500000', text: " 8:45 AM"},
    { value:'32400000', text: " 9:00 AM"},
    { value:'33300000', text: " 9:15 AM"},
    { value:'34200000', text: " 9:30 AM"},
    { value:'35100000', text: " 9:45 AM"},
    { value:'36000000', text: " 10:00 AM"},
    { value:'36900000', text: " 10:15 AM"},
    { value:'37800000', text: " 10:30 AM"},
    { value:'38700000', text: " 10:45 AM"},
    { value:'39600000', text: " 11:00 AM"},
    { value:'40500000', text: " 11:15 AM"},
    { value:'41400000', text: " 11:30 AM"},
    { value:'42300000', text: " 11:45 AM"},
    { value:'43200000', text: " 12:00 PM"},
    { value:'44100000', text: " 12:15 PM"},
    { value:'45000000', text: " 12:30 PM"},
    { value:'45900000', text: " 12:45 PM"},
    { value:'46800000', text: " 1:00 PM"},
    { value:'47700000', text: " 1:15 PM"},
    { value:'48600000', text: " 1:30 PM"},
    { value:'49500000', text: " 1:45 PM"},
    { value:'50400000', text: " 2:00 PM"},
    { value:'51300000', text: " 2:15 PM"},
    { value:'52200000', text: " 2:30 PM"},
    { value:'53100000', text: " 2:45 PM"},
    { value:'54000000', text: " 3:00 PM"},
    { value:'54900000', text: " 3:15 PM"},
    { value:'55800000', text: " 3:30 PM"},
    { value:'56700000', text: " 3:45 PM"},
    { value:'57600000', text: " 4:00 PM"},
    { value:'58500000', text: " 4:15 PM"},
    { value:'59400000', text: " 4:30 PM"},
    { value:'60300000', text: " 4:45 PM"},
    { value:'61200000', text: " 5:00 PM"},
    { value:'62100000', text: " 5:15 PM"},
    { value:'63000000', text: " 5:30 PM"},
    { value:'63900000', text: " 5:45 PM"},
    { value:'64800000', text: " 6:00 PM"},
    { value:'65700000', text: " 6:15 PM"},
    { value:'66600000', text: " 6:30 PM"},
    { value:'67500000', text: " 6:45 PM"},
    { value:'68400000', text: " 7:00 PM"},
    { value:'69300000', text: " 7:15 PM"},
    { value:'70200000', text: " 7:30 PM"},
    { value:'71100000', text: " 7:45 PM"},
    { value:'72000000', text: " 8:00 PM"}
  ];
   export const TimezoneArray = [
     {value: 'america/chicago', text: 'america/chicago'},
     {value: 'america/denver', text: 'america/denver'},
     {value: 'america/phoenix', text: 'america/phoenix'},
     {value: 'america/los_angeles', text: 'america/los_angeles'},
     {value: 'america/anchorage', text: 'america/anchorage'},
     {value: 'america/new_york', text: 'america/new_york'}
   ]
  export const allowDeleteUserId = [ 163, 1710, 791, 1189, 1180 ];
  export const udrPermissions = [
    { text: "All", value: "All" , key: "All"},
    { text: "Admin", value: 1 , key: "Admin"},
    { text: "Dentist", value: 2, key: "Dentist" },
    { text: "Orthodontics", value: 3, key: "Orthodontics" },
    { text: "Oral Surgeon", value: 4, key: "Oral Surgeon" },
    { text: "Traveling Team", value: 5, key: "Traveling Team" },
    { text: "Credentialing", value: 6, key: "Credentialing" }
  ];
  export const loanTypeOptions = [
      {key:'all',value:'all',text: 'All'},
      {key:'PPCC$:YES',value:'PPCC$:YES',text:'Credit Card'},
      {key:'PPCC$:NO',value:'PPCC$:NO',text:'Cash'},
      {key:'PPL$INH:PAYSIM',value:'PPL$INH:PAYSIM',text:'Pay Simple'}

      ];
export  const patientFinanceRole = {"Patient Finance":'524288',"Patient Finance Manager":'1048576'}
  
  export const booleanOptionsArrays = [
    { value:'99' ,  text:'All'},
    { value:'0', text: " No"},
    { value:'1', text: " Yes"}
  ];
  
  export const dentistryOptions = [
    {key:"All", text:"All", value:"All"},
    {key:"SURGERY", text:"Surgery", value:"Surgery"},
    {key:"GENERAL", text:"General", value:"General"},
      {key:"PRACTICE", text:"Practice", value:"Practice"},
    {key:"ORTHO", text:"Ortho", value:"Ortho"}
  ];

  export const collectedOptions = [
    { value:'All', text: "All" , key:'All'},
    { value:'Not Collected', text: "Not Collected" , key:'Not Collected'},
    { value:'Collected zero', text: "Collected zero" , key:'Collected zero'},
    { value:'Collected not zero', text: "Collected not zero" , key:'Collected not zero'}
  ];

  export const defaultPage = [
    { value: "/dashboard",text: "DASHBOARD" },
    { value: "/patientfinance/newflow", text: "CC TO RUN" },
    { value: "/patientfinance/setupactions", text: "SETUP ACTIONS" },
    { value: "/patientfinance/setupstatus",  text: "SETUP STATUS" },
    { value: "/patientfinance/setupassignments", text: "SETUP ASSIGNMENT"},
    { value: "/patientfinance/setuppplqa", text: "SETUP QA STATUS"},
    { value: "/patientfinance/collectionreports", text: "COLLECTION REPORTS"},
    { value: "/patientfinance/qaissues", text: "QA ISSUES"},
    { value: "/collections/dataverification", text: "DATA VERIFICATION"},
    { value: "/collections/production_collection", text: "PRODUCTION COLLECTION"},
    { value: "/claims", text: "CLAIMS" },
    { value: "/claims-aged-summary", text: "CLAIMS-AGED-SUMMARY" },
    { value: "/usage", text: "USAGE REPORT" }
  ];

  export const brandNames = [
    {key:'SMD' , value:"Smile Magic", text:'Smile Magic'},
    {key:'FSD' , value:"Family Smiles", text:'Family Smiles'},
    {key:'OTD' , value:"OrthoDent", text:'OrthoDent'},
    {key:'3CD' , value:"3C Dental", text:'3C Dental'}
  ]


  export const HelpPages = {
    150: { name: "BILLING - PatientFinance > Credit Cards to Run" },
    151: { name: "BILLING - PatientFinance> Setup > Setup Actions" },
    152: { name: "BILLING - PatientFinance> Setup > Setup Status" },
    153: { name: "BILLING - PatientFinance> Setup > Setup Assignments" },
    154: { name: "BILLING - PatientFinance> Setup > Setup QA Status" },
    155: { name: "BILLING - PatientFinance> Setup > Setup Xcharge" },
    156: { name: "BILLING - PatientFinance> Reports > Daily Payments Report"},
    157: { name: "BILLING - PatientFinance > Collection Reports" },
    158: { name: "BILLING - PatientFinance> PayPlan QA Issues" },
    159: { name: "BILLING - Usage Report" },
    160: { name: "BILLING - Claims" },
    161: { name: "BILLING - Production v Collection" }
  };

  export enum HELP_PAGE{
    BILLING_CC_TO_RUN = 150,
    BILLING_SETUP_ACTIONS,
    BILLING_SETUP_STATUS,
    BILLING_SETUP_ASSINGNMENTS,
    BILLING_SETUP_QA_STATUS,
    BILLING_SETUP_XCHARGE,
    BILLING_DAILY_PAYMENTS_REPORT,
    BILLING_COLLECTION_REPORTS,
    BILLING_QA_ISSUES,
    BILLING_USAGE,
    BILLING_CLAIMS,
    BILLING_PRODUCTION_COLLECTION
  }

export const appName="billing.cdp.dental";
export const supportMailList = 'support@cdp.dental,feedback.tools@cdp.dental';
export const googleClientID = '534451231648-d2a526h66llkn1e781gv6fgjvofsph2c.apps.googleusercontent.com';
export const defautPracticeAbbr = 'SMDLVL';
export const daysFilter= [
    {key:'all' , value:0, text:'All'},
    {key:'3' , value:3, text:'> 3 Days'},
    {key:'30' , value:30, text:'> 30 Days'},
    {key:'60' , value:60, text:'> 60 Days'}
]

export const agingBucketsRange = [
        { min: 0, max: 30, label: "0-30 Days" },
        { min: 31, max: 60, label: "31-60 Days" },
        { min: 61, max: 90, label: "61-90 Days" },
        { min: 91, max: 180, label: "91-180 Days" },
]

export const mycdp_uri: string = process.env.NODE_ENV === "development" ? "http://localhost:3000/partners" : "https://my.cdp.dental/partners"

export const acceptFileTypes = {
  image: ".jpg, .jpeg, .png",
  doc: ".doc, .docx",
  pdf: ".pdf",
  other: ".jpg, .jpeg, .png, .pdf"
}

export const numberWithCommas = (x: any) => {
    return Number(isNaN(x) ? 0 : x).toLocaleString("en-US", {style: "currency", currency: "USD"});
}
export const DentalXchangePopUpTable={
    headers:["Practice","P/N","DOS","Subid","MCO","MCO Status","Dentalxchange status"],
    keys:["practice","ptNum","dateOfService","subscriberId","type","status","emdeonStatus"]
}
