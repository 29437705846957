import React, {useEffect,useState} from "react";
import { connect } from "react-redux";
import _ from 'lodash';
import "semantic-ui-css/semantic.min.css";
import {Grid, Dropdown, Table, Icon, Tab, Button, Dimmer, Loader, Popup, Input, Card} from "semantic-ui-react";
import Template from "./Template";
import * as setupActions from "../redux/actions/setup"
import * as newflowActions from "../redux/actions/newflow";
import * as ActionSetup from "../redux/actions/setupActions";
import { useActions } from "../redux/actions";
import MultipleDatePicker from "react-multiple-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {RootState} from "../model";
import moment from "moment";
import {MemberStatus} from "../model/claims";
import EditSetupModal from "./EditSetupModal";
import EditQAStatusNewFlowModal from "./EditQAStatusNewFlowModal";
import {collectedOptions, dentistryOptions, booleanOptionsArrays, loanTypeOptions ,HELP_PAGE} from "../constants"
import PayPlanModal from "./PayPlanModal";
import ViewNotesModal from "./ViewNotesModal";
import PatientNumberViewModal from "./PatientNumberViewModal";
import HelpMark from "./Help/helpMark";
import CountUpTimer from "./CountUpTimer";

interface UsageData {
   newflowdata:any,
    filters:any,
    userPaymentAmountData :any,
    practiceList:any,
    column:string,
    direction:any,
    storedata:any,
    selectedPrac:string,
    specialists:any[],
    defaultSpecialist:any,
    setupActionlist:any[],
    setupStatuslist:any[],
    actionOption:number,
    statusOption:number,    
    formData:any,
    QAStatusList:any,
    editForm:any,
    collectedValue:string,
    dentistry:string,
    isClosed:string,
    loanType:any[]
  }
  


const NewFlow:React.FC<any>=(props:UsageData)=>{
    const initialState: UsageData = {
        newflowdata :[],
        filters: {ChargeDate:[Date.now()] , searchPatNum:''},
        practiceList:[],
        userPaymentAmountData :[],
        column: null,
        direction: null,
        storedata:[],
        selectedPrac:null,
        specialists:[],
        defaultSpecialist:null,
        setupActionlist:[],
        setupStatuslist:[],
        actionOption:null,
        statusOption:null,
        formData:null,
        QAStatusList:[],
        editForm:false,
        collectedValue:null,
        dentistry:null,
        isClosed:null,
        loanType:[]
      };
     
    const newflowAction = useActions(newflowActions);
    const setupAction = useActions(setupActions);
    const actionSetup = useActions(ActionSetup);
    const [state, setStates] = useState(initialState);
    const [loader, setLoader] = useState(false);
    const [CanQAStatus,setCanQAStatus]= useState(false)
    const [CurrentNewflowdataItem,setCurrentNewflowdataItem]= useState({})
    const [payPlan, setPayPlan] = useState(false);
    const [payPlanData, setPayPlanData] = useState({ytd:0,mtd:0,wtd:0,today:0});
    const [lastRefresh, setLastRefresh] = useState("");
    const [dates, setDates] = useState<Date[]|null>([new Date()]);
    const [viewmoreColumns , setViewColumns] = useState(false)
    const [payplans, setPayPlans] = useState([]);
    const [payPlanLoader,setPayPlanLoader] = useState(false)
    const [viewPatientPlans, setViewPatientPlans ] = useState(false);
    const [viewNotes, setViewNotes ] = useState(false);
    const [patientData , setPatientData] = useState({});
    const [statusIcons,setStatusIcons] = useState(React.createRef);
    useEffect(() => {
        setTimeout(function () {
            fetchData();
            refreshPayPlan();

        },1000)


    }, [])

    useEffect(() => {
        actionSetup.getSetupData('setup_action').then((data:any )=>{
            data && data.length>0 && data.map((item)=>{
                state.setupActionlist.push({
                  key:item.id,
                  value:item.id,
                  text:item.title
              })
            });
          
         })

         actionSetup.getSetupData('setup_status').then((data:any )=>{
            data && data.length>0 && data.map((item)=>{
                state.setupStatuslist.push({
                  key:item.id,
                  value:item.id,
                  text:item.title
              })
            });
           })
    }, [])

const refreshPayPlan=()=>{
    setLastRefresh(moment().format('YYYY-MM-DD hh:mm:ss'))  ;
    newflowAction.getPayPlanData().then((payPlan)=>{
        setPayPlanData(payPlan)
    })
}
const hidePayPlanModal=()=>{
    setPayPlan(false)
}
    const fetchData = async () =>{
        setLoader(true)
        newflowAction.getNewFlowData(dates)
            .then(({data,pracList} )=>{
                let practices = [{key:'All' , value:'All' , text:'All'}]               
                pracList && pracList.length > 0 && pracList.map((practice: any) => {
                    practices.push({
                    key: practice,
                    value: practice,
                    text: practice
                  });
              });
                setStates({...state, newflowdata:data,storedata:data,practiceList:practices});
                setLoader(false)
            });

            setupAction.getSetupData().then((data:any )=>{
                state.specialists.push({key:0 , value:'Select specialist' , text:'Select specialist'});
                data && data.length>0 && data.map((item)=>{
                    state.specialists.push({
                      key:item.id,
                      value:item.odUserName,
                      text:item.odUserName
                  })
                });
               });
        setupAction.getSetupQAList().then((data:any )=>{
            data && data.length>0 && data.map((item)=>{
                state.QAStatusList.push({
                    key:item.id,
                    value:item.QAStatus,
                    text:item.QAStatus
                })
            });

        });


    }

    const hideSetupModal = () => {
        setCanQAStatus(false)
    }
    const hidePatientPlansModal = () => {
        setViewPatientPlans(false)
    }
    const closeSetupModal = () => {
        setCanQAStatus(false)
    }
    const closePatientPlansModal = () => {
        setViewPatientPlans(false)
    }
    const openSetupModal = (currentItem: MemberStatus,id,index) => {
        const newflowdataEdit = state.newflowdata.map(item=>{
            if(item.id=== id){
                return item
            }
        })
        setStates({...state, formData: newflowdataEdit[index]})
        setCurrentNewflowdataItem(currentItem)
        setCanQAStatus(true)

    }
    const openPatientNumberModal = (currentItem: MemberStatus,id,index,PatNum) => {
        setPayPlanLoader(true)
        newflowAction.getAllPatientPlan(PatNum).then(data=>{
            setPayPlans(data)
            setPayPlanLoader(false)
        })

        const newflowdataEdit = state.newflowdata.map(item=>{
            if(item.id=== id){
                return item
            }
        })

        setStates({...state, formData: newflowdataEdit[index]})
        setCurrentNewflowdataItem(currentItem)
        setViewPatientPlans(true)

    }

    const openViewNotes = (notelong,PatNum)=>{
      
      setPatientData({notes:notelong , PatNum:PatNum})
      setViewNotes(true);
    }

    const closeViewNotes = () => {
        setViewNotes(false);
    }

   const handleSort = (clickedColumn: any) => () => {
        const { column, newflowdata, direction } = state;

        if(column!==clickedColumn && (clickedColumn== 'ChargeAmt' || clickedColumn== 'Current_Balance' || clickedColumn== 'PP_Balance' || clickedColumn=='SplitAmt')){
           let srtData;
           clickedColumn== 'ChargeAmt'?srtData =newflowdata.sort((a, b) => (parseInt((a[clickedColumn]).replace('$','')) - parseInt((b[clickedColumn]).replace('$',''))))
           :srtData =newflowdata.sort((a, b) => (a[clickedColumn]) - (b[clickedColumn]))
            setStates({...state,
                column: clickedColumn,
                newflowdata: srtData,
                direction: 'ascending',
              });

              return
        }
    
        if (column !== clickedColumn) {
          setStates({...state,
            column: clickedColumn,
            newflowdata: _.sortBy(newflowdata, [clickedColumn]),
            direction: 'ascending',
          });

          return
        }
    
        setStates({...state,
            newflowdata: newflowdata.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
      };

    const getTableRows =  ( )=>{
        const listRows = [];
            state.newflowdata && state.newflowdata.length>0 && state.newflowdata.map((data,index)=>{
                const selAction = 'action'+index;
                const selStatus = 'status'+index;
                listRows.push(
                    <Table.Row key={index}>

                        <Table.Cell>
                        <Dropdown  placeholder='Status Filter' selection options={state.setupStatuslist}
                         onChange={(e,{value})=> handleStatusFilter(e ,{value}, data,selStatus)}
                          value={data.statusId?data.statusId:null}/>
                        </Table.Cell>
                        <Table.Cell>
                        <Dropdown placeholder='Action Filter' selection options={state.setupActionlist}
                         onChange={(e,{value})=>handleActionFilter(e,{value} ,data,selAction)}
                          value={data.actionsId?data.actionsId:(state[selAction]?state[selAction]:null)}/>
                        </Table.Cell>
                        <Table.Cell >
                            {<span onClick={() => {openSetupModal(data,data.id,index)}}>{(data.QAStatus.includes("COMPLETED"))?<Icon  name="check circle" color="green"/>:(data.notelong.includes('PPQA$: '))?<Icon  name="cancel" color="red"/>:!(data.notelong.includes('PPQA$:'))?<Icon  name="cancel" color="red"/>:<Icon  name="check circle" color="green"/>}</span>}
                        </Table.Cell>
                        <Table.Cell>
                            {data.Practice}
                        </Table.Cell>
                        <Table.Cell>
                            <span onClick={() => {openPatientNumberModal(data,data.id,index,data.PatNum)}}><a>{data.PatNum}</a></span>
                        </Table.Cell>
                        <Table.Cell>
                            {data.ChargeDate}
                        </Table.Cell>
                        <Table.Cell>
                            {data.ChargeAmt}
                        </Table.Cell>
                        <Table.Cell>
                            {'$'+ data.Current_Balance.toFixed(2)}
                        </Table.Cell>
                        <Table.Cell>
                            {'$'+ data.PP_Balance.toFixed(2)}
                        </Table.Cell>
                        <Table.Cell>
                            {data.UserName }

                        </Table.Cell>
                        <Table.Cell>
                            {data.defaultUser }

                        </Table.Cell>
                        <Table.Cell>
                            { typeof(data.SplitAmt)=="number"? '$'+_.round(data.SplitAmt,2):data.SplitAmt}

                        </Table.Cell>
                        <Table.Cell>
                             <span onClick={()=>openViewNotes(data.notelong ,data.PatNum)} className='cursorPointer'><a>view</a></span>
                        </Table.Cell>
                    {  viewmoreColumns && <Table.Cell> {data.PayPlanNum}</Table.Cell>}
                    {  viewmoreColumns &&  <Table.Cell> {data.PP_Status}</Table.Cell>}
                    {  viewmoreColumns &&   <Table.Cell> {data.Dentistry}</Table.Cell>}
                    {  viewmoreColumns &&   <Table.Cell> {data.notelong.includes('PPL$INH:PAYSIM')?'Pay Simple':data.notelong.includes('PPCC$:NO')?'Cash':data.notelong.includes('PPCC$:YES')?'Credit Card':''}</Table.Cell>}
                    {  viewmoreColumns &&  <Table.Cell>{data.IsClosed}</Table.Cell>}
     
                    </Table.Row>
                )

        });

        return listRows;
    }
    const handleDateChange = async (date: any) => {
        const filters = state.filters;
         setDates(date)
        filters['searchPatNum']='';
        setStates({...state, filters: filters });
        setLoader(true);
        const asyncSubmit = async () =>{
            newflowAction.getNewFlowData(date).then(({data,pracList})=>{
                let practices = [{key:'All' , value:'All' , text:'All'}]
                pracList && pracList.length > 0 && pracList.map((practice: any) => {
                    practices.push({
                        key: practice,
                        value: practice,
                        text: practice
                    });
                });
                setStates({...state, newflowdata:data,actionOption:null,statusOption:null,
                    storedata:data,selectedPrac:null , defaultSpecialist:null , collectedValue:null,practiceList:practices})
                setLoader(false);

            });
        }
        asyncSubmit().then();
    };
    const submitQAStatusIssueData = (formInputFeilds) =>{
        setStates({...state,editForm:false})
        const payload = {

            QAStatus: formInputFeilds.QAStatus,
            Category:formInputFeilds.Category,
            ChargeAmt:formInputFeilds.ChargeAmt ,
            ChargeDate:formInputFeilds.ChargeDate,
            DatePay:formInputFeilds.DatePay,
            DateStart:formInputFeilds.DateStart,
            DateStop: formInputFeilds.DateStop,
            Dentistry:formInputFeilds.Dentistry,
            Interest:formInputFeilds.Interest,
            IsClosed:formInputFeilds.IsClosed,
            PP_Balance:formInputFeilds.PP_Balance,
            PP_Status:formInputFeilds.PP_Status,
            PP_line_note:formInputFeilds.PP_line_note,
            PatNum:formInputFeilds.PatNum,
            PayNote:formInputFeilds.PayNote,
            PayPlanNum:formInputFeilds.PayPlanNum,
            Practice:formInputFeilds.Practice,
            Principal:formInputFeilds.Principal,
            SplitAmt:formInputFeilds.SplitAmt,
            UserName:formInputFeilds.UserName,
            notelong:formInputFeilds.notelong,

        }
        const AsyncSubmit = async () => {

            setupAction.SubmitQAStatusIssueData(payload)
            setCanQAStatus(false)
        }
        AsyncSubmit()
    }

    const handleSearch = (e:any, storedata=null)=>{
        let returnFlag = true;
        if(!storedata) {
            storedata = state.storedata;
            returnFlag = false;
        }
        let result = _.filter(storedata, function (item) {
            return (item.PatNum && item.PatNum.toString().indexOf(e.target.value) > -1) 

        });

        if(returnFlag) {
          return result;
        }

        if(state.selectedPrac){
            result = onchangePractice({},{value:state.selectedPrac}, result)
        }  

        if(state.defaultSpecialist){
            result = handleSpecialistChange({},{value:state.defaultSpecialist}, result)
        }

        if(state.actionOption){
            result = onchangeAction({},{value:state.actionOption},result);
        }

        if(state.statusOption){
            result = onchangeStatus({},{value:state.statusOption},result);
        }
        if(state.collectedValue){
            result = handleCollectedFilter({},{value:state.collectedValue}, result)
        }

        if(state.isClosed){
            result = onchangeIsClosed({},{value:state.isClosed} , result);
        }
    
        if(state.dentistry){
            result = onchangeDentistry({},{value:state.dentistry},result);
        }
        if(state.loanType){
            result = onchangeLoanType({},{value:state.loanType},result);
        }

        const filters = state.filters;
        filters['searchPatNum'] = e.target.value;
        setStates({...state , newflowdata:result , filters:filters})
    }

    const onchangePractice = (e, {value}, storedata=null) => {
        let returnFlag = true;
        if(!storedata) {
          storedata = state.storedata;
          returnFlag = false;
        }

        let result = [];
        if (value && value!='All') {
            result = _.filter(storedata, function (item) {
                return value.indexOf(item.Practice) > -1
            });
        } else {
            result = storedata;
        }

        if(returnFlag) {
          return result;
        }


        if(state.filters.searchPatNum){
            result = handleSearch({target:{value:state.filters.searchPatNum}}, result)
        }  
        
        if(state.defaultSpecialist && state.defaultSpecialist!='Select specialist'){
            result = handleSpecialistChange({},{value:state.defaultSpecialist}, result)
        } 

        if(state.actionOption){
            result = onchangeAction({},{value:state.actionOption},result);
        }

        if(state.statusOption){
            result = onchangeStatus({},{value:state.statusOption},result);
        }
        if(state.collectedValue){
            result = handleCollectedFilter({},{value:state.collectedValue}, result)
        }
        if(state.isClosed){
            result = onchangeIsClosed({},{value:state.isClosed} , result);
        }
    
        if(state.dentistry){
            result = onchangeDentistry({},{value:state.dentistry},result);
        }
        if(state.loanType){
            result = onchangeLoanType({},{value:state.loanType},result);
        }

        setStates({...state , newflowdata:result , selectedPrac:value})

    };

    const  handleSpecialistChange = (e,{value},storedata=null)=>{
        let returnFlag = true;
        if(!storedata) {
          storedata = state.storedata;
          returnFlag = false;
        }

        let result = [];
        if (value&&value!='Select specialist') {
            result = _.filter(storedata, function (item) {
                return (item.defaultUser.indexOf(value) !== -1);
            });
        } else {
            result = storedata;
        }

        if(returnFlag) {
          return result;
        }


        if(state.filters.searchPatNum){
            result = handleSearch({target:{value:state.filters.searchPatNum}}, result)
        }  
        
        if(state.selectedPrac &&state.selectedPrac!='All'){
            result = onchangePractice({},{value:state.selectedPrac}, result)
        }

        if(state.actionOption){
            result = onchangeAction({},{value:state.actionOption},result);
        }

        if(state.statusOption){
            result = onchangeStatus({},{value:state.statusOption},result);
        }

        if(state.collectedValue){
            result = handleCollectedFilter({},{value:state.collectedValue}, result)
        } 

        if(state.isClosed){
            result = onchangeIsClosed({},{value:state.isClosed} , result);
        }
    
        if(state.dentistry){
            result = onchangeDentistry({},{value:state.dentistry},result);
        }
        if(state.loanType){
            result = onchangeLoanType({},{value:state.loanType},result);
        }

        setStates({...state , newflowdata:result , defaultSpecialist:value})
    }

    const onchangeAction = (e, {value}, storedata=null) => {
        let returnFlag = true;
        if(!storedata) {
          storedata = state.storedata;
          returnFlag = false;
        }

        let result = [];
        if (value) {
            result = _.filter(storedata, function (item) {
                return item.actionsId ===value;
            });
        } else {
            result = storedata;
        }

        if(returnFlag) {
          return result;
        }


        if(state.filters.searchPatNum){
            result = handleSearch({target:{value:state.filters.searchPatNum}}, result)
        }  
        
        if(state.selectedPrac &&state.selectedPrac!='All'){
            result = onchangePractice({},{value:state.selectedPrac}, result);
        } 

        if(state.defaultSpecialist){
              result = handleSpecialistChange({},{value:state.defaultSpecialist},result) ;
        }

        if(state.collectedValue){
            result = handleCollectedFilter({},{value:state.collectedValue}, result)
        } 

        if(state.statusOption){
            result = onchangeStatus({},{value:state.statusOption},result);
        }

        if(state.isClosed){
            result = onchangeIsClosed({},{value:state.isClosed} , result);
        }
    
        if(state.dentistry){
            result = onchangeDentistry({},{value:state.dentistry},result);
        }
        if(state.loanType){
            result = onchangeLoanType({},{value:state.loanType},result);
        }

        setStates({...state , newflowdata:result , actionOption:value})
    }

    const onchangeStatus = (e, {value}, storedata=null) => {
        let returnFlag = true;
        if(!storedata) {
          storedata = state.storedata;
          returnFlag = false;
        }

        let result = [];
        if (value) {
            result = _.filter(storedata, function (item) {
                return item.statusId ===value;
            });
        } else {
            result = storedata;
        }

        if(returnFlag) {
          return result;
        }


        if(state.filters.searchPatNum){
            result = handleSearch({target:{value:state.filters.searchPatNum}}, result)
        }  
        
        if(state.selectedPrac &&state.selectedPrac!='All'){
            result = onchangePractice({},{value:state.selectedPrac}, result);
        } 

        if(state.defaultSpecialist){
              result = handleSpecialistChange({},{value:state.defaultSpecialist},result);
        }

        if(state.collectedValue){
            result = handleCollectedFilter({},{value:state.collectedValue}, result)
        } 

        if(state.actionOption){
            result = onchangeAction({},{value:state.actionOption},result);
        }

        if(state.isClosed){
            result = onchangeIsClosed({},{value:state.isClosed} , result);
        }
    
        if(state.dentistry){
            result = onchangeDentistry({},{value:state.dentistry},result);
        }
        if(state.loanType){
            result = onchangeLoanType({},{value:state.loanType},result);
        }

        setStates({...state , newflowdata:result , statusOption:value})
    }

    const handleActionFilter = (e,{value},dataobj,selAction) => {
      let obj={actionsId:value}
       if(!dataobj.PatPractice){
            obj['PatPractice']=dataobj.Practice;
            obj['PatNum']=dataobj.PatNum;           

       }
       else{
            obj['id'] = dataobj.id;                      
       }

       newflowAction.addStatusAction(obj).then(result=>{
           setStates({...state, [selAction]:value})
        });

   }

   const handleCollectedFilter =(e,{value},storedata=null)=>{
    let returnFlag = true;
    if(!storedata) {
      storedata = state.storedata;
      returnFlag = false;
    }
    
    let result = [];
    if (value==="Collected not zero") {
        result = _.filter(storedata, function (item) {
            return (parseInt(item.SplitAmt)!==0.00 || parseInt(item.SplitAmt)!==0) && item.SplitAmt!="Not Collected";
        });
    } 
    else if(value==="Collected zero"){
        result = _.filter(storedata, function (item) {
            return parseInt(item.SplitAmt)===0.00 || parseInt(item.SplitAmt)===0
        });
    }
    else if(value=="Not Collected"){
        result = _.filter(storedata, function (item) {
            return item.SplitAmt==="Not Collected";
        });
    }
    else {
        result = storedata;
    }

    if(returnFlag) {
      return result;
    }


    if(state.filters.searchPatNum){
        result = handleSearch({target:{value:state.filters.searchPatNum}}, result)
    }  
    
    if(state.selectedPrac &&state.selectedPrac!='All'){
        result = onchangePractice({},{value:state.selectedPrac}, result)
    } 

    if(state.defaultSpecialist && state.defaultSpecialist!='Select specialist'){
        result = handleSpecialistChange({},{value:state.defaultSpecialist}, result)
    }

    if(state.actionOption){
        result = onchangeAction({},{value:state.actionOption},result);
    }

    if(state.statusOption){
        result = onchangeStatus({},{value:state.statusOption},result);
    }

    if(state.isClosed){
        result = onchangeIsClosed({},{value:state.isClosed} , result);
    }

    if(state.dentistry){
        result = onchangeDentistry({},{value:state.dentistry},result);
    }
       if(state.loanType){
           result = onchangeLoanType({},{value:state.loanType},result);
       }

    setStates({...state , newflowdata:result , collectedValue:value})

   }

   const handleStatusFilter = (e,{value},dataobj,selStatus) => {
    let obj={statusId:value}
    if(!dataobj.PatPractice){
         obj['PatPractice']=dataobj.Practice;
         obj['PatNum']=dataobj.PatNum;           

    }
    else{
         obj['id'] = dataobj.id;                      
    }
 
    newflowAction.addStatusAction(obj).then(result=>{
         setStates({...state , [selStatus]:value})
 })
  }

  const onchangeDentistry = (e,{value},storedata=null)=>{
    let returnFlag = true;
    if(!storedata) {
      storedata = state.storedata;
      returnFlag = false;
    }

    let result = [];
    if (value[0]!=='All') {
        result = _.filter(storedata, function (item) {

            if(value){
                let dentistry = value.filter(function (item2) {
                    return item2 === item.Dentistry
                })
                return dentistry[0]
            }

        });
    } else {
        result = storedata;
    }
    if(value.indexOf('All')!== -1){
        result = storedata;
    }
    if(value.length == 0 ){
        result = storedata;
    }

    if(returnFlag) {
      return result;
    }


    if(state.filters.searchPatNum){
        result = handleSearch({target:{value:state.filters.searchPatNum}}, result)
    }  
    
    if(state.selectedPrac &&state.selectedPrac!='All'){
        result = onchangePractice({},{value:state.selectedPrac}, result);
    } 

    if(state.defaultSpecialist){
          result = handleSpecialistChange({},{value:state.defaultSpecialist},result) ;
    }

    if(state.collectedValue){
        result = handleCollectedFilter({},{value:state.collectedValue}, result)
    } 

    if(state.statusOption){
        result = onchangeStatus({},{value:state.statusOption},result);
    }

    if(state.actionOption){
        result = onchangeAction({},{value:state.actionOption},result);
    }

    if(state.isClosed){
        result = onchangeIsClosed({},{value:state.isClosed} , result);
    }
      if(state.loanType){
          result = onchangeLoanType({},{value:state.loanType},result);
      }


    setStates({...state , newflowdata:result , dentistry:value})
  }

    const onchangeLoanType = (e,{value},storedata=null) => {

        let returnFlag = true;
        if(!storedata) {
            storedata = state.storedata;
            returnFlag = false;
        }

        let result = [];
        if (value==="PPCC$:YES") {
            result = _.filter(storedata, function (item) {
                return item.notelong.includes('PPCC$:YES');
            });
        }
        else if(value==='PPCC$:NO') {
            result = _.filter(storedata, function (item) {
                return item.notelong.includes('PPCC$:NO');
            });
        }
        else if(value==="PPL$INH:PAYSIM") {
            result = _.filter(storedata, function (item) {
                return item.notelong.includes('PPL$INH:PAYSIM');
            });
        }
        else {
            result = storedata;
        }

        if(returnFlag) {
            return result;
        }
        if(state.filters.searchPatNum){
            result = handleSearch({target:{value:state.filters.searchPatNum}}, result)
        }

        if(state.selectedPrac &&state.selectedPrac!='All'){
            result = onchangePractice({},{value:state.selectedPrac}, result);
        }

        if(state.defaultSpecialist){
            result = handleSpecialistChange({},{value:state.defaultSpecialist},result) ;
        }

        if(state.collectedValue){
            result = handleCollectedFilter({},{value:state.collectedValue}, result)
        }

        if(state.statusOption){
            result = onchangeStatus({},{value:state.statusOption},result);
        }

        if(state.actionOption){
            result = onchangeAction({},{value:state.actionOption},result);
        }

        if(state.isClosed){
            result = onchangeIsClosed({},{value:state.isClosed} , result);
        }
        if(state.dentistry){
            result = onchangeDentistry({},{value:state.dentistry},result);
        }
        setStates({...state , newflowdata:result , loanType:value})
    }
  const onchangeIsClosed = (e,{value},storedata=null) => {
    let returnFlag = true;
    if(!storedata) {
      storedata = state.storedata;
      returnFlag = false;
    }

    let result = [];
    if (value!=='99') {
        result = _.filter(storedata, function (item) {
            return item.IsClosed ===parseInt(value);
        });
    } else {
        result = storedata;
    }

    if(returnFlag) {
      return result;
    }


    if(state.filters.searchPatNum){
        result = handleSearch({target:{value:state.filters.searchPatNum}}, result)
    }  
    
    if(state.selectedPrac &&state.selectedPrac!='All'){
        result = onchangePractice({},{value:state.selectedPrac}, result);
    } 

    if(state.defaultSpecialist){
          result = handleSpecialistChange({},{value:state.defaultSpecialist},result) ;
    }

    if(state.collectedValue){
        result = handleCollectedFilter({},{value:state.collectedValue}, result)
    } 

    if(state.statusOption){
        result = onchangeStatus({},{value:state.statusOption},result);
    }

    if(state.actionOption){
        result = onchangeAction({},{value:state.actionOption},result);
    }

    if(state.dentistry){
        result = onchangeDentistry({},{value:state.dentistry},result);
    }
      if(state.loanType){
          result = onchangeLoanType({},{value:state.loanType},result);
      }


    setStates({...state , newflowdata:result , isClosed:value})
  }

    return (
        <Template activeLink="newflow" >
            
            {loader && <CountUpTimer></CountUpTimer>}
            <Card>
                <Card.Content>
                    <Grid>
                            <Grid.Row>
                                <Grid.Column >
                                    <h2 className="headingMain float-left" style={{position:'absolute'}}>CREDIT CARDS TO RUN ({state.newflowdata&&state.newflowdata.length})<HelpMark helpPage={HELP_PAGE.BILLING_CC_TO_RUN} application="Billing-Credits" /></h2>
                                    <div>
                                        <div className="datePicker mr10 nF1 mb7">
                                                <MultipleDatePicker
                                                className = 'newFlow datePicker'
                                                selected={dates[0]}
                                                onSubmit={dateObjects => handleDateChange(dateObjects)}
                                                />

                                        </div>                                   
                                        <Dropdown className='mr10 mb7' placeholder='Practice Filter' search selection multiple
                                                options={state.practiceList} onChange={onchangePractice} />
                                        <Dropdown  className='mr10 mb7' placeholder='Default Specialist' selection
                                                options={state.specialists} onChange={handleSpecialistChange} />
                                        <Dropdown  className='mr10 mb7' placeholder='Select Action' selection
                                                options={state.setupActionlist} onChange={onchangeAction} value={state.actionOption}/>
                                        <Dropdown  className='mr10 mb7' placeholder='Select Status' selection
                                                options={state.setupStatuslist} onChange={onchangeStatus} value={state.statusOption}/>
                                        <Dropdown  className='mr10 mb7' placeholder='Collected Filter' selection
                                                options={collectedOptions} onChange={handleCollectedFilter} value={state.collectedValue}/>
                                        <Dropdown  className='mr10 mb7' placeholder='Dentistry' selection multiple={true}
                                                options={dentistryOptions} onChange={onchangeDentistry} />
                                        <Dropdown  className='mr10 mb7' placeholder='IsClosed' selection
                                            options={booleanOptionsArrays} onChange={onchangeIsClosed}/>
                                        <Input className='mr10 mb7' onChange={(e)=>handleSearch(e)} placeholder="Search Patient Number" value={state.filters.searchPatNum||''}/>
                                        <Button className='mr10' primary onClick={()=>{setPayPlan(true)}}>Pay Plan</Button>
                                        <Dropdown  className='mr10 mb7' placeholder='Loan Type' selection
                                                    options={loanTypeOptions} onChange={onchangeLoanType}/>
                                                            
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                <div className="table-responsive FreezeTable">
                    <Table sortable striped size='small' unstackable className="customTable">
                            <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Action</Table.HeaderCell>
                                <Table.HeaderCell style={{ 'whiteSpace': 'unset' } } sorted={state.column === 'QAStatus' ? state.direction : null} onClick={handleSort('QAStatus')}>
                                    QA Status {state.column !== 'QAStatus' ? <Icon name="sort" /> : ''}
                                </Table.HeaderCell>
                                <Table.HeaderCell sorted={state.column === 'Practice' ? state.direction : null} onClick={handleSort('Practice')}> 
                                    Practice {state.column !== 'Practice' ? <Icon name="sort" /> : ''}
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ 'whiteSpace': 'unset' }}   sorted={state.column === 'PatNum' ? state.direction : null} onClick={handleSort('PatNum')}>
                                    Patient Number {state.column !== 'PatNum' ? <Icon name="sort" /> : ''}
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ 'whiteSpace': 'unset' }} sorted={state.column === 'ChargeDate' ? state.direction : null} onClick={handleSort('ChargeDate')}>
                                    Charge Date {state.column !== 'ChargeDate' ? <Icon name="sort" /> : ''}
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ 'whiteSpace': 'unset' }} sorted={state.column === 'ChargeAmt' ? state.direction : null} onClick={handleSort('ChargeAmt')}>
                                    Charge Amt {state.column !== 'ChargeAmt' ? <Icon name="sort" /> : ''}
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ 'whiteSpace': 'unset' }} sorted={state.column === 'Current_Balance' ? state.direction : null} onClick={handleSort('Current_Balance')}>
                                    Current Bal {state.column !== 'Current_Balance' ? <Icon name="sort" /> : ''}
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ 'whiteSpace': 'unset' }} sorted={state.column === 'PP_Balance' ? state.direction : null} onClick={handleSort('PP_Balance')}>
                                    Pay Plan Balance {state.column !== 'PP_Balance' ? <Icon name="sort" /> : ''}
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ 'whiteSpace': 'unset' }}>User Name</Table.HeaderCell>
                                <Table.HeaderCell style={{ 'whiteSpace': 'unset' }}>Default Specialist</Table.HeaderCell>
                                <Table.HeaderCell style={{ 'whiteSpace': 'unset' }} sorted={state.column === 'SplitAmt' ? state.direction : null} onClick={handleSort('SplitAmt')}>
                                    Collected Amount {state.column !== 'SplitAmt' ? <Icon name="sort" /> : ''}
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ 'whiteSpace': 'unset' }}>Notes</Table.HeaderCell>

                                {  viewmoreColumns &&   <Table.HeaderCell style={{ 'whiteSpace': 'unset' }} sorted={state.column === 'PayPlanNum' ? state.direction : null} onClick={handleSort('PayPlanNum')}>
                                    Pay Plan Number {state.column !== 'PayPlanNum' ? <Icon name="sort" /> : ''}
                                </Table.HeaderCell>}
                                {  viewmoreColumns && <Table.HeaderCell style={{ 'whiteSpace': 'unset' }} sorted={state.column === 'PP_Status' ? state.direction : null} onClick={handleSort('PP_Status')}>
                                    PP Status {state.column !== 'PP_Status' ? <Icon name="sort" /> : ''}
                                </Table.HeaderCell>}
                                {  viewmoreColumns && <Table.HeaderCell style={{ 'whiteSpace': 'unset' }} sorted={state.column === 'Dentistry' ? state.direction : null} onClick={handleSort('Dentistry')}>
                                    Dentistry {state.column !== 'Dentistry' ? <Icon name="sort" /> : ''}
                                </Table.HeaderCell>}
                                {viewmoreColumns&& <Table.HeaderCell sstyle={{ 'whiteSpace': 'unset' }}>Loan Type</Table.HeaderCell>}
                                {  viewmoreColumns && <Table.HeaderCell style={{ 'whiteSpace': 'unset' }}>Is Closed</Table.HeaderCell> }
                                <Table.HeaderCell style={{ 'whiteSpace': 'unset' }}>
                                <span style={{textDecoration:'underline'}} onClick={() => setViewColumns(!viewmoreColumns)}><a>{!viewmoreColumns?"View More":"View less"} </a> </span>
                                </Table.HeaderCell>
                            </Table.Row>

                            </Table.Header>
                            <Table.Body>
                                {loader && <Dimmer active={true}>

                                    <Loader>Loading</Loader>

                                </Dimmer>}



                            {
                                getTableRows()&& getTableRows().map(data=>{
                                    return data
                                })

                            }
                            </Table.Body>
                        </Table>

                    {CanQAStatus &&
                    <EditQAStatusNewFlowModal QAStatusList={state.QAStatusList} fields={state.formData}  hideSetupModal={hideSetupModal} closeSetupModal={closeSetupModal} submitQAStatusIssueData={submitQAStatusIssueData}/>}

                    {viewPatientPlans &&
                    <PatientNumberViewModal payplans={payplans} payPlanLoader={payPlanLoader} QAStatusList={state.QAStatusList} fields={state.formData}  hidePatientPlansModal={hidePatientPlansModal} closePatientPlansModal={closePatientPlansModal} submitQAStatusIssueData={submitQAStatusIssueData}/>}
                
                    {viewNotes && <ViewNotesModal patientData={patientData} closeViewNotes = {closeViewNotes}/>}
                    </div>
                {payPlan&&<PayPlanModal payPlanData={payPlanData} refreshPayPlan={refreshPayPlan} lastRefresh={lastRefresh} hidePayPlanModal={hidePayPlanModal}></PayPlanModal>}
                </Card.Content>
            </Card>

        </Template>
    )
}
function mapStateToProps(state: RootState) {
    return {
        userPaymentAmountData: state.rtp1.userPaymentAmountData,
        newflowdata: state.newflow.newflowdata,


    };
}

export default connect(mapStateToProps)(NewFlow);

