import {combineReducers} from "redux"
import * as authReducer from "./user";
import * as appReducer from "./app"
import * as alertReducer from "./alert"
import * as claimsReducer from "./claims"
import * as openIssuesReducer from "./openIssues";
import * as newflowReducer from "./newflow"
import * as usageReducer from "./usage"
import * as userPaymentReducer from "./rtp1"
import * as qaIssuesReducer from "./QAIssues"
import  * as payPlayReducer from "./payPlan"
import  * as setupReducer from "./setup"
import * as collectionsReducer from "./collections"



export default ()=>
   combineReducers({
       ...authReducer,
       ...appReducer,
       ...alertReducer,
       ...claimsReducer,
       ...newflowReducer,
       ...openIssuesReducer,
       ...usageReducer,
       ...userPaymentReducer,
       ...setupReducer,
       ...qaIssuesReducer,
       ...payPlayReducer,
       ...collectionsReducer

   })