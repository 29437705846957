import React, { useState,useEffect} from "react"
import GoogleLogin from "react-google-login"
import CookieModal from "./CookieModal"
import {useActions} from "../redux/actions";
import * as Actions from "../redux/actions/auth";
import {googleClientID} from "../constants"

const SignIn:React.FC<any>=(props)=>{
    const [cookieModal,setCookieModal] = useState(false)
    const authActions=useActions(Actions)
    useEffect(()=>{
      const queryParams :any= new URLSearchParams(window.location.search);
      const accessToken = queryParams.get("token");
      if (accessToken && accessToken.length > 0) {
           authActions.cdpLogin(accessToken);
      }
    },[authActions])

    const doLogin = async (data: any) => {
      await authActions.LogIn(data.profileObj);
  };
  const onFailure = (response: any) => new Promise((resolve, reject) => {
    if (response && response.error === 'idpiframe_initialization_failed') {
        setCookieModal(true);
    }
});
const closeCookieModal = () => {
  setCookieModal(false);
}
return (
<div className="login">
            <div className="contentCenter container-fluid">
                <div className="row">
                    <div className="col-12 text-center">
                      <div className="row">
                        <div className="col-12">
                          <img src="/images/logoLogin.png" className="align-self-center loginLogo" alt="Logo"/>
                          <h1 className="ui header inverted mt-0 mb-4">SIGN IN</h1>
                          <GoogleLogin
                          clientId={googleClientID}
                          buttonText="Sign in with Google"
                          className ="google"
                          onSuccess={doLogin}
                          onFailure={onFailure}
                      />
                    { cookieModal && <CookieModal handleClose={closeCookieModal}/>}
                        </div>
                      </div>

                    </div>
                </div>
            </div>
        </div>
  );
}
export default SignIn