import { App } from "../../model";
import { ActionType } from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const SidebarOpen = (flag: App) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		dispatch({ type: ActionType.SIDEBAR_OPEN, payload: { leftSidebarOpen: flag } });
	} catch (err) {
		return dispatch({ type: ActionType.SIDEBAR_OPEN, payload: { leftSidebarOpen: false } });
	}
};

export const Loader = (flag: App) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		dispatch({ type: ActionType.LOADER, payload: { loader: flag } });
	} catch (err) {
		return dispatch({ type: ActionType.LOADER, payload: { loader: false } });
	}
};

export const sendFeedback = (feedback : any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.post("report/sendFeedback", feedback);
		dispatch({ type: ActionType.SEND_FEEDBACK, payload: resp });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const saveUsage = (usage : any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const resp =  await api.post("report/saveUsage", usage);
		dispatch({ type: ActionType.SAVE_USAGE, payload: resp });
		return resp;
	} catch (err) {
		return dispatch(errorHandler(err));
	}
};

export const  FetchPracticeList  = () => async(dispatch:Function,getState:Function,api:any)=>{
	const url=`practice/practiceList`;
	try{
		const resp = await api.get(url);
		dispatch({
			type: ActionType.LOAD_PRACTICE_LIST, payload: { practiceList: resp },
		});
	}catch(error){
		dispatch({ type: ActionType.LOADER, payload: { practiceList: [] } });
		return dispatch(errorHandler(error));
	}
}

export const fetchRegions = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response:any = await api.get("practice/regions");
		dispatch({
			type: ActionType.LOAD_REGION_LIST, payload: { regions: response },
		})
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: { regions: [] } });
		return dispatch(errorHandler(err));
	}
};

