import {agingBucketsRange, carrierIdentifiers} from "./constants";
import moment from "moment/moment";

export const handleRange = (daysSinceSent: number) => {
    for (const range of agingBucketsRange) {
        if (daysSinceSent >= range.min && daysSinceSent <= range.max) {
            return range.label;
        }
    }

    return "180+ Days"; // If none of the ranges match
}

export const formatMinutes = (minutes) => {
    const oneDayInMinutes = 24 * 60;
    const oneHourInMinutes = 60;
  
    const days = Math.floor(minutes / oneDayInMinutes);
    const hours = Math.floor((minutes % oneDayInMinutes) / oneHourInMinutes);
    const remainingMinutes = (minutes % oneHourInMinutes).toFixed(0);
  
    let result = '';
  
    if (days > 0) {
      result += `${days} day${days === 1 ? '' : 's'}, `;
    }
  
    if (hours > 0) {
      result += `${hours} hour${hours === 1 ? '' : 's'}, `;
    }
    result += `${remainingMinutes} minute${remainingMinutes === '1' ? '' : 's'}`;
  
    return result;
  }

export const mapDownloadData = (memberStatus: any, allPracticeNames: any) => {
    const csvData =  memberStatus && memberStatus.map((row: any) => {
        const daysDiff = moment().diff(row.dateSent, 'days');
        return {
            practice: allPracticeNames ? allPracticeNames[row.practice] : "",
            ptNum : row.ptNum,
            subscriberId : row.subscriberId,
            carrier : carrierIdentifiers[row.type],
            carrierName : row.carrier,
            doctor: row.doctor,
            dateOfService: row.dateOfService,
            dateSent : row.dateSent,
            dateResent  : row.dateResent,
            feeBilled : row.feeBilled,
            amount : row.amount,
            paidAmount : row.paidAmount,
            claimType: row.claimType,
            status : row.status,
            emdeonStatus : row.emdeonStatus,
            daysSinceSent : daysDiff,
            agingBuckets : handleRange(daysDiff),
            statusDate : row.statusDate,
            getStatus : moment(row.getStatus).format('DD MMMM YYYY,h:mm:ss a'),
            version: row.version,
            createdAt: row.createdAt,
            updatedAt: row.updatedAt,
        }
    })
    return csvData.sort((a, b) => b.daysSinceSent - a.daysSinceSent);
}