import React,{ useState } from "react";
import {Modal, Table, Button, Checkbox} from "semantic-ui-react";
import moment from "moment/moment";

interface Props {
    data: { month?:string , productionData?:object ,payPlanPayData?:object};
    closePopup: () => void;
    sendData:Function;
}

const PayPlanPopUp: React.FC<Props> = ({ data ,closePopup,sendData }) => {

     const [practices] = useState(Object.keys(data&&data.payPlanPayData?data.payPlanPayData:[]));
     const [payPlanPayState,setPPlanPayState] = useState({
            payPlanPayData:data.payPlanPayData,
            productionData:data.productionData,
            selectedMonth:data.month,
            setLevelOne:true,
            setLevelTwo:false
        })
    const [ showHideData ,setCheckBoxes] = useState<any>({isDollars:true , isTTL:true ,isGP:true});

    const renderPayPlanPay= (monthSelected:string) => {
            const dataForMonth = {};
            for (const practice of practices) {
                if(payPlanPayState.payPlanPayData[practice][monthSelected]){
                    dataForMonth[practice] = payPlanPayState.payPlanPayData[practice][monthSelected];
                }
            }
            
            let payPlanPayByProcDate: any = {};
            Object.values(dataForMonth).forEach((value: any) => {
                if (value&&Object.keys(value).length > 0) {
                Object.entries(value).forEach(([code, data]: any) => {
                    if (!payPlanPayByProcDate[code] && code!=='total') {
                        payPlanPayByProcDate[code] = {
                            [code]:0
                        }
                    }
                    if(payPlanPayByProcDate[code] && code!=='total')
                        payPlanPayByProcDate[code][code] += data;
                    if(code=="total"){
                        if(!payPlanPayByProcDate["total"]) {                       
                          payPlanPayByProcDate[code] = {
                            total:0,
                          }
                        }
                         payPlanPayByProcDate[code]["total"]+=data
                   
                    }
                });
                
                }
            });
    
            //calculate total productiondata for month
    
            let sortedData = {total:{}}
            const keys = Object.keys(payPlanPayByProcDate);
            // Sort the keys based on absolute value of their corresponding values
            keys.sort((a, b) => {
                if (!payPlanPayByProcDate[a] || !payPlanPayByProcDate[b]) return 0; // check if properties exist
                return Math.abs(payPlanPayByProcDate[b][b]) - Math.abs(payPlanPayByProcDate[a][a])
            });
            // Add the sorted keys to the new object
            keys.forEach(key => {
                sortedData[key] = payPlanPayByProcDate[key];
            });
            const sortedEntries = Object.entries(sortedData).sort(([keyA], [keyB]) => {
                if (keyA === "total") {
                    return 1; // move total to the end
                } else if (keyB === "total") {
                    return -1; // move total to the end
                } else {
                    return 1 // sort alphabetically
                }
            });
            const sortedObject = Object.fromEntries(sortedEntries)
            // render the table
            return <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Proc Date</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {Object.entries(sortedObject).map(([procDate, data]: any) => <Table.Row key={procDate}>
                            <Table.Cell>{procDate}</Table.Cell>
                            <Table.Cell content={(data[procDate] || 0).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD"
                            })}/>
                        </Table.Row>)}


                </Table.Body>
            </Table>;
        }
    const renderL2PayPlanPay = (monthSelected:string) => {
        const dataForMonth = {};
        for (const practice of Object.keys(payPlanPayState.payPlanPayData)) {
            if(payPlanPayState.payPlanPayData[practice][monthSelected]){
                dataForMonth[practice] = payPlanPayState.payPlanPayData[practice][monthSelected];
            }
        }
        const pPlanPayByDate: object = {};
        practices&&practices.forEach((prac: string) => {
            if (dataForMonth[prac]&&Object.keys(dataForMonth[prac]).length > 0) {
                Object.keys(dataForMonth[prac]).forEach((code: string) => {
                    if (!pPlanPayByDate[code]) {
                        pPlanPayByDate[code] = { };
                        pPlanPayByDate[code][prac] = dataForMonth[prac];

                    }
                    else if(!pPlanPayByDate[code][prac] && dataForMonth[prac][code]){
                        pPlanPayByDate[code][prac] = dataForMonth[prac];
                    }
                });
            }
        });


        let  payPlanDateEntries={};

        // total productiondata for month
        const totalProductionData = Object.keys(payPlanPayState.productionData)&&payPlanPayState.productionData[monthSelected]?payPlanPayState.productionData[monthSelected].production:0;

        Object.entries(pPlanPayByDate).forEach(([code,data]: [string,object]) => {

            practices&&practices.forEach((pracz: string) => {
                if(data[pracz]){
                    if(!payPlanDateEntries[code]){
                        payPlanDateEntries[code] = {}
                        payPlanDateEntries[code]["cdeTotal"]=0;
                    }
                    payPlanDateEntries[code][pracz]={}
                    payPlanDateEntries[code][pracz][code] = data[pracz][code];
                    payPlanDateEntries[code][pracz]['percentageOfTotal'] = ((data[pracz][code] / data[pracz]['total']) * 100).toFixed(2)
                    payPlanDateEntries[code][pracz]['percentageOfGrossProduction']=((data[pracz][code]/totalProductionData)*100).toFixed(2);

                    payPlanDateEntries[code]['cdeTotal']+=Math.abs(Number(parseFloat(data[pracz][code]).toFixed(2)));
                }

            });

            payPlanDateEntries[code]["percentageofcdeGrossTotal"] = Number(((payPlanDateEntries[code]["cdeTotal"]/totalProductionData)*100).toFixed(2));
        });

        let sortedData = {total:{}}
        const keys = Object.keys(payPlanDateEntries);
        // Sort the keys based on absolute value of their corresponding values
        keys.sort((a, b) => {
            if (a==="total"||b ==="total") return 0; // check if properties exist
            return Date.parse(a)-Date.parse(b)
        });

        // Add the sorted keys to the new object
        keys.forEach(key => {
            sortedData[key] = payPlanDateEntries[key];
        });
        const sortedEntries = Object.entries(sortedData).sort(([keyA], [keyB]) => {
            if (keyA === "total") {
                return 1; // move total to the end
            } else if (keyB === "total") {
                return -1; // move total to the end
            } else {
                return 1 // sort alphabetically
            }
        });
        const sortedObjectEntries = Object.fromEntries(sortedEntries)

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell>{""}</Table.Cell>
                        {practices && practices.map((prac , idx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        key={idx} colspan="1">{prac} </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">Total</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Proc Date</Table.HeaderCell>
                        {practices && practices.map((practice , indx)=>
                            <Table.Cell style={{"border-bottom":"1px solid rgba(34 , 36,38,0.1)"}}
                                        colspan="1" key={indx}>
                                {showHideData.isDollars?"$":""}
                                {showHideData.isTTL ?" (%TTL)":""}
                                {showHideData.isGP ?"(%of GP)" : ""}
                            </Table.Cell>
                        )}
                        <Table.HeaderCell colspan="1">
                            {showHideData.isDollars?"$":""}
                            {showHideData.isTTL ?" (%TTL)":""}
                            {showHideData.isGP ?"(%of GP)" : ""}

                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedObjectEntries&&Object.entries(sortedObjectEntries).map(([code,dataf]:[string,object],indx:number) => (

                        <Table.Row key={indx}>
                            <Table.Cell key={indx}>{code}</Table.Cell>
                            {practices && practices.map((practize , index)=>
                                <Table.Cell colspan="1" key={indx-index}>
                                    {showHideData.isDollars?(Object.keys(dataf).indexOf(practize)>-1?dataf[practize][code]:0).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD"
                                    }):""} {showHideData.isTTL?"("+Math.abs(Object.keys(dataf).indexOf(practize)>-1?dataf[practize]["percentageOfTotal"]:0)+'%' +")" :""}
                                    {showHideData.isGP?"(" +Math.abs(Object.keys(dataf).indexOf(practize)>-1?dataf[practize]["percentageOfGrossProduction"]:0)+'%'+")":""}
                                </Table.Cell>
                            )}
                            <Table.Cell>{showHideData.isDollars?"$"+ dataf["cdeTotal"].toFixed(2):""}
                                {showHideData.isTTL?"("+((dataf["cdeTotal"]/sortedObjectEntries["total"]["cdeTotal"])*100).toFixed(2) + '%' +")":""}
                                {showHideData.isGP? "("+dataf["percentageofcdeGrossTotal"].toFixed(2) +'%' +")":""}
                            </Table.Cell>
                        </Table.Row>
                    ))}


                </Table.Body>
            </Table>
        );
    }
    const handleLevelChange =() => {
        setPPlanPayState({...payPlanPayState,setLevelOne:!payPlanPayState.setLevelOne , setLevelTwo:!payPlanPayState.setLevelTwo});
    }
    const handleCheckBoxes =( name:string) => {
        if(name=="dollars"){
            setCheckBoxes({...showHideData , isDollars:!showHideData.isDollars})
        }
        if(name=="%ttl"){
            setCheckBoxes({...showHideData , isTTL:!showHideData.isTTL})
        }
        if(name=="%gp"){
            setCheckBoxes({...showHideData , isGP:!showHideData.isGP})
        }

    }

    return <Modal
        open={true}
        onClose={closePopup}
        centered={true}
        closeIcon
    >
        <Modal.Header>Pay Plan Pay</Modal.Header>
        {payPlanPayState.setLevelOne && <Modal.Content>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan='1'> Months/years</Table.HeaderCell>
                        <Table.HeaderCell colSpan='1'> Practices</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><b>{moment(payPlanPayState.selectedMonth + '-01').format('MMM YYYY')}</b></Table.Cell>
                        <Table.Cell width={3}
                                    content={practices && practices.toString()}> {practices && practices.toString()}</Table.Cell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>

                    <Table.Row>
                        {renderPayPlanPay(payPlanPayState.selectedMonth)}
                    </Table.Row>
                </Table.Body>

                <Table.Footer>

                </Table.Footer>
            </Table>
        </Modal.Content>
        }
        {payPlanPayState.setLevelTwo&&<Modal.Content>
            <div className="FreezeTable freezeHeight">
                <Table celled width="100%">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell  colSpan='1'>
                                Months/years  <Checkbox  label={showHideData.isDollars?"Hide $" : "Show $"}  toggle className="ml20" checked={showHideData.isDollars} onChange={() => handleCheckBoxes("dollars")} />
                                <Checkbox  label={showHideData.isTTL ?"Hide %TTL" : "Show %TTL"} toggle  className="ml20" checked={showHideData.isTTL} onChange={() => handleCheckBoxes("%ttl")}/>
                                <Checkbox  label={showHideData.isGP ?"Hide %of GP" : "Show %of GP"} toggle className="ml20" checked={showHideData.isGP} onChange={() => handleCheckBoxes("%gp")}/>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>{moment(payPlanPayState.selectedMonth + '-01').format('MMM YYYY')}</b></Table.Cell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        <Table.Row>
                            {renderL2PayPlanPay(payPlanPayState.selectedMonth)}
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer>

                    </Table.Footer>
                </Table>
            </div>
        </Modal.Content>}
        <Modal.Actions>
            {payPlanPayState.setLevelTwo &&<> <Button className="mr10" primary onClick={handleLevelChange}>
                Details L1
            </Button>
                <Button className="mr10" primary disabled>
                    Details L3
                </Button>
            </>}
            {payPlanPayState.setLevelOne && <Button className="mr10" primary onClick={handleLevelChange}>
                Details L2
            </Button> }

        <Button className="mr10" primary onClick={payPlanPayState.setLevelOne?()=>sendData('level1'):()=>sendData('level2',showHideData)} >
                  Send Email
                </Button>
        </Modal.Actions>
    </Modal>
}

export default PayPlanPopUp;