import React from "react";
import Template from "../components/Template";
import SetupQA from "../components/SetupPPLQA";

function SetupPPLQA() {

    return (
        <Template activeLink='setup'>
            <SetupQA/>
        </Template>
    );

}

export default SetupPPLQA;